  <!-- <div class="users_header"> </div> -->
    <div class="profile_page">
        <div class="profile_info">
                <div class="personal_details">
                    <!-- <button class="edit-btn btn-outline btn-small secondary-outline" *ngIf="hideUserdetail" (click)="showHideDetails()">Edit</button> -->
                    <form [formGroup]="orgForm" (ngSubmit)="uploadOrganisationConnect(orgForm)">
                        <div class="personal_details_header">
                            <div class="profile_photo" *ngIf="imageSrc?.length===0">
                                <mat-icon aria-hidden="false" aria-label="Example person icon" fontIcon="person"
                                    (click)="openFilePicker()"></mat-icon>
                                <input type="file" class="hidden-file-input" #fileInput
                                    (change)="handleImageInput(fileInput?.files)">
                            </div>
                            <div class="profile_photo" *ngIf="imageSrc?.length">
                                <mat-icon [style.background-image]="'url(' + imageSrc + ')'" aria-hidden="false"
                                    (click)="openFilePicker()"></mat-icon>
                                <input type="file" class="hidden-file-input" #fileInput
                                    (change)="handleImageInput(fileInput?.files)">
                            </div>
                            <div>
                            </div>
                            <div *ngIf="hideUserdetail" class="profile_info ">
                                <p>Organization Name</p>
                                <p class="label_value">{{makeFirstLetterCapital(orgDetails.organization_name)}}</p>
                            </div>
                        </div>
                        <div *ngIf="hideUserdetail" class="pt_4 profile_details">
                                    <div class="profiletab-item">
                                        <div class="profiletab-itemlable">Parent Name</div>
                                        <div class="profiletab-itemvalue">{{makeFirstLetterCapital(orgDetails.parent_organization_name)}}</div>
                                    </div>  
                                    <!-- <p>Address</p>
                                    <P *ngIf="hideUserdetail" title=" ">{{orgDetails.organization_address}}</P> -->
                                    <div class="profiletab-item">
                                        <div class="profiletab-itemlable">Address</div>
                                        <div class="profiletab-itemvalue" *ngIf="hideUserdetail">{{orgDetails.organization_address}}</div>
                                    </div>
                                 
                                    
                                    <!-- <div *ngIf="!hideUserdetail">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Organization Address</mat-label>
                                            <input matInput placeholder="Organization Address"
                                                [(ngModel)]="orgDetails.organization_address"
                                                formControlName="organization_address">
                                            <mat-error
                                                *ngFor="let validation of validation_messages.organization_address">
                                                <mat-error class="error-message"
                                                    *ngIf="orgForm.get('organization_address')?.hasError(validation.type) && (orgForm.get(' organization_address')?.dirty || orgForm.get('organization_address')?.touched)">
                                                    {{validation.message}}</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                            </div> -->
                            <!-- <div class="mb_3 profile_basic">
                                <p class="label_object">Location & time zone</p>
                                <P title=" " *ngIf="hideUserdetail">1234{{orgDetails.organization_location}}</P>
                                <div *ngIf="!hideUserdetail">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Location & time zone</mat-label>
                                        <input matInput placeholder="Location & time zone"
                                            [(ngModel)]="orgDetails.organization_location"
                                            formControlName="organization_location">
                                        <mat-error *ngFor="let validation of validation_messages.organization_location">
                                            <mat-error class="error-message"
                                                *ngIf="orgForm.get('organization_location')?.hasError(validation.type) && (orgForm.get('organization_location')?.dirty || orgForm.get('organization_location')?.touched)">
                                                {{validation.message}}</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>  -->
                        </div>
                        <div class="pt_4 profile_details">
                            <div class="profiletab-item">
                                <div class="profiletab-itemlable">Jira Integration</div>
                                <div class="profiletab-itemvalue">Not Integrated 
                                    <button class="btn"><mat-icon>edit</mat-icon></button>
                                </div>
                            </div>
                        </div>
                        <!-- <div *ngIf="!hideUserdetail">
                            <mat-form-field appearance="outline" class="full-width mb_3">
                                <mat-label>Company Name</mat-label>
                                <input matInput formControlName="Company Name" tabindex="1">
                                <mat-error *ngFor="let validation of validation_messages.userName">
                                    <mat-error class="error-message"
                                        *ngIf="loginForm.get('userName')?.hasError(validation.type) && (loginForm.get('userName')?.dirty || loginForm.get('userName')?.touched)">
                                        {{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="full-width mb_3">
                                <mat-label>Parent Company Name</mat-label>
                                <input matInput formControlName="Parent Company Name" tabindex="1">
                                <mat-error *ngFor="let validation of validation_messages.userName">
                                    <mat-error class="error-message"
                                        *ngIf="loginForm.get('userName')?.hasError(validation.type) && (loginForm.get('userName')?.dirty || loginForm.get('userName')?.touched)">
                                        {{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Address</mat-label>
                                <input matInput placeholder="Address"
                                    [(ngModel)]="orgDetails.organization_address"
                                    formControlName="organization_address">
                                <mat-error
                                    *ngFor="let validation of validation_messages.organization_address">
                                    <mat-error class="error-message"
                                        *ngIf="orgForm.get('organization_address')?.hasError(validation.type) && (orgForm.get(' organization_address')?.dirty || orgForm.get('organization_address')?.touched)">
                                        {{validation.message}}</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div> -->
                     
                    </form>
                    <!-- <div *ngIf="!hideUserdetail" class="profile_buttons">
 
                        <button *ngIf="!hideUserdetail" class="btn btn-blue"
                        >Save</button>
                    <button *ngIf="!hideUserdetail" class="btn btn-white"
                        (click)="hideDetails()">Cancel</button>
                </div> -->
 
                    <div class="profile_buttons">
                        <span [ngClass]="{'disableMe': defaultSso === ssoData.sso}">
                            <button class="btn btn-blue" (click)="onSaveSSO()">Save</button>
                            <button class="btn btn-white" (click)="onCancelSSO()">Cancel</button>
                        </span>
                        <!-- <button *ngIf="!hideUserdetail" class="btn btn-blue"
                            (click)="uploadOrganisationConnect(orgForm)">Submit</button>
                        <button *ngIf="!hideUserdetail" class="btn btn-white" (click)="hideDetails()">Cancel</button> -->
                    </div>
                </div>
            </div>
            <div class="sso-info">
                <div class="sso-switch">
                    <label class="label_object">SSO</label>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle formControlName="sso_status" (change)="ssoToggle($event)"></mat-slide-toggle>
                    </div>
                </div>
                
            <div class="mb_3 profile_basic full-width" [ngClass]="{'disableMe': !ssoData.sso}">
                <mat-form-field appearance="outline" class="border-field no-bg">
                    <mat-label>Define SSO</mat-label>
                    <mat-select formControlName="sso_id" placeholder="Define SSO">
                        <mat-option *ngFor="let sso of ssoList"
                            [value]="sso.id">{{sso.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
            </div>
        </div>