export interface ICyberResilienceResponse {
    status?: number;
    message?: string;
    data?: ICyberResilience[]
}

export interface ICyberResilience {
    date_time: string;
    security_rating: number | any;
    device_count: number;
    new_event: boolean;
}
export interface ICyberDrop {
    id?: string;
    name?: string;
    label?:string;
    
}
export interface IEventData {
    eventid: string;
    event_devices: string;
    event_code: string;
    event_name: string;
    event_date: string;
    previous_score: number;
    current_score: number;
    score_difference: number;
    reference_id?: any;
}
export const cyberConstant = {
    custom_range: {
        label: 'Custom Date Range',
        id: 'custom_date_range',
        name:'Custom Date Range'
    }
}