import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../services/notification.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent implements OnInit {
  @Input() type: any;
  @Input() data: any;
  @Output() toastDismissed = new EventEmitter<void>();
  

  closeToast() {
    this.data = null; 
    this.toastDismissed.emit(); 
  }
  constructor(private sanitizer: DomSanitizer){
  }
ngOnInit(): void {
  const setTime:boolean = this.data.resp.shouldNotClose ? false : true; 
 if (this.data && setTime) {
    setTimeout(() => {
      this.closeToast(); 
    }, 5000);
  }
}
sanitizeHTML(htmlContent: string): SafeHtml {
  return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
}

isHTML(message: string): boolean {
  if (!message || typeof message !== 'string') {
    return false;
  }
  const doc = new DOMParser().parseFromString(message, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1); 
}
getFillColor(categoryType: string): string {
  if (categoryType.toLowerCase() === 'alert') {
    return '#F82323';
  } else if (categoryType.toLowerCase() === 'notification') {
    return '#290EA7';
  }
  return '#888994';
}
}
