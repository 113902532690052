import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Utils } from 'src/app/modules/shared/utils';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  getScoreStatus(score: number): string {
    return Utils.getRatingBadge(score);
  }
  public isSuccess(item: any): boolean {
    return item?.status === 3;
  }
  public isFailed(item: any): boolean {
    return item?.status === 4;
  }
  public isCanceled(item: any): boolean {
    return item?.status === 5;
  }

  getStatus(item: any): boolean {
    return item?.status < 3;
  }

 
  
}
