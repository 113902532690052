<div class="device-sec-icon ag-center paramer-value"
    *ngIf="params.value != null && !getStatus(params.data)">
       <div  *ngIf="params.value > 0" class="donut-vul" [appCustomTooltip]="customTooltip" [style.background]="gredientColor"></div>
       <div *ngIf="params.value === 0" class="donut-vul"></div>
    <p class="Vul" >{{params.value | numberFormat}}</p>
</div>
<ng-template #customTooltip>
    <div class="ag-vul-tooltip"> 
        <div class="ag-dialog">
            <div *ngIf="params && params.data && params.data.vulnerability_levels[0].critical">Critical: {{params.data.vulnerability_levels[0].critical}}</div>
            <div *ngIf="params && params.data && params.data.vulnerability_levels[0].high">High: {{params.data.vulnerability_levels[0].high}}</div>
            <div *ngIf="params && params.data && params.data.vulnerability_levels[0].medium">Medium: {{params.data.vulnerability_levels[0].medium}}</div>
            <div *ngIf="params && params.data && params.data.vulnerability_levels[0].low">Low: {{params.data.vulnerability_levels[0].low}}</div>
        </div>
    </div>
</ng-template>