import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ITreeView, IApply, IFilterFlags } from 'src/app/modules/shared/components/filter/filter.model';
import { DeviceService } from 'src/app/modules/shared/services/device.service';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { ScanService } from 'src/app/modules/shared/services/scan.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { IDeleteDeviceResponse, IDeviceList, IDeviceListResponse, IDeviceSaveResponse } from './device.model';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Utils } from 'src/app/modules/shared/utils';
import { DevicePopupComponent } from './device-popup/device-popup.component';
import { DeleteDevicePopupComponent } from './delete-device-popup/delete-device-popup.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { HeaderService } from 'src/app/modules/shared/services/header.service';
import { CONSTANT, ROLES_CONTENT_PROPS, ROLES_ROUTE, ToolTipConfig } from 'src/app/modules/shared/constants/constant-data';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { PasswordConfirmationPopupComponent } from 'src/app/modules/shared/components/password-confirmation-popup/password-confirmation-popup.component';
import { Subscription, debounceTime, distinctUntilChanged, filter, fromEvent, switchMap, throwError } from 'rxjs';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { ISortingFilterData, SortingFilterService } from 'src/app/modules/shared/services/sorting-filter.service';
import * as _ from 'lodash';
import { GlobalSearchService } from 'src/app/modules/shared/services/global-search.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { GlobalFilterService } from 'src/app/modules/shared/services/global-filter.service';
import { MyScansService } from 'src/app/modules/shared/services/my-scans.service';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { ProfileService } from 'src/app/modules/shared/services/profile.service';
import { IUserDetail } from 'src/app/modules/shared/model/profile/profile';
import { AssetSummaryDownloadComponent } from '../asset-summary-download/asset-summary-download.component';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ResponseService } from 'src/app/modules/shared/services/response.service';
import { ScheduleDialogComponent } from 'src/app/modules/shared/components/schedule-dialog/schedule-dialog.component';
import { ActiveScanScheduleComponent } from 'src/app/modules/schedule-scan/active-scan-schedule/active-scan-schedule.component';
import { ScheduleScanService } from 'src/app/modules/shared/services/schedule-scan.service';
import { DatePipe } from '@angular/common';
import 'ag-grid-community';

import { CredentialPopupComponent } from './credential-popup/credential-popup.component';
import { MapCredentialComponent } from './map-credential/map-credential.component';
import {
  GridReadyEvent,
  ColDef,
  CellClickedEvent,
  GridSizeChangedEvent
} from 'ag-grid-community';
import { TableLoaderComponent } from 'src/app/modules/shared/components/table-loader/table-loader.component';
import * as moment from 'moment';
import { ITooltipParams, SideBarDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { LastScanComponent } from './last-scan/last-scan.component';
import { ScoreComponent } from './score/score.component';
import { VulnerabilityValComponent } from './vulnerability-val/vulnerability-val.component';
import { DomSanitizer } from '@angular/platform-browser';
import { VulnerabilityTooltipComponent } from './vulnerability-tooltip/vulnerability-tooltip.component';
import { OptionsComponent } from './options/options.component';
import { AssetsNameComponent } from './assets-name/assets-name.component';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { AgGridComponent } from 'src/app/modules/shared/components/ag-grid/ag-grid.component';
import { CveAssetsService } from 'src/app/modules/shared/services/cve-assets.service';
import { CveCountComponent } from 'src/app/modules/dashboard/components/cve-count/cve-count.component';

@Component({
  selector: 'app-device-management',
  templateUrl: './device-management.component.html',
  styleUrls: ['./device-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class DeviceManagementComponent implements OnInit, OnDestroy {
  @ViewChild('deviceSidenav') deviceSidenav!: MatSidenav;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild('searchField') searchField!: ElementRef;
  @ViewChild('customTooltip', { static: true }) customTooltip!: TemplateRef<any>;
  @ViewChild(MatMenuTrigger, { static: false })
  isMac: boolean = false;
  devicePopup: any;
  filterColumnData: Map<String, ITreeView[]> = new Map<String, ITreeView[]>();
  selection = new SelectionModel<IDeviceList>(true, []);
  displayedColumns: string[] = ['checkItem', 'device_name', 'device_type', 'vendor', 'region_name', 'last_scan', 'security_score', 'vulnerability', 'menu'];
  menuListItems: string[] = [];
  source: IDeviceList[] = [];
  userProfile!: IUserDetail;
  public clearSubscription!: Subscription;
  unique_devices: any
  canFilterMenu: any
  decommissionedDevices: IDeviceList = {}
  multipleDecommissionedDevices: IDeviceList[] = []
  dataSource!: MatTableDataSource<IDeviceList>;
  selectedRegionId: string | number | undefined = undefined;
  filterName: string = 'deviceMapping';
  DECOMMISSION: string = 'Decommission';
  RECOMMISSION: string = 'Commission';
  Summary_Data: any = []
  RESCAN: string = 'Rescan';
  scheduleAsset: any = null;
  CANCESCHEDULE: string = 'Cancel Schedule'
  CREATESCHEDULE: string = 'Schedule Scan'
  COMPAREDSCANS: string = 'Show Config Diff';
  DECOMMISIONED_DEVICE: string = 'Decommissioned';
  CREDENTIONAL: string = 'Re-scan with different credential'
  intervalRequest: ReturnType<typeof setInterval> | undefined;
  totalLastScanFilters!: any;
  private headerServiceSubscription!: Subscription;
  public filterSubscription!: Subscription;
  public selectedRow: any[] = [];
  vendorFilters!: any;
  isLoadingData: boolean = false;
  selectedDevicComment: string = '';
  filterApplyFlags: IFilterFlags = { "assignedRegion": false, "deviceType": false, "vendor": false };
  initialTableData!: MatTableDataSource<IDeviceList>;
  rescanResponseData: any;
  isFirstLevelFetchBtn: boolean = false;
  cancelMenuItem: any
  isDecommissioned: boolean = false;
  public selectedFilter: ISortingFilterData | any = {
    assigned_region: [],
    total_last_scan: [],
    device_type: []
  }
  latestRescanDevicesList: string[] = [];
  orderColumn: string = 'device_name';
  finalSortDirection: string = 'asc';
  isMiniTable: boolean = true;
searchText: string='';
  assestSearchTrue: boolean = false;
  assectSharedServiceData: any;
  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }
  public isApiCallDone = false;
  numberOfDeviceListResults: number = Math.ceil((window.innerHeight - 153) / 30);
  glbSearch!: Subscription;
  authSubscription!: Subscription;
  MAP_CREDENTIONAL: string = 'Map credential'
  dialogRefMap!: MatDialogRef<CredentialPopupComponent> | null;
  public disableTooltip = {
    isFlag: false
  };
  public toolTipOption = ToolTipConfig;
  isDataAvailableOnFilter: boolean = true;
  isloaderEligible: boolean = true;
  filterMenuOpen = false;
  noDataMsg: String = CONSTANT.NO_ACTIVE_DEVICE_MSG;
  public isDataLoading: boolean = false;
  public openedMenuId: string | null = null;
  private pageType: string = 'deviceManagement'
  private props: any = {};
  public is_rescan: boolean = true;
  isSafari!: boolean;
  public sortDirections: any = {
    'device_name': 'newSortDirection',
    'last_scan_date': 'lastScanSortDirectiong',
    'score': 'scoreSortDirection',
    'vulnerability': 'vulnerabilitySortDirection'
  };
  public permission: boolean = false;
  isAllselect: boolean = false;
  dialogRef!: MatDialogRef<CredentialPopupComponent> | null;
  // AG grid config
  @ViewChild('myGrid') grid!: AgGridComponent;
  isExportEnabled:boolean=true;  // for export excel enable
  public tableHeader = CONFIG.TABLE_INFO.ASSETS.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.ASSETS.PROPS;
  public pageNumber: number = 1;
  public rowHeight = 43;
  public agDeviceColumnDefs: ColDef[] = [
    {
      headerName: this.tableHeader.BLANK,
      field: this.tableProps.CHECKBOX,
      headerTooltip:this.tableHeader.BLANK,
      filter: false,
      sortable: false,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      lockPosition: true,
      suppressColumnsToolPanel: true,
      minWidth: 60,
      maxWidth:100,
      // pinned: 'left'
    },
    {
      headerName: this.tableHeader.ASSETS_NAME,
      field: this.tableProps.ASSETS_NAME,
      headerTooltip:this.tableHeader.ASSETS_NAME,
      cellRenderer: AssetsNameComponent,
      width: 300,
      minWidth: 150,
      suppressColumnsToolPanel: true,
      lockPosition: true,
      // pinned: 'left',
      onCellClicked: (event: CellClickedEvent) => {
        this.selectCell(event);
      }
    },
    {
      headerName: this.tableHeader.ASSETS_TYPE,
      field: this.tableProps.ASSETS_TYPE,
      headerTooltip:this.tableHeader.ASSETS_TYPE,
      onCellClicked: (event: CellClickedEvent) => {
        this.selectCell(event);
      },
      minWidth: 130,
    
    },
    {
      headerName: this.tableHeader.OEM,
      field: this.tableProps.OEM,
      headerTooltip:this.tableHeader.OEM,
      onCellClicked: (event: CellClickedEvent) => {
        this.selectCell(event);
      },
      minWidth: 100,
    },
    {
      headerName: this.tableHeader.REGION,
      field: this.tableProps.REGION,
      headerTooltip:this.tableHeader.REGION,
      onCellClicked: (event: CellClickedEvent) => {
        this.selectCell(event);
      },
      minWidth: 120,
    },
    {
      headerName: this.tableHeader.TOTAL_SCAN,
      field: this.tableProps.TOTAL_SCAN,
      headerTooltip:this.tableHeader.TOTAL_SCAN,
      cellRenderer: LastScanComponent,
      minWidth: 200,
      // tooltipValueGetter: (params: ITooltipParams) => {
      //   return moment(params?.data?.last_scan).format('D MMMM, YYYY HH:mm:ss');
      // },
      onCellClicked: (event: CellClickedEvent) => {
        this.selectCell(event);
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        const dateA = nodeA.data.last_scan_date ? new Date(nodeA.data.last_scan_date) : null;
        const dateB = nodeB.data.last_scan_date ? new Date(nodeB.data.last_scan_date) : null;

        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return -1;
        if (dateB === null) return 1;

        return dateA.getTime() - dateB.getTime();
      },
      sortable: true,
      width: 250,
    },
    {
      headerName: this.tableHeader.SECURITY_SCORE,
      field: this.tableProps.SECURITY_SCORE,
      headerTooltip:this.tableHeader.SECURITY_SCORE,
      tooltipField: this.tableProps.SECURITY_SCORE,
      cellRenderer: ScoreComponent,
      minWidth: 150,
      onCellClicked: (event: CellClickedEvent) => {
        this.selectCell(event);
      }
    },
    {
      headerName: this.tableHeader.VULNERABILITY,
      field: this.tableProps.VULNERABILITY,
      headerTooltip:this.tableHeader.VULNERABILITY,
      cellRenderer: VulnerabilityValComponent,
      onCellClicked: (event: CellClickedEvent) => {
        this.selectCell(event);
      },
      
      width: 300,
      minWidth: 130,
    },
    {
      headerName: this.tableHeader.CVES,
      field: this.tableProps.CVES,
      headerTooltip:this.tableHeader.CVES,
      cellRenderer: CveCountComponent,
      onCellClicked: (event: CellClickedEvent) => {
        this.selectCell(event);
      },
      width: 180,
      minWidth: 120,
    },
    {
      headerName: this.tableHeader.OPTIONS,
      field: this.tableProps.OPTIONS,
      headerTooltip:this.tableHeader.OPTIONS,
      cellRenderer: OptionsComponent,
      suppressColumnsToolPanel: true,
      width: 150,
      minWidth: 60,
      filter: false,
      sortable: false
    }
  ];
  constructor(private filterService: FilterService,
    private datePipe: DatePipe,
    private scheduleService: ScheduleScanService,
    private spinnerService: SpinnerService,
    private deviceService: DeviceService,
    private scanService: ScanService,
    private messageService: MessageServiceService,
    private headerService: HeaderService,
    private dialog: MatDialog,
    public dialogService: DialogService,
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService,
    private sortingFilterService: SortingFilterService,
    private globalSearchService: GlobalSearchService,
    private changeDetectorRefs: ChangeDetectorRef,
    private globalFilterService: GlobalFilterService,
    private profileService: ProfileService,
    private myScansService: MyScansService,
    private responseService: ResponseService,
    public dialogservice: DialogService,
    public cveAssetsService: CveAssetsService,
    private domSanitizer: DomSanitizer) {
    const modify = this.hasPermission(this.permissionProps.MODIFIY_DEVICE_LOCATION);
    const delete1 = this.hasPermission(this.permissionProps.DELETE_DEVICE);
    const addDevice = this.hasPermission(this.permissionProps.ADD_DEVICE);
    const decommision = this.hasPermission(this.permissionProps.DECOMMISSION_DEVICE);
    const rescan = this.hasPermission(this.permissionProps.RESCAN_DEVICE)
    this.permission = modify || delete1 || addDevice || decommision || rescan;
    this.headerService.setHeading('Asset Management', { type: this.pageType, props: this.props });
    // this.sharedService.updateDataOnSocketNotify.subscribe((data: any)=>{
    //  let updatedData: any = _.cloneDeep(this.sharedService.currentModuleUpdatedData(CONFIG.UNIQUE_API_PROPS.asset_management, CONFIG.PAGE_API.ASSETS_DETAILS, this.dataSource.data, data));
    //  this.dataMassging(updatedData ? updatedData : []);
    //  this.dataSource.data = updatedData ? updatedData: [];
    // });
    this.dialogService.onClose.subscribe((result:any)=>{
      console.log(result);
      this.is_rescan = true;
    })
  }
  ngOnInit(): void {
    this.scheduleService.loadAllList(this.loadAllscheduleCallback)
    this.glbSearch = this.globalSearchService.searchCleared.subscribe(() => {
      this.dataSource.data = [];
      this.isDataAvailableOnFilter = true;
      this.loadDeviceList();
    });
    this.authSubscription = this.authService.userLoggedOutSubject.subscribe(() => {
      clearInterval(this.intervalRequest);
      this.intervalRequest = undefined;
      this.latestRescanDevicesList = [];
    });
    this.filterSubscription = this.globalFilterService.updateAfterApplyState.subscribe((flag: boolean) => {
      this.dataSource.data = [];
      this.isDataAvailableOnFilter = true;
      this.selectedRow = [];
      this.loadDeviceList();
    })
    this.profileService.fetchUserDetails(this.userProfileCallback);
    this.clearSubscription = this.deviceService.clearSelection$.subscribe(clear => {
      if (clear) {
        this.selectedRow = [];
        this.grid.grid.api.deselectAll();
      }
    });
    this.isMac = this.authService.isMac();
  const navigationState = this.router.getCurrentNavigation()?.extras?.state || history.state;
  if (navigationState && navigationState['searchTrue']) {
    this.assestSearchTrue = navigationState['searchTrue']
  }
  }

  loadAllscheduleCallback = (response: any) => {
    console.log(response);
    if (response.status === 200) {
      this.Summary_Data = response.data.summary_data
    }
  }

  public userProfileCallback = (results: IUserDetail) => {
    this.userProfile = results;
    this.unique_devices = this.userProfile?.subscription_features?.unique_devices;
  }

  ngAfterViewInit(): void {
    this.loadDeviceList();
    this.dataSource = new MatTableDataSource<IDeviceList>([]);
    this.dataSource.sort = this.sort;
    this.filterService.setSidenav(this.deviceSidenav, this.filterName);
    this.authService.userLoggedOut.subscribe(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    });
    this.authService.userLoggedOut.subscribe(() => {
      if (this.dialogRefMap) {
        this.dialogRefMap.close();
      }
    });

  }
  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
    clearInterval(this.intervalRequest);
    this.latestRescanDevicesList = [];
    if (this.glbSearch) {
      this.glbSearch.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    this.globalSearchService.clearSearch();
    this.headerService.dispatchDisableFilter(false);
    if (this.headerServiceSubscription) {
      this.headerServiceSubscription.unsubscribe();
    }
    this.clearSubscription.unsubscribe();
    if (this.assestSearchTrue) {
      this.assectSharedServiceData = []
      this.assectSharedServiceData.response = []
    }
  }

  public deviceListCallback = (response: IDeviceListResponse) => {
    this.spinnerService.hide();
    this.isApiCallDone = true;
    if (response?.status === 200 && response.data) {
      this.isDataAvailableOnFilter = false;
      this.dataMassging(response.data.device_list);
      this.source = response.data.device_list as IDeviceList[];
      this.dataSource.data = [...this.source];
      this.initialTableData.data = [...this.dataSource.data]
      this.showDefaultDevice();
      this.isEligibleForTimer();
      window.setTimeout(() => {
        this.grid.grid.api.sizeColumnsToFit();
      }, 10);
      this.changeDetectorRefs.detectChanges();
    }
  }

  private showDefaultDevice(): void {
    const sData = this.sharedService.getData();
    // if(sData=='Unassigned'){
    // this.searchText =sData;
    //   this.filterAssetsField(sData);
    // }
    if(sData=='lastScanned') {
      this.dataSource.data.sort((a: any, b: any) => {
        const dateA = a.last_scan ? new Date(a.last_scan).getTime() : 0;
        const dateB = b.last_scan ? new Date(b.last_scan).getTime() : 0;
        return dateA - dateB;
      });
    }
    if (Object.keys(sData).length) {
      const matchRow: any =  this.dataSource.data.find(item => item.device_name === sData.host_name);
      if (matchRow) {
        matchRow.id = matchRow?.id;
        if (matchRow.host_name) {
          matchRow.device_name = matchRow.host_name;
        }
        if (matchRow.category) {
          matchRow.device_type = matchRow.category;
        }
        if (matchRow.region) {
          matchRow.region_name = matchRow.region;
        }
        this.onRowClick(matchRow);
        this.headerService.dispatchDisableSearch(false);
      }
      this.sharedService.clearData();
    }
  }

  public loadDeviceList(showLoader: boolean = true) {
    this.isApiCallDone = false;
    this.dataSource = new MatTableDataSource<IDeviceList>([]);
    this.dataSource.data = [];
    this.assectSharedServiceData =  this.sharedService.getAPIData(CONFIG.PAGE_API.ASSETS_DETAILS)
    if (this.assestSearchTrue) {
      if (this.assectSharedServiceData && this.assectSharedServiceData.isDataAvailable) {
         this.assectSharedServiceData = []
         this.assectSharedServiceData.response = []
      }
      this.deviceService.loadDeviceList(this.loadDeviceListCallback);
    }else if(this.assectSharedServiceData && this.assectSharedServiceData.isDataAvailable) {
      const timer = setTimeout(()=>{
        this.loadDeviceListCallback(this.assectSharedServiceData.response);
        clearTimeout(timer);
      })
    } else {
      this.deviceService.loadDeviceList(this.loadDeviceListCallback);
    }
  }

  public loadDeviceListCallback = (response: IDeviceListResponse) => {
    this.spinnerService.hide();
    this.isApiCallDone = true;
    this.searchField.nativeElement.value = '';
    if (response?.status === 200 && response.data) {
      this.isDataAvailableOnFilter = false;
      //for now disabling the code to ensure the data to be loaded at oneshot.
      let data = this.dataSource?.data.length ? this.dataSource?.data : [];
      // if (data.length) {
      //   data = data.concat(response.data.device_list || []);
      // } else {
      //   
      // }
      data = response.data.device_list || [];
      this.dataMassging(data);
      data = this.updateDecommissioned(data);
      this.dataMassging(data);
      this.dataSource.data = data;
      this.initialTableData = this.dataSource;
      this.showDefaultDevice();
      this.isEligibleForTimer();
      this.changeDetectorRefs.detectChanges();
      if (this.dataSource?.data.length === 0) {
        this.authService.toggleUserInfo();
      } else {
        this.filterAssetsField();
        this.isLoadingData = false;
        this.sharedService.setAPIData(CONFIG.PAGE_API.ASSETS_DETAILS, response, this.dataSource?.data.length > 0);
          window.setTimeout(() => {
            this.grid.grid.api.sizeColumnsToFit();
          }, 10);
      }
    }
  }
  updateDecommissioned(data: any) {
    let finalArray = [];
    if (data && data.length) {
      const decoArray = data.filter((device: IDeviceList, index: number) => {
        return device.security_score === this.DECOMMISIONED_DEVICE;
      })
      const withOutdecoArray = data.filter((device: IDeviceList, index: number) => {
        return device.security_score !== this.DECOMMISIONED_DEVICE;
      })
      finalArray = withOutdecoArray.concat(decoArray);
    };
    return finalArray;
  }

  public openRegionSidebar(): void {
    // this.spinnerService.show();
    this.isFirstLevelFetchBtn = false;
    this.filterColumnData.clear();
    this.filterService.toggleSidenav(this.filterName);
    this.scanService.loadRegionList(this.loadRegionListCallback);
  }

  public loadRegionListCallback = (response: any) => {
    this.spinnerService.hide();
    this.isFirstLevelFetchBtn = true;
    if (response?.status === 200 && response.data) {
      this.scanService.removePropsSelectedAndIndterMinateProps(response.data.regions_list);
      this.filterColumnData.set('Region', response.data.regions_list);
    }
  }

  public stopBubbling(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public deviceDetails(currentRow: IDeviceList) {
    const deviceInfo = `${currentRow.device_name}`;
    this.headerService.setHeading(deviceInfo, { dropdown: [], type: 'device' });
    this.router.navigate(['admin/asset-compare', currentRow.id]);
  }


  hasSchedulableOrNotSchedulable(): boolean {
    return this.selectedRow.some(item => item.schedule === 'schedulable' || item.schedule === 'not_schedulable');
  }

  isNotSchedulable(): boolean {
    return this.selectedRow.some(item => item.schedule === 'not_schedulable');
  }

  hasScheduled(): boolean {
    return this.selectedRow.some(item => item.schedule === 'scheduled');
  }

  public selectedFilters(values: IApply) {
    this.selectedRegionId = undefined;
    this.finalFilterData(values.firstLevel || []);
    if (this.selectedRegionId && this.selectedRow.length) {
      this.spinnerService.show();
      this.deviceService.saveDevices(this.saveDeviceCallback, this.selectedRegionId, this.selectedRow);
    }
  }
  private saveDeviceCallback = (response: IDeviceSaveResponse) => {
    this.spinnerService.hide();
    this.selectedRow = [];
    this.filterService.closeSidenav();
    if (response?.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.updateDeviceInfo(response);
      this.messageService.showSuccess(snakMsg);
    }
  }

  private finalFilterData(data: ITreeView[]): any {
    data?.map(item => {
      if ('data' in item) {
        item.data = this.finalFilterData(item.data || []);
      } else {
        this.selectedRegionId = item.id;
      }
    })
  }

  validateScore(row: IDeviceList): boolean {
    if (this.isRescan(row)) {
      this.showNoAccessMesg();
      return false;
    }
    if (row.is_decommissioned) {
      this.showNoAccessMesg();
      return false;
    }
    if (Number(row.security_score) > 0 || Number(row?.rating) > 0) {
      return true;
    }
    this.showNoAccessMesg();
    return false;
  }

  showNoAccessMesg() {
    const snakMsg: IToastrOptions = {
      message: CONSTANT.NO_DEVICE_DATA
    }
    this.messageService.showError(snakMsg);
  }


  public onRowSelected(selectedRow: any) {
    this.selectedRow = selectedRow;
  }
  public openAssetsDetails() {
    this.filterService.toggleSidenav('cveAssetsDetails');
  }
  selectCell(event: CellClickedEvent) {
    if (event && event.data) {
      this.onRowClick(event.data)
    }
  }
  onRowClick(row: IDeviceList) {
    if (!this.validateScore(row)) {
      return;
    }
    this.devicePopup = row;
    // const payload: IDialog = {
    //   component: DevicePopupComponent,
    //   width: '90%',
    //   class: 'device-popup',
    //   data: { deviceData: this.devicePopup },
    //   position: {
    //     top: '65px',
    //   }
    // }
    // this.dialogService.openDialog(payload);
    this.openAssetsDetails();
    this.cveAssetsService.dataForAssetsDetails.emit({isAssetDetails: true, selectedRow: {...this.devicePopup},selectedIndex: 0,isDeviceManagementNavigation:true})
    this.authService.setCookie('duration', '');
    this.authService.setCookie('cyberDevice', '');
    this.authService.setCookie('vulnerability', '');
    this.authService.setCookie('commonVulDevice', '');
  }

  anySelectedDeviceRequiresRescan(): boolean {
    return this.selectedRow.every(device => device.rescan);
  }

  deleteDeviceRow(selectedDevice: any) {
    const payload: IDialog = {
      component: DeleteDevicePopupComponent,
      width: '500px',
      data: {
        setCallbackFunction: (result: any) => {
          this.deleteCallback(result, selectedDevice)
        }
      },
    }
    this.dialogService.openDialog(payload)
  }

  deleteDevices(selectedDevice: any) {
    this.dialogService.openDialog(
      {
        title: 'Are you sure want to delete the asset(s)?',
        content: 'Please note that even if you delete this asset, it will be considered in the limit of unique assets.',
        class: 'free-delete-popup',
        buttons: [
          {
            label: 'Cancel',
            class: 'btn-lightblack-outline',
            callBack: () => {
            }
          },
          {
            label: 'Delete',
            class: 'btn-tartorange',
            callBack: () => {
              this.deleteDeviceRow(selectedDevice);
            }
          }
        ],
        disableClose: true
      }
    );
  }

  public deleteCallback(result: any, selectedDevice: any) {
    if (result && result.reason) {
      this.spinnerService.show();
      if (!result.authValidation) {
        this.deleteDevice(selectedDevice, result);
        return;
      }
      this.authService.passwordValidation(result.password).subscribe({
        next: (response) => {
          this.spinnerService.hide();
          if (response.status === 200) {
            this.deleteDevice(selectedDevice, result);
          }
        },
        error: (err) => {
          this.spinnerService.hide();
          const snakMsg: IToastrOptions = {
            message: err.error.message
          }
          this.messageService.showError(snakMsg);
          return throwError(() => err);
        }
      })
    }
  }

  deleteDevice(item: IDeviceList, result: any) {
    this.spinnerService.show();
    this.deviceService.deleteDevices(item, result.reason, this.deleteDeviceRowCallback);
  }

  deleteDeviceRowCallback = (response: IDeleteDeviceResponse) => {
    this.spinnerService.hide();
    if (response.status === 200) {
      const snackbar: IToastrOptions = {
        message: response?.message
      };
      this.messageService.showSuccess(snackbar);
      const selectedIds = this.selectedRow.map((selected: any) => selected.id)
      this.dataSource.data = this.dataSource.data.filter((d: any) => !(selectedIds.includes(d.id)))
      if (this.dataSource.data.length === 0 || (this.dataSource.data.length < this.numberOfDeviceListResults)) {
        this.dataSource.data = [];
        this.isDataAvailableOnFilter = true;
        this.isloaderEligible = true;
        this.loadDeviceList();
      }
      this.selectedRow = [];
    }
  }
  public hasPermission(roleName: string): boolean {
    return !!this.authService.isRoleAccess(roleName);
  }
  createScheduleDialog() {
    console.log("user");
  }
  public cancelScheduleCallBack = (res: any) => {
    this.spinnerService.hide();
    console.log(res);
    if (res.status === 200) {
      const snakMsg: IToastrOptions = {
        message: res.message
      }
      this.selectedRow = [];
      this.messageService.showSuccess(snakMsg);
      this.dataSource.data = [];
      this.isDataAvailableOnFilter = true;
      this.sharedService.setAPIData(CONFIG.PAGE_API.ASSETS_DETAILS, {}, false);
      this.loadDeviceList();
    }
  }
  checkAuthorization(item: IDeviceList, action: string): void {
    let passwordData = {
      title: action === this.RECOMMISSION ? CONSTANT.RECOMM_TITLE : CONSTANT.DECOMM_TITLE,
      text: action === this.RECOMMISSION ? CONSTANT.PASSWORD_ALERT : CONSTANT.PASSWORD_ALERT_DECOMMISSION,
      buttonLabel: action === this.RECOMMISSION ? CONSTANT.RECOMM_BUTTON : CONSTANT.DECOMM_BUTTON,
      password: CONSTANT.PASSWORD,
    }
    const payload: IDialog = {
      component: PasswordConfirmationPopupComponent,
      width: '350px',
      data: {
        userData: passwordData,
        setCallbackFunction: () => {
          this.decommissionRecommissionDevice(item);
        }
      },
    }
    this.dialogService.openDialog(payload);
  }
  public MapCredentional(item: any) {
    this.is_rescan = false;
    const payload: IDialog = {
      component: MapCredentialComponent,
      width: '369px',
      data: {
        assetIds: [item.id],
        setCallbackFunction: (res: any) => {
          this.map_cred(res);
        }
      },
    }
    this.dialogService.openDialog(payload);
  }
  public map_cred(response: any) {
    this.is_rescan = true;
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.selectedRow = [];
      this.changeDetectorRefs.detectChanges();
    }
  }


  public MapMultiCredentional(assets: any) {
    const asset_ids = assets.map((asset: { id: any; }) => asset.id);
    this.is_rescan = false;
    const payload: IDialog = {
      component: MapCredentialComponent,
      width: '369px',
      data: {
        assetIds: asset_ids,
        setCallbackFunction: (res: any) => {
          this.map_cred(res);
        }
      },
    }
    this.dialogService.openDialog(payload);
  }

  public rescanWithCredentional(item: any) {
    this.is_rescan = false;
    const payload: IDialog = {
      component: CredentialPopupComponent,
      width: '369px',
      data: {
        assetIds: [item.id],
        setCallbackFunction: (res: any) => {
          this.rescan_cred(res);
        }
      },
    }
    this.dialogService.openDialog(payload);
  }

  public rescan_cred(response: any) {
    this.is_rescan = true;
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.updateDeviceInfo(response);
      this.rescanResponseData = response;
      this.changeDetectorRefs.detectChanges();
      this.selectedRow = [];
      this.startTimer();
    }
  }

  public rescanWithMultiCredentional(assets: any) {
    const asset_ids = assets.map((asset: { id: any; }) => asset.id);
    this.is_rescan = false;
    const payload: IDialog = {
      component: CredentialPopupComponent,
      width: '369px',
      data: {
        assetIds: asset_ids,
        setCallbackFunction: (res: any) => {
          this.rescan_cred(res);
        }
      },
    }
    this.dialogService.openDialog(payload);
  }

  checkMultipleAuthorization(items: IDeviceList[]) {
    let passwordData = {
      title: this.isDecommissioned ? CONSTANT.RECOMM_TITLE : CONSTANT.DECOMM_TITLE,
      text: this.isDecommissioned ? CONSTANT.PASSWORD_ALERT : CONSTANT.PASSWORD_ALERT_DECOMMISSION,
      buttonLabel: this.isDecommissioned ? CONSTANT.RECOMM_BUTTON : CONSTANT.DECOMM_BUTTON,
      password: CONSTANT.PASSWORD,
    }
    const payload: IDialog = {
      component: PasswordConfirmationPopupComponent,
      width: '350px',
      data: {
        userData: passwordData,
        setCallbackFunction: () => {
          this.multipleDecommissionRecommissionDevice(items);
        }
      },
    }
    this.dialogService.openDialog(payload);
  }

  multipleDecommissionRecommissionDevice(items: IDeviceList[]) {
    this.multipleDecommissionedDevices = items;
    this.spinnerService.show()
    const action = this.isDecommissioned ? 'recommission' : 'decommission';
    items.forEach(item => {
      this.selection.deselect(item);
    });
    this.deviceService.multipledecommissionDevice(items, action, this.multipleDecommissionRecommissionCallback);
  }

  multipleDecommissionRecommissionCallback = (response: any) => {
    this.spinnerService.hide()
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      if (this.isDecommissioned) {
        this.authService.toggleUserInfo(false);
        this.showDecommissionedDevices(this.isDecommissioned);
      } else {
        this.multipleUpdateDeviceInfoAfterDecommisined();
      }
    }
  }
  public multipleUpdateDeviceInfoAfterDecommisined() {
    this.multipleDecommissionedDevices.forEach(device => {
      if (device) {
        let data = this.dataSource?.data.length ? this.dataSource?.data : [];
        if (data.length) {
          const index = data.findIndex((itemObj) => {
            return itemObj.id === device.id;
          })
          if (index > -1) {
            data.splice(index, 1);
          }
          this.dataSource.data = [...data]
          if (this.dataSource?.data.length === 0) {
            this.authService.toggleUserInfo();
          }
          this.searchField.nativeElement.value = "";
          this.filterAssetsField();
          this.changeDetectorRefs.detectChanges();
        }
      }
    });
  }

  decommissionRecommissionDevice(item: IDeviceList) {
    this.decommissionedDevices = item;
    this.spinnerService.show()
    const action = item.security_score === this.DECOMMISIONED_DEVICE ? 'recommission' : 'decommission';
    this.selection.deselect(item);
    this.deviceService.decommissionDevice(item, action, this.decommissionRecommissionDeviceCallback);
  }

  decommissionRecommissionDeviceCallback = (response: any) => {
    this.spinnerService.hide()
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      if (this.isDecommissioned) {
        this.authService.toggleUserInfo(false);
        this.showDecommissionedDevices(this.isDecommissioned);
      } else {
        this.updateDeviceInfoAfterDecommisined(this.decommissionedDevices);
      }
    }
  }
  public updateDeviceInfoAfterDecommisined(decommissionedDevices: IDeviceList) {
    if (decommissionedDevices) {
      let data = this.dataSource?.data.length ? this.dataSource?.data : [];
      if (data.length) {
        const index = data.findIndex((itemObj) => {
          return itemObj.id === this.decommissionedDevices.id;
        })
        if (index > -1) {
          data.splice(index, 1);
        }
        this.dataSource.data = [...data]
        if (this.dataSource?.data.length === 0) {
          this.authService.toggleUserInfo();
        }
        this.searchField.nativeElement.value = '';
        this.filterAssetsField();
        this.changeDetectorRefs.detectChanges();
      }
    }
  }
  public rescan(item: any) {
    this.deviceScan(item);
  }
  public updateDeviceInfo(response: any) {
    if (response.data && response.data?.device_list.length) {
      let data = this.dataSource?.data.length ? this.dataSource?.data : [];
      if (data.length) {
        response.data?.device_list.forEach((item: any) => {
          const findIndex = data.findIndex((itemObj) => {
            return itemObj.id === item.id;
          })
          data[findIndex] = item;
        })
        this.dataMassging(data);
        data = this.updateDecommissioned(data);
        this.dataMassging(data);
        this.dataSource.data = [...data]
        this.changeDetectorRefs.detectChanges();
      }
    }
  }

  public deviceScan(item: any) {
    this.is_rescan = false;
    this.spinnerService.show();
    this.deviceService.rescanDevice(item, this.rescanCallback);
  }

  rescanCallback = (response: any) => {
    this.spinnerService.hide();
    this.is_rescan = true;
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.updateDeviceInfo(response);
      this.rescanResponseData = response;
      this.changeDetectorRefs.detectChanges();
      this.startTimer();
    }
  }

  public updateRescan() {
    const deviceId = this.rescanResponseData?.data?.device_list[0].id;
    if (deviceId && !this.latestRescanDevicesList.includes(deviceId)) {
      this.latestRescanDevicesList.push(deviceId);
    }
    const checkEligibleRescanDevices = this.dataSource.data.filter((item: any) => item.status < 3);
    checkEligibleRescanDevices?.forEach((item: any) => {
      if (item.id && !this.latestRescanDevicesList.includes(item.id)) {
        this.latestRescanDevicesList.push(item.id);
      }
    });
    this.deviceService.rescanDeviceOrder(this.latestRescanDevicesList, this.rescanCallbackOrder);
  }

  rescanCallbackOrder = (response: any) => {
    const snakMsg: IToastrOptions = {
      message: response.message
    }
    this.rescanResponseData = response;
    if (this.rescanResponseData.data.device_list[0]) {
      this.updateDeviceInfo(response);
      this.changeDetectorRefs.detectChanges();
      this.isEligibleForTimer();
    }
  }

  startTimer(): void {
    if (!this.intervalRequest) {
      this.intervalRequest = setInterval(() => {
        this.updateRescan();
      }, 25000);
    }
  }

  isEligibleForTimer(): void {
    const dt = this.dataSource.data.filter((item: any) => item.status < 3);
    if (dt.length === 0) {
      clearInterval(this.intervalRequest);
      this.intervalRequest = undefined;
      this.latestRescanDevicesList = [];
    } else {
      this.startTimer();
    }
  }

  getScoreStatus(score: number): string {
    return Utils.getRatingBadge(score);
  }

  getStatus(item: any): boolean {
    return item?.status < 3;
  }

  isFailed(item: any): boolean {
    return item?.status === 4;
  }

  isSuccess(item: any): boolean {
    return item?.status === 3;
  }

  isRescan(item: any): boolean {
    return item?.status === 1;
  }

  isCanceled(item: any): boolean {
    return item?.status === 5;
  }
  shouldDisableCheckBox(item: any): boolean {
    return (item?.status === 1 || item?.status === 4 || item?.status === 5);
  }

  public isSelected(row: any) {
    return this.selection.isSelected(row);
  }

  onMenuOpen(id: string) {
    this.openedMenuId = id;
  }

  onMenuClose() {
    this.openedMenuId = null;
  }
  showFilter(data: any) {
    return this.sortingFilterService.showFilterDropDown(data);
  }

  trackById(index: number, device: IDeviceList) {
    return device.id;
  }

  dataMassging(data: any) {
    data?.forEach((element: any) => {
      element.security_score_rounded = parseInt(element.security_score) ? Math.floor(element.security_score) : element.security_score;
      element.vendor = Utils.makeFirstLetterCapital(element.vendor);
      const formattedDate = element.last_scan ? moment(element.last_scan).format('D, MMMM, YYYY') : '';
      element.last_scan_date = formattedDate;
      element.region_name = element.region_name ? element.region_name : 'Unassigned';
    });
    data.sort((a: any, b: any) => {
      const dateA = a.last_scan ? new Date(a.last_scan).getTime() : 0;
      const dateB = b.last_scan ? new Date(b.last_scan).getTime() : 0;
      return dateB - dateA;
    });
  }
  showDecommissionedDevices(flag: boolean) {
    this.headerService.dispatchDisableFilter(flag);
    this.isApiCallDone = false;
    this.dataSource.data = [];
    this.isDataAvailableOnFilter = true;
    this.isAllselect = false;
    this.selectedRow = [];
    this.changeDetectorRefs.detectChanges();
    if (flag) {
      this.noDataMsg = CONSTANT.NO_DECOMMISSION_DEVICE_MSG;
      this.searchField.nativeElement.value = '';
      this.deviceService.getDecommissionedDevices().subscribe({
        next: (response) => {
          this.getDecommissionedDevicesCallback(this.responseService.successResponse(response));
        },
        error: (err) => {
          this.getDecommissionedDevicesCallback(this.responseService.errorResponse(err));
        },
      })
    } else {
      this.noDataMsg = CONSTANT.NO_ACTIVE_DEVICE_MSG;
      this.loadDeviceList();
    }
  }
  getDecommissionedDevicesCallback = (response: any) => {
    this.isApiCallDone = true;
    this.isDataAvailableOnFilter = false;
    this.searchField.nativeElement.value = "";
    if (response?.status === 200 && response.data) {
      this.dataMassging(response.data.device_list);
      this.dataSource.data = response.data.device_list;
      this.changeDetectorRefs.detectChanges();
      this.filterAssetsField();
      this.sharedService.setAPIData(CONFIG.PAGE_API.DECOMMISION_ASSETS, this.dataSource.data, this.dataSource.data.length > 0)
    }
  }

  public downloadAssetsSummary() {
    const dialogRef = this.dialog.open(AssetSummaryDownloadComponent, {
      width: '338px',
      panelClass: 'dowload-report-box',
      disableClose: true,
      data: {
        title: 'Download Asset Summary Report?',
        content: `This report offers a  summary of essential information for ${this.selectedRow.length} Asset's.`,
        assetsIds: this.selectedRow,
        buttons: [{
          label: 'Download',
          class: 'btn-primary',
          callBack: (selectedFormat: string) => {
            this.deviceService.assetSummaryData(this.selectedRow, selectedFormat)
          }
        }, {
          label: 'Cancel',
          class: 'btn-grayborder',
          callBack: () => {
          }
        }],
        disableClose: true
      },
    });
    return dialogRef.afterClosed();
  }
  isActiveScans = false;

  openCalender(element: any) {
    this.isActiveScans = true
    this.spinnerService.show();
    this.scheduleService.loadScanAssets(this.loadScansCallback, element.id, element)

  }

  openCalenderWithPropagationCheck(element: any, event: MouseEvent,isdbclikc:boolean=false): void {
    if (!this.isActiveScans) {
      this.openCalender(element);
      event.stopPropagation();
    }
  }

  loadScansCallback = (response: any, element: any) => {
    this.filterService.closeSidenav();
   this.spinnerService.hide();
    if (response?.status === 200 && response.data) {

      const payload: IDialog = {
        component: ActiveScanScheduleComponent,
        width: '660px',
        height: '480px',
        class: 'calender-popup',
        data: {
          payload: response.data,
          deviceName: element.device_name,
          setCallbackFunction: (result: any) => {
            //this.deleteCallback(result,selectedDevice)
          }
        },
      }
      const dialogRef = this.dialogService.openDialog(payload)
      this.isActiveScans = false;

    }

  }

  onScheduleAsset(radioData: any = null, element: any = null) {
    let isallScheduleable: any = []
    if (element) {
      isallScheduleable = element.schedule == 'schedulable' ? true : false
      this.scheduleAsset = _.cloneDeep(element)
    } else {
      isallScheduleable = this.selectedRow.every(item => item.schedule === 'schedulable');
    }

    //this.openScheduleDialog(null)
    if (!isallScheduleable) {
      this.dialogService.openDialog(
        {
          title: 'Scan Schedule',
          width: '369px',
          class: 'custom-schedule-popup',
          data: {
            radioData: radioData,
            radio: [
              {
                label: "Remove from all the existing schedules",
                value: 0

              },
              {
                label: "Remove from all of my schedules",
                value: 1
              },
              {
                label: "Add without removing from other schedules",
                value: 2
              }
            ]
          },
          buttons: [
            {
              label: 'Cancel',
              class: 'btn-lightblack-outline',
              callBack: () => {
              }
            },
            {
              label: 'Next',
              class: 'btn-primary',
              callBack: (data: any = '', radioFlag: boolean = false) => {
                console.log(data)
                this.openScheduleDialog(null, data, radioFlag)
              }
            }
          ],
          disableClose: true
        }
      );

    } else {
      this.openScheduleDialog(null)
    }

  }

  public openScheduleDialog(scanelement: any, radioData?: any, radioFlag: boolean = false) {
    const payload: IDialog = {
      component: ScheduleDialogComponent,
      width: '360px',
      data: {
        isNewschedule: true,
        isMyscansNewschedule: false,
        scheduleData: scanelement,
        scanname: scanelement?.scanname,
        radioData: radioData,
        radioFlag: radioFlag,
        summary_data: this.Summary_Data,
        setCallbackFunction: (radioData?: any, isSave: boolean = false, req: any = null, radioFlag: boolean = false) => {
          if (req) {
            let device_ids: any = []
            let temp_device_ids = []
            if (req['device_ids'] != undefined && req['device_ids'] != null) {
              device_ids = req['device_ids'];
            }
            temp_device_ids = this.scheduleAsset ? [this.scheduleAsset.id] : this.selectedRow.map((selected: any) => selected.id)
            req['device_ids'] = [...temp_device_ids, ...device_ids]
            if (radioFlag) {
              req.remove_option = radioData;
            }
          }
          if (isSave) {
            this.scheduleService.saveSchduleDetails(req, this.saveScheduleCallback)

          } else if (radioFlag) {
            this.onScheduleAsset(radioData)
          }
        }
      },
    }
    this.dialogService.openDialog(payload);
  }
  saveScheduleCallback = (response: any) => {
    if (response.status === 200) {
      this.selectedRow = [];
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.dataSource.data = [];
      this.isDataAvailableOnFilter = true;
      this.sharedService.setAPIData(CONFIG.PAGE_API.ASSETS_DETAILS, {}, false);
      this.loadDeviceList();
    }

  }
  onCancelSchedule(item: any = null) {
    this.dialogService.openDialog(
      {
        title: 'Cancel Schedule ',
        content: `Select an option to cancel the schedule scan. `,
        width: '369px',
        class: 'custom-schedule-popup',
        data: {
          radio: [
            {
              label: "Remove from my schedule",
              value: false

            },
            {
              label: "Remove from all schedule",
              value: true
            }
          ],
          device: true
        },
        buttons: [
          {
            label: 'Cancel',
            class: 'btn-lightblack-outline',
            callBack: () => {
            }
          },
          {
            label: 'Remove',
            class: 'btn-primary',
            callBack: (remove_all_schedules: any) => {

              let selectedIds: any = []
              if (item) {
                selectedIds = [item.id]
              } else {
                selectedIds = this.selectedRow.map((selected: any) => selected.id)
              }
              let req = {
                device_ids_list: selectedIds,
                remove_all_schedules: remove_all_schedules
              }
              this.scheduleService.cancelSchedule(this.cancelScheduleCallBack, req)
              //cancelSchedule
            }
          }
        ],
        disableClose: true
      }
    );
  }

  cancelCallback = (response: any) => {

  }
  showClm = true;
  toggleColumn(field: string) {
    this.showClm = !this.showClm;
  }

  exportToExcel() {
    const columnList = this.grid.grid.api.getAllDisplayedColumns();
    const params: any = {
      columnKeys: []
    };
    columnList.forEach((colNode: any) => {
      if (colNode.colDef.headerName && colNode.colDef.field) {
        const field: any = colNode.colDef.field;
        params.columnKeys.push(field);
      }
    })
    this.grid.grid.api.exportDataAsExcel(params);
  }
  filterAssetsField(searchText:string='') {
    if(searchText==''){
      searchText=this.searchField?.nativeElement?.value
    }
    this.grid.grid.api.setGridOption(
      "quickFilterText",
       searchText
    );
  }
  goToPage(): void {
    if (this.pageNumber && this.pageNumber > 0) {
      this.grid.grid.api.paginationGoToPage(this.pageNumber - 1);
    }
  }
}
