import { formatDate } from "@angular/common";
import { environment } from "src/environments/environment"; 
 export const tableResponse = {
    Assets: 'Assets',
    Parameters: 'Parameters',
    Benchmarks: 'Benchmarks',
    Roles: 'Roles',
    Scans: 'Scans',
    Users: 'Users',
    CVE: 'Cve'
 }
 export class Search {
    public column_one: string | number ;
    public column_two: string | number;
    public column_three: string | number;
    public column_four: string | number;
    public column_five: number | string;
    public column_six:string |number;
    public type: string;
    public id: string;
    public data: any;
    constructor(item: any, tableObj: any) {
        const source: any = item;
        this.column_one = '';
        this.column_two = '';
        this.column_three = '';
        this.column_four = '';
        this.column_five = '';
        this.column_six='';
        this.type = tableObj.key;
        this.id = '';
        this.data = source;
        if(this.type === tableResponse.Assets) {
            this.column_one = source.host_name;
            this.column_two = source.vendor;
            this.column_three = source.category;
            this.column_four = source.region;
            this.column_five = source.is_decommissioned ? 'Decommissioned' : !isNaN(source.rating) ? parseFloat(source.rating).toFixed(2) : source.rating;
            this.column_six = !isNaN(source.failed_count)?'Vulnerabilities: '+source.failed_count : 'Severity: '+source.failed_count;
            if( !isNaN(source.failed_count)) {
                this.column_six = !isNaN(source.failed_count)?'Vulnerabilities: '+source.failed_count : '';
             }
            this.id = source.id_device
        } else if(this.type === tableResponse.Scans) {
            this.column_one = source.name;
            this.column_two = source.date_added ? formatDate(new Date(source.date_added), 'yyyy-MM-dd HH:mm:ss', 'en'): source.date_added;
            this.column_three = source.user_email;
            this.column_four = source.device_count
            this.column_five = parseFloat(source.rating).toFixed(2);
            this.column_six = !isNaN(source.vulnerabilities)?'Vulnerabilities: '+source.vulnerabilities : 'Severity: '+source.vulnerabilities;
            if(source.status === '5') {
                this.column_five = "Cancelled"
            }
            if(source.status === '4') {
                this.column_five = "Failed"
            }
            this.id = source.id;
        } else if(this.type === tableResponse.Benchmarks) {
            this.column_one = source.name;
            this.id = source.id;
        } else if(this.type === tableResponse.Parameters) {
            this.column_one = source.name;
            this.column_two = source.bechmark;
            this.column_three  = source.severity;
            this.column_four = source.description;
            this.id = source.id;
            this.column_six = !isNaN(source.severity)?'Vulnerabilities: '+source.severity : 'Severity: '+source.severity;
        }  
        else if(this.type === tableResponse.Roles) {
            this.column_one = source.name;
            this.column_two = source.default_role==='True'?'Pre-Defined Role':'Custom Role';
            this.id = source.id;
        } 
        else if(this.type === tableResponse.Users) {
            this.column_one = source.email;
            this.column_two = source.firstname + ' ' +source.lastname;
            this.column_three = source.role_name
            this.id = source.id;
        } else if(this.type === tableResponse.CVE) {
            this.column_one = source.id;
            this.column_two = source.description;
            this.id = source.id;
        }   
    }
}
export interface ITable {
    scan_device: ITableType;
    scan_summary_table: ITableType;
    roles: ITableType;
    parameters: ITableType;
    benchmarks: ITableType
}
export interface ITableType {
    title: string;
    type: string;
}

export interface  IRequestObject {
    search: any;
    category: string;
    type: any;
    page_no?: any;
    content_no?: any; 
  }