import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { CveAssetsService } from '../../services/cve-assets.service';
import { END_POINTS } from '../../constants/endpoints';
import { MatSidenav } from '@angular/material/sidenav';
import { SpinnerService } from '../../services/spinner.service';
import { SecurityInformationService } from '../../services/security-information.service';
import { ISelectedListDetailsResponse, ISelectedListInfoDetails } from '../list/list.model';
import * as _ from 'lodash';
import { Utils } from '../../utils';
import { AssetSummaryDownloadComponent } from 'src/app/modules/admin/components/asset-summary-download/asset-summary-download.component';
import { DeviceService } from '../../services/device.service';
import { MatDialog } from '@angular/material/dialog';
import { CveService } from '../../services/cve.service';
import { MyScansService } from '../../services/my-scans.service';
import { IDeviceList } from 'src/app/modules/admin/components/device-management/device.model';
import { AuthService } from '../../services/auth.service';
import { ROLES_CONTENT_PROPS } from '../../constants/constant-data';

@Component({
  selector: 'app-cve-assets-details',
  templateUrl: './cve-assets-details.component.html',
  styleUrls: ['./cve-assets-details.component.scss']
})
export class CveAssetsDetailsComponent {
  public dataForCveDetails!: any;
  public isCVEdetails: boolean = false;
  public cveDetails: any;
  public assetsDataLoader:boolean = false;
  public affectedDeviceResponse: any = [];
  public isAssetDetails:boolean = false;
  public assetsData:any;
  deviceId: any;
  deviceVendor: any;
  deviceType: any;
  regionName: any;
  status: any;
  statusClass:any;
  image_url: any;
  public securityGraphTitle = 'Security Score';
  public securityApiName: any;
  public vulnerabilityGraphTitle = 'Vulnerabilities';
  public vulnerabilityApiName: any;
  public vulnerabilityCveApiName:any
  public cyberResilienceGraphTitle = 'Cyber Resilience Trend';
  public cyberResilienceGraphApiName: any;
  public cyberResilienceFilter = true; 
  public apiPayload: any;
  public commonVulnerabilityTitle = 'Vulnerabilities List';
  public commonVulnerabilityApiName = END_POINTS.DASHBOARD_COMMONVUL;
  public commonVulnerabilityFilter = false;
  public vulRowData: boolean = true
  public vulHeading: any;
  public selectedRowInfoDetails: ISelectedListInfoDetails | undefined = {};
  public selectedRowApiCall = false;
  showDescription: boolean = false;
  showRectification: boolean = false;
  showRationale: boolean = false;
  showIssues: boolean = false;
  public selectedIndex = 0;
  constructor(public filterService: FilterService,
    private cveAssetsService: CveAssetsService,
    private spinnerService: SpinnerService,
    private cveService: CveService,
    private securityInformationService: SecurityInformationService,
    private deviceService: DeviceService,
    private dialog: MatDialog,
    public myscansService:MyScansService,
    private authService: AuthService


  ) {
    this.filterService.sidenavCloseEvent.subscribe(()=>{
     this.isAssetDetails = false;
     this.isCVEdetails = false;
    })
    this.cveAssetsService.dataForCveDetails.subscribe((data)=>{
      this.dataForCveDetails = data;
      this.isCVEdetails = data.isCveDetails;
      this.loadCVEDetails();
      this.loadAffectedAssetsList();
    })
    this.cveAssetsService.dataForAssetsDetails.subscribe((data) => {
      this.assetsData = data
      console.log('this.assetsData', this.assetsData);
      if(this.assetsData?.selectedRow){
        this.assetDataDetails=this.assetsData?.selectedRow;
      }
      if(this.assetsData?.isDeviceManagementNavigation){
        this.isDeviceManagementNavigation=this.assetsData?.isDeviceManagementNavigation;
      }
      this.deviceId =  this.assetsData?.selectedRow?.device_name || '';
      this.deviceVendor = this.assetsData?.selectedRow?.vendor || '';
      this.deviceType = this.assetsData?.selectedRow?.device_type || '';
      this.regionName =  this.assetsData?.selectedRow?.region_name || '';
      this.image_url = this.assetsData?.selectedRow?.cloud_type || '';
      let status  = this.status = this.assetsData?.selectedRow?.status != null 
      ? this.assetsData.selectedRow.status === 3 ? 'Active' : 'Inactive'
      : '';
      this.status = status || '';
      this.statusClass = this.assetsData?.selectedRow?.status;
      if(data){
        this.isAssetDetails = data.isAssetDetails;
      }
      this.selectedIndex = data.selectedIndex;
      this.setKebabMenuItems();
    });
    
   
  }

  public openedMenuId: string | null = null;
  isDeviceManagementNavigation:boolean=false
  menuListItems: string[] = [];
  DECOMMISSION: string = 'Decommission';
  RECOMMISSION: string = 'Commission';
  COMPAREDSCANS: string = 'Show Config Diff';
  CANCESCHEDULE: string = 'Cancel Schedule';
  CREATESCHEDULE: string = 'Schedule Scan';
  CREDENTIONAL: string = 'Re-scan with different credential';
  RESCAN: string = 'Rescan';
  MAP_CREDENTIONAL: string = 'Map credential';
  DECOMMISIONED_DEVICE: string = 'Decommissioned';
  assetDataDetails:any={};
  onMenuOpen(id: string) {
    this.openedMenuId = id;
  }

  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }

  onMenuClose() {
    this.openedMenuId = null;
  }
  public hasPermission(roleName: string): boolean {
    return !!this.authService.isRoleAccess(roleName);
  }

  setKebabMenuItems() {
    this.menuListItems=[];
    // if (this.hasPermission(this.permissionProps.DECOMMISSION_DEVICE)) {
    //   this.menuListItems.push(this.DECOMMISSION);
    // }
    //this.menuListItems.push(this.RECOMMISSION);
    this.menuListItems.push(this.COMPAREDSCANS);
    //this.menuListItems.push(this.CANCESCHEDULE)
    //this.menuListItems.push(this.CREATESCHEDULE);
    // if (this.hasPermission(this.permissionProps.RESCAN_DEVICE)) {
    //   this.menuListItems.push(this.RESCAN);
    // }
    // if (this.hasPermission(this.permissionProps.CREDENTIAL_MANAGER)) {
    //   this.menuListItems.push(this.CREDENTIONAL);
    //   this.menuListItems.push(this.MAP_CREDENTIONAL);
    // }
  }

  valideKebabMenu(device: any): boolean {
    const dt: boolean[] = [];
    this.menuListItems.forEach((item) => { dt.push(this.kebabMenuController(item, device)) })
    const unique: boolean[] = _.uniq(dt);
    return unique.length > 0 ? unique.length === 1 ? !unique[0] ? false : true : true : false;
  }
  kebabMenuController(item: string, device: IDeviceList) {
    if (item !== this.RECOMMISSION && device.security_score === this.DECOMMISIONED_DEVICE) {
      return false;
    } else if (item === this.RECOMMISSION && device.security_score !== this.DECOMMISIONED_DEVICE) {
      return false;
    } else if (item === this.RESCAN && (!device.rescan || device.status == 4)) {
      return false;
    } else if (item === this.COMPAREDSCANS && (!device.total)) {
      return false;

    } else if (item === this.CANCESCHEDULE && device.schedule !== 'scheduled') {
      return false;
    } else if (item === this.CREATESCHEDULE && device.schedule !== 'scheduled' && device.schedule != 'schedulable') {
      return false;
    } else if (item === this.CREDENTIONAL && !device.rescan) {
      return false;
    } else if (item === this.MAP_CREDENTIONAL && !device.rescan) {

      return false;
    }
    return true;
  }

  menuClick(action: string, item: IDeviceList) {
   let  response:any={
      action:action,
      item:item
    };
    this.filterService.closeSidenav();
    this.authService.deviceActionSubject.next(response);
  }



  public validateObjectData(data: any): boolean{
    return Utils.validateObjectData(data);
  } 

 
  public downloadAssetsSummary() {
    this.filterService.closeSidenav();
    this.deviceService.complaince.next(this.assetsData.selectedRow.id);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['isCVEdetails'] &&
      changes['selectedCVE']?.currentValue != changes['selectedCVE']?.previousValue) {
        this.isCVEdetails = changes['isCVEdetails'].currentValue;
    }
  }
  public loadCVEDetails() {
    this.cveDetails = null;
    this.cveService.fetchCVEDetails(this.dataForCveDetails?.selectedRow?.cve_id, this.loadCVEDetailsCallback)
  }
  public loadCVEDetailsCallback = (response: any) => {
    if (response.status === 200 && response.data && response.data.cve_summary_details) {
      this.cveDetails = response.data.cve_summary_details;
    }
  }
  public loadAffectedAssetsList() {
    this.assetsDataLoader = false;
    this.affectedDeviceResponse = [];
    this.cveService.fetchAffectedAssetsList(this.dataForCveDetails?.selectedRow?.cve_id, this.loadAffectedAssetsListCallback);
  }
  public loadAffectedAssetsListCallback = (response: any) => {
    this.assetsDataLoader = true;
    if (response.status === 200 && response.data) {
      this.affectedDeviceResponse = response.data.parameters || [];
    }
  }
  public closeSidenav() {
    this.isAssetDetails = false;
    this.filterService.closeSidenav();
  }
  // toggleDescription() {
  //   this.showDescription = !this.showDescription;
  // }
  // toggleRectification() {
  //   this.showRectification = !this.showRectification;
  // }
  // toggleRationale() {
  //   this.showRationale = !this.showRationale;
  // }
  // toggleIssues() {
  //   this.showIssues = !this.showIssues;
  // }
}
