import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
@Component({
  selector: 'app-vulnerability-val',
  templateUrl: './vulnerability-val.component.html',
  styleUrls: ['./vulnerability-val.component.scss']
})
export class VulnerabilityValComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  public grpMidColor = "#fff";
  public gredientColor: string = '';
  constructor(private sharedService: SharedService){
    if(this.sharedService.theme.type === 'dark-theme') {
      this.grpMidColor = '#191C24';
    }
    this.sharedService.isThemeUpdate.subscribe((theme: any)=>{
      this.grpMidColor = "#fff";
      if(theme.type==='dark-theme' && theme.isUpdate) {
        this.grpMidColor = "#191C24"
      }
      this.graphProps(this.params?.data, this.params?.value);
      this.refresh(this.params);
    })
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.graphProps(this.params?.data, this.params?.value);
  }
  getStatus(item: any): boolean {
    return item?.status < 3;
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  graphProps(data: null | any, total: any) {
    let dataVul: any = data?.vulnerability_levels[0];
    let conic_gradient: any, color: any;
    if (dataVul === null || dataVul === undefined && total) {
      return '#fff';
    }
    if (dataVul) {
        const vulPercetange = {
          critical: (dataVul.critical/total)*100 + '%',
          high: (dataVul.high/total)*100 + (dataVul.critical/total)*100 + '%',
          medium: (dataVul.medium/total)*100 + (dataVul.high/total)*100 + (dataVul.critical/total)*100 + '%',
          low: (dataVul.low/total)*100+ + (dataVul.medium/total)*100 + (dataVul.high/total)*100 + (dataVul.critical/total)*100+ '%'
        }
        const totalVal = data
        color = '#FA4450';
        conic_gradient = `radial-gradient(${this.grpMidColor} 40%, transparent 41%),conic-gradient(#F53B3B 0% ${vulPercetange.critical},  #F7941F ${vulPercetange.critical} ${vulPercetange.high}, #DBD301 ${vulPercetange.high} ${vulPercetange.medium}, #00A61B ${vulPercetange.medium} ${vulPercetange.low})`;
    }
    this.gredientColor =  conic_gradient;
    //return 'radial-gradient(white 43%, transparent 41%), conic-gradient(#c23035 0% 35% , #E78B1E; 35% 60%, #B6B13A 60% 80%, #00A61B 80% 100%)';
  }
}
