import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }

}
