<div class="asset-data d-flex scroll">
    <div class="asset-data-left">
        <div class="asset-data-left-toprow  d-flex">
            <div class="sec-card spark-whitebox spark-whiteboxshadow relative">
                <app-security-score [type]="type" [isOverlay]="true" [apiName]="securityApiName" [title]="securityGraphTitle"></app-security-score>
            </div>
            <div class="vul-card spark-whitebox spark-whiteboxshadow graphdirection">
                <!-- <app-vulnerability  [apiName]="vulnerabilityApiName"
                    [title]="vulnerabilityGraphTitle">
                </app-vulnerability> -->
                <app-line-grpah [apiName]="vulnerabilityApiName"
                    [title]="vulnerabilityGraphTitle">
                    </app-line-grpah>
                    <!-- <app-vulnerability  [apiName]="vulnerabilityCveApiName"
                    [title]="vulnerabilityCveGraphTitle">
                </app-vulnerability> -->
                <app-line-grpah  [apiName]="vulnerabilityCveApiName"
                [title]="vulnerabilityCveGraphTitle">
                    </app-line-grpah>
                    <div class="severity-label-section">
                        <div class="severity-label">
                            <div class="color-indicator critical"></div>
                            <div class="text">Critical</div>
                        </div>
                        <div class="severity-label">
                            <div class="color-indicator high"></div>
                            <div class="text">High</div>
                        </div>
                        <div class="severity-label">
                            <div class="color-indicator medium"></div>
                            <div class="text">Medium</div>
                        </div>
                        <div class="severity-label">
                            <div class="color-indicator low"></div>
                            <div class="text">Low</div>
                        </div>

                    </div>
            </div>
        </div>
        <div class="spark-whitebox spark-whiteboxshadow device-cyber-reilience">
            <app-cyber-reilience  [filterPage]="'cyberDevice'" [title]="cyberResilienceGraphTitle"  [deviceId]="deviceId"
                [apiName]="cyberResilienceGraphApiName" [showFilter]="cyberResilienceFilter" [payLoad]="apiPayload">
            </app-cyber-reilience>
        </div>
    </div>
</div>