import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { AgGridComponent } from '../ag-grid/ag-grid.component';
import { CONFIG } from '../../constants/config';
import { CveAssetsService } from '../../services/cve-assets.service';
import { ICommonVulnarability, ICommonVulnarabilityResponse, IDrop, ISelectedVulnarability } from '../common-vulnerability/common.vulnerability.model';
import { RestService } from '../../services/rest.service';
import { ResponseService } from '../../services/response.service';
import { AuthService } from '../../services/auth.service';
import { END_POINTS } from '../../constants/endpoints';
import { CveService } from '../../services/cve.service';
import { SeverityVulComponent } from '../severit-vul/severity-vul.component';
import { CisaKevHeaderComponent } from 'src/app/modules/cve-dashboard/cisa-kev-header/cisa-kev-header.component';
import { CisaKevComponent } from 'src/app/modules/cve-dashboard/cisa-kev/cisa-kev.component';
import { EpssHeaderComponent } from 'src/app/modules/cve-dashboard/epss-header/epss-header.component';
import { CVE_PAGE } from '../../constants/constant-data';


@Component({
  selector: 'app-cve-assets',
  templateUrl: './cve-assets.component.html',
  styleUrls: ['./cve-assets.component.scss']
})
export class CveAssetsComponent {
  @ViewChild('myGrid') grid!: AgGridComponent;
  @ViewChild('searchField') searchField!: ElementRef;
  @Output() selectRowChange = new EventEmitter<any>();
  @Output() openCveSummery = new EventEmitter<any>();
  @Input() filterPage: string = '';
  @Input() isDashboard:boolean = false;
  public isApiCalled: boolean = false;
  public isCveSelected:boolean = false;
  public tableHeader = CONFIG.TABLE_INFO.CVE_DASHBOARD.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.CVE_DASHBOARD.PROPS;
  public commonVulnarabilites: ICommonVulnarability[] = [];
  public allCommonVulnarabilites: ICommonVulnarability[] = [];
  @Input() payLoad: any;
  @Input() apiName: string = '';
  @Input() showSearch: boolean = true
  @Input() isCveSummery: boolean = true;
  public vulnarabilityMenu: IDrop[] = [];
  public selectedVulnarability: ISelectedVulnarability = {};
  @Output() cveListLength = new EventEmitter<number>();

  public agDeviceColumnDefs: ColDef[] = [];

  constructor(private cveAssetsService: CveAssetsService,private restService: RestService,
    private responseService: ResponseService,
    private authService: AuthService,
    private cveService: CveService // Inject CveService here
  ) {
  }

  ngOnInit(): void {
    this.buildColumnDefs();
    this.loadCveCommonData();
  }

  private buildColumnDefs(): void { 
   this.agDeviceColumnDefs = [
    { headerName: this.tableHeader.POSITION,
      field: this.tableProps.POSITION,
      headerTooltip:this.tableHeader.POSITION,
      lockPosition: true,
      // pinned: 'left',
      minWidth:60,
      maxWidth:70,
      filter:false,
      sortable:false
    },
    { headerName: this.tableHeader.CVE_ID, field: this.tableProps.CVE_ID, headerTooltip:this.tableHeader.CVE_ID,lockPosition: true,minWidth:110},
    { headerName: this.tableHeader.CVSS, field: this.tableProps.CVSS,  headerTooltip:this.tableHeader.CVSS,minWidth:90,lockPosition: this.isDashboard},
    {
      headerName: this.tableHeader.SEVERITY,
      field: this.tableProps.SEVERITY,
      headerTooltip:this.tableHeader.SEVERITY,
      lockPosition: this.isDashboard,
      cellRenderer: SeverityVulComponent,
      minWidth:120,
      onCellClicked: (event: CellClickedEvent) => {
        this.getRowData(event);
      }
    },
    {
      headerName: this.tableHeader.CISA_KEY,
      field: this.tableProps.CISA_KEY,
      headerTooltip:CVE_PAGE.cisaKevHeaderTooltip,
      lockPosition: this.isDashboard,
      //headerComponent: CisaKevHeaderComponent,
      cellRenderer: CisaKevComponent,
      minWidth:100,
      cellClass: (params: any)=>{
        const exploitClass = params.data?.cisas_kev ? 'ag-red' : '';
        return exploitClass;
      },
      valueGetter: (params: any) => {
        return params.data?.cisas_kev ? 'Exploited' : 'Not Exploited';
      }
    },
    {
      headerName: this.tableHeader.EPSS,
      valueFormatter:(params) =>{    
              const value = params.value;    
              return value != null && ! isNaN (value) ?`${value}%`: value; },
      field: this.tableProps.EPSS,
      lockPosition:this.isDashboard,
      minWidth:100,
      // headerTooltip:this.tableHeader.EPSS,
      // tooltipField: this.tableProps.EPSS,
      headerComponent: EpssHeaderComponent
    },
   ]
  }

  loadCveCommonData(): void {
    this.isApiCalled = false;
    this.commonVulnarabilites = [];
    if (this.payLoad && this.payLoad.device_uuid) {
      const formattedPayload = [this.payLoad.device_uuid]; // Wrap the UUID in an array
      this.cveService.fetchCveCommonData((error: any, data: any) => {
        if (error) {
          console.error('Error fetching CVE common data', error);
          return;
        }
        this.isApiCalled = true
        this.commonVulnarabilites = data?.data?.cve_list || [];
        //sorting the data in format of critical,high,medium,low
        const critical = this.commonVulnarabilites.filter(item => item.severity === "Critical");      
        const high = this.commonVulnarabilites.filter(item => item.severity === "High");
        const medium = this.commonVulnarabilites.filter(item => item.severity === "Medium");
        const low = this.commonVulnarabilites.filter(item => item.severity === "Low");
        const sortedData = [...critical, ...high, ...medium, ...low]; 
        this.commonVulnarabilites=sortedData;     
        this.commonVulnarabilites.forEach((element: any,index:number) => {
          element.position=index+1;
        });
        this.cveListLength.emit(this.commonVulnarabilites.length);
        window.setTimeout(() => {
          if(this.grid && this.grid.grid && this.grid.grid.api){
            this.grid.grid.api.sizeColumnsToFit();
          }
        }, 10);
      },formattedPayload);
    } else {
      this.fetchCommonVulFilter(this.fetchCommonVulFilterCallback);
    }
  }

  public fetchCommonVulFilter(callback: Function) {
    this.isApiCalled = false;
    const filterDetails = JSON.parse(this.authService.getCookie('cve_vulnerability') || '[]');
    this.vulnarabilityMenu = filterDetails;
    if(this.vulnarabilityMenu && this.vulnarabilityMenu.length) {
      callback(this.vulnarabilityMenu);
    } else {
      this.restService.getApi(END_POINTS.DASHBOARD_COMMON_VUL_FILTER).subscribe({
        next: (response) => {
          const data = this.responseService.successResponse(response);
          if(data.status === 200 && data?.data?.vulnerabilitylevel) {
            this.setFilter('cve_vulnerability', data.data.vulnerabilitylevel);
            callback(data?.data?.vulnerabilitylevel);
          }
        },
        error: (err) => {
          this.setFilter('cve_vulnerability', []);
          callback([]);
          this.responseService.errorResponse(err);
        },
      })
    }
  }
  public setFilter(type: string, data: any) {
    this.vulnarabilityMenu = data;
    this.authService.setCookie(type, JSON.stringify(data));
  }
  public fetchCommonVulFilterCallback = (response: IDrop[]) => {
    this.isApiCalled = false;
    if (response.length) {
      this.vulnarabilityMenu = response;
      this.selectedVulnarability.commonVul = this.vulnarabilityMenu[0].name;
      this.selectedVulnarability.id = this.vulnarabilityMenu[0].id;
      this.setSelectedCookies();
      this.cveService.fetchCveCommonData((error: any, data: any) => {
        if (error) {
          console.error('Error fetching CVE common data', error);
          return;
        }
        this.commonVulnarabilites = data?.data?.cve_list || [];
        const critical = this.commonVulnarabilites.filter(item => item.severity === "Critical");      
        const high = this.commonVulnarabilites.filter(item => item.severity === "High");
        const medium = this.commonVulnarabilites.filter(item => item.severity === "Medium");
        const low = this.commonVulnarabilites.filter(item => item.severity === "Low");
        const sortedData = [...critical, ...high, ...medium, ...low]; 
        this.commonVulnarabilites=sortedData;     
        this.allCommonVulnarabilites = sortedData || [];
        this.commonVulnarabilites.forEach((element: any,index:number) => {
          element.position=index+1;
        });
        this.isApiCalled = true
        window.setTimeout(() => {
          this.grid.grid.api.sizeColumnsToFit();
        }, 10);
        if(this.selectedVulnarability.id){
          const value = {
            id:this.selectedVulnarability.id,
            name:this.selectedVulnarability.commonVul
          }
          this.filterVulnarability(value, true);
        }
      });
    }
  }
  setSelectedCookies(){
    if(this.filterPage) {
      const savedSelectedCookies = JSON.parse(this.authService.getCookie(this.filterPage) || '{}');
      if(savedSelectedCookies && Object.keys(savedSelectedCookies).length) {
        this.selectedVulnarability.commonVul = savedSelectedCookies.label;
        this.selectedVulnarability.id = savedSelectedCookies.id;
      }
      this.authService.setCookie(this.filterPage, JSON.stringify({label:this.selectedVulnarability.commonVul, id: this.selectedVulnarability.id }));
    }
  }

  public filterVulnarability(value: IDrop, isSelected: boolean = false) {
    const columnState = this.agDeviceColumnDefs
    .filter(col => col.field)
    .map(col => ({
      colId: col.field as string,
      sort: null
    }));
    this.grid.gridApi.applyColumnState({
      state: columnState,
      applyOrder: true
    });
    this.selectedVulnarability.commonVul = value.name;
    this.selectedVulnarability.id = value.id;
  if (this.selectedVulnarability.commonVul === 'All') {
    this.commonVulnarabilites = [...this.allCommonVulnarabilites];
  } else {
    this.commonVulnarabilites = this.allCommonVulnarabilites.filter(vul => vul.severity === this.selectedVulnarability.commonVul);
  }
    if(this.filterPage) {
      this.authService.setCookie(this.filterPage, JSON.stringify({label:this.selectedVulnarability.commonVul, id: this.selectedVulnarability.id }));
    }
  }

  filterAssetsField() {
    this.grid.grid.api.setGridOption(
      "quickFilterText",
       this.searchField?.nativeElement?.value
    );
  }

  getRowData(row: any){
    this.cveAssetsService.vulnerabilityRow.next(row.data);
   }
   public onRowSelected(event: any){
    if(event && event.length) {
      if(this.isCveSummery) { // this flag we have added becase in control panel same component has used  to open cve dettails overlay
        this.isCveSelected = true;
        this.openCveSummery.emit(event);
      } else {
        this.selectRowChange.emit(event[0]); //
      }
    }
 }
}
