import { Component, Input } from '@angular/core';
import { END_POINTS } from '../../constants/endpoints';
import { CveAssetsService } from '../../services/cve-assets.service';


@Component({
  selector: 'app-summary-assets',
  templateUrl: './summary-assets.component.html',
  styleUrls: ['./summary-assets.component.scss']
})
export class SummaryAssetsComponent {
  public securityGraphTitle = 'Security Score';
  @Input() securityApiName: any;
  public vulnerabilityGraphTitle = 'Misconfigurations';
  @Input() vulnerabilityApiName: any;
  public vulnerabilityCveGraphTitle = 'CVE'
  @Input() vulnerabilityCveApiName:any;
  public cyberResilienceGraphTitle = 'Asset  Resilience Trend';
  @Input() cyberResilienceGraphApiName: any;
  public cyberResilienceFilter = true; 
  @Input() apiPayload: any;
  public type: number = 1;
  @Input() deviceId:any;
}
