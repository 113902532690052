<!-- Agent Details section -->
<div *ngIf="is_api_called" class="admin_section out-layout loader-data-page">
    <div class="loader-data-section">
      <div class="loader-list white-box p0">
        <div class="role-data-section cred-data white-box p0">
          <ngx-skeleton-loader count="18">
          </ngx-skeleton-loader>
        </div>
     </div>
     <div class="loader-data-section white-box p0">
      <div class="role-data-section cred-data white-box p0">
        <ngx-skeleton-loader count="18">
        </ngx-skeleton-loader>
      </div>
     </div>
    </div>
   </div>
  <div *ngIf="isAgentsAvailable"  class="admin_section out-layout vpn-management-page"> 
  <div class="vpn-main-section">
      <div  class="vpn-list white-box p0">
        <div class="role-data-section cred-data white-box p0">
          <!-- <ngx-skeleton-loader count="18" *ngIf="is_api_called">
          </ngx-skeleton-loader> -->
        </div>
        <div class="vpn-search-header">
          <button class="btn add-vpn" (click)="scanNow()"><mat-icon>add</mat-icon></button>
          <div class="search-bar">
            <mat-form-field appearance="fill">
              <mat-label>Search </mat-label>
              <input matInput placeholder="" [(ngModel)]="searchAgent" autocomplete="off" autocomplete="off" type="text">
              <mat-icon class='search-icon' matPrefix>search</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="vpn-search-list scroll">  
          <div *ngFor="let item of agentData | searchAgent : searchAgent; let i = index " (click)="agentRowClicked(item, i)" [class.active]="i === selectedIndex" 
            class="vpn-list-item">
            <!-- <label class="labelHeading text_ellipsis" appSafariEllipsis tooltip="{{agentData.description}}">{{agentData.description}}</label> -->
              <p class="labelHeading" matTooltip="{{agentData.description}}" class="text_ellipsis paramer-value">{{agentData.description}}</p>
            <div class="vpn-tunnelname">
              <span>
                <img [src]="'../../../../../../assets/images/agent_' + item?.devices_vendor_name.toLowerCase() + '.svg'" class="normal-state">
                <img [src]="'../../../../../../assets/images/' +'agent_'+ item?.devices_vendor_name.toLowerCase() +'_hover'+ '.svg'" class="hover-state">
              </span> 
              <span class="vulnerab_label" [ngClass]="item?.agentClass">{{item?.agentLabel}}</span>
            </div>
            <!-- <p class="vpn-description text_ellipsis" appSafariEllipsis matTooltip="{{item?.description}}">{{item?.description}}</p> -->
            <p class="vpn-description">
              <label>
                <p matTooltip="{{item?.description}}" class="text_ellipsis paramer-value">{{item?.description}}</p>
            </label>
            </p>
          </div>
        </div>
        
        <!-- <div class="delete-agent">
          <mat-checkbox>
          </mat-checkbox>
          <span>Deleted & Unenrolled Agent</span>
        </div> -->
      </div>
      <div class="vpn-data-section white-box p0" >
        <div class="role-data-section cred-data white-box p0">
          <!-- <ngx-skeleton-loader count="18" *ngIf="is_api_called">
          </ngx-skeleton-loader> -->
        </div>
        <div class="vpn-data">
          <div class="vpn-data-header">
            <h3>Agent Details</h3>
            <div class="vpn-btns">
              <button class="download-btn btn-link" (click)="downloadTemplate(agentDetails)"> <img src="../../../../../../assets/images/dowmlod_template.svg"
                alt="">Download template</button>
                            <div class="actions-btn">
                <button [matMenuTriggerFor]="menu" class="dropdown-button">Actions <mat-icon
                    class="sort-icon">keyboard_arrow_down</mat-icon></button>
                <mat-menu #menu="matMenu" class="white-menu">
                  <button mat-menu-item (click)="editCloudScan(agentDetails)">Edit</button>
                  <button mat-menu-item *ngIf="agentDetails.Status===2" (click)="pauseCloudScan(agentDetails)">Pause</button>
                  <button mat-menu-item (click)="deleteCloudScan(agentDetails)">Delete</button>
                  <button mat-menu-item *ngIf="agentDetails.Status===2" (click)="unEnrollCloudScan(agentDetails)">Unenroll</button>
                </mat-menu>
              </div>
            </div>
          </div>
          <div class="vpn-data-inner">
            <div class="right-inner">
              <div class="item">
                <p class="text">Agent Name</p>
                <div class="text-row">
                <img [src]="'../../../../../../assets/images/' +'agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'" class="normal-state">
                <p class="sub-text">{{agentDetails?.name}}</p>
                </div>
              </div>
              <div class="item">
                <p class="text">Description</p>
                <p class="sub-text text_ellipsis" appSafariEllipsis matTooltip="{{agentDetails?.description}}">{{agentDetails?.description}}</p>
              </div>
              <div class="item">
                <p class="text">Status</p>
                <p class="sub-text"> <span class="vulnerab_label text_ellipsis" appSafariEllipsis [ngClass]="agentDetails?.agentClass" matTooltip="{{agentDetails?.agentLabel}}">{{agentDetails?.agentLabel}}</span></p>
              </div>
  
  
              <!-- <div class="item">
                <p class="text">Version</p>
                <p class="sub-text">{{agentDetails?.version}}</p>
              </div>
              <div class="item">
                <p class="text">Server</p>
                <p class="sub-text">{{agentDetails?.server}}</p>
              </div> -->
  
              <div class="item">
                <div>
                  <mat-form-field appearance="outline">
                    <mat-label>Enrollment Token</mat-label>
                    <input matInput *ngIf="hide" value="{{agentDetails?.otp}}" type="password" readonly>
                    <input matInput *ngIf="!hide" value="{{agentDetails?.otp}}" type="text" readonly>
                    <span matSuffix class="icon-container">
                      <button mat-icon-button (click)="copyToClipboard(agentDetails?.otp)">
                        <mat-icon>content_copy</mat-icon>
                      </button>
                      <button mat-icon-button (click)="hide = !hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                    </span>
                  </mat-form-field>
                </div>
              </div>
              
              <!-- <div class="item">
                <p class="text">Fleet URl</p>
                <p class="sub-text">{{agentDetails?.Fleet_uri}}</p>
              </div> -->
              <div class="item">
                <p class="text">Created By</p>
                <p class="sub-text">{{agentDetails?.created_by}}</p>
              </div>
              <div class="item">
                <p class="text">Last Modified</p>
                <p class="sub-text">{{agentDetails?.last_modified_by}}</p>
              </div>
              <!-- <div class="item">
                <p class="text">Organisation Unit Ids</p>
                <p class="sub-text">{{agentDetails?.organization_unit_ids}}</p>
              </div> -->
              <!-- <div class="item">
                <p class="text">Instance Type</p>
                <p class="sub-text">{{agentDetails?.instance_type}}</p>
              </div> -->
              <div class="item">
                <p class="text">Last Activity Time</p>
                <p class="sub-text">{{agentDetails?.last_modified_by}}</p>
              </div>
              <!-- <div class="item">
                <p class="text">Instance Type</p>
                <p class="sub-text">Lorem ipsum dolor</p>
              </div> -->
              <div class="item">
                <p class="text">Date Installed</p>
                <p class="sub-text">{{agentDetails?.created_at | date: 'd MMM, y HH:mm:ss'}}</p>
              </div>
              <div class="item">
                <p class="text">Date Created</p>
                <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
              </div>
              <!-- <div class="item">
                <p class="text">Last Activity Time</p>
                <p class="sub-text">{{agentDetails?.last_activity_time}}</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
  <div  *ngIf="!isAgentsAvailable" class="no-data-section d-flex">
      <div class="no-data-info text_center">
        <img src="../../../../assets/images/Group.svg" />
        <h3>There is no data available,</h3>
        <p>You have zero Cloud Agents. Configure an Agent by clicking on 'Scan Now' to get security analysis of your cloud resources.</p>
        <button class="btn btn-primary" (click)="scanNow()">Scan Now</button>
      </div>
  </div>
    <div>
      <mat-sidenav-container  class="global-filter-main">
        <mat-sidenav  #cloudNewScan mode="over" position="end" class="cloud-scan-sidenav">
          <app-cloud-new-scan></app-cloud-new-scan>
        </mat-sidenav>
     
    </mat-sidenav-container>
  
    </div>
  