import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { ResponseService } from '../../services/response.service';
import { RestService } from '../../services/rest.service';
import { IVulnarability, IVulnarabilityResponse } from './vulnerability.model';
import { SharedService } from '../../services/shared.service';
import { CONSTANT } from '../../constants/constant-data';
import * as echarts from 'echarts';
import { Utils } from '../../utils';
import { ControlPanelService } from '../../services/control-panel.service';
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-vulnerability',
  templateUrl: './vulnerability.component.html',
  styleUrls: ['./vulnerability.component.scss']
})
export class VulnerabilityComponent {
  onNavigateTopage(type: string, role: {}) {
    throw new Error();
  }
  @Input() title: string = '';
  @Input() apiName: string = '';
  @Input() link: boolean = false;
  @Input() chartheight: number| any = null;
  @ViewChild('vulnarability') vulnarability!: ElementRef;
  @ViewChild('donutChart', { static: true }) donutChart!: ElementRef;
  @Input() vulnerabilityGraphSec: string = 'pie-chart';
  public vulnarabilityChartCanvas: HTMLCanvasElement;
  public vulnarabilityChartCtx: CanvasRenderingContext2D | [];
  public vulnarabilityResponse: IVulnarability[] = []
  public vulnarabilityLegends: string[] = [];
  public vulnarabilityColor: string[] = ['#F53B3B', '#F7941F', '#BDB615', '#00A61B'];
  public vulnarabilityChartData: number[] = [];
  public isSpinner: boolean = false;
  public vulChart!: Chart;
  public constant = CONSTANT;
  noDataMsg: String = CONSTANT.NO_DATA_MSG;
  public myChart: any = null;
  public vulnarabilitiesTotalCount: number = 0;
  public getFirstLetter = Utils.getFirstLetter;
  vulnarabilitiesGraphDataArr: any = [];
  public totalCount: number = 0;
  public vulnChart:any;
  public initsChart:any;
  @Output() dataExport = new EventEmitter<any>();
  severityLevels: ('critical' | 'high' | 'medium' | 'low')[] = ['critical', 'high', 'medium', 'low'];
  data:any = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  };
  constructor(
    private responseService: ResponseService,
    private restService: RestService,
    private sharedService: SharedService,
    private controlPannelService: ControlPanelService,
    private apiService: ApiService
  ) {
    this.vulnarabilityChartCanvas = this.vulnarability?.nativeElement;
    this.vulnarabilityChartCtx = this.vulnarabilityChartCanvas?.getContext("2d") as CanvasRenderingContext2D;
    this.controlPannelService.vulFilter.subscribe((res:any)=>{
      if(res && this.apiName){
        this.totalCount = 0;
        this.data = {
          critical: 0,
          high: 0,
          medium: 0,
          low: 0,
          total: 0,
        };
        this.loadVulnarabilityChart(this.loadVulnarabilityChartCallback);
      }
    })
  }
  ngOnInit(): void {
    if (this.apiName) {
      this.loadVulnarabilityChart(this.loadVulnarabilityChartCallback);
    }   this.sharedService.isThemeUpdate.subscribe((theme: any)=>{
      if ( this.initsChart) {
        this.initsChart.dispose();
      }
      this.initChart();

      if ( this.vulnChart) {
        this.vulnChart.dispose();
      }
      this.initVulChart();
    })

  }
  ngAfterViewInit(): void {
    this.vulnarabilityChartCanvas = this.vulnarability?.nativeElement;
    this.vulnarabilityChartCtx = this.vulnarabilityChartCanvas?.getContext("2d") as CanvasRenderingContext2D;
  }
  public loadVulnarabilityChart(callback: Function): void {
    this.isSpinner = true;
    console.log(this.apiName);
    const subs = this.restService.getApi(this.apiName).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
   this.apiService.apiSubscriber.push(subs);
  }
  public loadVulnarabilityChartCallback = (response: any) => {
    this.isSpinner = false;
    if (response.status === 200 && response.data) {
      this.vulnarabilityResponse = response.data;
       //if(this.title === 'Vulnerabilities'){
      if(this.title === 'Misconfigurations'){
       this.totalCount = response.data.reduce((sum: any, item: any) => sum + item.count, 0);
        this.vulnarabilityResponse.forEach(item => {
          switch (item.vulnerabilities_name) {
            case "Critical":
              this.data.critical = item.count;
              break;
            case "High":
              this.data.high = item.count;
              break;
            case "Medium":
              this.data.medium = item.count;
              break;
            case "Low":
              this.data.low = item.count;
              break;
          }
        });
        this.dataExport.emit({data:this.data,name:'vul',total: this.totalCount});
        this.initVulChart();
      } 
      else {
        if(response?.data){
          this.totalCount = response.data.count ? response.data.count : 0;
          if(response?.data?.count){
            this.data = response?.data
          } else {
            if(response?.data.length === 0){
              this.data = {
                critical: 0,
                high: 0,
                medium: 0,
                low: 0,
                total: 0,
              };
            }
          }
          this.dataExport.emit({data:this.data,name:'cve',total: this.totalCount});
          this.initChart();
        }
        
      }
    }
  }
  initChart() {
    const isDarkTheme = document.body.classList.contains('dark-theme');
    const titleColor = isDarkTheme ? '#fff' : '#333';  
    const borderColor = isDarkTheme ? '#191C24' : '#fff'; 
    this.initsChart = echarts.init(this.donutChart.nativeElement);
    const option = {
      title: {
        text: this.totalCount.toString(),
        left: 'center',
        top: 'center',
        textStyle: {
          fontSize: 24,
          fontWeight: 'bold',
          color: titleColor,
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)',
      },
      series: [
        {
          name: 'Vulnerabilities',
          type: 'pie',
          radius: ['60%', '80%'],
          avoidLabelOverlap: false,
          itemStyle: {
            // borderRadius: 10,
            borderColor: borderColor,
            borderWidth: 2
          },
          label: { show: false },
          labelLine: { show: false },
          data: [
            { value: this.data.critical, name: 'Critical', itemStyle: { color: '#750000' } },
            { value: this.data.high, name: 'High', itemStyle: { color: '#d91e18' } },
            { value: this.data.medium, name: 'Medium', itemStyle: { color: '#f9690e' } },
            { value: this.data.low, name: 'Low', itemStyle: { color: '#f4d03f' } },
          ],
        },
      ],
    };
    this.initsChart.setOption(option);
  }

  initVulChart() {
    const isDarkTheme = document.body.classList.contains('dark-theme');
    const titleColor = isDarkTheme ? '#fff' : '#333';  
  const borderColor = isDarkTheme ? '#191C24' : '#fff';  
    this.vulnChart = echarts.init(this.donutChart.nativeElement);
    const option = {
      title: {
        text: this.totalCount.toString(),
        left: 'center',
        top: 'center',
        textStyle: {
          fontSize: 24,
          fontWeight: 'bold',
          color: titleColor,
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)',
      },
      series: [
        {
          name: 'Vulnerabilities',
          type: 'pie',
          radius: ['60%', '80%'],
          avoidLabelOverlap: false,
          itemStyle: {
            // borderRadius: 10,
            borderColor: borderColor,
            borderWidth: 2
          },
          label: { show: false },
          labelLine: { show: false },
          data: [
            { value: this.data.critical, name: 'Critical', itemStyle: { color: '#750000' } },
            { value: this.data.high, name: 'High', itemStyle: { color: '#d91e18' } },
            { value: this.data.medium, name: 'Medium', itemStyle: { color: '#f9690e' } },
            { value: this.data.low, name: 'Low', itemStyle: { color: '#f4d03f' } },
          ],
        },
      ],
    };
    this.vulnChart.setOption(option);
  }
}
