import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-vulnerabilities',
  templateUrl: './vulnerabilities.component.html',
  styleUrls: ['./vulnerabilities.component.scss']
})
export class VulnerabilitiesComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  filterMenuOpen = false;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  graphProps(data: null | any, total: any) {
    let dataVul: any = data?.vulnerability_levels[0];
    let conic_gradient: any, color: any;
    if (dataVul === null || dataVul === undefined && total) {
      return '#fff';
    }
    if (dataVul) {
        const vulPercetange = {
          critical: (dataVul.critical/total)*100 + '%',
          high: (dataVul.high/total)*100+ '%',
          medium: (dataVul.medium/total)*100+ '%',
          low: (dataVul.low/total)*100+ '%'
        }
        const totalVal = data
        color = '#FA4450';
        conic_gradient = `radial-gradient(white 40%, transparent 41%),conic-gradient(#FD6165 0% ${vulPercetange.critical}, #E78B1E ${vulPercetange.critical} ${vulPercetange.high}, #B6B13A ${vulPercetange.high} ${vulPercetange.medium}, #00A61B ${vulPercetange.medium} ${vulPercetange.low})`;
    }
    return conic_gradient;
    //return 'radial-gradient(white 43%, transparent 41%), conic-gradient(#c23035 0% 35% , #E78B1E; 35% 60%, #B6B13A 60% 80%, #00A61B 80% 100%)';
  }

}
