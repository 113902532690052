import { ChangeDetectorRef, Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { Subscription, debounceTime, distinctUntilChanged, filter, fromEvent, switchMap, of } from 'rxjs';
import { GlobalFilterService } from '../services/global-filter.service';
import { FilterService } from '../services/filter.service';
import { ResponseService } from '../services/response.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SpinnerService } from '../services/spinner.service';
import { MatTableDataSource } from '@angular/material/table';
import { Search, tableResponse } from './search.model';
import { GlobalSearchService } from '../services/global-search.service';
import { NavigationExtras, Router } from '@angular/router';
import * as _ from 'lodash';
import { environment } from "src/environments/environment";
import { SharedService } from '../services/shared.service';
import { AuthService } from '../services/auth.service';
import { ToolTipConfig } from '../constants/constant-data';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MyScansService } from '../services/my-scans.service';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  @ViewChild('ssoCheckbox') ssoCheckbox!: MatCheckbox;
  public searchSubscription!: Subscription;
  public authSubscription!: Subscription;
  // new global code start
  public displayedColumns: string[] = ['column_one', 'column_two', 'column_three', 'column_four', 'column_five', 'column_six'];
  public searchSubscriptionList: Subscription[] = [];
  public typeTimeOut: any;
  public isApiStartedCalling: boolean = true;
  public noResultFound: boolean = false;
  public resultAvailable: boolean = false;
  public pageNum = 1;
  public contentNum = Math.ceil((window.innerHeight - 50) / 50);
  public headersInfo: any[] = _.cloneDeep(this.globalSearchService.defaultSearchDetails);
  public apiCount = 0;
  // new global code end here
  public searchText: string = '';
  public disableTooltip = false;
  public regex_search = 0;
  public regex_html = true;
  public regex_hide = false;
  public isNoDataAvailable:boolean=false
  public highlight = false;
  public timer: any;
  public restrictContent: boolean = true;
  public toolTipOption: any = _.cloneDeep(ToolTipConfig);
  public pageCall: boolean = true;
  public isPageLoad: boolean = true;
  public numberOfPages: number = 1;
  public isScrollOn: boolean = false;
  public selectedTab: any;
  @ViewChild('searchField') searchField!: ElementRef;
  dialogRef!: MatDialogRef<SearchBarComponent> | null;

  public tablesObjects: any = {
    [tableResponse.Assets]: { title: 'Assets', type: tableResponse.Assets, url: 'on-premises/asset-management' },
    [tableResponse.Scans]: { title: 'Scans', type: tableResponse.Scans, url: 'on-premises/scan-details' },
    [tableResponse.Roles]: { title: 'Roles', type: tableResponse.Roles, url: 'organization/role-management' },
    [tableResponse.Benchmarks]: { title: 'Benchmarks', type: tableResponse.Benchmarks, url: 'on-premises/security-information' },
    [tableResponse.Parameters]: { title: 'Parameters', type: tableResponse.Parameters, url: 'on-premises/security-information' },
    [tableResponse.Users]: { title: 'Users', type: tableResponse.Users, url: 'organization/user-management' },
    [tableResponse.CVE]: { title: 'CVE', type: tableResponse.CVE, url: 'on-premises/cve-dashboard' }
  }
  constructor(private globalFilterService: GlobalFilterService,
    private globalSearchService: GlobalSearchService,
    private filterService: FilterService,
    private responseService: ResponseService,
    private spinnerService: SpinnerService,
    private router: Router,
    private renderer: Renderer2,
    private sharedService: SharedService,
    private authService: AuthService,
    private dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef,
    private myScansService: MyScansService,
    private headerService: HeaderService) {
    this.toolTipOption.display = true;
    this.globalSearchService.searchCleared?.subscribe(() => {
      this.renderer?.setValue(this.searchField?.nativeElement, this.globalSearchService.searchTerm);
    });
  }
  restrictApiCall() {
    this.searchSubscriptionList?.forEach((sub: Subscription) => sub.unsubscribe());
    this.isApiStartedCalling = true;
    this.noResultFound = false;
    this.apiCount = 0;
    this.resultAvailable = false;
    this.headersInfo = _.cloneDeep(this.globalSearchService.defaultSearchDetails);
    return;
  }
  public searchContent(event: any, isClear = false) {
    this.searchSubscriptionList?.forEach((sub: Subscription) => sub.unsubscribe());
    clearTimeout(this.typeTimeOut);
    this.globalSearchService.setSearchTerm(this.searchField.nativeElement.value);
    this.globalSearchService.setSearchText(this.searchText);
    if ((!isClear && !event?.target?.value || event?.target?.value?.length < 3) || (isClear && !this.searchField.nativeElement.value || this.searchField.nativeElement.value.length > 3)) {
      this.restrictApiCall()
      this.dataIsAllNotNull=false;
    }
    this.typeTimeOut = setTimeout(()=> {
      if (event?.keyCode != 13) {
        if ((!isClear && event?.target?.value && event?.target?.value?.length > 2) || (isClear && this.searchField.nativeElement.value.length > 2)) {
          this.isApiStartedCalling = false;
          this.regex_hide = false;
          this.noResultFound = false;
          this.apiCount = 0;
          this.resultAvailable = true;
          this.highlight = true;
          this.isScrollOn = false;
          this.isNoDataAvailable=false;
          this.headersInfo = _.cloneDeep(this.globalSearchService.defaultSearchDetails);
          this.headersInfo.forEach((headerObj: any, index) => {
            headerObj.isApiCall = false;
            const subScription: Subscription = this.globalSearchService.search(isClear ? this.searchField.nativeElement.value : event.target.value, headerObj.key, this.regex_search).subscribe({
              next: (response) => {
                this.apiCount++;
                if(isClear) {
                  headerObj.highlight = false;
                }
                const res = this.responseService.successResponse(response);
                if (res && res.status === 200 && res.data?.data?.length) {
                  headerObj.value = res.data?.count || 0;
                  headerObj.response = res.data.data;
                  this.processSearchData(res.data.data, headerObj);
                  headerObj.isApiCall = true;
                } else {
                  headerObj.value = 0;
                  if(res.data==null && this.regex_search) {
                    headerObj.response = null
                  } else {
                    headerObj.response = [];
                  }
                  headerObj.data = new MatTableDataSource<any>([]);
                  if(this.regex_search && !this.regex_hide && !this.isNoDataAvailable) {
                    this.isNoDataAvailable = true;
                  }
                }
                if(this.apiCount === this.headersInfo.length) {
                  if(!this.regex_search) {
                    this.noResultFound = this.isResultNotAvailalable(this.headersInfo).noResult;
                  }
                  this.resultAvailable = this.isResultNotAvailalable(this.headersInfo).dataAvailable;
                  if(this.regex_search) {
                    this.regex_hide = this.isResultNotAvailalable(this.headersInfo).noResult;
                  }
                  this.isApiStartedCalling = true;
                  this.globalSearchService.setMainSearchData(this.headersInfo);
                }
                headerObj.isApiCall = true;
                this.changeDetectorRefs.detectChanges();
              },
              error: (err) => {
                this.authService.userLoggedOutSubject.next();
                this.responseService.errorResponse(err);
                this.apiCount++;
                if(this.regex_search && !this.regex_hide) {
                  this.regex_hide = true;
                }
                if(this.apiCount === this.headersInfo.length) {
                  if(!this.regex_search) {
                    this.noResultFound = this.isResultNotAvailalable(this.headersInfo).noResult;
                  }
                  this.resultAvailable = this.isResultNotAvailalable(this.headersInfo).dataAvailable;
                }
                headerObj.data = new MatTableDataSource<any>([]);
                headerObj.value = 0;
                headerObj.isApiCall = true;
                this.globalSearchService.setMainSearchData(this.headersInfo);
              },
            })
            this.searchSubscriptionList.push(subScription);
          })
          if(this.isNoDataAvailable) {
            this.regex_hide = true;
          }
        }
      }
    }, 500);
  }
  public processSearchData(response: any, headerObj: any, isScroll = false) {
    const searchData: any = [];
    headerObj.response = response;
    response?.forEach((dataRes: any)=>{
      const s = new Search(dataRes, headerObj);
      searchData.push(s);
    })
    if(isScroll) {
      let data = headerObj?.data.data.length ? headerObj?.data.data : [];
      if (data.length) {
        data = data.concat(searchData || []);
      } else {
        data = searchData || [];
      }
      headerObj.data = new MatTableDataSource<any>(data);
    } else {
      headerObj.data = new MatTableDataSource<any>(searchData);
    }
  }
  ngAfterViewInit(): void {
    this.searchText = this.globalSearchService.searchText;
    this.regex_search=this.globalSearchService.type;
    if (this.regex_search === 1) {
      this.ssoCheckbox.checked = true;
    }
    if(this.globalSearchService.mainSearchData && Object.keys(this.globalSearchService.mainSearchData).length) {
      this.headersInfo = this.globalSearchService.mainSearchData;
      this.isApiStartedCalling = true;
      this.highlight = true;
      this.resultAvailable =  this.isResultNotAvailalable(this.headersInfo).dataAvailable;
      if(!this.regex_search) {
        this.noResultFound = this.isResultNotAvailalable(this.headersInfo).noResult;
        if(!this.resultAvailable) {
          this.noResultFound = false;
        }
      }
    }
    if(!this.headersInfo) {
      this.headersInfo = _.cloneDeep(this.globalSearchService.defaultSearchDetails);
    }
    this.pageNum = this.globalSearchService.pageNum || 1;
  }
  ngOnInit(): void {
    this.searchSubscription = this.globalFilterService.gobalSearchState.subscribe(
      (flag: boolean) => {
        this.openGlobalSearch(flag);
      }
    )
    this.authSubscription = this.authService.userLoggedOutSubject.subscribe(() => {
      this.searchField.nativeElement.value = '';
      this.globalSearchService.clearAll();
      this.searchText = '';
      this.globalSearchService.setSearchTerm('');
      this.renderer?.setValue(this.searchField?.nativeElement, '');
      this.sharedService.clearData();
    })
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.searchSubscriptionList?.forEach((sub: Subscription) => sub.unsubscribe());
  }

  navigateToShowResults(item: any) {
    this.headerService.dispatchDisableSearch(true)
    this.globalSearchService.isRedirected = true;
    this.globalSearchService.headerText = '';
    this.globalSearchService.searchTerm = this.searchField.nativeElement.value;
    this.globalSearchService.type = this.regex_search;
    this.globalSearchService.redirectiontype = item.type;
    const navigationExtras: NavigationExtras = {
      state: {
        searchTrue: true // Set searchTrue flag
      }
    };
    if (item.type === tableResponse.Assets) {
      this.sharedService.setData(item.data);
      this.router.navigate([this.tablesObjects[item.type].url], navigationExtras).then(() => {
        this.globalSearchService.isRedirected = true;
      });
    } else if (item.type === tableResponse.Scans) {
      this.globalSearchService.headerText = item.data.name;
      this.router.navigate([this.tablesObjects[item.type].url, item.id, 'status', item.data.status], navigationExtras).then(() => {
        this.globalSearchService.isRedirected = true;
      });
    } else if ((item.type === tableResponse.Parameters) || (item.type === tableResponse.Benchmarks)) {
      if (item.type === tableResponse.Parameters) {
        this.authService.setCookie(`agGridFilter_sec-info-table`,JSON.stringify({}));
        this.globalSearchService.searchTerm = item.data.name;
        item.data.redirection=true;
        //item.data.
        this.sharedService.setData(item.data);
      }
      if(item.type === tableResponse.Benchmarks){
        this.authService.setCookie(`agGridFilter_sec-info-table`,JSON.stringify({}));
        this.globalSearchService.searchTerm = item.data.name;
        item.data['benchmark'] = true;
        this.sharedService.setData(item.data);
      }
      this.router.navigate([this.tablesObjects[item.type].url], navigationExtras).then(() => {
        this.globalSearchService.isRedirected = true;
      });
    } else if (item.type === tableResponse.Roles) {
      this.sharedService.setData(item.data);
      this.router.navigate([this.tablesObjects[item.type].url], navigationExtras).then(() => {
        this.globalSearchService.isRedirected = true;
      });
    } else if (item.type === tableResponse.Users) {
      this.router.navigate([this.tablesObjects[item.type].url], navigationExtras).then(() => {
        this.globalSearchService.isRedirected = true;
      });
    } else if (item.type === tableResponse.CVE) {
      this.router.navigate([this.tablesObjects[item.type].url], navigationExtras).then(() => {
        this.globalSearchService.isRedirected = true;
      });
    }
    this.filterService.closeSidenav();
    this.dialog.openDialogs.forEach(dialogRef => {
      const componentInstance = dialogRef.componentInstance;
      if (componentInstance instanceof SearchBarComponent) {
        dialogRef.close();
      }
    });
    this.globalSearchService.searchCleared.emit();
  }
  public openGlobalSearch(flag: boolean) {
    this.filterService.toggleSidenav('globalSearch');
  }
  public closeFilter() {
    this.filterService.closeSidenav();
  }
  public onScroll() {
    if (this.isApiStartedCalling && (this.pageNum !== this.numberOfPages)) {
      this.pageNum++;
      this.isScrollOn = true;
      this.viewApiCall(this.selectedTab, true)
    }
  }
  public viewApiCall(selectedTab: any = {}, isScroll = false) {
    this.searchSubscriptionList?.forEach((sub: Subscription) => sub.unsubscribe());
    this.isApiStartedCalling = false;
    if(isScroll) {
      this.isApiStartedCalling = true;
    }
    this.apiCount = 1;
    this.headersInfo.forEach((headerObj: any)=>{
      if(!isScroll) {
        headerObj.data.data = [];
        headerObj.value = 0;
        headerObj.highlight = false;
        headerObj.response = [];
        headerObj.isApiCall = true;
        this.highlight = true;
      }
      if(!isScroll && selectedTab && selectedTab.key === headerObj.key ) {
        headerObj.highlight = true;
        headerObj.isApiCall = false;
      }
    })
    this.globalSearchService.search(this.searchField.nativeElement.value, selectedTab.key, this.regex_search, this.pageNum, this.contentNum).subscribe({
      next: (response) => {
        this.isScrollOn = false;
        this.searchSubscriptionList?.forEach((sub: Subscription) => sub.unsubscribe());
        const res = this.responseService.successResponse(response);
        if (res && res.status === 200 && res.data?.data.length) {
          selectedTab.value = res.data?.count || 0;
          this.processSearchData(res.data.data, selectedTab, isScroll);
          selectedTab.isApiCall = true;
          this.isApiStartedCalling = true;
        } else {
          selectedTab.value = 0;
          selectedTab.data = new MatTableDataSource<any>([]);
          selectedTab.isApiCall = true;
          this.isApiStartedCalling = true;
        }
        this.numberOfPages = Number(res?.data?.numberofpages) || 0;
        this.globalSearchService.setMainSearchData(this.headersInfo);
      },
      error: (err) => {
        selectedTab.data = new MatTableDataSource<any>([]);
        selectedTab.value = 0;
        selectedTab.isApiCall = true;
        this.globalSearchService.setMainSearchData(this.headersInfo);
        this.authService.userLoggedOutSubject.next();
        this.responseService.errorResponse(err);
        this.isApiStartedCalling = true;
      },
    })
  }
  public viewClicked() {
    return this.globalSearchService.isViewClicked;
  }
  public showSelectedSerachResults(selectedTab: any) {
    this.globalSearchService.setIsViewClicked(true);
    this.selectedTab = selectedTab;
    this.isScrollOn = false;
    this.viewApiCall(selectedTab);
  }
  public onViewAllHandler(selectedTab: any) {
    this.pageNum = 1;
    this.selectedTab = selectedTab;
    this.isScrollOn = false;
    this.showSelectedSerachResults(selectedTab);
  }

  public clearSelection(data: string | any) {
    this.isApiStartedCalling = false;
    this.resultAvailable = true;
    this.highlight = true;
    this.selectedTab = null;
    this.isScrollOn = false;
    this.dataIsAllNotNull=false;
    this.globalSearchService.setIsViewClicked(false);
    this.headersInfo.forEach((headerObj: any)=>{
      headerObj.data.data = [];
      headerObj.value = 0;
      headerObj.highlight = false;
      headerObj.response = [];
      headerObj.isApiCall = false;
    })
    this.searchContent(this.searchField, true);
  }
  public disableRow(item: any) {
    if (item.column_five === 'Cancelled' || item.column_five === 'Failed') {
      return false;
    }
    return true;
  }
  clearAllData(): void {
    this.searchText = '';
    this.regex_search=0;
    this.ssoCheckbox.checked = false
    this.globalSearchService.clearAll();
    this.globalSearchService.setSearchTerm('');
    this.globalSearchService.setMainSearchData({});
    this.globalSearchService.setIsViewClicked(false);
    this.globalSearchService.setPageNum(1);
    this.globalSearchService.searchCleared.emit();
    this.renderer?.setValue(this.searchField?.nativeElement, '');
    this.sharedService.clearData();
    this.headersInfo = _.cloneDeep(this.globalSearchService.defaultSearchDetails);
    this.isApiStartedCalling = true;
    this.noResultFound = false;
    this.resultAvailable = false;
    this.highlight = false;
    this.dataIsAllNotNull=false;
    this.regex_hide=false;
  }
  ssoToggle(event: MatCheckboxChange): void {
    this.regex_search = event.checked ? 1 : 0;
    this.globalSearchService.type = this.regex_search;
    if (this.regex_search == 0) {
      this.regex_hide = false;
    }
  }
  dataIsAllNotNull:boolean=false;
  isResultNotAvailalable(headersInfo: any) {
    let isResultNotExist = true, dataAvailable = false;
    let dataIsAllNotNull = false;

    headersInfo?.forEach((headerObj: any)=>{
      if (headerObj?.response !== null) {
        dataIsAllNotNull = true;
        this.dataIsAllNotNull=true;
      }
      if(headerObj?.response?.length) {
        if(isResultNotExist) {
          isResultNotExist = false;
        }
        if(!dataAvailable) {
          dataAvailable = true;
        }
      }
    })
    return {noResult : isResultNotExist, dataAvailable: dataAvailable,dataIsAllNotNull: dataIsAllNotNull};
  }
}
