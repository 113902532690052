<!-- <div class="cp-tooltip ag-tooltip" >
    <div class="scroll">
        <p class="text_ellipsis" title="{{params.data.failed_count}} of {{params.data.count}} assets has been failed"><strong>{{params.data.failed_count}} of {{params.data.count}} assets has been failed</strong></p>
        <ul>
            <li *ngFor="let vulObj of params.data.hover[0]">
                {{makeCapital(vulObj.device)}} ({{vulObj.count}})
            </li>
        </ul>
    </div>
</div> -->
<div class="ag-center paramer-value">
    <span [appCustomTooltip]="customTooltip">
        {{params.data.failed_count}}
        </span>
</div>
<ng-template #customTooltip>
    <div class=" asset-vul-tooltip ag-tooltip"> 
        <div class="ag-dialog">
            <div class="scroll">
                <p class="" title="{{params.data.failed_count}} of {{params.data.count}} assets has been failed"><strong>{{params.data.failed_count}} of {{params.data.count}} assets has been failed</strong></p>
                <ul>
                    <li *ngFor="let vulObj of params.data.hover[0]">
                        {{makeCapital(vulObj.device)}} ({{vulObj.count}})
                    </li>
                </ul>
            </div>
        </div>
        </div>
</ng-template>


<!-- <div class="ag-vul-tooltip ag-tooltip" (mouseover)="currentMousePostion($event)"> 
    <div class="ag-dialog" [style.left]="positionVal.x+'px'" [style.top]="positionVal.y+'px'">
        <div>Critical: 21</div>
        <div>High: 32</div>
        <div>Medium: 42</div>
        <div>Low: 78</div>
    </div>
</div> -->