import { Component } from '@angular/core';

@Component({
  selector: 'app-cloud-resource-overlay',
  templateUrl: './cloud-resource-overlay.component.html',
  styleUrls: ['./cloud-resource-overlay.component.scss']
})
export class CloudResourceOverlayComponent {

}
