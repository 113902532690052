<div class="edit_cloud_scan">
  <div class="edit_cloud_scan_header">
    <div class="header1">
      <p>Edit</p>
      <div class="close-btn">
        <button mat-button class="btn close-sidebar" (click)="closeNotification()"><mat-icon>cancel</mat-icon></button>
      </div>
    </div>
  </div>
  <div class="info-fields">
    <form [formGroup]="agentEditForm" class="cloud-form">
      <div class="input-box-container">
        <mat-form-field class="full-width border-field no-bg" appearance="outline">
          <mat-label>Name</mat-label>
          <input formControlName="cloudname" matInput maxlength="21"> 
          <mat-error *ngIf="agentEditForm.get('cloudname')?.hasError('maxlength')">
              Maximum 20 characters allowed
          </mat-error>
          <mat-error *ngIf="agentEditForm.get('cloudname')?.hasError('required')">
              Name is required
          </mat-error>
      </mat-form-field>
      </div>
      <div class="input-box-container">
        <mat-form-field class="full-width border-field no-bg" appearance="outline">
          <mat-label>Description</mat-label>
          <input formControlName="description" matInput maxlength="51">
          <mat-error *ngIf="agentEditForm.get('description')?.hasError('maxlength')">
              Maximum 50 characters allowed
          </mat-error>
          <mat-error *ngIf="agentEditForm.get('description')?.hasError('required')">
              Description is required
          </mat-error>
      </mat-form-field>
      </div>

    </form>
  </div>
  <div class="profile_buttons">

    <button (click)="onSubmit()" class="btn btn-primary">Save</button>
  </div>
</div>