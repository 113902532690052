import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { CONFIG } from '../constants/config';
import { END_POINTS } from '../constants/endpoints';
import { ApiService, IAPIDetails } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public themeDetails: EventEmitter<any> = new EventEmitter();
  constructor(private apiService: ApiService) {
  }
  
  saveTheme(req: any, callBack: Function) {
    req = {
      theme: req
    }
    const apiDetails: IAPIDetails | any = {
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callBack
    }
    apiDetails.req = req;
    apiDetails.url = END_POINTS.ADD_THEME;
    this.apiService.callAPI(apiDetails);
  }
}
