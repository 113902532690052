import { Component, ViewChild } from '@angular/core';
import { FilterService } from '../../shared/services/filter.service';
import { MatSidenav } from '@angular/material/sidenav';
import { CloudSecurityService } from '../Services/cloud-security.service';
import { HeaderService } from '../../shared/services/header.service';
import { CONSTANT, CONTENT_AGENT_MESSAGE, CONTENT_MESSAGE, GET_MENU_ITEM_DATA, ToolTipConfig } from '../../shared/constants/constant-data';
import { PasswordConfirmationPopupComponent } from '../../shared/components/password-confirmation-popup/password-confirmation-popup.component';
import { DialogService } from '../../shared/services/dialog.service';
import { IDialog } from '../../shared/model/Dialog.model';
import { UserDeleteComponentComponent } from '../../admin/components/users/user-delete-component/user-delete-component.component';
import { ApiService } from '../../shared/services/api.service';

@Component({
  selector: 'app-cyber-security',
  templateUrl: './cyber-security.component.html',
  styleUrls: ['./cyber-security.component.scss']
})
export class CyberSecurityComponent {
  @ViewChild('cloudNewScan') cloudNewScan!: MatSidenav;
  type: boolean = false;
  agentData: any;
  agentDetails: any; 
  selectedIndex: number = 0;
  hide:boolean=true;
  isCopied:boolean=false;
  searchAgent: string = '';
  public toolTipOption = ToolTipConfig;
  is_api_called:boolean= false;
  //public newDevice:boolean=true;
  constructor(
    private filterService: FilterService,
    public cloudSecurityService: CloudSecurityService,
    private headerService: HeaderService,
    public dialogService: DialogService,
    private apiService: ApiService
  ) {
    this.headerService.setHeading('Cloud Agent', { type: 'cloudAgent' });
  }

  ngOnInit(): void {
    //this.CloudDevices();
    this.getAgents();
    this.cloudSecurityService.createDataState.subscribe((payload)=>{
      const {data, action} = payload;
      if(action === 'create'){
       if(!this.isAgentsAvailable){
       return this.getAgents();
       } else {
        this.agentData.push(data.data);
        this.agentData.reverse();
        this.agentData = this.dataMassaging(this.agentData)
        this.agentDetails = this.agentData[0];
        this.selectedIndex = 0;
       }
      }
      else{
        const index = this.agentData.findIndex((item: { id: any; }) => item.id === data.data.id);
        if (index !== -1) {
          this.agentData[index] = { ...this.agentData[index], ...data.data };
        }
        this.agentData = this.dataMassaging(this.agentData);
        this.agentDetails = this.agentData[index]
      }
    })

  }
  ngOnDestroy(): void {
    this.apiService.unsubscribeApiCall();
  }
  isAgentsAvailable: boolean = false;
  public getAgents() {
    this.is_api_called = true
    this.cloudSecurityService.cloudAgent(this.agentsCallBack)
  }

  agentsCallBack = (response: any) => {
    //this.agentData = [];
    this.is_api_called = false;
    if(response.status==200 && response.data.length > 0){
      this.isAgentsAvailable = response.data.length>0?true:false
      this.agentData = this.dataMassaging(response.data)
      this.agentDetails = this.agentData[0];
    }
    //this.isAgentsAvailable = true
    //this.newDevice = response.data.total_assets === 0 ? true : false
  }

  public dataMassaging(data:any){
   return data.map((item: { status: any; }) => ({
    ...item,
    agentLabel: this.getLabel(item.status),
    agentClass: this.getClass(item.status),
  }));
  }

  private getLabel(agentStatus: number): string {
    const labels:any = {
      1: 'In Progress',
      2: 'Success',
      3: 'Failed',
      4: 'Deleted',
      5: 'Paused',
      6: 'Enrolled'
    };
    return labels[agentStatus] || 'Unknown';
  }
  
  private getClass(agentStatus: number): string {
    const classes:any = {
      1: 'medium',
      2: 'critical',
      3: 'low',
      4: 'high',
      5: 'medium',
      6: 'low',
    };
    return classes[agentStatus] || '';
  }


  public CloudDevices() {
    this.cloudSecurityService.cloudDevice(this.cloudDeviceCallback)
  }
  cloudDeviceCallback = (response: any) => {
    //this.newDevice = response.data.total_assets === 0 ? true : false
  }

  ngAfterViewInit(): void {
    if (this.cloudNewScan) {
      this.filterService.setSidenav(this.cloudNewScan, 'cloudNewScan');
    }

  }
  scanNow() {
    this.type = true;
    this.cloudSecurityService.resetData.next(true);
    this.filterService.toggleSidenav('cloudNewScan');
  }
  openNewScanSidebar() {


  }
  addCloudService() {

  }
  public editCloudScan(agentDetails:any) {
    this.cloudSecurityService.editData.next(agentDetails);
    this.filterService.toggleSidenav('editCloudScan');
  }
  public pauseCloudScan(agentDetails:any) {
     const payload: IDialog = {
            component:UserDeleteComponentComponent,
            width: '380px',
            data: {
              content:CONTENT_AGENT_MESSAGE('pause', agentDetails),
              title: 'pause',
              setCallbackFunction: (result: any) => {
                if (result) {
                  this.cloudSecurityService.agentPause(this.agent_pause_callback,agentDetails.id)
                }else{
                  this.dialogService.closeDialog();
                }
                // this.onOptionCall(result,option,currentUser)
              },
              menuData:GET_MENU_ITEM_DATA('pause',{firstname: 'user123'})
            },
          }
          this.dialogService.openDialog(payload);
    // this.filterService.toggleSidenav('editCloudScan');
  }

  public agent_pause_callback = (res:any)=>{
  //  this.getAgents();
  const index = this.agentData.findIndex((item: { id: any; }) => item.id === res.data.id);
  if (index !== -1) {
    this.agentData[index] = { ...this.agentData[index], ...res.data };
  }
  this.agentData = this.dataMassaging(this.agentData);
  this.agentDetails = this.agentData[index]
  }
  public deleteCloudScan(agentDetails:any) {
   this.checkAuthorization('delete',agentDetails)
    // this.filterService.toggleSidenav('editCloudScan');
  }
  public unEnrollCloudScan(agentDetails:any) {
    this.checkAuthorization('unEnroll',agentDetails)
  }
    checkAuthorization(action:any='unEnroll',agentDetails?:any): void {
      let passwordData = {
        title: action==='unEnroll'?CONSTANT.AGENT_UNENROLL:CONSTANT.AGENT_DELETE,
        // text: CONSTANT.AGENT_UNENROLL_MESSAGE,
        buttonLabel: action==='unEnroll'? 'Unenroll': 'Delete',
        password: CONSTANT.PASSWORD,
      }
      const payload: IDialog = {
        component: PasswordConfirmationPopupComponent,
        width: '350px',
        data: {
          userData: passwordData,
          setCallbackFunction: (res:any) => {            
           if(res){
            if(action==='unEnroll'){
              this.cloudSecurityService.agentUnEnroll(this.agentUnEnrollCallBack,agentDetails.id)
            } else if(action === 'delete'){
              this.cloudSecurityService.agentDelete(this.agentDeleteCallback,agentDetails.id)
            } 
           }else{
            this.dialogService.closeDialog();
           }
            //this.decommissionRecommissionDevice(item);
          }
        },
      }
      this.dialogService.openDialog(payload);
    }
   public agentUnEnrollCallBack = (res:any)=>{
      // this.getAgents();
      this.dialogService.closeDialog();
      const index = this.agentData.findIndex((item: { id: any; }) => item.id === res.data.id);
      if (index !== -1) {
        this.agentData[index] = { ...this.agentData[index], ...res.data };
      }
      this.agentData = this.dataMassaging(this.agentData);
      this.agentDetails = this.agentData[index]
    }
  public  agentDeleteCallback= (res:any)=>{
      // this.getAgents();      
      this.dialogService.closeDialog();
      const index = this.agentData.findIndex((item:any) => item.id === res.data.id);
    if (index !== -1) {
      this.agentData.splice(index, 1);
      this.selectedIndex = 0;
      this.agentDetails = this.agentData[0]
    } 

    }
    agentRowClicked(item: any, index: number) {
      this.hide=true;
      this.agentDetails = item;
      this.selectedIndex = index;
      this.filterService.closeSidenav();
    }    
    public copyToClipboard(value: string): void {
      if (!value) {
        return;
      }
   
      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = value;
      document.body.appendChild(textarea);
   
      // Select the text
      textarea.select();
      textarea.setSelectionRange(0, 99999); // For mobile devices
   
      // Copy the text to clipboard
      document.execCommand('copy');
   
      // Remove the temporary element
      document.body.removeChild(textarea);
      this.isCopied = true;
   
      // Optionally, you can show a success message or a toast notification
      console.log('Copied to clipboard:', value);
    }
  // public getScanStatusClass(value: any): string {
  //   const arr = ['active', 'paused','pending', 'inactive'];
  //   return arr[value];
  // }
  public downloadTemplate(data:any){
    if(data?.cloud_formation_template?.length > 0){
      this.cloudSecurityService.cloudTemplateData(data?.cloud_formation_template);
    }
  }
}
