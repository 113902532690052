import { Component, ElementRef, EventEmitter, HostListener, Input,  Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IList } from './list.model';
import * as _ from 'lodash';
import { ScanService } from '../../services/scan.service';
import { MyScansService } from '../../services/my-scans.service';
import { IFilterFlags } from 'src/app/modules/whitelist/components/whitelist/whitelist.model';
import { Utils } from '../../utils';
import { CONSTANT, ToolTipConfig } from '../../constants/constant-data';
import { ISortingFilter, ISortingFilterData, ISortingFilterObj, SortingFilterService } from '../../services/sorting-filter.service';
import {
  GridReadyEvent,
  ColDef,
  CellClickedEvent,
  GridSizeChangedEvent
} from 'ag-grid-community';
import { AgGridComponent } from '../ag-grid/ag-grid.component';
import { CONFIG } from '../../constants/config';
import { ParametersComponent } from './parameters/parameters.component';
import { BenchmarkComponent } from './benchmark/benchmark.component';
import { AcceptriskComponent } from './acceptrisk/acceptrisk.component';
import { CompliantAssetsComponent } from './compliant-assets/compliant-assets.component';
import { SeverityComponent } from './severity/severity.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent  {
  @Input() public data: IList[] = [];
  @Input() public totalPage: number = 1;
  @Input() public page: number = 1;
  @Input() public filter: string | undefined;
  @Input() public isScrollable: boolean = false;
  @Input() public isRowClickable: boolean = false;
  @Input() public selectedRow!: IList;
  @Input() public filterList!: ISortingFilter;
  @Input() public isApiCallDone: boolean = false;
  @Output() public updateListOnScroll = new EventEmitter();
  @Output() public updateListFilter = new EventEmitter();
  @Output() public currentSelectedRow: EventEmitter<IList> = new EventEmitter<IList>();
  @Input () public numberOfResults : any
  @Input () public scanDetails :boolean = false;
  @Input () public tableSearch:string="";
  @Input () public tableKey:string="";
  @Input() public cloudScanApi:boolean = false;
  public appliedClassCondition: IFilterFlags  =  { "benchmark": false, "severity": false, "accepted_risk_filter": false,
  "perameter": false, "device_failed_filter": false }
  public disableTooltip = {
    isFlag: false
  };
  public toolTipOption = ToolTipConfig;
  public rowHeight: number = 48; 
  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns: string[] = ['id','paramter','benchmark', 'vendor', 'priorities', 'actions'];
  public selectedItem:any;
  //scanDetails : any;
  public selectedFilter: ISortingFilterData | any = {
    parameters: [],
    accepted_risk_sort: [],
    device_failed_sort: [],
    severity:[],
    benchmark:[],  
  }
  public parametertItems: ISortingFilterObj[] = [];
  public whitelistItems: ISortingFilterObj[] = [];
  public deviceItems: ISortingFilterObj[] = [];
  public severityItems: ISortingFilterObj[] = [];
  public benchmarkItems: ISortingFilterObj[] = [];
  public aggreDeviceId:any;
  public currentSelectedDeviceId: any;
  public noDataMsg: String = CONSTANT.NO_DATA_MSG;
  isSafari!: boolean;
  public filterApplyFlags: IFilterFlags = { "benchmark": false, "severity": false, "accepted_risk_filter": false,
   "perameter": false, "device_failed_filter": false };
  isDataAvailableOnFilter: boolean = true;
  isloaderEligible: boolean = true;
  public openedMenuId: string | null = null;
  @ViewChild('myGrid') grid!: AgGridComponent;
  public tableHeader = CONFIG.TABLE_INFO.SECURE_INFO.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.SECURE_INFO.PROPS;
  public customSeverityComparator = (valueA: string, valueB: string, nodeA: any, nodeB: any, isInverted: boolean): number => {
    const severityOrder = ['low', 'medium', 'high', 'critical'];
  
    const indexA = severityOrder.indexOf(valueA.toLowerCase());
    const indexB = severityOrder.indexOf(valueB.toLowerCase());
  
    // Normal sorting: Critical > High > Medium > Low
    if (!isInverted) {
      return indexA - indexB;
    }
  
    // Inverted sorting: Low > Medium > High > Critical
    return indexB - indexA;
  };
  public agScanColumnDefs: ColDef[] = [
    {
      headerName: this.tableHeader.POSITION,
      headerTooltip: this.tableHeader.POSITION,

      field:this.tableProps.POSITION,
      filter: false,
      sortable:false,
      lockPosition: true,
      minWidth: 50,
      maxWidth:50,
      // valueGetter: (e: any)=>{
      //   return e.node.rowIndex+1;
      // },
      // pinned: 'left'
      
    },
    {
      headerName: this.tableHeader.PARAMETERS,
      headerTooltip: this.tableHeader.PARAMETERS,
      field:this.tableProps.PARAMETERS,
      lockPosition: true,
      cellRenderer:ParametersComponent,
      //width: 450, 
      minWidth: 230,
      //pinned: 'left'
    },
    {
      headerName: this.tableHeader.BENCHMARK,
      headerTooltip: this.tableHeader.BENCHMARK,
      field: this.tableProps.BENCHMARK,
      cellRenderer:BenchmarkComponent,
      //width:300,
      minWidth: 145,
      filterValueGetter: (params: any) => {
        return params.data.bechmark ? params.data.bechmark.join(', ') : '';
      }
    },
    {
      headerName: this.tableHeader.ACCEPTED_RISK,
      headerTooltip: this.tableHeader.ACCEPTED_RISK,
      field: this.tableProps.ACCEPTED_RISK,
      cellRenderer:AcceptriskComponent,
      //width:250
      minWidth: 140,
    },
    {
      headerName: this.tableHeader.COMPLAINT_ASSETS,
      headerTooltip: this.tableHeader.COMPLAINT_ASSETS,
      cellRenderer:CompliantAssetsComponent,
      field: this.tableProps.COMPLAINT_ASSETS,
      //width: 300
      minWidth: 200,
    },
    {
      headerName: this.tableHeader.SEVERITY,
      headerTooltip: this.tableHeader.SEVERITY,
      cellRenderer:SeverityComponent,
      field: this.tableProps.SEVERITY,
      comparator: this.severityComparator.bind(this),
      //width: 200 
      minWidth: 110,
      
      
    },
    {
      headerName: '',
      field: 'bechmark',  // Original benchmark field
      lockPosition:true,
      valueGetter: (params) =>params.data.bechmark.join(', '),
      filter:false,
      width: 0, 
      cellStyle: { display: 'none' }, // Visually hide the column
      hide: true  
    },
    {
      headerName: '',
      field: 'id',  // Original id field
      lockPosition:true,
      valueGetter: (params) =>params.data.id.join(', '),
      filter:false,
      width: 0, 
      cellStyle: { display: 'none' }, // Visually hide the column
      hide: true
    }

  ]

  severityComparator(valueA: string, valueB: string): number {
    const indexA = this.customSortOrder.indexOf(valueA);
    const indexB = this.customSortOrder.indexOf(valueB);
  
    // If both values are in the custom order, sort them
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB; // Sort based on index in customSortOrder
    }
  
    // If one of the values is not in the custom order, keep original order
    return indexA === -1 ? 1 : -1; // If valueA is not found, put it after valueB
  }

  
  
  
  constructor(private scanService: ScanService,
    public myscanService: MyScansService,
    private sortingFilterService: SortingFilterService,
    private elementRef: ElementRef,
    private authService:AuthService){

    }
    
  ngOnChanges(changes: SimpleChanges) {
    this.isloaderEligible = true;
    if(changes && changes['data'] 
    && changes && changes['isApiCallDone']?.currentValue
    // && 
    // changes['data'].previousValue &&
    // changes['data'].currentValue &&
    // !_.isEqual(changes['data'].previousValue, changes['data'].currentValue)
    ){
      this.isDataAvailableOnFilter = false;
      this.isloaderEligible = false;
      // this.dataSource = new MatTableDataSource<IList>(this.sortDataBasedOnFailCount(this.data));
      //this.sortDataByVulnerability();
      this.dataMassging(this.data)
      this.dataSource = new MatTableDataSource<IList>(this.data);
      if(this.data.length === 0 && this.isApiCallDone) {
        this.noDataMsg = CONSTANT.NO_DATA_MSG;
      }
      this.updateAppliedFilter()
    }
    
    if(changes && changes['filter'] &&
    !_.isEqual(changes['filter'].previousValue, changes['filter'].currentValue)){
        this.updateTableOnFilter();
    }

    if(changes && changes['tableSearch']) {
      this.filterAssetsField(changes['tableSearch'].currentValue);

    }
  }
  ngOnInit(): void {
    // this.dataSource = new MatTableDataSource<IList>(this.sortDataBasedOnFailCount(this.data));
    if (this.cloudScanApi) {
      this.agScanColumnDefs = this.agScanColumnDefs.filter(
        (column) => column.headerName !== this.tableHeader.ACCEPTED_RISK
      );
    }
    this.isSafari = this.detectSafari();
    //this.sortDataByVulnerability();
    this.dataMassging(this.data)
    this.dataSource = new MatTableDataSource<IList>(this.data);
    this.scanService.changeDisplay$.subscribe(item => {
      this.selectedItem = item;      
    });
    // if(window.location.pathname.includes("scan-details")){
    //   this.scanDetails = true;
    // }
    // else{
    //   this.scanDetails = false;
    // }
    this.updateFilterDropdown();
    this.selectedFilter.parameters = [];
    this.selectedFilter.accepted_risk_sort = [];
    this.selectedFilter.device_failed_sort = [];
    this.selectedFilter.severity = [];
    this.selectedFilter.benchmark = [];
    //this.isDataAvailableOnFilter = false; 
  }
  detectSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
  }

  // filterAssetsField(tableSearch:string) {
  //   this.grid.grid.api.setGridOption(
  //     "quickFilterText",
  //     tableSearch
  //   );
  // }

  filterAssetsField(tableSearch:string) {
   // this.authService.setCookie(`agGridFilter_sec-info-table`,JSON.stringify({}));
    // Preprocess each row to make array fields searchable
    this.grid?.grid?.api?.forEachNode((node: any) => {
      if (node.data.bechmark && Array.isArray(node.data.bechmark)) {
        // Convert the benchmark array to a comma-separated string
        node.data.benchmarkString = node.data.bechmark.join(', ');
      }
    });
  
    // Set the quick filter text
    this.grid?.grid.api?.setGridOption("quickFilterText",tableSearch);
  }
  public searchText: string = '';
  public perameterId: string = '';
  ngAfterViewInit(): void {
    this.filterAssetsField(this.tableSearch);
  }

  
  

  public sortDataBasedOnFailCount=(data:any)=>{
    return data.sort((a:any, b:any) => {
      if (a.passd_percentage !== b.passd_percentage) {
          return a.passd_percentage - b.passd_percentage;
      } else {
          return b.count - a.count;
      }
  });
  }

  public updateFilterDropdown() {
    this.parametertItems = this.filterList?.filters.parameters;
    this.whitelistItems = this.filterList?.filters.accepted_risk_sort;
    this.deviceItems = this.filterList?.filters.device_failed_sort;
    this.severityItems = _.cloneDeep(this.filterList?.filters.severity);
    this.benchmarkItems = _.cloneDeep(this.filterList?.filters.benchmark);
  }
  public clickRow(currentRow: any) {
    if(this.isRowClickable){
      this.selectedRow = currentRow;
      this.currentSelectedRow.emit(currentRow);
    }
  }
  public onScroll = () => {    
    if (this.isScrollable && this.isApiCallDone && (this.page < this.totalPage)) {
      this.isDataAvailableOnFilter = false;
      this.isloaderEligible = true;
      this.updateListOnScroll.emit(++this.page);
    }
  }
  public updateTableOnFilter() {
    if (this.filter !== undefined) {
      this.isloaderEligible = false;
      this.dataSource.filter = this.filter;
    } else {
      this.isloaderEligible = true;
    }
  }
  severityStatus(type:string){
    return Utils.addBadgeClass(type)
  }
  
  toggleMenuSelection(currentDropdownData: any, selectedItem: any): void {
    if (selectedItem.name?.toLocaleLowerCase() === 'all') {
      if (selectedItem.activated) {
        currentDropdownData.forEach((item: any) => { (item.activated = selectedItem.activated) })
      } else {
        currentDropdownData.forEach((item: any) => { (item.activated = selectedItem.activated) })
      }
    } else {
      let isSelectedAll = true;
      currentDropdownData.forEach((item: any) => {
        if (item.name?.toLocaleLowerCase() !== 'all' && !item.activated) {
          isSelectedAll = false;
        }
      });
      currentDropdownData[0].activated = isSelectedAll;
    }
  }

  toggleSorting(event: any, selectedItem: any,  type: string) {
    this.isloaderEligible = false;
    this.isDataAvailableOnFilter = true
    this.dataSource.data = []
    this.severityItems.forEach((item: any) => { (item.activated = false) });
    this.filterList?.filters.severity.forEach((item: any) => { (item.activated = false) });
    this.deviceItems.forEach((item: any) => { (item.activated = false) });
    this.whitelistItems.forEach((item: any) => { (item.activated = false) });
    this.parametertItems.forEach((item: any) => { (item.activated = false) });
    this.benchmarkItems.forEach((item: any) => { (item.activated = false) });
    this.filterList?.filters.benchmark.forEach((item: any) => { (item.activated = false) });
    selectedItem.activated = !selectedItem.activated;
    this.page = 1;
    let filtered: number = 0;
      if (this.filterList && this.filterList?.filters) {
        if (type == 'perameter') {
          this.filterList.filters.parameters = this.parametertItems;
           filtered = Utils.getSelectedItems(this.parametertItems)?.length;
        } else if (type == 'accepted_risk_filter') {
          this.filterList.filters.accepted_risk_filter = this.whitelistItems;
           filtered = Utils.getSelectedItems(this.whitelistItems)?.length;
        } else if (type == 'device_failed_filter') {
          this.filterList.filters.device_failed_sort = this.deviceItems;
           filtered = Utils.getSelectedItems(this.deviceItems)?.length;
        }
      }
      if(selectedItem.id === 'default') {
        this.appliedClassCondition[type  as keyof IFilterFlags] = false;

      } else {
        this.appliedClassCondition[type  as keyof IFilterFlags] = filtered === 0 ? false : true;
      }this.updateListFilter.emit(1);
  }
  onApplyHanlder(type: string): void {
    if (this.dataSource) {
      this.isloaderEligible = false;
      this.dataSource.data = [];
      if(type == 'benchmark'){
        this.severityItems.forEach((item: any) => { (item.activated = false) });
        this.filterList?.filters.severity.forEach((item: any) => { (item.activated = false) });
      }
      if(type == 'severity'){
        this.benchmarkItems.forEach((item: any) => { (item.activated = false) });
        this.filterList?.filters.benchmark.forEach((item: any) => { (item.activated = false) });
      } 
      this.deviceItems.forEach((item: any) => { (item.activated = false) });
      this.whitelistItems.forEach((item: any) => { (item.activated = false) });
       this.parametertItems.forEach((item: any) => { (item.activated = false) });
      let filtered: number = 0;
      if (this.filterList && this.filterList?.filters) {
        if (type == 'benchmark') {
          this.filterList.filters.benchmark = this.benchmarkItems;
           filtered = Utils.getSelectedItems(this.benchmarkItems)?.length;
           this.isloaderEligible = true;
        } else if (type == 'accepted_risk_filter') {  
          this.filterList.filters.accepted_risk_filter = this.whitelistItems;
           filtered = Utils.getSelectedItems(this.whitelistItems)?.length;
           this.isloaderEligible = true;
        } else if (type == 'severity') {
          this.filterList.filters.severity = this.severityItems;
          filtered = Utils.getSelectedItems(this.severityItems)?.length;
          this.isloaderEligible = true;
        }
      }
      this.filterApplyFlags[type as keyof IFilterFlags] = filtered === 0 ? false : true
    }
    this.page = 1;
    this.updateListFilter.emit(1);
  }
  updateAppliedFilter() {
    const type: any = {
      'benchmark': false,
      'severity': false,
      'perameter': false,
      'accepted_risk_filter': false,
      'device_failed_filter': false

    }
    type.benchmark = Utils.getSelectedItems(this.filterList.filters.benchmark)?.length ? true : false;
    type.severity = Utils.getSelectedItems(this.filterList.filters.severity)?.length ? true : false;
    type.perameter = Utils.getSelectedItems(this.filterList.filters.parameters)?.length ? true : false;
    type.accepted_risk_filter = Utils.getSelectedItems(this.filterList.filters.accepted_risk_sort)?.length ? true : false;
    type.device_failed_filter = Utils.getSelectedItems(this.filterList.filters.device_failed_sort)?.length ? true : false;
    for(const key in type) {
      if(type[key]) {
        this.filterApplyFlags[key as keyof IFilterFlags] = true;
      } else {
        this.filterApplyFlags[key as keyof IFilterFlags] = false;
      }
    }
  }
  isPreloaderEligible(): boolean {
    return this.page !== this.totalPage;
  }
  getFirstLetter(str:string | undefined){
    return Utils.getFirstLetter(str)
  }
  showFilter(data: any) {
    return this.sortingFilterService.showFilterDropDown(data);
  }
    onMenuOpen(id: string) {
    this.openedMenuId = id;
    }

onMenuClose() {
    this.openedMenuId = null;
    this.severityItems = _.cloneDeep(this.filterList?.filters.severity);
    this.benchmarkItems = _.cloneDeep(this.filterList?.filters.benchmark);
}

customSortOrder = ['Critical', 'High', 'Medium', 'Low']; // Mixed case order

sortDataByVulnerability(): void {
  const sortedData = [...this.data].sort((a: any, b: any) => {
    const vulnerabilityA = a.vulnerability.toLowerCase();
    const vulnerabilityB = b.vulnerability.toLowerCase();

    // Ensure case-insensitive comparison for customSortOrder
    const orderA = this.customSortOrder.findIndex(order => order.toLowerCase() === vulnerabilityA);
    const orderB = this.customSortOrder.findIndex(order => order.toLowerCase() === vulnerabilityB);

    return orderB - orderA;
  });

  this.data = sortedData.reverse(); // Reassign sorted data to this.data
}

dataMassging(data:any){
  data.forEach((element: any,index:number) => {
    element.position=index+1;
    element.desctiption_tooltip =element.desctiption.length>500 ? element.desctiption.substring(0,500)  + '...': element.desctiption 
  });
  // data=data.sort((a:any, b:any) => {
  //   return this.customSortOrder.indexOf(a.vulnerability.toLowerCase()) - this.customSortOrder.indexOf(b.vulnerability.toLowerCase());
  // });
}
}
