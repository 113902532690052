import { Component, ElementRef, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AgGridComponent } from 'src/app/modules/shared/components/ag-grid/ag-grid.component';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { ParametersComponent } from './parameters/parameters.component';
import { BenchmarkComponent } from './benchmark/benchmark.component';
import { SeverityComponent } from './severity/severity.component';
import { AcceptedriskComponent } from './acceptedrisk/acceptedrisk.component';
import { CompliantAssetsComponent } from './compliant-assets/compliant-assets.component';
import { IDrop } from 'src/app/modules/dashboard/components/controlpanel/controlPanelModel';
import { FilterManualComponent } from './filter-manual/filter-manual.component';
import { CloudControlService } from './cloud-control.service';
import { HeaderService } from 'src/app/modules/shared/services/header.service';

@Component({
  selector: 'app-cloud-security',
  templateUrl: './cloud-security.component.html',
  styleUrls: ['./cloud-security.component.scss']
})
export class CloudSecurityComponent {
  showresorceTypeData: boolean = false;
  public rowHeight = 43;
  public tableHeader = CONFIG.TABLE_INFO.CLOUD_SECURE_INFO.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.CLOUD_SECURE_INFO.PROPS;
  public isApiCallDone = true;
  public agScanColumnDefs: ColDef[] = [
    {
      headerName: this.tableHeader.POSITION,
      headerTooltip: this.tableHeader.POSITION,
      field:this.tableProps.POSITION,
      filter: false,
      lockPosition: true,
    },
    {
      headerName: this.tableHeader.PARAMETERS,
      headerTooltip: this.tableHeader.PARAMETERS,
      field:this.tableProps.PARAMETERS,
      lockPosition: true,
      // filter:FilterManualComponent,
      cellRenderer:ParametersComponent,
      width: 550,
    },
    {
      headerName: this.tableHeader.BENCHMARK,
      headerTooltip: this.tableHeader.BENCHMARK,
      field: this.tableProps.BENCHMARK,
      cellRenderer:BenchmarkComponent,
      width:300,
      filterValueGetter: (params: any) => {
        return params.data.bechmark ? params.data.bechmark.join(', ') : '';
      }
    },
    {
      headerName: this.tableHeader.SEVERITY,
      headerTooltip: this.tableHeader.SEVERITY,
      cellRenderer:SeverityComponent,
      field: this.tableProps.SEVERITY,
      width: 200       
    },
    {
      headerName: this.tableHeader.ACCEPTED_RISK,
      headerTooltip: this.tableHeader.ACCEPTED_RISK,
      field: this.tableProps.ACCEPTED_RISK,
      cellRenderer:AcceptedriskComponent,
      width:200
    },
    {
      headerName: this.tableHeader.COMPLAINT_ASSETS,
      headerTooltip: this.tableHeader.COMPLAINT_ASSETS,
      cellRenderer:CompliantAssetsComponent,
      field: this.tableProps.COMPLAINT_ASSETS,
      width: 300
    }
  ];
  public agScanColumnTypeDefs: ColDef[] = [
    {
      headerName: '',
      headerTooltip: '',
      field: '',
      filter: false,
      lockPosition: true,
      width: 72,
      maxWidth: 72,
      minWidth: 72
    },
    {
      headerName: this.tableHeader.POSITION,
      headerTooltip: this.tableHeader.POSITION,
      field:this.tableProps.POSITION,
      filter: false,
      lockPosition: true,
      width: 110,
      maxWidth: 110,
      minWidth: 110
    },
    {
      headerName: this.tableHeader.PARAMETERS,
      headerTooltip: this.tableHeader.PARAMETERS,
      field:this.tableProps.PARAMETERS,
      lockPosition: true,
      filter:FilterManualComponent,
      cellRenderer:ParametersComponent,
      width: 550,
    },
    {
      headerName: this.tableHeader.BENCHMARK,
      headerTooltip: this.tableHeader.BENCHMARK,
      field: this.tableProps.BENCHMARK,
      cellRenderer:BenchmarkComponent,
      width:300,
      filterValueGetter: (params: any) => {
        return params.data.bechmark ? params.data.bechmark.join(', ') : '';
      }
    },
    {
      headerName: this.tableHeader.SEVERITY,
      headerTooltip: this.tableHeader.SEVERITY,
      cellRenderer:SeverityComponent,
      field: this.tableProps.SEVERITY,
      width: 200       
    },
    {
      headerName: this.tableHeader.ACCEPTED_RISK,
      headerTooltip: this.tableHeader.ACCEPTED_RISK,
      field: this.tableProps.ACCEPTED_RISK,
      cellRenderer:AcceptedriskComponent,
      width:200
    },
    {
      headerName: this.tableHeader.COMPLAINT_ASSETS,
      headerTooltip: this.tableHeader.COMPLAINT_ASSETS,
      cellRenderer:CompliantAssetsComponent,
      field: this.tableProps.COMPLAINT_ASSETS,
      width: 300
    }
  ]
  public selectedRow: any[] = [];
  // public isApiCallDone = false;
  dataResource:any=[]
  // dataSource: any = [
  //   {
  //     position: 1,
  //     controls: "Enable Firewall, Enable Encryption",
  //     benchmark:  ["CIS v8.0", "NIST 800-53", "SPARK", "ISO 27001", "GDPR"],
  //     vulnerability: "High",
  //     accepted_risk: 56,
  //     resourceType:'vpc',
  //     resource:'vpc-1',
  //     controls_type:'Manual',
  //     passd_percentage: 56,
  //     passed_count: 43,
  //     count: 76,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]

  //   },
  //   {
  //     position: 2,
  //     controls: "Disable Guest Accounts, Enforce Password Policies",
  //     benchmark: ["ISO 27001"],
  //     vulnerability: "Medium",
  //     accepted_risk: 24,
  //     resourceType:'vpc',
  //     resource:'vpc-2',
  //     controls_type:'Automated',
  //     passd_percentage: 51,
  //     passed_count: 41,
  //     count: 71,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]

    
  //   },
  //   {
  //     position: 3,
  //     controls: "Restrict Admin Access, Enable Logging",
  //     benchmark: ["PCI DSS", "SOC 2"],
  //     vulnerability: "Critical",
  //     accepted_risk: 68,
  //     resourceType:'ec2',
  //     resource:'ec2-1',
  //     controls_type:'Automated',
  //     passd_percentage: 57,
  //     passed_count: 47,
  //     count: 77,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]


  //   },
  //   {
  //     position: 4,
  //     controls: "Configure Secure DNS, Regular Backups",
  //     benchmark: ["HIPAA", "CIS v7.1"],
  //     vulnerability: "Low",
  //     accepted_risk: 71,
  //     resourceType:'ec2',
  //     resource:'ec2-2',
  //     controls_type:'Manual',
  //     passd_percentage: 59,
  //     passed_count: 46,
  //     count: 70,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]

  //   },
  //   {
  //     position: 5,
  //     controls: "Enable MFA, Restrict IPs",
  //     benchmark: ["GDPR", "NIST 800-171"],
  //     vulnerability: "High",
  //     accepted_risk: 31,
  //     resourceType:'ec2',
  //     resource:'ec2-1',
  //     controls_type:'Automated',
  //     passd_percentage: 69,
  //     passed_count: 43,
  //     count: 76,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]

  //   },
  //   {
  //     position: 6,
  //     controls: "Update Antivirus, Block Unused Ports",
  //     benchmark: ["CIS v8.1"],
  //     vulnerability: "Medium",
  //     accepted_risk: 58,
  //     resourceType:'ec2',
  //     resource:'ec2-1',
  //     controls_type:'Manual',
  //     passd_percentage: 87,
  //     passed_count: 43,
  //     count: 76,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]

  //   },
  //   {
  //     position: 7,
  //     controls: "Implement IDS/IPS, Secure SSH Access",
  //     benchmark: ["SOC 2"],
  //     vulnerability: "Critical",
  //     accepted_risk: 98,
  //     resourceType:'ec2',
  //     resource:'ec2-1',
  //     controls_type:'Automated',
  //     passd_percentage: 42,
  //     passed_count: 43,
  //     count: 76,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]

  //   },
  //   {
  //     position: 8,
  //     controls: "Deploy Patch Management, Harden OS",
  //     benchmark: ["NIST 800-53", "PCI DSS"],
  //     vulnerability: "High",
  //     accepted_risk: 45,
  //     resourceType:'ec2',
  //     resource:'ec2-1',
  //     controls_type:'Manual',
  //     passd_percentage: 65,
  //     passed_count: 43,
  //     count: 76,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]

  //   },
  //   {
  //     position: 9,
  //     controls: "Restrict Public IP Exposure, Enforce TLS",
  //     benchmark: ["ISO 27001", "CIS v7.2"],
  //     vulnerability: "Low",
  //     accepted_risk: 61,
  //     resourceType:'ec2',
  //     resource:'ec2-1',
  //     controls_type:'Automated',
  //     passd_percentage: 81,
  //     passed_count: 43,
  //     count: 76,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]

  //   },
  //   {
  //     position: 10,
  //     controls: "Configure Audit Logs, Regular Scans",
  //     benchmark: ["GDPR", "HIPAA"],
  //     vulnerability: "Medium",
  //     accepted_risk: 100,
  //     resourceType:'ec2',
  //     resource:'ec2-1',
  //     controls_type:'Manual',
  //     passd_percentage: 26,
  //     passed_count: 43,
  //     count: 76,
  //     compliance_details:[
  //       { iconClass: 'pass-icon', label: 'Pass', value: 100 },
  //       { iconClass: 'fail-icon', label: 'Fail', value: 1300 },
  //       { iconClass: 'na-icon', label: 'Not Applicable', value: 124 },
  //       { iconClass: 'pending-review-icon', label: 'Pending Review', value: 1411 },
  //       { iconClass: 'manually-fail-icon', label: 'Manually Fail', value: 2178 },
  //       { iconClass: 'manually-pass-icon', label: 'Manually Pass', value: 124 },
  //     ]

  //   },
  // ];
  dataSource: any = [];
  public sortByDropdownList: IDrop[] = [
    {
     id:1,
     name:"Controls"
    },
    {
      id:2,
      name:"Resources"
    },
    {
      id:3,
      name:"Resource Type"
    },
  ];
  public selectedDeviceWithSortBy: IDrop = {};
  searchText: string='';
  @ViewChild('myGrid') grid!: AgGridComponent;
  @ViewChild('searchField') searchField!: ElementRef;
  constructor(private filterService: FilterService, public controlService: CloudControlService ,     private headerService: HeaderService,){
    this.headerService.setHeading('Cloud Controls', { type: 'cloudControls' });
    this.selectedDeviceWithSortBy.id = this.sortByDropdownList[0].id;
    this.selectedDeviceWithSortBy.name = this.sortByDropdownList[0].name;
    this.generateGroupedData();
  }
  ngOnInit(): void{
   this.getCloudControlData();
  }

  getCloudControlData(){
    this.isApiCallDone = false;
    this.controlService.getCloudControlData(this.CloudControlDataCallBack,{type: 1})
  }
  CloudControlDataCallBack = (response: any) => {
      this.isApiCallDone = true;
    this.dataSource=response.data.controls;
    console.log(this.dataSource)
    this.dataMassging(this.dataSource);
      // this.generateGroupedData();
  }

    dataMassging(data:any){
            data.forEach((element: any, index: number) => {
              element.position = index + 1; 
          });
        }


  onClick(){
    this.filterService.toggleSidenav('cloudOverlay');
  }
  onClose(){
    this.filterService.closeSidenav();
  }
  get mergedChildRows() {
    // Merges all childRows from all resources into a single array
    return this.dataResource.reduce((acc:any, resource:any) => {
        return acc.concat(resource.childRows);
    }, []);
    console.log("sdfsdfsdf")
    console.log(this.mergedChildRows)
}
  public onRowSelected(selectedRow: any) {
    this.selectedRow = selectedRow;
    this.filterService.toggleSidenav('cloudOverlay');
  }
  filterAssetsField(searchText:string='') {
    if(searchText==''){
      searchText=this.searchField?.nativeElement?.value
    }
    this.grid.grid.api.setGridOption(
      "quickFilterText",
       searchText
    );
  }
  groupByValue: number = 1;
  public sortByDevice(value: IDrop){
    console.log(value)
    this.selectedDeviceWithSortBy.id = value.id;
    this.groupByValue=value.id;
    this.selectedDeviceWithSortBy.name = value.name;
  }
  generateGroupedData() {
    const groupedMap: Map<string, any[]> = new Map();
    this.dataSource.forEach((item:any) => {
      // Check if the resourceType is already present in the map
      if (!groupedMap.has(item.resourceType)) {
        // Initialize an empty array if it's the first entry for this resourceType
        groupedMap.set(item.resourceType, []);
      }
      groupedMap.get(item.resourceType)?.push(item);
    });
    const groupedData: any[] = [];

    // Iterate through the groupedMap to structure the final output
    groupedMap.forEach((childRows, resourceType) => {
      // Push the grouped structure for each resourceType into the final result
      groupedData.push({
        resourceType,
        childRows
      });
    });
    this.dataResource=groupedData;
    console.log(this.dataResource)
    console.log(groupedData)
  }
  toggleDescription(resource: any) {
    resource.selected = !resource.selected;
  }
  
}
