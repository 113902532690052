<div class="out-layout">
    <div *ngIf="isAgentsAvailable" class="cloud-resource page_content">
        <div class="cloud-resource-header">
            <div class="left-mgt-head">
                <div class="search-bar">
                    <mat-form-field appearance="fill">
                        <mat-label>Search Resource</mat-label>
                        <input (input)="filterAssetsField()" matInput placeholder="" autocomplete="off" class="search-input" autocomplete="off"
                            type="text" #searchField>
                        <mat-icon class='search-icon' matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="right-mgt-head">
               <span class="cloud-text-data">Previous Resource</span>
               <mat-checkbox [(ngModel)]="isPreviousResource" [ngModelOptions]="{standalone: true}" (change)="showPreviousResources(isPreviousResource)"></mat-checkbox>
            </div>
        </div>
    <app-ag-grid
    #myGrid
    [agDeviceColumnDefs]="colDefs"
    [data]="dataSource?.data"
    [loader]="isApiCallDone"
    [gridClass]="'cloud-resource-table ag-grid-tabel'"
    [rowHeight]="rowHeight"
    [restrictCheckBoxSelection]="false"
    [tableKey]="'resourceTable'">
    </app-ag-grid>
     <!-- (onRowDataClick)="onRowSelected($event)" -->
</div>
<div  *ngIf="!isAgentsAvailable" class="no-data-section d-flex theme-bg">
    <div class="no-data-info text_center">
      <img src="../../../../assets/images/Group.svg" />
      <h3>There is no data available</h3>
    </div>
</div>
</div>
