<div class="out-layout">
    <div class="cloud-resource page_content">
        <div class="cloud-resource-header">
            <div class="left-mgt-head">
                <div class="search-bar">
                    <mat-form-field appearance="fill">
                        <mat-label>Search Resource</mat-label>
                        <input matInput placeholder="" autocomplete="off" class="search-input" autocomplete="off"
                            type="text" #searchField>
                        <mat-icon class='search-icon' matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="right-mgt-head">
               <span class="text-data">Previous Resource</span>
               <mat-checkbox [(ngModel)]="isPreviousResource" [ngModelOptions]="{standalone: true}" (change)="showPreviousResources(isPreviousResource)"></mat-checkbox>
            </div>
        </div>
    <app-ag-grid
    #myGrid
    [agDeviceColumnDefs]="colDefs"
    [data]="dataSource?.data"
    [loader]="isApiCallDone"
    [gridClass]="'cloud-resource-table ag-grid-tabel'"
    [rowHeight]="rowHeight"
    [restrictCheckBoxSelection]="false"
    (onRowSelectedChange)="onRowSelected($event)"
    [tableKey]="'resourceTable'">
    </app-ag-grid>
</div>
</div>
