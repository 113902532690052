import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  get statusClass(): string {
    return `status-badge ${this.params.data.status === 3 ? 'active' : 'inactive'}`;
  }

  get statusText(): string {
    return this.params.data.status === 3 ? 'Active' : 'Inactive';
  }
}
