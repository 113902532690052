import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { ScanHeaderComponent } from './components/scan-header/scan-header.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDialog } from './components/custom-dialog/custom-dialog.component';
import { TooltipListPipe } from './pipes/tooltip-list.pipe';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { HeaderComponent } from './components/header/header.component';
import { FilterComponent } from './components/filter/filter.component';
import { TableComponent } from './components/table/table.component';
import { FilterDataComponent } from './components/filter/filter-data/filter-data.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TreeComponent } from './components/filter/tree/tree.component';
import { HighlightPipe, SearchAgentPipe, SearchPipe, SearchPipeT } from './pipes/search.pipe';
import { ListComponent } from './components/list/list.component';
import { SelectedListDetailsComponent } from './components/selected-list-details/selected-list-details.component';
import { IsEllipsisActiveDirective } from './directives/is-ellipsis-active.directive';
import { SecurityScoreComponent } from './components/security-score/security-score.component';
import { CategoryWiseComponent } from './components/category-wise/category-wise.component';
import { CyberReilienceComponent } from './components/cyber-reilience/cyber-reilience.component';
import { VulnerabilityComponent } from './components/vulnerability/vulnerability.component';
import { CommonVulnerabilityComponent } from './components/common-vulnerability/common-vulnerability.component';
import { GlobalFilterComponent } from './components/global-filter/global-filter.component';
import { PasswordConfirmationPopupComponent } from './components/password-confirmation-popup/password-confirmation-popup.component';
import { ProfileIconComponent } from './components/profile-icon/profile-icon.component';
import { PanelChartComponent } from './components/panel-chart/panel-chart.component';
import { SemiDonutChartComponent } from './semi-donut-chart/semi-donut-chart.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { NgApexchartsModule } from "ng-apexcharts";
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { SessionExpiredModalComponent } from './components/session-expired-modal/session-expired-modal.component';
import { ActiveSessionComponent } from './components/active-session/active-session.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TooltipDirective, TooltipModule } from 'ng2-tooltip-directive';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { NotificationComponent } from './components/notification/notification.component';
import { UserListComponent } from './components/user-list/user-list/user-list.component';
import { UnsavedDialogComponent } from './components/unsaved-dialog/unsaved-dialog.component';
import { AccountServicesComponent } from './components/account-services/account-services.component';
import { TrailMessageComponent } from './components/trail-message/trail-message.component';
import { AllPlansComponent } from './components/all-plans/all-plans.component';
import { SubscriptionRequestComponent } from './components/subscription-request/subscription-request.component';
import { DeleteDataComponent } from './components/delete-data/delete-data.component';

import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { SafariEllipsisDirective } from './directives/safari-ellipsis.directive';
import { NumberFormatPipe } from './shared-format.pipe';
import { ImageCropperComponent, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { CustomSnackBarComponent } from './custom-snack-bar/custom-snack-bar.component';
import { ScheduleDialogComponent } from './components/schedule-dialog/schedule-dialog.component';

import { DeviceManagementComponent } from '../admin/components/device-management/device-management.component';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { TooltipComponent } from './directives/tooltip/tooltip.component';
import { EventsManagerComponent } from './components/events-manager/events-manager.component';
import { NotificationMessageComponent } from './components/notification-message/notification-message.component';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapterService } from './services/custom-date-adapter.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AgGridAngular } from 'ag-grid-angular';
import { TableLoaderComponent } from './components/table-loader/table-loader.component';
import { AgGridComponent } from './components/ag-grid/ag-grid.component';
import { ParametersComponent } from './components/list/parameters/parameters.component';
import { BenchmarkComponent } from './components/list/benchmark/benchmark.component';
import { AcceptriskComponent } from './components/list/acceptrisk/acceptrisk.component';
import { CompliantAssetsComponent } from './components/list/compliant-assets/compliant-assets.component';
import { SeverityComponent } from './components/list/severity/severity.component';
import { CveAssetsDetailsComponent } from './components/cve-assets-details/cve-assets-details.component';
import { CveOverlayHeaderComponent } from './components/cve-overlay-header/cve-overlay-header.component';
import { CveDetailsComponent } from './components/cve-details/cve-details.component';
import { CveEnumerationComponent } from '../cve-dashboard/cve-enumeration/cve-enumeration.component';
import { SummaryAssetsComponent } from './components/summary-assets/summary-assets.component';
import { VulnerabilityAssetsComponent } from './components/vulnerability-assets/vulnerability-assets.component';
import { CveAssetsComponent } from './components/cve-assets/cve-assets.component';
import { ParameterComponent } from './components/parameter/parameter.component';
import { VulnerabilityTooltip } from './components/vul-tooltip/vul-tooltip.component';
import { TabsAssetsComponent } from './components/tabs-assets/tabs-assets.component';
import { NewVulnerabilityComponent } from './components/new-vulnerability/new-vulnerability.component';
import { ComplianceComponent } from './components/compliance/compliance.component';
import { RiskComponent } from './components/risk/risk.component';
import { SeverityVulComponent } from './components/severit-vul/severity-vul.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { WhitelistPercentageComponent } from './components/list/whitelist-percentage/whitelist-percentage.component';
import { AssetsTabComponent } from './components/assets-tab/assets-tab.component';
import { CheckboxWhitelistComponent } from './components/checkbox-whitelist/checkbox-whitelist.component';
import { CyberresilienceDwonloadReportComponent } from './components/cyberresilience-dwonload-report/cyberresilience-dwonload-report.component';
import { CommonTooltipComponent } from './components/common-tooltip/common-tooltip.component';
import { CyberZoominComponent } from './components/cyber-zoomin/cyber-zoomin.component';
import { JiraIntegrationComponent } from './components/jira-integration/jira-integration.component';
import { VulnerabilityParameterComponent } from './components/list/vulnerability-parameter/vulnerability-parameter.component';
import { DashboardParametersComponent } from './components/dashboard-parameters/dashboard-parameters.component';
import { LineGrpahComponent } from './components/line-grpah/line-grpah.component';
import { LineBreaksPipe } from './components/selected-list-details/line-breaks.pipe';

@NgModule({
  declarations: [
    ScanHeaderComponent,
    SpinnerComponent,
    CustomSnackbarComponent,
    CustomDialog,
    TooltipListPipe,
    SideNavComponent,
    HeaderComponent,
    FilterComponent,
    TableComponent,
    FilterDataComponent,
    TreeComponent,
    SearchPipe,
    SearchPipeT,
    HighlightPipe,
    ListComponent,
    SelectedListDetailsComponent,
    IsEllipsisActiveDirective,
    SecurityScoreComponent,
    CategoryWiseComponent,
    CyberReilienceComponent,
    VulnerabilityComponent,
    CommonVulnerabilityComponent,
    GlobalFilterComponent,
    PasswordConfirmationPopupComponent,
    ProfileIconComponent,
    PanelChartComponent,
    SemiDonutChartComponent,
    SearchBarComponent,
    SessionExpiredModalComponent,
    ActiveSessionComponent,
    NotFoundPageComponent,
    NotificationComponent,
    UserListComponent,
    UnsavedDialogComponent,
    TrailMessageComponent,
    AllPlansComponent,
    AccountServicesComponent,
    SubscriptionRequestComponent,
    DeleteDataComponent,
  
    DeleteDialogComponent,
    SafariEllipsisDirective,
    NumberFormatPipe,
    ProfileImageComponent,
    CustomSnackBarComponent,
    ScheduleDialogComponent,
    CustomTooltipDirective,
    TooltipComponent,
    EventsManagerComponent,
    NotificationMessageComponent,
    TableLoaderComponent,
    AgGridComponent,
    CveAssetsDetailsComponent,
    SummaryAssetsComponent,
    VulnerabilityAssetsComponent,
    CveAssetsComponent,
    ParameterComponent,
    VulnerabilityTooltip,
    ParametersComponent,
    BenchmarkComponent,
    AcceptriskComponent,
    CompliantAssetsComponent,
    SeverityComponent,
    CveAssetsDetailsComponent,
    CveOverlayHeaderComponent,
    CveDetailsComponent,
    CveEnumerationComponent,
    SideBarComponent,
    AssetsTabComponent,
    TabsAssetsComponent,
    NewVulnerabilityComponent,
    ComplianceComponent,
    RiskComponent,
    SeverityVulComponent,
    SideBarComponent,
    WhitelistPercentageComponent,
    CheckboxWhitelistComponent,
    CyberresilienceDwonloadReportComponent,
    CommonTooltipComponent,
    CyberZoominComponent,
    JiraIntegrationComponent,
    VulnerabilityParameterComponent,
    DashboardParametersComponent,
    SearchAgentPipe,
    LineGrpahComponent,
    LineBreaksPipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    InfiniteScrollModule,
    NgApexchartsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    NgxSkeletonLoaderModule.forRoot(),
    TooltipModule,
    ImageCropperComponent,
    MatDatepickerModule,
    TooltipModule,
    AgGridAngular
  ],
  providers:[
    { provide: DateAdapter, useClass: CustomDateAdapterService },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'MM/DD/YYYY',
        },
        display: {
          dateInput: 'MM/DD/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'MM/DD/YYYY',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],

  exports: [
    MaterialModule, 
    ScanHeaderComponent, 
    SpinnerComponent, 
    FormsModule, 
    ReactiveFormsModule,
    SideNavComponent,
    HeaderComponent,
    TableComponent,
    TooltipListPipe,
    FilterComponent,
    InfiniteScrollModule,
    SearchPipe,
    SearchPipeT,
    HighlightPipe,
    ListComponent,
    SelectedListDetailsComponent,
    IsEllipsisActiveDirective,
    SecurityScoreComponent,
    CategoryWiseComponent,
    CyberReilienceComponent,
    VulnerabilityComponent,
    CommonVulnerabilityComponent,
    GlobalFilterComponent,
    PasswordConfirmationPopupComponent,
    ProfileIconComponent,
    PanelChartComponent,
    SearchBarComponent,
    ProfileIconComponent,
    SemiDonutChartComponent,
    SessionExpiredModalComponent,
    ActiveSessionComponent,
    TooltipModule,
    NotificationComponent,
    EventsManagerComponent,
    NotificationMessageComponent,
    UserListComponent,
    AccountServicesComponent,
    ImageCropperComponent,
    TooltipComponent,
    CustomTooltipDirective,
    AgGridAngular,
    TableLoaderComponent,
    AgGridComponent,
    CveAssetsDetailsComponent,
    CveOverlayHeaderComponent,
    CveDetailsComponent,
    CveEnumerationComponent,
    AssetsTabComponent,
    CveAssetsComponent,
    CheckboxWhitelistComponent,
    CommonTooltipComponent,
    SearchAgentPipe,
    LineGrpahComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
