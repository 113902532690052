<div class="out-layout enumeration-out-layout">
    <div class="enumeration-page">
        <div class="enumeration-left" [ngClass]="{'scroll': cveDetails}">
            <div class="enu-loader-section" [ngClass]="{'cve-panel-main-loader': true}" *ngIf="!cveDetails">
            <div class="spark-whitebox">
                <ngx-skeleton-loader class="cve-head-loader" count="1"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="cve-date-loader cve-date-head-loader"  count="3"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="cve-date-loader" count="3"></ngx-skeleton-loader>
            </div>
            <div class="cve-vul-loader">
                <div class="spark-whitebox">
                    <div class="cve-vul-left-loader">
                        <div class="cve-vul-loader-header">
                            <ngx-skeleton-loader  count="1"></ngx-skeleton-loader>
                            <ngx-skeleton-loader  count="1"></ngx-skeleton-loader>    
                        </div>
                        <div class="cve-vul-content-loader">
                            <ngx-skeleton-loader appearance="circle" [theme]="{ width: '120px', height: '120px' }" count="1"></ngx-skeleton-loader>
                            <div class="cve-vul-left-content-loader">
                                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                            </div>
                            <div class="cve-vul-left-content-loader">
                                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                                <ngx-skeleton-loader class="cve-desc-loader" count="1"></ngx-skeleton-loader>
                            </div>
                            
                         </div>
                    </div>
                </div>
                <div class="spark-whitebox">
                    <div class="cve-vul-right-content-loader">
                        <div class="cve-vul-loader-header">
                            <ngx-skeleton-loader  count="1"></ngx-skeleton-loader>  
                        </div>
                        <ngx-skeleton-loader class="cve-desc-loader" count="6"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
            <div class="cve-tab-loader spark-whitebox">
                <ngx-skeleton-loader class="cve-desc-loader" count="3"></ngx-skeleton-loader>
            </div>
            <div class="cve-tab-loader spark-whitebox">
                <ngx-skeleton-loader class="cve-desc-loader"  count="3"></ngx-skeleton-loader>
            </div>
            <div class="cve-tab-loader spark-whitebox">
                <ngx-skeleton-loader class="cve-desc-loader"  count="3"></ngx-skeleton-loader>
            </div>
            </div>
            <div *ngIf="cveDetails" class=" cve-summarybox">
                <!-- <h3 class="spark-sec-tittle">CVE Summary <label mat-button *ngIf="cveDetails.cve_summary_desc"
                        [matMenuTriggerFor]="cisa">{{cveDetails.cve_summary_desc}}</label>

                    <mat-menu #cisa="matMenu" class="vector-list cve-summarypopup scroll">
                        <div class="vector-list-section">
                            <div class="vector-list-header vector-drop-header">
                                <p [options]="toolTipConfig" appSafariEllipsis class="text_ellipsis"
                                [tooltip]="cveDetails.cve_summary_desc">{{cveDetails.cve_summary_desc}}</p>
                            </div>
                            <div class="vector-list-info">
                                <div *ngIf="cveDetails.cve_details.vulnerability_name"
                                    class="vector-list-item full-width">
                                    <p class="vector-list-label">Vulnerability Name</p>
                                    <p class="vector-list-value">{{cveDetails.cve_details.vulnerability_name}}</p>
                                </div>
                                <div *ngIf="cveDetails.cve_details.date_added" class="vector-list-item">
                                    <p class="vector-list-label">Date Added</p>
                                    <p class="vector-list-value">{{cveDetails.cve_details.date_added}}</p>
                                </div>
                                <div *ngIf="cveDetails.cve_details.due_date" class="vector-list-item">
                                    <p class="vector-list-label">Due Date</p>
                                    <p class="vector-list-value">{{cveDetails.cve_details.due_date}}</p>
                                </div>
                                <div *ngIf="cveDetails.cve_details.required_action?.length"
                                    class="vector-list-item full-width">
                                    <p class="vector-list-label">Required Action</p>
                                    <p class="vector-list-value">
                                        {{cveDetails.cve_details.required_action}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </mat-menu>
                </h3> -->
                <div class="cve-summaryinfo">
                    <p *ngIf="cveDetails.cve_summary">{{!showMore ?
                        showLimitedContent(cveDetails.cve_summary): cveDetails.cve_summary}}
                        <span (click)="showMore = !showMore" *ngIf="cveDetails.cve_summary.length > 200"
                            class="show-more">{{!showMore ? 'More': 'Less'}}</span>
                    </p>
                    <div class="enumeration-summaryrow">
                        <div class="enumeration-summaryitem">
                            <p class="summaryitem-label">NVD Published Date:</p>
                            <p class="summaryitem-value">{{cveDetails.nvd_pd}}

                            </p>
                        </div>
                        <div class="enumeration-summaryitem">
                            <p class="summaryitem-label">NVD Last Modified:</p>
                            <p class="summaryitem-value">{{cveDetails.nvd_lmd}}</p>
                        </div>
                        <div class="enumeration-summaryitem">
                            <p class="summaryitem-label">Source:</p>
                            <p class="summaryitem-value">{{cveDetails.source}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="cveDetails" class=" cve-vuldetails">
                <div class="cve-vuldetails-header">
                    <h3 class="spark-sec-tittle">Vulnerability Details</h3>
                    <div class="cve-vuldetails-dropdown-section">
                    <div class="cve-vuldetails-dropdown">
                        <label class="authoruty-label">{{splitedCVSS(selectedMatrix.name)?.[0]}}: </label>
                        <label  class="authoruty-value"><span>{{splitedCVSS(selectedMatrix.name)?.[1]}}</span><mat-icon mat-button
                                [matMenuTriggerFor]="cvss" *ngIf="cveDetails?.severity_metrics?.length > 1">arrow_drop_down</mat-icon></label>
                        <mat-menu #cvss="matMenu" class="vector-list nist-list">
                            <ul>
                                <li (click)="selectMatrix(metrix)" *ngFor="let metrix of cveDetails.severity_metrics">{{metrix.name}}</li>
                            </ul>
                        </mat-menu>
                    </div>
                    
                    <div class="cve-vuldetails-dropdown">
                        <label class="authoruty-label">Authority: </label>
                        <label  class="authoruty-value"><span>{{selectedAuthority}}</span>
                            <mat-icon *ngIf="isAuthDropdrowShow()" mat-button [matMenuTriggerFor]="nist">arrow_drop_down</mat-icon>
                        </label>
                       
                        <mat-menu #nist="matMenu" class="vector-list nist-list">
                            <ul>
                                <li [ngClass]="{'hide': !hideNonAvailableList(vector)}" (click)="selectAuthority(vector)" *ngFor="let vector of selectedMatrix.authority">{{vector}}</li>
                            </ul>
                        </mat-menu>
                    </div>
                </div>
                </div>
                <div class="cve-vuldetailsinfo">
                    <div class="cve-vuldetails-graph">
                        <apx-chart [series]="chartOptions?.series" [chart]="chartOptions?.chart"
                            [labels]="chartOptions?.labels" [legend]="chartOptions?.legend"
                            [tooltip]="chartOptions?.tooltip" [dataLabels]="chartOptions?.dataLabels"
                            [colors]="chartOptions?.colors" [stroke]="chartOptions?.stroke" [options]="{display: false}"
                            [plotOptions]="chartOptions?.plotOptions"
                            [states]="chartOptions?.states"></apx-chart>
                        <h6>CVSS Score</h6>
                    </div>
                    <div class="cve-vuldetails-list" *ngIf="selectedAuthorityValue && selectedMatrix.value[selectedAuthorityValue]">
                        <div  class="cve-vuldetails-item" *ngFor="let obj of selectedMatrix.value[selectedAuthorityValue]">
                            <p class="cve-vuldetails-item-label">{{obj.name}}</p>
                            <p class="cve-vuldetails-item-value high" [ngClass]="lowerCaseClass(obj.value)">{{obj.value}}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf="cveDetails" class=" cve-epss">
                <h3 class="spark-sec-tittle">Exploit prediction scoring system (EPSS)</h3>
                <div class="cve-epss-info">
                    <div *ngIf="cveDetails.vulnerability_details.epss_details.pea || cveDetails.vulnerability_details.epss_details.pea===0"
                        class="cve-epss-infoitem">
                        <p class="cve-epss-title">Probability of exploitation activity</p>
                        <p class="cve-epss-subtitle">Next 30 days</p>
                        <P class="cve-epss-label none">{{cveDetails.vulnerability_details.epss_details.pea}}%</P>
                    </div>
                    <div *ngIf="cveDetails.vulnerability_details.epss_details.pov || cveDetails.vulnerability_details.epss_details.pov===0"
                        class="cve-epss-infoitem">
                        <p class="cve-epss-title">Percentile, the proportion of vulnerabilities that are scored at or
                            less</p>
                        <P class="cve-epss-label low">~ {{cveDetails.vulnerability_details.epss_details.pov}}%</P>
                    </div>
                </div>
            </div>
            <div *ngIf="cveDetails" class="cve-accordionbox">
                
                <mat-accordion>
                    <mat-expansion-panel class="cve-accordion spark-whitebox" [expanded]="selctedIndex === 1">
                        <mat-expansion-panel-header class="cve-accordion-title spark-sec-tittle">
                            <h3 class="spark-sec-tittle">Reference</h3>
                            <!-- <button class="expand-btn" *ngIf="selctedIndex != 1" mat-button><mat-icon>keyboard_arrow_down</mat-icon></button>
                            <button class="expand-btn" *ngIf="selctedIndex === 1" mat-button><mat-icon>chevron_right</mat-icon></button> -->
                        </mat-expansion-panel-header>
                        <div class="cve-accordion-info">
                            <div class="cve-reference">
                                <div class="cve-reference-header cve-resourceslist">
                                    <div class="cve-resourcesitem">
                                        <div class="hyperlinks-item">
                                            Hyperlinks
                                        </div>
                                        <div class="resources-item">
                                            Resources
                                        </div>
                                    </div>
                                </div>
                                <div class="cve-reference-content cve-resourceslist">
                                    <div class="cve-resourcesitem" *ngFor="let reference of cveDetails.reference">
                                        <!-- <div class="hyperlinks-item text_ellipsis" appSafariEllipsis matTooltip="{{reference.hyperlinks.link}}"> -->
                                            <div class="hyperlinks-item text_ellipsis">
                                                <p matTooltip="{{reference.hyperlinks.link}}" class="text_ellipsis paramer-value"> <a href="{{reference.hyperlinks.link}}" target="_blank">{{reference.hyperlinks.link}}</a></p>
                                           
                                        </div>
                                        <!-- <div class="resources-item text_ellipsis" appSafariEllipsis matTooltip="{{reference.hyperlinks.resources}}">
                                            {{reference.hyperlinks.resources}}
                                        </div> -->
                                        <div class="resources-item text_ellipsis">
                                            <p matTooltip="{{reference.hyperlinks.resources}}" class="text_ellipsis paramer-value">{{reference.hyperlinks.resources}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="cve-accordion spark-whitebox" [expanded]="selctedIndex === 2"
                        >
                        <mat-expansion-panel-header class="cve-accordion-title spark-sec-tittle">
                            <h3 class="spark-sec-tittle">Weakness Enumeration</h3>
                            <!-- <button class="expand-btn" *ngIf="selctedIndex != 2" mat-button><mat-icon>keyboard_arrow_down</mat-icon></button>
                            <button class="expand-btn" *ngIf="selctedIndex === 2" mat-button><mat-icon>chevron_right</mat-icon></button>         -->
                                        </mat-expansion-panel-header>
                        <div class="cve-accordion-info">
                            <div class="cve-reference">
                                <div class="cve-reference-header cve-enumerationlist">
                                    <div class="cve-enumerationitem">
                                        <div class="id-item">
                                           <span class="enumeration-font"> CWE ID</span>
                                        </div>
                                        <div class="name-item  ">
                                            <span class="enumeration-font"> CWE Name</span>
                                        </div>
                                        <div class="source-item">
                                            <span class="enumeration-font">   Source</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="cve-reference-content cve-enumerationlist">
                                    <div class="cve-enumerationitem"
                                        *ngFor="let weakness of cveDetails.weakness_enumeration">
                                        <!-- <div class="id-item text_ellipsis" appSafariEllipsis [tooltip]="weakness.cwe_id">
                                            {{weakness.cwe_id}}
                                        </div>
                                        <div [tooltip]="weakness.cwe_name" class="name-item text_ellipsis" appSafariEllipsis>
                                            {{weakness.cwe_name}}
                                        </div>
                                        <div [tooltip]="weakness.source" class="source-item text_ellipsis" appSafariEllipsis>
                                        {{weakness.source}}
                                        </div> -->
                                        <div class="id-item" >
                                            <p matTooltip="{{weakness.cwe_id}}" class="text_ellipsis paramer-value">{{weakness.cwe_id}}</p>
                                        </div>
                                        <div class="name-item">
                                            <p matTooltip="{{weakness.cwe_name}}" class="text_ellipsis paramer-value">{{weakness.cwe_name}}</p>
                                           
                                        </div>
                                        <div class="source-item">
                                            <p matTooltip="{{weakness.source}}" class="text_ellipsis paramer-value">
                                        {{weakness.source}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="cve-accordion spark-whitebox" [expanded]="selctedIndex === 3"
                        >
                        <mat-expansion-panel-header class="cve-accordion-title spark-sec-tittle">
                            <h3 class="spark-sec-tittle">Known Affected Software Configurations</h3>
                            <!-- <button class="expand-btn" *ngIf="selctedIndex != 3" mat-button><mat-icon>keyboard_arrow_down</mat-icon></button>
                            <button class="expand-btn" *ngIf="selctedIndex === 3" mat-button><mat-icon>chevron_right</mat-icon></button>     -->
                                            </mat-expansion-panel-header>
                        <div class="cve-accordion-info">
                            <div class="cve-reference">
                                <div class="cve-reference-content cve-configurationslist">
                                    <div class="cve-configurationsitem"
                                        *ngFor="let affectedDevice of cveDetails.known_affected_sc">
                                        <div class="configurations-item configurations-cpe text_ellipsis">
                                            <p class="configurations-label">{{affectedDevice.cpe}}</p>
                                            <p class="configurations-value"> Show Matching CPE(s)</p>
                                        </div>
                                        <div *ngIf="affectedDevice.from || affectedDevice.from === 0" class="configurations-item configurations-from text_ellipsis">
                                            <p class="configurations-label">From({{affectedDevice?.from_include?'Including': 'excluding'}})</p>
                                            <p class="configurations-value">{{affectedDevice.from}}</p>
                                        </div>
                                        <div *ngIf="affectedDevice.to || affectedDevice.to === 0" class="configurations-item configurations-upto text_ellipsis">
                                            <p class="configurations-label">Up to({{affectedDevice?.to_include?'Including': 'excluding'}})</p>
                                            <p class="configurations-value">{{affectedDevice.to}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <!-- <div class="enumeration-right spark-whitebox">
            <h3 class="spark-sec-tittle">Affected Assets List -
                <span>{{affectedDeviceResponse?.affected_assets_list}}</span></h3>
            <div class="scroll assets-table" infinite-scroll [infiniteScrollDistance]="6" [infiniteScrollThrottle]="63"
                [alwaysCallback]="true" [scrollWindow]="false" (scrolled)="onScroll()">
                <table *ngIf="dataSource?.data?.length" mat-table [dataSource]="dataSource"
                    class="spark_table table_fixed ">
                    <ng-container matColumnDef="asset_name">
                        <th mat-header-cell *matHeaderCellDef class="asset-col"> Asset Name 
                            <span class="filter-icon" *ngIf="showFilter(assetsNames)">
                                <button mat-icon-button class="filter-icon-button" [matMenuTriggerFor]="assetsName" [class.show]="openedMenuId === assetsName.panelId"
                              (menuOpened)="onMenuOpen(assetsName.panelId, 'assetsName')" (menuClosed)="onMenuClose()"
                                  [ngClass]="{'applied':  filterApplyFlags['assetsName'] }">
                                  <mat-icon>filter_list</mat-icon>
                                </button>
                                <mat-menu #assetsName="matMenu" class="assets-list-menu">
                                    <div class="userfilter-box affected-assets-box">
                                        <div class="user-filtersearch">
                                            <div (click)="$event.stopPropagation()" class="search-bar user-search">
                                                <mat-form-field appearance="fill" class="border-field">
                                                    <input  [(ngModel)]="searchAssets" matInput placeholder="Search.." autocomplete="off" type="text">
                                                    <mat-icon class='search-icon' matPrefix>search</mat-icon>
                                                </mat-form-field>
                                
                                            </div>
                                            <button mat-button class="global-button apply-btn" (click)="onApplyHanlder('assetsName')">Apply</button>
                                        </div>
                                        <div class="scroll userfiltr-list">
                                            <div  *ngFor="let item of assetsNames | search : searchAssets;">
                                                <mat-checkbox [(ngModel)]="item.activated" [checked]="item.selected" (click)="$event.stopPropagation()"
                                                    (change)="toggleMenuSelection(assetsNames,item)">
                                                    <label class="text_ellipsis" [options]="toolTipOption" appSafariEllipsis 
                                                    [tooltip]="item.name">{{item.name}}</label>
                                                    
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                
                                    </div>
                                </mat-menu>
                              </span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="asset-col">
                            <p class="cve-col-title"><span class="text_ellipsis" [options]="toolTipOption" appSafariEllipsis
                                [tooltip]="element.asset_name">{{element.asset_name}}</span></p>
                            <p class="cve-col-info"><span class="text_ellipsis" [options]="toolTipOption" appSafariEllipsis
                                [tooltip]="element.region_name">{{element.region_name}}</span></p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="asset_type">
                        <th mat-header-cell *matHeaderCellDef class="type-col"> Asset Type 
                            <span class="filter-icon" *ngIf="showFilter(assetsTypes)">
                                <button mat-icon-button class="filter-icon-button" [matMenuTriggerFor]="assetsTypesDrop" [class.show]="openedMenuId === assetsTypesDrop.panelId"
                              (menuOpened)="onMenuOpen(assetsTypesDrop.panelId, 'assetsType')" (menuClosed)="onMenuClose()"
                                  [ngClass]="{'applied':  filterApplyFlags['assetsType'] }">
                                  <mat-icon>filter_list</mat-icon>
                                </button>
                                <mat-menu #assetsTypesDrop="matMenu" class="role-list">
                                  <div class="status-options" *ngFor="let item of assetsTypes; let i=index;">
                                    <mat-checkbox [(ngModel)]="item.activated" [checked]="item.selected"
                                      (click)="$event.stopPropagation()" (change)="toggleMenuSelection(assetsTypes,item)">
                                      {{item.name}}
                                    </mat-checkbox>
                                  </div>
                                  <button mat-button class="global-button apply-btn" (click)="onApplyHanlder('assetsType')">Apply</button>
                                </mat-menu>
                              </span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="type-col">
                            {{element.asset_type}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="vendor">
                        <th mat-header-cell *matHeaderCellDef class="vendor-col"> Vendor 
                            <span class="filter-icon" *ngIf="showFilter(vendors)">
                                <button mat-icon-button class="filter-icon-button" [matMenuTriggerFor]="vendorsDrop" [class.show]="openedMenuId === vendorsDrop.panelId"
                              (menuOpened)="onMenuOpen(vendorsDrop.panelId, 'vendor')" (menuClosed)="onMenuClose()"
                                  [ngClass]="{'applied':  filterApplyFlags['vendor'] }">
                                  <mat-icon>filter_list</mat-icon>
                                </button>
                                <mat-menu #vendorsDrop="matMenu" class="role-list">
                                  <div class="status-options" *ngFor="let item of vendors; let i=index;">
                                    <mat-checkbox [(ngModel)]="item.activated" [checked]="item.selected"
                                      (click)="$event.stopPropagation()" (change)="toggleMenuSelection(vendors,item)">
                                      {{item.name}}
                                    </mat-checkbox>
                                  </div>
                                  <button mat-button class="global-button apply-btn" (click)="onApplyHanlder('vendor')">Apply</button>
                                </mat-menu>
                              </span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="vendor-col"> {{element.vendor}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div *ngIf="assetsDataLoader && !dataSource?.data?.length" class="no-cve-data">{{noData}}</div>
                <div class="item" *ngIf="!assetsDataLoader">
                    <ngx-skeleton-loader count="5" class="white-list-table-loader" [theme]="{
                              width: '15%',
                              'border-radius': '0',
                              height: '15px',
                              'margin-bottom': '10px'
                            }" *ngFor="let item of [].constructor(!dataSource?.data?.length ? 18 : 3); let i = index">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </div> -->
    </div>
</div>