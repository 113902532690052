import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CyberSecurityRoutingModule } from './cyber-security-routing.module';
import { CyberSecurityComponent } from './cyber-security/cyber-security.component';
import { CloudNewScanComponent } from './cyber-security/child-components/cloud-new-scan/cloud-new-scan.component';
import { SharedModule } from '../shared/shared.module';
import { CloudSecurityComponent } from './cyber-security/cloud-security/cloud-security.component';
import { CloudSecurityOverlayComponent } from './cyber-security/cloud-security-overlay/cloud-security-overlay.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ParametersComponent } from './cyber-security/cloud-security/parameters/parameters.component';
import { BenchmarkComponent } from './cyber-security/cloud-security/benchmark/benchmark.component';
import { SeverityComponent } from './cyber-security/cloud-security/severity/severity.component';
import { AcceptedriskComponent } from './cyber-security/cloud-security/acceptedrisk/acceptedrisk.component';
import { CompliantAssetsComponent } from './cyber-security/cloud-security/compliant-assets/compliant-assets.component';
import { DynamicGridComponent } from './cyber-security/cloud-security/dynamic-grid/dynamic-grid.component';
import { OverlayResourceTypeComponent } from './cyber-security/cloud-security-overlay/overlay-resource-type/overlay-resource-type.component';
import { OverlayAccptedRiskComponent } from './cyber-security/cloud-security-overlay/overlay-accpted-risk/overlay-accpted-risk.component';
import { OverlayComplainceComponent } from './cyber-security/cloud-security-overlay/overlay-complaince/overlay-complaince.component';
import { FilterManualComponent } from './cyber-security/cloud-security/filter-manual/filter-manual.component';
import { EditCloudScanComponent } from './cyber-security/child-components/edit-cloud-scan/edit-cloud-scan.component';
import { ResourceComponent } from './cyber-security/cloud-security-overlay/cloud-tabs-component/resource/resource.component';
import { CloudResourceComponent } from './cloud-resource/cloud-resource.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CloudscansComponent } from './cloudscans/cloudscans.component';
import { CloudscanOPtionsComponent } from './cloudscans/childcomponent/cloudscan-options/cloudscan-options.component';
import { CustomFilterComponent } from './cloud-resource/custom-filter/custom-filter.component';
import { CloudResourceOverlayComponent } from './cloud-resource/cloud-resource-overlay/cloud-resource-overlay/cloud-resource-overlay.component';
import { ResourceNameComponent } from './cloud-resource/resource-name/resource-name.component';
import { ScoreComponent } from './cloud-resource/score/score/score.component';
import { StatusComponent } from './cloud-resource/status/status.component';
import { OnPremAgentComponent } from './on-prem-agent/on-prem-agent.component';
import { CloudScanImageComponent } from './cloudscans/cloud-scan-image/cloud-scan-image.component';



@NgModule({
  declarations: [
    
  
    CyberSecurityComponent,
            CloudNewScanComponent,
            CloudSecurityComponent,
            CloudSecurityOverlayComponent,
            ParametersComponent,
            BenchmarkComponent,
            SeverityComponent,
            AcceptedriskComponent,
            CompliantAssetsComponent,
            DynamicGridComponent,
            OverlayResourceTypeComponent,
            OverlayAccptedRiskComponent,
            OverlayComplainceComponent,
            DynamicGridComponent,
            FilterManualComponent,
            EditCloudScanComponent,
            ResourceComponent,
            CloudResourceComponent,
            CloudscansComponent,
            CloudscanOPtionsComponent,
            OnPremAgentComponent,
            CustomFilterComponent,
            CloudResourceOverlayComponent,
            ResourceNameComponent,
            ScoreComponent,
            StatusComponent,
            CloudScanImageComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    AgGridModule,
    CyberSecurityRoutingModule,
    NgxSkeletonLoaderModule.forRoot(),
  ],
  exports: [
    CloudSecurityComponent,
    CloudSecurityOverlayComponent,
    EditCloudScanComponent
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CyberSecurityModule { }
