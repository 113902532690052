import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ResetPasswordComponent } from 'src/app/modules/login/components/reset-password/reset-password.component';
import { IDialog } from '../../model/Dialog.model';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { HeaderService } from '../../services/header.service';
import { GlobalFilterService } from '../../services/global-filter.service';
import { COMPOENT_NAME, ROLES_CONTENT_PROPS } from '../../constants/constant-data';
import { MatMenuTrigger } from '@angular/material/menu';
import { Utils } from 'src/app/modules/shared/utils';
import { ProfileService } from '../../services/profile.service';
import { IUserDetail } from '../../model/profile/profile';
import { SharedService } from '../../services/shared.service';
import { GlobalSearchService } from '../../services/global-search.service';
import { DeviceService } from '../../services/device.service';
import { MyScansService } from '../../services/my-scans.service';
import { NotificationService } from '../../services/notification.service';
import * as _ from 'lodash';
import { ImyScan } from '../../model/scan/scan';
import { SearchBarComponent } from '../../search-bar/search-bar.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SortingFilterService } from '../../services/sorting-filter.service';
import { FilterService } from '../../services/filter.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public heading: string = '';
  selectedItems: any[] = [];
  showDashBoardGlobalFilter = false;
  public paramId: string = '';
  userData: any;
  imageSrc: any;
  @ViewChild('commonDropdownMenu') commonDropdownMenu!: MatMenuTrigger;
  public currentRoute!: string;
  public dropDownData: any[] = [];
  public pageType: string = '';
  public pageSubTitle = '';
  public profileData: any = {};
  public pageProps: any = {};
  public avatarImage:any;
  public scanHeaderDetails: any = {};
  public disableFilter: boolean = false;
  public disableSearch: boolean = false;
  public get userDetails(){
    return this.profileService?.getUserDetails();
  }
  
  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }
  get componentName() {
    return COMPOENT_NAME;
  }
  constructor(
    public route: Router,
    public router: ActivatedRoute ,
    public authService: AuthService,
    private dialogService: DialogService,
    private headerService: HeaderService,
    public globalFilterService: GlobalFilterService,
    public location: Location,
    private profileService: ProfileService,
    private sharedService: SharedService,
    public globalSearchService: GlobalSearchService,
    private deviceService: DeviceService,
    private myScanService: MyScansService,
    public notificationService: NotificationService,
    private matIconRegistry: MatIconRegistry,
    private sortingFilterService: SortingFilterService,
    private domSanitizer: DomSanitizer,
    private filterService: FilterService
  ) {}

  ngOnInit() {
    
    this.sharedService.toggleBodyClass(true)
    this.headerService.getHeading().subscribe((heading: string) => {
      this.heading = heading;
      this.pageSubTitle = '';
    });
    this.headerService.getDropdown().subscribe((dropdownData: any)=>{
        let dropdown = _.cloneDeep(dropdownData.dropdown)
        if(dropdownData?.type === "scanDetail"){
          dropdown = dropdown.filter((scan:ImyScan)=>(scan.status === 3 || scan.status==2|| scan.status==1))
        }
        this.dropDownData = dropdown || [];
        this.pageType = dropdownData.type;
        this.pageProps = dropdownData.props || {};
        this.pageSubTitle = '';
        this.scanHeaderDetails = dropdownData.scanHeaderDetails || {};
        this.updateSubTitle(dropdownData.type);
    });
    this.loadProfile();
    this.loadImage();
    this.profileService.updateImageObserver.subscribe((imgUrl)=>{
      this.avatarImage = imgUrl ? imgUrl : this.avatarImage;
    });
    this.headerService.getDisableFilter().subscribe((flag: boolean)=>{
      this.disableFilter = flag;
    })
    this.headerService.getDisableSearch().subscribe((flag: boolean)=>{
      this.disableSearch = flag;
    })
    this.registerIcons();
  }
  ngAfterViewInit(): void{
    this.registerIcons();
  }
  registerIcons(): void {
    // The URL should be the path to your SVG icon
    const url = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/filter-icon.svg');
    this.matIconRegistry.addSvgIcon('filter_icon', url);
  }
  goBack() {
    if(this.pageType === 'device') {
      const deviceInfo = this.deviceService.getDevices();
      if(deviceInfo.deviceInfo.length) {
        deviceInfo.fromBack = true;
        this.deviceService.setDevices(deviceInfo);
      }
      this.route.navigate(['admin/asset-management']);
      
    } else if(this.pageType === 'scanDetail') {
      const myScansDetails = this.myScanService.getMyScans();
      if(myScansDetails.myScan.length) {
        myScansDetails.fromBack = true;
        this.myScanService.setMyScans(myScansDetails);
      }
      this.route.navigate(['dashboard/my-scans']);
    }else if(this.pageType === 'cveDashDetails') {
      this.route.navigate(['dashboard/cve-dashboard']);
    } else {
      this.location?.back();
    }
      
  }
  // onDeviceManagementHandler(action: string){
  //   const obj = {
  //     type: 'deviceManagement',
  //     action: action
  //   }
  //   this.headerService.dispatchActions(obj);
  // }

  // onUserManagementHandler(action: string){
  //   const obj = {
  //     type: 'userMgnt',
  //     action: action
  //   }
  //   this.headerService.dispatchActions(obj);
  // }

  private loadProfile(): void {
    this.profileService.fetchUserDetails(this.userProfileCallback);
  }

  clearSearchTerm(): void {
    this.globalSearchService.clearSearch();
    this.globalSearchService.searchCleared.emit();
  }
  
  public userProfileCallback = (results: IUserDetail) => {
      this.userData = results;
      this.profileData['firstname'] = this.userData.firstname;
      this.profileData['lastname'] = this.userData.lastname;
      const currentDate = new Date();
      const expiryDate = new Date(this.userData.expiry_date);
      const expiresInMilliseconds = expiryDate.getTime() - currentDate.getTime();
      const expiresInDays = Math.ceil(expiresInMilliseconds / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
      this.profileData['expiresIn'] = expiresInDays;
      
  if (expiresInDays <= 0) {
    this.profileData['expiredOn'] = this.formatDate(expiryDate);
  }
      this.profileData['subscriptionName'] = this.userData.subscription_name;
      this.profileData['subscriptionCode'] = this.userData.subscription_code;
      this.profileData['subscription'] = Utils.makeFirstLetterCapital(this.userData.subscription_code);
  }

  private formatDate(date: Date): string {
    const day = date.getDate();
    const monthNames = ["Jan", 
    "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();
    
    const suffix = this.getOrdinalSuffix(day);
    return `Expired on ${day}${suffix} ${monthName}, ${year}`;
  }
  private getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th'; // for numbers like 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }

  public loadImage() {
    this.profileService.fetchUserImage(this.fetchImageCallback,40,40);
  }

  public fetchImageCallback = (response: any) => {
    this.imageSrc = response;
    this.avatarImage = this.imageSrc;

  }

  logout() {
    this.profileService.reset();
    this.authService.logoutUser().subscribe({
      next: (result) => {
        if (result.status === 200) {
          this.authService.logOut(true, true, true);
          this.authService.userLoggedOutSubject.next();
        }
      },
      error: (error) => {
        console.error('Error during logout:', error);
        this.authService.logOut(true, true, true);
        this.authService.userLoggedOutSubject.next();
        // Handle the error as needed, such as displaying a message to the user
      }
    });
    
  }

  onChangePassWord(): void{
    const payload: IDialog = {
      component: ResetPasswordComponent,
      width: '350px',
      data: {'isChangePassword': true}
    };
    this.dialogService.openDialog(payload);
  }

  newScan = () => {
    this.route.navigate(['dashboard/new-scan']);
  }
  
  showNewScanBtn() {
    let showBtn = false;
    showBtn = this.hasScanPermission() &&
     this.route.url && (this.route.url !== '/dashboard/new-scan') || false;
    return showBtn;
  }
  public openGlobalFilter(): void {
    this.globalFilterService.toggleFilter.next(true);
  }
  public openGlobalSearch():void{
    const payload: IDialog = {
      component:SearchBarComponent,
      width: '70%',
      class:'globalsearch-popup',
      position: {
          top: '65px',
        }
    }
    this.dialogService.openDialog(payload);
  }
  public openNotification():void{
    this.notificationService.notification.next(true);
  }

  public openSideNav():void{
    this.globalFilterService.sideNavFilter.next(true)
  }
  public openAccount():void{
    this.globalFilterService.accountFilter.next(true)
  }


  public hasPermission(roleName: string): boolean {
    return !!this.authService.isRoleAccess(roleName);
  }

  

  showFilter() {
    return this.globalFilterService.isFilter;
  }
  public hasScanPermission () {
    const hasPermission = this.authService.isRoleAccess(this.permissionProps.NEW_SCAN);
    return hasPermission;
  }
  openMenu() {
    if(!this.dropDownData.length){
      this.commonDropdownMenu.closeMenu();
    }
  }
  public updateScans(data: any, type?: string) {
    const dropdown: any = this.dropDownData;
    if(this.pageType==='schedule'){
      this.globalSearchService.headerText = '';
      this.globalSearchService.isRedirected = false;
      this.globalSearchService.searchTerm = '';
      let req:any={}
      req['schedule_scan_id']=data.schedule_scan_id;
      req['schedule_status']=data.schedule_status;
      this.route.navigate(['dashboard/schedule-scan/schedule', req]);
    }
    if (this.pageType === 'scanDetail') {
      this.globalSearchService.headerText = '';
      this.globalSearchService.isRedirected = false;
      this.globalSearchService.searchTerm = '';
      this.sortingFilterService.resetFilter('scanDetail', true);
      this.route.navigate(['dashboard/scan-details', data.scan_uuid, 'status',data.status]);
    } else if (this.pageType === 'device') {
      const deviceInfo = `${data.device_name}`;
      this.headerService.setHeading(deviceInfo || '', { dropdown: dropdown, type: this.pageType });
      this.route.navigate(['admin/asset-compare', data.device_id]);
    }  else if (this.pageType === 'cveDashDetails') {
      const cveInfo = `${data.cveid}`;
      this.headerService.setHeading(cveInfo || '', { dropdown: dropdown, type: this.pageType });
      this.route.navigate(['dashboard/cve-dashboard/enumeration', data.cveid]);
    }
  }
  public updateSubTitle(type: string) {
    if(type === 'device') {
      this.pageSubTitle = '/  Asset Management'
    } else if (type === 'scanDetail'){
      this.pageSubTitle = '/  My Scans'
    }
  }
  closeSideNav() {
    this.filterService.closeSidenav();
  } 
  getNameCombination(firstName:any, lastName:any | undefined){
    return Utils.getNameCombination(firstName, lastName)
  }
  getLabelClass(subscriptionCode:string) {
    if (subscriptionCode === 'free') {
        return 'free';
    } else if (subscriptionCode === 'gold') {
        return 'gold';
    } else if (subscriptionCode === 'diamond') {
        return 'diamond';
    } else if (subscriptionCode === 'platinum') {
        return 'platinum';
    } else {
        return ''; 
    }
  }
}