import { Component, Input, SimpleChanges } from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IDeviceList } from 'src/app/modules/admin/components/device-management/device.model';
import { GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-excel-export',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss']
})
export class ExcelExportComponent {

  // @Input() rowData:any; // Ensure it always defaults to an empty array
  @Input() gridApi!: any;
  @Input() isExportEnabled = false;

  exportToExcel(): void {
    // if (this.rowData.length === 0) return;
    // const filteredData = this.rowData.map((row: { device_name: any; device_type: any; vendor: any; region_name: any; last_scan: any; security_score: any; vulnerability: any; }) => ({
    //   'Asset Name': row.device_name,
    //   'Asset Type': row.device_type,
    //   'OEM': row.vendor,
    //   'Region': row.region_name,
    //   'Last Scan': row.last_scan,
    //   'Security Score': row.security_score,
    //   'Vulnerabilities': row.vulnerability,
    // }));

    if (!this.gridApi) {
      return;
    }

    const displayedData: any[] = [];
    this.gridApi.grid.api.forEachNodeAfterFilterAndSort((node:any)=>{
      if (node.data) {
        displayedData.push(node.data);
      }
    });
    if (!displayedData.length) {
      return;
    }
    const filteredData = displayedData?.map((row: { device_name: any; device_type: any; vendor: any; region_name: any; last_scan: any; security_score: any; vulnerability: any; }) => ({
      'Asset Name': row.device_name,
      'Asset Type': row.device_type,
      'OEM': row.vendor,
      'Region': row.region_name,
      'Last Scan': row.last_scan,
      'Security Score': row.security_score,
      'Misconfigurations': row.vulnerability,
    }));

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Filtered Device List');

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
    const timestamp = new Date().toISOString().replace(/[:.]/g, '_');
    this.saveAsExcelFile(excelBuffer, `Assets_list_${timestamp}`);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, `${fileName}.xlsx`);
  }
}
