import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, Validator, ValidatorFn, AbstractControl, Form } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service' ;
import { CustomDailog } from '../../model/Dialog.model';
import { RenamedialogComponent } from 'src/app/modules/my-scans/renamedialog/renamedialog.component';
import { FrequencyOptions } from 'src/app/modules/new-scan/components/new-scan/new.model';
import { END_POINTS } from '../../constants/endpoints';
import { RestService } from '../../services/rest.service';
import { ResponseService } from '../../services/response.service';
import { Frequency } from '../../model/scan/scan';
import { MatRadioChange } from '@angular/material/radio';
import { ScheduleScanService } from '../../services/schedule-scan.service';
import { ScanService } from '../../services/scan.service';
import { Observable, debounceTime, delay, distinctUntilChanged, filter, fromEvent, map, of, switchMap } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { SpinnerService } from '../../services/spinner.service';

export interface PeriodicElement {
  name: string;
  position: string;
}

// const ELEMENT_DATA: any = [
//   {schedule_name: 'Scan1234', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan1234', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan1234', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan1234', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan1234', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan1234', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan1234', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'},
//   {schedule_name: 'Scan123', frequecy_name: '10 Jan, 2023 - 12:12:08'}
  
// ];
@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.scss'],
  providers: [DatePipe]
})
export class ScheduleDialogComponent implements OnInit {

  searchForm: FormGroup;
  @ViewChild('searchField') searchField!: ElementRef;
  displayedColumns: string[] = ['position', 'name'];

  dataSource:any = []

  showDateField = true;
  showWeeklyDaysField = false;

  timeOptions: string[] = [];
  timeZone: string = '';
  frequency:any = [];
  selectedValue:boolean = true;
  frequencyOptions:FrequencyOptions ={};
  isFrequencyLoaded:boolean=false;
  scheduleFormGrp: FormGroup;
  scheduled_frequency !:Frequency
  isMyscansNewschedule:boolean=false;
  public scheduleData: any;
  allScheduleList:any;
  filteredData: string[] | undefined;
  selectedRow: PeriodicElement | null = null;
  isApiCall:boolean=false
  isBack:boolean=false;
  isSearchActive=false;
  isCloudScan=false;
  constructor(public dialogRef: MatDialogRef<RenamedialogComponent>,
    public spinnerService:SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog,
    public dialogService: DialogService,private fBuilder: FormBuilder,
    private restService: RestService,
    private responseService: ResponseService,
    private cdr: ChangeDetectorRef,
    private scheduleService:ScheduleScanService,
    private scanService: ScanService,
    private datePipe:DatePipe
  ) {
    //this.oldScanName = data?.data?.scanname;
    //this.newScanName = data?.data?.scanname;
    console.log(this.data?.data?.isCloudScan);
    if(this.data?.data?.isCloudScan!=undefined&& this.data?.data?.isCloudScan){
      this.isCloudScan=true
    }
    this.searchForm = this.fBuilder.group({
      searchTerm: ['']
    });
    if(data?.data?.scheduleData){
     
    } else {
      this.selectedValue = true;
      
    }
    if(data?.data.isMyscansNewschedule) {
       this.isMyscansNewschedule= data?.data.isMyscansNewschedule;
    }
    if(data?.data.radioFlag){
      this.isBack = data?.data.radioFlag;
    }
    this.timeOptions=this.scheduleService.timeOptions();

    if(data?.data?.summary_data){
      this.allScheduleList = data?.data?.summary_data
      console.log(this.allScheduleList)
      this.dataSource = this.allScheduleList.slice(0,5)
      console.log (this.allScheduleList)
    }
    if(!this.data?.data?.isCloudScan){
      this.scheduleFormGrp = this.fBuilder.group({
      
        "scan_name": new FormControl(null),
          "frequency_id":new FormControl(null,[Validators.required]),
          "time":new FormControl(null,[Validators.required]),
          "date":new FormControl(null,[Validators.required]),
          "day":new FormControl(null,[Validators.required])
      
      });

    } else {
      this.scheduleFormGrp = this.fBuilder.group({
      
        //"scan_name": new FormControl(null),
          "frequency_id":new FormControl(null,[Validators.required]),
          "time":new FormControl(null,[Validators.required]),
         // "date":new FormControl(null,[Validators.required]),
          //"day":new FormControl(null,[Validators.required])
      
      });

    }
    
    //this.scheduleFormGrp.get('date')?.disable();
    this.scheduleFormGrp.get('time')?.setValue('12:00 AM');
    //data?.data?.scheduleData.scheduleData
    // if(data?.data?.scheduleData){
    //   this.scheduleFormGrp.get('scan_name')?.setValue(data?.data?.scheduleData.scanname);
    //   this.scheduleFormGrp.get('scan_name')?.updateValueAndValidity();
    //   this.scheduleFormGrp.get('frequency_id')?.setValue('e5e5056a-de56-4941-ba63-bab4c2bde768');
    //   this.scheduleFormGrp.get('frequency_id')?.updateValueAndValidity();
    // }

   

   
    this.frequencyOptions.timeOptions = this.timeOptions;
    this.detectTimeZone();
    this.scheduleFormGrp.get('date')?.valueChanges.subscribe(value => {
      const data = this.scheduleFormGrp.get('date')?.value
      console.log(data)
      if (!value) {
        this.scheduleFormGrp.get('date')?.setValue("")
        return;
      } 
    });
    this.loadfrequency();
  }

  detailsCallBack= (response:any)=>{
    if(response.status==200){    
      this.scheduleData=response.data
      this.setData();
    }
   }
   public setData(){
    this.scheduleFormGrp.get('scan_name')?.setValue(this.scheduleData.schedule_name);
      this.scheduleFormGrp.get('scan_name')?.updateValueAndValidity();
      this.scheduleFormGrp.get('frequency_id')?.setValue(this.scheduleData.frequency_id);
      this.scheduleFormGrp.get('frequency_id')?.updateValueAndValidity();
      
      this.scheduleFormGrp.get('day')?.setValue(this.scheduleData?.day);
      this.scheduleFormGrp.get('day')?.updateValueAndValidity();
     
      // let datetimeValue: string = "2024-07-21T16:11:28.189Z";
       if(this.scheduleData.date){
        this.setInitialValues(this.scheduleData)
       } else {
        this.showDateField = false;

       }
       
       
      this.setInitialfrequencyValues(this.scheduleData.frequency_id)
      this.isFrequencyLoaded=true;
      // this.scheduleFormGrp.disable()
     
  }

  editDate:any;
  setInitialValues(data: any): void {
    const dateString = data.date;
    const [day, month, year] = dateString.split("-").map(Number);
    const dateObj = new Date(year, month - 1, day); // month is zero-based in JS Date

    this.editDate=dateObj;
    this.editDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const [hourString, minuteString] = data.time.split(":");
      const hours = parseInt(hourString, 10);
      const minutes = parseInt(minuteString, 10);

      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = (hours % 12 || 12).toString().padStart(2, '0'); // Convert to 12-hour format and ensure two-digit format
      const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure two-digit format for minutes

      const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;

  
      
      
      
    if(this.editDate.getTime()===today.getTime()){
      this.frequencyOptions.timeOptions=this.scheduleService.generateTimeOptionsFromNow();
      
      if (!this.frequencyOptions.timeOptions.includes(formattedTime)) {
        this.frequencyOptions.timeOptions.unshift(formattedTime); // Add formattedTime as the first value
      }

    } else {
      this.timeOptions=this.scheduleService.timeOptions(data.time);
      this.frequencyOptions.timeOptions = this.timeOptions;
    }
    this.scheduleFormGrp.get('time')?.setValue(formattedTime);
      this.scheduleFormGrp.get('time')?.updateValueAndValidity();
    if (!isNaN(dateObj.getTime())) {
      // Set the date
      this.scheduleFormGrp.get('date')!.setValue(dateObj);
    } else {
      console.error('Invalid date');
    }
    
    this.editDate.setHours(0,0,0,0)
    if(this.editDate.getTime()<today.getTime()){
      //this.frequencyOptions.timeOptions=this.scheduleService.generateTimeOptionsFromNow();
      this.scheduleFormGrp.get('time')?.disable({ emitEvent: false });
      this.scheduleFormGrp.get('time')?.clearValidators()
      this.scheduleFormGrp.get('time')?.updateValueAndValidity();

    } else{
      this.scheduleFormGrp.get('time')?.enable();
    }
    
    
    
    this.scheduleFormGrp.get('date')?.updateValueAndValidity();

    
    console.log(this.scheduleFormGrp.value)
    
  }

  

  setInitialfrequencyValues(frequencyId: string) {
    const selectedFrequency = this.frequencyOptions.frequency.find((f: any) => f.id === frequencyId);
    if (selectedFrequency) {
      this.onEditFormFields(selectedFrequency.fields);
    }
  }

  onEditFormFields(fields: string[]) {
    this.showDateField = fields.includes('date');
    this.showWeeklyDaysField = fields.includes('day');
    //this.scheduleFormGrp.get('date')?.disable();
    

    if (!this.showWeeklyDaysField) {
      this.scheduleFormGrp.get('day')?.disable();
      this.scheduleFormGrp.get('day')?.clearValidators()
      this.scheduleFormGrp.get('day')?.updateValueAndValidity();

    } else {
      this.scheduleFormGrp.get('day')?.enable();
      this.scheduleFormGrp.get('day')?.setValidators(Validators.required)
      this.scheduleFormGrp.get('day')?.updateValueAndValidity();
    }
  }

  onSelectionChange($event: MatRadioChange) {
        console.log($event.value)
        this.selectedValue = $event.value;
        this.selectedRow = null;
        this.scheduleFormGrp.reset();
        this.cdr.detectChanges();
  }

  scheduleConnect() {
    this.spinnerService.show()
    let req:any={}
    const date = this.scheduleFormGrp.get('date')!.value;
    const formattedDate = this.datePipe.transform(date, 'dd-MM-yyyy');
    this.scheduleFormGrp.get('date')!.setValue(formattedDate);
    
    if(this.selectedRow){
       req=this.selectedRow
    } else {
      
      req = this.scheduleFormGrp.value;
      req.time=this.scheduleFormGrp.get('time')?.value;
    }
    req= this.scheduleService.createRequestObject(req)
    // if(this.data?.data?.scheduleData.schedule_scan_id){
    //   req['schedule_scan_id'] = this.data?.data?.scheduleData.schedule_scan_id;
    // }
    this.data?.data?.setCallbackFunction(this.data?.data.radioData,true,req,this.data?.data.radioFlag);
    
    
    //this.saveSchduleDetails(req, this.selectedScheduleDetailsCallBack);
    this.dialogRef.close()
    
    }
    
  selectedScheduleDetailsCallBack(selectedListRow: any, selectedScheduleDetailsCallBack: any) {
    //throw new Error('Method not implemented.');
  }

  dateFilter(date: Date | null): boolean {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1); // Set date to yesterday
    yesterday.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds to 0
  
    const maxDate = new Date(today.getFullYear() + 100, today.getMonth(), today.getDate()); // 100 years from today
  
    return date ? date > yesterday && date <= maxDate : false; // Disable dates until yesterday and after 100 years from today
  }

  detectTimeZone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(timeZone)
    this.timeZone = timeZone;
    this.frequencyOptions.timeZone = this.timeZone;
  }

  public loadfrequency() {
    this.isFrequencyLoaded = false
    
    
    this.scanService.loadScheduleFrequency(this.frequencyCallback,this.isCloudScan)

  }

  onFrequencyChange(selectedFrequencyId: string) {
    const selectedFrequency = this.frequencyOptions.frequency.find((f:any) => f.id === selectedFrequencyId);
    if (selectedFrequency) {
      this.updateFormFields(selectedFrequency.fields);
    }
    
  }

  onDatepickerClosed(): void {
    const selectedDate = this.scheduleFormGrp?.get('date')?.value;
    selectedDate.setHours(23, 59, 0, 0);
  }

  onDateChange(event: any): void {
    console.log(event.value);
    const selectedDate = new Date(event);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate.getTime() === today.getTime()) {
      this.frequencyOptions.timeOptions=this.scheduleService.generateTimeOptionsFromNow();
      selectedDate.setHours(23, 59, 0, 0); // Set the time to 11:59 PM
      this.scheduleFormGrp.get('date')?.setValue(selectedDate);
      this.scheduleFormGrp.get('date')?.updateValueAndValidity();
    }  else {
      this.frequencyOptions.timeOptions=this.scheduleService.timeOptions()
      this.scheduleFormGrp.get('date')?.setValidators([
        Validators.required,
       this.dateRangeValidator()
       ]);
    }
    
     this.scheduleFormGrp.get('date')?.updateValueAndValidity();
     this.scheduleFormGrp.get('time')?.enable();
    this.scheduleFormGrp.get('time')?.setValue(this.frequencyOptions.timeOptions[0]);
    this.scheduleFormGrp.get('time')?.updateValueAndValidity();
    
  }

  dateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const date = new Date(control.value);
      const today = new Date();
      const maxDate = new Date(today.getFullYear() + 100, today.getMonth(), today.getDate());
  
      if (isNaN(date.getTime())) {
        return { invalidDate: { value: control.value } }; // Invalid date
      }
  
      if (date < today || date > maxDate) {
        return { outOfRange: { value: control.value } }; // Date out of range
      }
  
      return null; // Valid date
    };
  }

  updateFormFields(fields: string[]) {
    this.showDateField = fields.includes('date');
    this.showWeeklyDaysField = fields.includes('day');
    
    if (!this.showDateField) {
      this.scheduleFormGrp.get('date')?.disable();
      this.scheduleFormGrp.get('date')?.clearValidators()
      this.scheduleFormGrp.get('date')?.updateValueAndValidity();
    } else {
      this.scheduleFormGrp.get('date')?.enable();
      this.scheduleFormGrp.get('date')?.setValidators(Validators.required)
      this.scheduleFormGrp.get('date')?.enable();
     this.scheduleFormGrp.get('date')?.setValidators([
      Validators.required,
     this.dateRangeValidator()
     ]);
this.scheduleFormGrp.get('date')?.updateValueAndValidity();
    }

    if (!this.showWeeklyDaysField) {
      this.scheduleFormGrp.get('day')?.disable();
      this.scheduleFormGrp.get('day')?.clearValidators()
      this.scheduleFormGrp.get('day')?.updateValueAndValidity();

    } else {
      this.scheduleFormGrp.get('day')?.enable();
      this.scheduleFormGrp.get('day')?.setValidators(Validators.required)
      this.scheduleFormGrp.get('day')?.updateValueAndValidity();
    }
    if(this.scheduleFormGrp?.get('date')?.value) {
      this.onDateChange(this.scheduleFormGrp?.get('date')?.value)
    }
  }
  
  public frequencyCallback = (response: any)=>{
    this.isFrequencyLoaded = false
    if (response?.status === 200) {
      if(this.data?.data?.isCloudScan){
        this.frequencyOptions.frequency = [
          {"id": "1", "name": "1 hours"},
          {"id": "2", "name": "2 hours"},
          {"id": "3", "name": "3 hours"},
          {"id": "4", "name": "4 hours"},
          {"id": "5", "name": "5 hours"},
          {"id": "6", "name": "6 hours"},
          {"id": "7", "name": "7 hours"},
          {"id": "8", "name": "8 hours"},
          {"id": "9", "name": "9 hours"},
          {"id": "10", "name": "10 hours"},
          {"id": "11", "name": "11 hours"},
          {"id": "12", "name": "12 hours"},
          {"id": "13", "name": "13 hours"},
          {"id": "14", "name": "14 hours"},
          {"id": "15", "name": "15 hours"},
          {"id": "16", "name": "16 hours"},
          {"id": "17", "name": "17 hours"},
          {"id": "18", "name": "18 hours"},
          {"id": "19", "name": "19 hours"},
          {"id": "20", "name": "20 hours"},
          {"id": "21", "name": "21 hours"},
          {"id": "22", "name": "22 hours"},
          {"id": "23", "name": "23 hours"},
          {"id": "24", "name": "24 hours"}
      ]

      } else {
        this.frequencyOptions.frequency = response.data.frequency;
        this.frequencyOptions.Days=response.data.weekly;

      }
      
      
        // this.scheduleFormGrp.get('frequency_id')?.setValue(this.frequencyOptions.frequency[0].id);
        // this.scheduleFormGrp.get('frequency_id')?.updateValueAndValidity();
        if(this.data?.data?.scheduleData?.schedule_scan_id){
          this.scheduleService.scheduleInfoMethod(this.detailsCallBack,this.data?.data?.scheduleData.schedule_scan_id)
        } else{
          this.isFrequencyLoaded=true
        }
 
      console.log(response)
      //this.vendorList = response.data.vendors;
    }
  }
 
  public frequencyCallback1 = (response: any)=>{
    this.isFrequencyLoaded = false
    if (response?.status === 200) {
      //
      if(this.data?.data?.isCloudScan){
        this.frequencyOptions.frequency = [
          {"id": "1", "name": "1 hours"},
          {"id": "2", "name": "2 hours"},
          {"id": "3", "name": "3 hours"},
          {"id": "4", "name": "4 hours"},
          {"id": "5", "name": "5 hours"},
          {"id": "6", "name": "6 hours"},
          {"id": "7", "name": "7 hours"},
          {"id": "8", "name": "8 hours"},
          {"id": "9", "name": "9 hours"},
          {"id": "10", "name": "10 hours"},
          {"id": "11", "name": "11 hours"},
          {"id": "12", "name": "12 hours"},
          {"id": "13", "name": "13 hours"},
          {"id": "14", "name": "14 hours"},
          {"id": "15", "name": "15 hours"},
          {"id": "16", "name": "16 hours"},
          {"id": "17", "name": "17 hours"},
          {"id": "18", "name": "18 hours"},
          {"id": "19", "name": "19 hours"},
          {"id": "20", "name": "20 hours"},
          {"id": "21", "name": "21 hours"},
          {"id": "22", "name": "22 hours"},
          {"id": "23", "name": "23 hours"},
          {"id": "24", "name": "24 hours"}
      ]

      }

      // this.frequencyOptions.frequency = response.data.frequency;
      this.frequencyOptions.Days=response.data.weekly;
        // this.scheduleFormGrp.get('frequency_id')?.setValue(this.frequencyOptions.frequency[0].id);
        // this.scheduleFormGrp.get('frequency_id')?.updateValueAndValidity();
        if(this.data?.data?.scheduleData?.schedule_scan_id){
          this.scheduleService.scheduleInfoMethod(this.detailsCallBack,this.data?.data?.scheduleData.schedule_scan_id)
        } else{
          this.isFrequencyLoaded=true
        }

      console.log(response)
      //this.vendorList = response.data.vendors;
    } 
  }

  ngOnInit(): void {
    
    this.searchForm.valueChanges.pipe(
      debounceTime(300), // wait for 300ms pause in events
      distinctUntilChanged(), // only emit when the current value is different from the last
      switchMap(value => this.fakeSearch(value.searchTerm)) // switch to new observable each time
    ).subscribe(results => {
      const search=this.searchForm.get('searchTerm')?.value.trim()
      if (results && results.length > 0) {
        if (search === '') {
          this.dataSource = results.slice(0, 5); 
          //this.isSearchActive=false;
        } else {
          //this.isSearchActive=true
          this.dataSource = results; 
        }
      } else {
        this.dataSource = []; 
      }
      if(search.length>0){
        this.isSearchActive=true;
      }else{
        this.isSearchActive=false;
      }
      this.isApiCall=true
    });
    this.scheduleFormGrp.valueChanges.subscribe(() => {
      this.checkFormValidity();
    });
  }
  isScheduleButtonEnabled: boolean = false; // Initially disabled
  checkFormValidity() {
    // You can add custom logic here if needed
    this.isScheduleButtonEnabled = this.scheduleFormGrp.valid && this.scheduleFormGrp.dirty;
  }

  fakeSearch(term: string): Observable<any> {
    this.dataSource=[] 
    this.isApiCall=false
    return of(this.allScheduleList).pipe(
      delay(500), 
      map((list: any[]) =>
        list.filter((item: any) =>
          item.schedule_name.toLowerCase().includes(term.toLowerCase())
        )
      )
    );
  }
  onBack() {
    this.data?.data?.setCallbackFunction(this.data?.data.radioData,false,null,true);
  }

  // ngAfterViewInit(): void {
    
  // }

  // private filterData(query: string) {
  //   return new Promise<string[]>(resolve => {
  //     if (query) {
  //       this.dataSource = []
  //       resolve(this.allScheduleList.filter((item: string) => item.toLowerCase().includes(query)));
  //     } else {
  //       this.dataSource = this.allScheduleList.slice(0,5)
  //       resolve([...this.dataSource]);
  //     }
  //   });
  // }

  newName(value: string): void {
    const result = {
      click: true,
      value: value.trim()
    };
    this.data?.data?.setCallbackFunction(result);
    this.dialogService.closeDialog();
  }

  cancelRename(): void {
    this.dialogService.closeDialog();
  }

  saveSchduleDetails(selectedRow: any=null, callback: Function ){
    if(this.data?.data?.scheduleData.schedule_scan_id){
      selectedRow['schedule_scan_id'] = this.data?.data?.scheduleData.schedule_scan_id;
    }
    if(selectedRow['scan_name'] && selectedRow['scan_name'].trim().length==0){
      selectedRow['scan_name']=null;
    }
    this.restService.postApi(END_POINTS.SCAN_SCHEDULE, selectedRow).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  clickRow(row: PeriodicElement): void {
    this.selectedRow = row;
    console.log(this.selectedRow)
  }
  clearSearch(): void {
    this.searchForm.get('searchTerm')?.setValue('');
    this.dataSource = this.allScheduleList.slice(0, 5);
  }
  changeSchedule(){
    // this.scheduleService.scheduleChangeMethod(this.detailsCallBack,this.data?.data?.scheduleData.schedule_scan_id)
    this.spinnerService.show()
    let req:any={}
    req.hourly_frequency = this.scheduleFormGrp.get('frequency_id')?.value;
    req.time=this.scheduleFormGrp.get('time')?.value;
    req= this.scheduleService.createRequestObject(req); 
    // req = this.scheduleService.createUTCObject(req);
    // if(this.data?.data?.scheduleData.schedule_scan_id){
    //   req['schedule_scan_id'] = this.data?.data?.scheduleData.schedule_scan_id;
    // }
    const now = new Date();
        const [hours, minutes] = req.time.split(':').map(Number);
        const localDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
       let utcDate=new Date(localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds())
        const utcHours = String(utcDate.getHours()).padStart(2, '0');
      const utcMinutes = String(utcDate.getMinutes()).padStart(2, '0');
        req.time = `${utcHours}:${utcMinutes}`;
        
    this.data?.data?.setCallbackFunction(this.data?.data.radioData,true,req,this.data?.data.radioFlag);
    
    
    //this.saveSchduleDetails(req, this.selectedScheduleDetailsCallBack);
    this.dialogRef.close()
    
  }
}