
<div class="sidebar-container" *ngIf="customSidebar && (data && data.length > 0 )">
    <app-side-bar [columnDefs]="agDeviceColumnDefs" [gridApi]="gridApi"></app-side-bar>
</div>
<div class="no-data" *ngIf="((( loader)&& data && data.length==0))">
    <p>No data available to display.</p>
  </div>
  <div   *ngIf="showNoData" class="no-data index">
    <p>No data available to display.</p>
  </div>
<ag-grid-angular *ngIf="(data && data.length > 0 ) || !loader" #myGrid [class.hide-table]="hideTable" [ngClass]="gridClass" class="ag-scroll ag-theme-material ag-grid-custom-font"
    [rowData]="data"   
    [columnDefs]="agDeviceColumnDefs"
    [autoGroupColumnDef]="autoGroupColumnDef"  
    [rowSelection]="rowSelection"
    [loading]="!loader"
    [rowGroupPanelShow]="'always'"  
  [groupMultiAutoColumn]="true"   
  [groupDefaultExpanded]="1"     
  [animateRows]="true"            
    [suppressContextMenu]="hideContextMenu"
    [loadingOverlayComponent]="loadingCellRenderer"
    [loadingOverlayComponentParams]="loadingCellRendererParams"
    [suppressRowClickSelection]="restrictCheckBoxSelection"
    [tooltipShowDelay]="0" 
    [pagination]="isPegination && loader" 
    [scrollbarWidth]="scrollWidth"
    [paginationPageSizeSelector]="paginationPageSizeSelector"
    [paginationPageSize]="paginationPageSize"
    [rowHeight]="rowHeight"
    [headerHeight]="rowHeight"
    [defaultColDef]="defaultColDef"
    (selectionChanged)="onRowSelected()"
    (rowSelected)="onRowClick($event)"
    (gridSizeChanged)="onGridSizeChanged($event)" 
    (gridReady)="onGridReady($event)"
    (rowClicked)="onRowDataSelect($event)"
    (filterChanged)="onFilterChanged()"
    (sortChanged)="onSortChanged()"
    [suppressDragLeaveHidesColumns]="true"
    [overlayNoRowsTemplate]="'No rows to show'"
     />
     
       
  
