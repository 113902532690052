import { Injectable } from '@angular/core';
import { RestService } from '../../shared/services/rest.service';
import { ResponseService } from '../../shared/services/response.service';
import { EncryptService } from '../../shared/services/encrypt.service';
import { END_POINTS } from '../../shared/constants/endpoints';
import { Subject } from 'rxjs';
import { SpinnerService } from '../../shared/services/spinner.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService, IAPIDetails } from '../../shared/services/api.service';
import { CONFIG } from '../../shared/constants/config';

@Injectable({
  providedIn: 'root'
})
export class CloudSecurityService {

  public editData = new Subject<any>();
  public editDataState = this.editData.asObservable();
  public createData = new Subject<any>();
  public createDataState = this.createData.asObservable();
  public resetData = new Subject<any>();
  public resetDataState = this.resetData.asObservable();
  constructor(private restService: RestService,
    private responseService: ResponseService,
    private spinnerService: SpinnerService,
    private http: HttpClient,
    private encryptService: EncryptService,
    private apiService: ApiService) { }

    public cloudDevice(callback: Function){
      this.restService.getApi(END_POINTS.CLOUD_ASSETS).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public cloudAgent(callback: Function){
      this.restService.getApi(END_POINTS.CLOUD_AGENT).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public accountList(callback: Function){
      this.restService.getApi(END_POINTS.ACCOUNTS_LIST).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public resourcesList(callback: Function,req:any){
      this.restService.postApi(END_POINTS.RESOURCE_LIST,req).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public createAgent(callback: Function,req:any){
      this.restService.postApi(END_POINTS.CLOUD_AGENT,req).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public accountsList(callback: Function){
      this.restService.getApi(END_POINTS.ACCOUNTS_LIST).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public cloudList(callback: Function){
      this.restService.getApi(END_POINTS.CLOUD_AGENT).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public agentPause(callback: Function,cred_id:any,is_paused:boolean){
      const req = {
        "is_paused":is_paused
      }
      this.restService.putApi(END_POINTS.CLOUD_AGENT+ '/' + cred_id,req).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public agentEdit(callback: Function,cred_id:any,req:any){
      this.restService.putApi(END_POINTS.CLOUD_AGENT+ '/' + cred_id,req).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public agentDelete(callback: Function,cred_id:any){
      const req = {
        "is_deleted":true
      }
      this.restService.putApi(END_POINTS.CLOUD_AGENT+ '/' + cred_id,req).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     public agentUnEnroll(callback: Function,cred_id:any){
      const req = {
        "is_unenrolled": true
      }
      this.restService.putApi(END_POINTS.CLOUD_AGENT+ '/' + cred_id,req).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
     }

     cloudTemplateData(coud_url:any) {
         this.http.get(coud_url, {  headers: new HttpHeaders({ 'Exclude-Authorization': 'true' }), responseType: 'blob' }).subscribe((blob: Blob | MediaSource) => {
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = 'spark-agent.yaml'; 
          link.click();
          window.URL.revokeObjectURL(downloadURL); 
        });
       }
      
    getReviewDetails(callback: Function, req: any) {
      const apiDetails: IAPIDetails | any = { 
        url: [],
        req: [],
        method: CONFIG.METHOD.POST,
        responseType: CONFIG.RESPONSE_TYPE.NONE,
        callBack: callback
      }
      apiDetails.req = req;
      apiDetails.url =END_POINTS.SCANS;
      this.apiService.callAPI(apiDetails);
    }
    addLastReview(callback: Function, req: any) {
      const apiDetails: IAPIDetails | any = { 
        url: [],
        req: [],
        method: CONFIG.METHOD.POST,
        responseType: CONFIG.RESPONSE_TYPE.NONE,
        callBack: callback
      }
      apiDetails.req = req;
      apiDetails.url =END_POINTS.SCANS;
      this.apiService.callAPI(apiDetails);
    }
    fetchLastReview(callback: Function) {
      const apiDetails: IAPIDetails | any = { 
        url: [],
        req: [],
        method: CONFIG.METHOD.GET,
        responseType: CONFIG.RESPONSE_TYPE.NONE,
        callBack: callback
      }
      apiDetails.url =END_POINTS.SCANS;
      this.apiService.callAPI(apiDetails);
    }
}
