import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  public isStatusComponent:boolean=false;
  constructor(private activatedRoute: ActivatedRoute){
    this.activatedRoute.params.subscribe((param: Params) => {    
      if(param['status'] ) {
        this.isStatusComponent=true;
      }
    })
  }
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
