<div class="out-layout">
    <div *ngIf="isAgentsAvailable" class="cloudscan_page page_content">
        <!-- <div class="table-title">
          <span class="drn_text">My Scans</span>
          <mat-icon class="refresh_icon" [class.rotate]="rotateIcon" (click)="refreshGetScanDetail()">refresh</mat-icon>
          <span class="note">Note: Users can't click or navigate on in-progress scans to check the details.</span>
        </div> -->
        <div class="cloudscan-header">
          <div class="left-mgt-head">
            <div class="search-bar">
              <mat-form-field appearance="fill">
                <mat-label>Search Scans</mat-label>
                <input matInput placeholder="" autocomplete="off" class="search-input" autocomplete="off" type="text"
                (input)="filterAssetsField()" #searchField>
                <mat-icon class='search-icon' matPrefix>search</mat-icon>
              </mat-form-field>
              <!-- <div *ngIf="securityInfoData && securityInfoData.length <= 0">
                              <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                            </div> -->
            </div>
          </div>
          <div class="right-mgt-head">
            <div *ngIf="this.hasPermission(this.permissionProps.SCHEDULE)"  class="schedule-tool_switch">
              <label class="switch-label-right">Scans</label>
              <mat-slide-toggle [(ngModel)]="toggleState" [disabled]="!isApiCallDone" (toggleChange)="onToggleChange()"></mat-slide-toggle>
              <label class="switch-label">Schedules </label>
            </div>
  
          </div>
        </div>
        <!--
          infinite-scroll [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="43" [alwaysCallback]="true"
        [scrollWindow]="false" 
        (scrolled)="onScroll()" 
        device_page_content
        -->      
      
      <app-ag-grid
        #myGrid
        [agDeviceColumnDefs]="agScanColumnDefs"
        [data]="data"
        [loader]="isApiCallDone"
        [gridClass]="'cloud-my-scans ag-grid-tabel'"
        [restrictCheckBoxSelection]="false"
        (onRowSelectedChange)="getScanList($event[0])"
        [tableKey]="'cloudMyScanTable'"
        ></app-ag-grid>
  
    </div>
    <div  *ngIf="!isAgentsAvailable" class="no-data-section d-flex theme-bg">
      <div class="no-data-info text_center">
        <img src="../../../../assets/images/Group.svg" />
        <h3>There is no data available</h3>
      </div>
  </div>
  </div>
  