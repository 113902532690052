<div class="jira-integration-overlay">
  <div *ngIf="!integratedCall">
  <div  class="jira-field-value">
    <ngx-skeleton-loader class="skeleton-loader" count="6"></ngx-skeleton-loader>
  </div>
  <div class="bottom-btns">
    <div class="jira-btn-value">
      <ngx-skeleton-loader class="skeleton-loader" count="1"></ngx-skeleton-loader>
    </div>
    <div class="jira-btn-value">
      <ngx-skeleton-loader class="skeleton-loader" count="1"></ngx-skeleton-loader>
    </div>
  </div>
</div>
  <div *ngIf="integratedCall">
  <div *ngIf="!showProjectDetails">
  <form [formGroup]="jiraIntegrationForm" class="jire-integration-form">
    <div class="input-box-container">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>API Token</mat-label>
        <input matInput formControlName="api_token">
        <mat-error *ngIf="jiraIntegrationForm.get('api_token')?.invalid && jiraIntegrationForm.get('api_token')?.touched">
          API Token is required
        </mat-error>
      </mat-form-field>
    </div> 
    <div class="input-box-container">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>URL</mat-label>
        <input matInput formControlName="server">
        <mat-error *ngIf="jiraIntegrationForm.get('server')?.invalid && jiraIntegrationForm.get('server')?.touched">
          Please enter a valid URL
        </mat-error>
      </mat-form-field>
    </div> 
    <div class="input-box-container">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Email ID</mat-label>
        <input matInput formControlName="email_id">
        <mat-error *ngIf="jiraIntegrationForm.get('email_id')?.invalid && jiraIntegrationForm.get('email_id')?.touched">
          Please enter a valid email
        </mat-error>
      </mat-form-field>
    </div> 
    <div  class="submit_btns fetch-btn">
      <button class="btn btn-outline" (click)="fetchDetails()" [disabled]="jiraIntegrationForm.invalid">
        Fetch Details
      </button>
    </div>
  </form>
</div>
   <div *ngIf="showProjectDetails">
    <form [formGroup]="jiraForm" class="jire-integration-form">
      <div class="input-box-container">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>API Token</mat-label>
          <input matInput formControlName="api_token">
          <mat-error *ngIf="jiraIntegrationForm.get('api_token')?.invalid && jiraIntegrationForm.get('api_token')?.touched">
            API Token is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="input-box-container">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>URL</mat-label>
          <input matInput formControlName="server">
          <mat-error *ngIf="jiraIntegrationForm.get('server')?.invalid && jiraIntegrationForm.get('server')?.touched">
            A valid URL is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="input-box-container">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Email ID</mat-label>
          <input matInput formControlName="email_id">
          <mat-error *ngIf="jiraIntegrationForm.get('email_id')?.invalid && jiraIntegrationForm.get('email_id')?.touched">
            A valid email is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="input-box-container">
        <mat-form-field appearance="outline">
          <mat-label>Project</mat-label>
          <mat-select formControlName="project" (selectionChange)="onProjectChange($event)" [disabled]="!typeShow">
            <mat-option *ngFor="let val of dropDownValues" [value]="val.key">
              {{ val.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="jiraIntegrationForm.get('project')?.invalid && jiraIntegrationForm.get('project')?.touched">
            Project is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="typeValues.length > 0 && typeShow" class="input-box-container">
        <mat-form-field appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" (selectionChange)="onTypeChange($event)" [disabled]="!assigneShow">
            <mat-option *ngFor="let val of typeValues" [value]="val.name">
              {{ val.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="jiraIntegrationForm.get('type')?.invalid && jiraIntegrationForm.get('type')?.touched">
            Type is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="jira-field-select" *ngIf="!typeShow">
        <ngx-skeleton-loader class="skeleton-loader" count="1"></ngx-skeleton-loader>
      </div>
      <div *ngIf="typeValues.length > 0 && dropDownValues.length > 0 && assigneShow && this.type_name.length > 0 && this.assignee.length > 0" class="input-box-container">
        <mat-form-field appearance="outline">
          <mat-label>Assignee</mat-label>
          <mat-select formControlName="assigne" (selectionChange)="onAssigneeChange($event)">
            <mat-option *ngFor="let val of assignee" [value]="val.account_id">
              {{ val.display_name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="jiraIntegrationForm.get('project')?.invalid && jiraIntegrationForm.get('project')?.touched">
            Project is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="jira-field-select" *ngIf="!assigneShow">
        <ngx-skeleton-loader class="skeleton-loader" count="1"></ngx-skeleton-loader>
      </div>
      <!-- <div class="input-box-container password-type">
        <p>Priority</p>
        <div class="password-type-group">
          <div *ngFor="let item of priorityOptions" class="priority-options">
            {{ item }}
            <button class="drop-down-btn" mat-button [matMenuTriggerFor]="parameterMenuDrop">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #parameterMenuDrop="matMenu" class="white-menu"></mat-menu>
          </div>
        </div>
      </div> -->
      <div class="submit_btns">
        <button class="btn btn-outline" (click)="goBack()">
          Previous
        </button>
        <button class="btn btn-primary" [disabled]="this.jiraForm.invalid || (!typeShow || !assigneShow)"  (click)="submitForm()">
          Submit
        </button>
      </div>
    </form>
    
  </div>
</div>
</div>
