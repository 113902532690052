import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {
  constructor(private inject: Injector, private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;

    // Add the X-Content-Type-Options header
    headers = headers.set('X-Content-Type-Options', 'nosniff');

    // Check if the token exists in the auth service
    if (this.authService.getToken()) {
      // Exclude Bearer auth type for logout and refresh API calls
      if (!req.url.includes('secure-auth/logout') && !req.url.includes('secure-auth/token')) {
        const clonedRequest = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getToken()}`, // Add Authorization token
          },
          headers // Add the nosniff header to the request
        });
        return next.handle(clonedRequest);
      } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }
}
