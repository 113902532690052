import { Component } from '@angular/core';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { OverlayResourceTypeComponent } from '../../overlay-resource-type/overlay-resource-type.component';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { OverlayAccptedRiskComponent } from '../../overlay-accpted-risk/overlay-accpted-risk.component';
import { OverlayComplainceComponent } from '../../overlay-complaince/overlay-complaince.component';
import { CloudTabsService } from 'src/app/modules/cyber-security/Services/cloud-tabs.service';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent {
  public tableHeader = CONFIG.TABLE_INFO.CLOUD_SECURE_RESOURCE.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.CLOUD_SECURE_RESOURCE.PROPS;
  rowHeight:number=40;
  public agDeviceColumnDefs: ColDef[] = [
    {
      headerName: this.tableHeader.RESOURCE,
      headerTooltip:this.tableHeader.RESOURCE,
      field: this.tableProps.RESOURCE,
      cellRenderer:OverlayResourceTypeComponent,
      lockPosition: true,
      onCellClicked: (event: CellClickedEvent) => {
        this.getRowData(event);
      },
      // pinned: 'left'
    },
    {
      headerName: this.tableHeader.RESOURCE_NAME,
      field: this.tableProps.RESOURCE_NAME,
      headerTooltip:this.tableHeader.RESOURCE_NAME,
      lockPosition: true,
      onCellClicked: (event: CellClickedEvent) => {
        this.getRowData(event);
      },
    },
    {
      headerName: this.tableHeader.ACCOUNT,
      field: this.tableProps.ACCOUNT,
      headerTooltip:this.tableHeader.ACCOUNT,
      onCellClicked: (event: CellClickedEvent) => {
        this.getRowData(event);
      },
    
    },
    {
      headerName: this.tableHeader.ACCEPTED_RISK,
      field: this.tableProps.ACCEPTED_RISK,
      headerTooltip:this.tableHeader.ACCEPTED_RISK,
      cellRenderer:OverlayAccptedRiskComponent,
      onCellClicked: (event: CellClickedEvent) => {
        this.getRowData(event);
      },
    },
    {
      headerName: this.tableHeader.COMPLAINCE,
      field: this.tableProps.COMPLAINCE,
      headerTooltip:this.tableHeader.COMPLAINCE,
      cellRenderer:OverlayComplainceComponent,
      onCellClicked: (event: CellClickedEvent) => {
        this.getRowData(event);
      },
    },

  ];
  public resourceJson= [
    {
      resource_type:'aws',
    control_type:'VPC',
    resource_name: 'vpc-04b1ceeq16656df312',
    account:'27401280115698',
    accepted_risk:false,
    complaince:'Not Applicable',
  
  },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:false,
      complaince:'Fail'
    },
    {
      resource_type:'azure',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:true,
      complaince:'Pass'
    },
    {
      resource_type:'gooleCloud',
    control_type:'EC2',
    resource_name: 'vpc-04b1ceeq16656df312',
    account:'27401280115698',
    accepted_risk:false,
    complaince:'Manually Pass'
  },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:false,
      complaince:'Manually Fail'
    },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:true,
      complaince:'Pending Review'
    },
    {
      resource_type:'aws',
    control_type:'EC2',
    resource_name: 'vpc-04b1ceeq16656df312',
    account:'27401280115698',
    accepted_risk:false,
    complaince:'Not Applicable'
  },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:false,
      complaince:'Fail'
    },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:true,
      complaince:'Manually Pass'
    },
    {
      resource_type:'aws',
    control_type:'VPC',
    resource_name: 'vpc-04b1ceeq16656df312',
    account:'27401280115698',
    accepted_risk:false,
    complaince:'Not Applicable',
  
  },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:false,
      complaince:'Fail'
    },
    {
      resource_type:'azure',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:true,
      complaince:'Pass'
    },
    {
      resource_type:'gooleCloud',
    control_type:'EC2',
    resource_name: 'vpc-04b1ceeq16656df312',
    account:'27401280115698',
    accepted_risk:false,
    complaince:'Manually Pass'
  },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:false,
      complaince:'Manually Fail'
    },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:true,
      complaince:'Pending Review'
    },
    {
      resource_type:'aws',
    control_type:'EC2',
    resource_name: 'vpc-04b1ceeq16656df312',
    account:'27401280115698',
    accepted_risk:false,
    complaince:'Not Applicable'
  },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:false,
      complaince:'Fail'
    },
    {
      resource_type:'aws',
      control_type:'VPC',
      resource_name: 'vpc-04b1ceeq16656df312',
      account:'27401280115698',
      accepted_risk:true,
      complaince:'Manually Pass'
    },
    ] 
    public selectedRow: any[] = [];

    constructor(public cloudTabsService:CloudTabsService){
    }

    public onRowSelected(selectedRow: any) {
      this.selectedRow = selectedRow;
      console.log(this.selectedRow);
    }

    getRowData(row: any){
      // this.cveAssetsService.vulnerabilityRow.next(row.data);
      this.cloudTabsService.resourceRow.emit(row.data);
      console.log('clicked');
      
     }

}
