import { Component } from '@angular/core';
import { IAfterGuiAttachedParams, IFilterParams } from 'ag-grid-community';



@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss']
})
export class CustomFilterComponent {
  params!: IFilterParams;
  clouds: any[] = [];
  vpcs: any[] = [];
  vpcSearchTerm = '';
  filteredVpcs: any[] = [];
  selectedClouds: string[] = [];
  selectedVpcs: string[] = [];
  isFilterPopupOpen = false;
  closePopup!: () => void;

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    if (params.hidePopup) {
      this.closePopup = params.hidePopup; // Store the hidePopup function
    }
  }

  toggleFilterPopup() {
    this.isFilterPopupOpen = !this.isFilterPopupOpen;
  }
 

 agInit(params: IFilterParams): void {
  this.params = params;
  this.populateData();
  if (this.params && this.params.api) {
    console.log('params.api is available');
    this.params.api.addEventListener('firstDataRendered', () => {
      console.log('firstDataRendered event triggered');
      this.populateData();
    });
  
    this.params.api.addEventListener('rowDataUpdated', () => {
      console.log('rowDataUpdated event triggered');
      this.populateData();
    });
  } 
  
}

private populateData(): void {
  const rowData: any[] = [];
  this.params.api.forEachNode((node) => {
    rowData.push(node.data);
  });

  console.log('Row data:', rowData);

  // Extract unique values for clouds and VPCs
  this.clouds = Array.from(new Set(rowData.map((row) => row['cloud'])));
  this.vpcs = Array.from(new Set(rowData.map((row) => row['resource_name'])));

  console.log('Clouds:', this.clouds);
  console.log('VPCs:', this.vpcs);

  // Initialize filtered VPCs
  this.filteredVpcs = [...this.vpcs];
}

onCheckboxCloudChange(filter: string, event: any): void {
  const checkbox = event;
  if (checkbox.checked) {
    this.selectedClouds.push(filter);
  } else {
    this.selectedClouds = this.selectedClouds.filter((value) => value !== filter);
  }
}

onCheckboxVpcChange(filter: string, event: any): void {
  const checkbox = event;
  if (checkbox.checked) {
    this.selectedVpcs.push(filter);
  } else {
    this.selectedVpcs = this.selectedVpcs.filter((value) => value !== filter);
  }
}

filterVpcs(): void {
  this.filteredVpcs = this.vpcs.filter((vpc) =>
    vpc.toLowerCase().includes(this.vpcSearchTerm.toLowerCase())
  );
}

isFilterActive(): boolean {
  return this.selectedClouds.length > 0 || this.selectedVpcs.length > 0;
}

applyFilters(): void {
  this.params.filterChangedCallback();
  if (this.closePopup) {
    this.closePopup(); // Close the filter popup
  }
}

doesFilterPass(params: any): boolean {
  const {  resource_name, cloud } = params.data; 
  return this.selectedClouds.includes(cloud) || this.selectedVpcs.includes(resource_name)
}

getModel(): any {
  return {
    selectedClouds: this.selectedClouds,
    selectedVpcs:this.selectedVpcs,
  };
}

setModel(model: any): void {
  this.selectedClouds = model?.selectedClouds || [];
  this.selectedVpcs = model?.selectedVpcs || [];
}


}
