import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-resource-name',
  templateUrl: './resource-name.component.html',
  styleUrls: ['./resource-name.component.scss']
})
export class ResourceNameComponent {
  params!: ICellRendererParams;
  agInit(params: ICellRendererParams): void {
    this.params = params;
    console.log('params', params);
  }
}
