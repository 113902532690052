import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { JiraService } from '../../services/jira.service';
import { IUserDetail } from '../../model/profile/profile';
import { ProfileService } from '../../services/profile.service';
import { SpinnerService } from '../../services/spinner.service';
import { IToastrOptions } from '../../model/IMessage.model';
import { MessageServiceService } from '../../services/message-service.service';

@Component({
  selector: 'app-jira-integration',
  templateUrl: './jira-integration.component.html',
  styleUrls: ['./jira-integration.component.scss']
})
export class JiraIntegrationComponent {
  @Input() isApiIntegrated:any;
  @Input() JirDetails:any;
  showProjectDetails: boolean =false;
  jiraIntegrationForm: FormGroup;
  jiraForm: FormGroup;
  public PreviousData:any;
  org_id: any;
  project_key:any;
  assignee_key:any;
  dropDownValues:any = [];
  typeValues:any = [];
  assignee:any = [];
  priorityOptions = [
    'Critical',
    'High',
    'Meddium',
    'Low'
  ]
  selectedPriorityVal: string = "";
  lowValues = [
    'Low',
    'Lowest'
  ];
  userProfile: IUserDetail | any;
  user_org_id: any;
  type_name: any;
  typeShow: boolean = true;
  assigneShow: boolean = true;
  integratedCall:boolean = true;
  ngOnInit(){
    if(this.isApiIntegrated){
      const { api_token, server, email_id } = this.JirDetails 
      this.setFormValues(api_token, server, email_id);
      this.fetchAllJiraData()  
      this.user_org_id = this.JirDetails.org_id;
    }
    this.showProjectDetails= this.isApiIntegrated;
    this.profileService.fetchUserDetails((userDetails: any) => {
      this.userProfile = userDetails;
      if(!this.isApiIntegrated){
        this.user_org_id = this.userProfile?.organization_id;
      }
     },true);
  }
  constructor(private fb: FormBuilder,public jiraSerive:JiraService,public profileService:ProfileService,public messageService:MessageServiceService){
    this.jiraIntegrationForm = this.fb.group({
      api_token: ['', Validators.required],
      server: ['', [Validators.required, Validators.pattern(/https?:\/\/[^\s/$.?#].[^\s]*$/)]],
      email_id: ['', [Validators.required, Validators.email]]
    });
    this.jiraForm = this.fb.group({
      api_token: ['', Validators.required],
      server: ['', [Validators.required, Validators.pattern(/https?:\/\/[^\s/$.?#].[^\s]*$/)]],
      email_id: ['', [Validators.required, Validators.email]],
      project: ['', Validators.required],
      type: ['', Validators.required],
      assigne: ['']
    });
    
  }
  private fetchJiraProjectList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.jiraSerive.getJiraProjectList(this.JirDetails.org_id,(response: any) => {
        if (response.status === "200") {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }
  private fetchJiraProjectStatuse(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.jiraSerive.getJiraProjectStatuses(this.JirDetails.project_type_key,this.JirDetails.org_id,(response: any) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }
  private fetchJiraProjectAssignee(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.jiraSerive.getJiraProjectAssignee( this.JirDetails.project_type_key,this.JirDetails.org_id,(response: any) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }
  async fetchAllJiraData() {
    this.integratedCall = false;
    let projectResponse: any = null;
    let statusResponse: any = null;
    let assigneeResponse: any = null;
    projectResponse = await this.fetchJiraProjectList();
    this.fetchListCallback(projectResponse);
    statusResponse = await this.fetchJiraProjectStatuse(); 
    this.fetchStatusCallback(statusResponse);
    assigneeResponse = await this.fetchJiraProjectAssignee();
    this.fetchAssigneeCallback(assigneeResponse);
    this.integratedCall = true;
  }
  
  fetchListCallback = (response:any)=>{
    if(response.status === "200"){
      this.dropDownValues = response.data;
      if(this.isApiIntegrated){
        this.jiraForm.get('project')?.setValue(this.JirDetails.project_type_key);
        this.project_key = this.JirDetails.project_type_key
      }
    }
  }
  fetchStatusCallback = (response:any)=>{
    if(response.status === 200){
      this.typeValues = response.data.statuses;
      if(this.isApiIntegrated){
        this.jiraForm.get('type')?.setValue(this.JirDetails.type);
        this.type_name = this.JirDetails.type 
      }
    }
  }
  fetchAssigneeCallback = (response:any)=>{
   if(response.status === 200){
    this.assignee = response.data;
    if(response.data.length > 0){
      this.jiraForm.get('assigne')?.setValidators(Validators.required);
      this.jiraForm.get('assigne')?.updateValueAndValidity();
      if(this.isApiIntegrated){
        this.jiraForm.get('assigne')?.setValue(this.JirDetails.assignee);
        this.assignee_key = this.JirDetails.assignee
       }
    }
    else {
      this.jiraForm.get('assigne')?.setValidators(null);
      this.jiraForm.get('assigne')?.updateValueAndValidity();
    }
   }
  }
  submitForm() {
    if (this.jiraForm.valid) {
      this.integratedCall = false;
      const project = this.dropDownValues.filter((data: { key: any; }) => data.key === this.project_key);
      let jiraIntegrateData:any = {
        project_type_key : this.project_key,
        priority:"Highest",
        assignee: this.assignee_key,
        email_id: this.userProfile.organization_email,
        project:project[0].name,
        type: this.type_name
      }
      this.jiraSerive.updateJira(this.jiraSubmitCallback,jiraIntegrateData);
    } 
  }
  jiraSubmitCallback = (response:any)=>{
  if(response.status === "200"){
    this.integratedCall = true;
    const snakMsg: IToastrOptions = {
      message: response.message
    }
    this.messageService.showSuccess(snakMsg);
    this.jiraSerive.rowBack.next({sumbitData:true});
  } else {
    this.integratedCall = true;
  }
  }
  setFormValues(apiToken: string, server: string, email: string) {
    this.jiraForm.patchValue({
      api_token: apiToken,
      server: server,
      email_id: email
    });
  }
  
  fetchDetails1() {
  this.showProjectDetails = true;
  }
  getFormValues(): any {
    if (this.jiraIntegrationForm.valid) {
      return this.jiraIntegrationForm.value;
    } else {
      console.log("Form is invalid");
      return null;
    }
  }

  fetchDetails() {
    if (this.jiraIntegrationForm.valid) {
      let formData = this.jiraIntegrationForm.value;
       formData.org_id = this.user_org_id;
       this.integratedCall = false;
      this.jiraSerive.createJiraIntegration(formData,this.saveCallback)
    } 
  }

  saveCallback=(response:any)=>{
    if (response.status === "200") {
      this.integratedCall = true;
      this.PreviousData = response.data;
      const { api_token, server, email_id } = response.data; 
      this.setFormValues(api_token, server, email_id); 
      this.jiraSerive.getJiraProjectList(response.data.org_id,this.projectCallback)
      this.showProjectDetails= true;
    } else {
      this.integratedCall = true;
    }

  }

  projectCallback= (response:any)=>{
  if(response.status === "200"){
    this.dropDownValues = response.data;
  }
  }

  onProjectChange(type: any){
    this.project_key = type.value;
    this.jiraForm.get('type')?.reset();
    this.typeShow = false;
    this.type_name = '';
    this.typeValues = [];
    this.assignee = [];
    this.jiraSerive.getJiraProjectStatuses(type.value,this.user_org_id,this.projectStatusCallback)
  }
projectStatusCallback = (response:any)=>{
  if(response.status === 200){
    this.typeValues = response.data.statuses;
    this.typeShow = true;
  }
} 

projectAssigneeCallback = (response:any)=>{
  this.assigneShow = true
  this.integratedCall = true;
 if(response.status === 200){
  this.assignee = response.data;
  if(response.data.length > 0){
    this.jiraForm.get('assigne')?.setValidators(Validators.required);
    this.jiraForm.get('assigne')?.updateValueAndValidity();
  }
  else {
    const snakMsg: IToastrOptions = {
      message: response.message
    }
    this.messageService.showError(snakMsg);
    this.jiraForm.get('assigne')?.setValidators(null);
    this.jiraForm.get('assigne')?.updateValueAndValidity();
  }
 }
}

onAssigneeChange(type: any){
  this.assignee_key = type.value;
}

onTypeChange(type:any){
 this.type_name = type.value;
 this.jiraForm.get('assigne')?.reset();
 this.assigneShow = false;
 this.assignee = [];
 if(this.dropDownValues.length > 0 && this.typeValues.length > 0){
  this.jiraSerive.getJiraProjectAssignee(this.project_key,this.user_org_id,this.projectAssigneeCallback)
}
}

setJiraFormValues(apiToken: string, server: string, email: string) {
  this.jiraIntegrationForm.patchValue({
    api_token: apiToken,
    server: server,
    email_id: email
  });
}

goBack(){
  if(this.isApiIntegrated){
    this.jiraSerive.rowBack.next(true);
  } else{
    const { api_token, server, email_id } = this.PreviousData 
      this.setJiraFormValues(api_token, server, email_id); 
      this.showProjectDetails= false;
  }
}
  
}
