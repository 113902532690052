import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { WhitelistService } from 'src/app/modules/shared/services/whitelist.service';
import { Utils } from 'src/app/modules/shared/utils';
import { IBadgeClass } from '../whitelist/whitelist.model';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';

@Component({
  selector: 'app-whitelist-history-details',
  templateUrl: './whitelist-history-details.component.html',
  styleUrls: ['./whitelist-history-details.component.scss']
})
export class WhitelistHistoryDetailsComponent {
  isChangePassword = false;
  whitelistHistoryData:any;
  public profileData: any = {};
  public imageBase64!:any;

  constructor(public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog,
    ) {
      this.whitelistHistoryData = data?.data?.whitelistHistoryData
      this.profileData['firstname'] = data?.data?.whitelistHistoryData.whitelist_info.first_name;
      this.profileData['lastname'] = data?.data?.whitelistHistoryData.whitelist_info.last_name;
      if(this.whitelistHistoryData.whitelist_info.image_base64 && this.whitelistHistoryData.whitelist_info.image_base64 !== 'None') {
          this.getBaseImage64(this.whitelistHistoryData.whitelist_info.image_base64)
        }
     }
    
  ngOnInit(): void {
  }
  onClose(): void{
    this.dialogService.closeDialog();
  }
 
  public getBaseImage64(item:any){
    const img = new Image();
    img.src = item;
    this.imageBase64 = img.src;
    return this.imageBase64

  }
  severityStatus(type:string){
    return Utils.addBadgeClass(type)
  }
  // public addBadgeClass = (type: string) => {
  //   const obj: IBadgeClass = {
  //     'Critical': 'severity-critical',
  //     'High': 'severity-high',
  //     'Medium': 'severity-medium',
  //     'Low': 'severity-low'
  //   }
  //   return obj[type as keyof IBadgeClass];
  // }

  getNameCombination(firstName:any, lastName:any | undefined){
    return Utils.getNameCombination(firstName, lastName)
  }
  makeFirstLetterCapital(str:string | undefined){
    return Utils.makeFirstLetterCapital(str)
  }
}
