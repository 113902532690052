import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANT } from '../constants/constant-data';
import { AuthService } from './auth.service';
import { SpinnerService } from './spinner.service';
import { MessageServiceService } from './message-service.service';
import { IToastrOptions } from '../model/IMessage.model';
import { EncryptService } from './encrypt.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  constructor(
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private messageServive: MessageServiceService,
    private encryptService: EncryptService,
    private commonService: CommonService) { }
  successResponse = (data: HttpResponse<any> | any) => {
    // if(this.commonService.getEncryptionAllowed() && data && data.url && this.commonService.isUrlAvailable(data.url)) {
    //   data.body = this.encryptService.doDecrypt(data.body);
    //   // console.log("API Name >>>>>>>>>>>>>>>>>>>>>>>>>" , data.url);
    //   // console.log("Response >>>>>>>>>>>>>>>>>>>>>>>>>" , data.body);
    // }
    if (data?.status === 200 || data?.status === 201) {
      if (data.body && (data.body?.status === 401 || data.body?.status === 403)) {
        data.body.status = Number(data.body.status);
      } else {
        return data.body;
      }
    } else {
      return null;
    }
  }

  errorFileupload=(data:HttpErrorResponse | any)=>{
    // if(this.commonService.getEncryptionAllowed() && data && data.url && this.commonService.isUrlAvailable(data.url) && data.status!==503) {
    //   data.error = this.encryptService.doDecrypt(data.error);
    //  // console.log("API Name >>>>>>>>>>>>>>>>>>>>>>>>>" , data.url);
    //  // console.log("Response >>>>>>>>>>>>>>>>>>>>>>>>>" , data.error);
    //  return data.error
    // } else{
    //   return data.error
    // }
    return data.error;
  }
  errorResponse = (data: HttpErrorResponse | any) => {
    // if(this.commonService.getEncryptionAllowed() && data && data.url && this.commonService.isUrlAvailable(data.url) && data.status!==503) {
    //   data.error = this.encryptService.doDecrypt(data.error);
    //  // console.log("API Name >>>>>>>>>>>>>>>>>>>>>>>>>" , data.url);
    //  // console.log("Response >>>>>>>>>>>>>>>>>>>>>>>>>" , data.error);
    // }
    const errMsg: IToastrOptions = {}
    if (data.status === 401 || data.status === 403) {  
      this.spinnerService.hide();
      errMsg.message = CONSTANT.UNAUTH_ACCESS_MSG;
      this.messageServive.showError(errMsg);
      this.authService.logOut();
      return '';
    } else if (data?.status === 501 && data?.error?.message) {
      errMsg.message = data.error.message;
      this.messageServive.showError(errMsg);
      
      return data.error;
    } else {
      errMsg.message = data?.error?.message || CONSTANT.SERVICE_FAILED_MSG;
      this.messageServive.showError(errMsg);
      return data.error;
    }
  }
}
