<div class="ag-center">
    <div *ngIf="false">
        <span class="line-bar" (mouseenter)="showTooltip($event)" (mouseleave)="hideTooltip()"></span>

    </div>
    
 
    <span class="text_ellipsis" matTooltip="{{params.value}}">  {{ params.value || 'INVALID HOSTNAME' }}</span>  
    <img  class="mr-left" *ngIf="params.data.rescan && params.data.schedule=='scheduled'"  (click)="$event.stopImmediatePropagation(); deviceComp.openCalenderWithPropagationCheck(params.data, $event)" src="../../../../../assets/images/calender.png"/>
    <img class="mr-left" *ngIf="params.data.rescan && params.data.schedule=='schedulable'" src="../../../../../assets/images/calender-blue.png"/>

</div>