import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { MyScansComponent } from 'src/app/modules/my-scans/components/my-scans/my-scans.component';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { MyScansService } from 'src/app/modules/shared/services/my-scans.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { CloudscansComponent } from '../../cloudscans.component';
import * as _ from 'lodash';
import { CloudSecurityService } from '../../../Services/cloud-security.service';

@Component({
  selector: 'app-cloudscan-options',
  templateUrl: './cloudscan-options.component.html',
  styleUrls: ['./cloudscan-options.component.scss']
})
export class CloudscanOPtionsComponent {
  public params!: ICellRendererParams;

   constructor(private service:MyScansService,private spinnerService: SpinnerService,
      public dialogservice: DialogService,private messageService: MessageServiceService,
      private cloudScansComponent:CloudscansComponent,public cloudService:CloudSecurityService,){
  
    }

  public stopBubbling(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

    agInit(params: ICellRendererParams): void {
      console.log(params.data)
      this.params = params;
    }
    refresh(params: any): boolean {
      throw new Error('Method not implemented.');
    }
    public openedMenuId: string | null = null;
    onMenuOpen(id: string) {
      this.openedMenuId = id;
      
  }
  
  onKebabMenuHandler(type: string, element: any){
    if(type==='changeSchedule'){
      this.cloudScansComponent.scheduleData=element;
      this.cloudScansComponent.openScheduleDialog(element);
    }
    else if(type==='cancelSchedule'){
      this.cloudScansComponent.openCancelSchedule(element);
    }
    else if(type==='createSchedule'){
          this.cloudScansComponent.scheduleData=element;
           this.cloudScansComponent.openScheduleDialog(null,true);
        }
  }

  
  // onKebabMenuHandler(type: string, element: any){
  //   if(type === 'rescan'){
  //     this.myScanComponent.openReScanDialog(element);
  //   }else if(type === 'rename'){
  //     this.myScanComponent.openDailog(element, element.scan_uuid)
  //   }else if(type === 'cancel') {
  //     this.myScanComponent.openScanDialog(element, '2')
      
  //   } else if (type==='status') {
      
  //     this.myScanComponent.router.navigate(['dashboard/scan-details', element.scan_uuid, 'status',element.status==4?4:2]);
  //   } else if(type==='changeSchedule' ) {
  //     this.myScanComponent.scheduleData=element;
  //     this.myScanComponent.openScheduleDialog(element);
  //   } else if(type==='cancelSchedule') {
  //      this.myScanComponent.openCancelSchedule(element);
  //   } else if(type==='createSchedule'){
  //     this.myScanComponent.scheduleData=element;
  //      this.myScanComponent.openScheduleDialog(null,true);
  //   }
  
  // }
  
  onMenuClose() {
    this.openedMenuId = null;
    // this.service.userMenuClosed.next(1);
    //   this.myScanComponent.selectedScans = _.cloneDeep(this.myScanComponent.intialSelectedScans);
    //   this.myScanComponent.is_clicked = true
    //   this.myScanComponent.benchmarkItems?.forEach((user: any)=>{
    //     if(this.myScanComponent.selectedScans && this.myScanComponent.selectedScans.length && this.myScanComponent.selectedScans.indexOf(user.id) > -1) {
    //       user.selected = true;
    //     } else {
    //       user.selected = false;
    //     }
    //   })
  }
  
  

}
