import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiService } from './api.service';
import { SpinnerService } from './spinner.service';
import { AuthService } from './auth.service';
import { ResponseService } from './response.service';
import { RestService } from './rest.service';
import { END_POINTS } from '../constants/endpoints';

@Injectable({
  providedIn: 'root'
})
export class JiraService {

  constructor(private apiService: ApiService,
    private restService: RestService,
    private responseService: ResponseService,
    private authService: AuthService,
    private spinnerService: SpinnerService,) {
  }

  @Output()
  rowBack: EventEmitter<any> = new EventEmitter();

  public createJiraIntegration(integrationObj:any,callback: Function) {
    return this.restService.postApi(END_POINTS.CREATE_JIRA_INTEGRATION, integrationObj).subscribe({
      next: (response) => {
        // callback(response)
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
  }

  public createTicket(integrationObj:any, callback: Function) {
    
    return this.restService.postApi(END_POINTS.JIRA_CREATE_TICKET, integrationObj).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
  }

  public updateJira(callback: Function,integrationObj:any){
    return this.restService.putApi(END_POINTS.JIRA_INTEGRATION_UPDATE, integrationObj).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
   }

  public getJiraProjectList(org_id: any,callback: any) {
    const benchmarkAPI = END_POINTS.JIRA_PROJECT_LIST + "?org_id=" + org_id
    this.restService.getApi(benchmarkAPI).subscribe({
      next: (response) => {
        // callback(response.body);
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
  }

  public getJiraProjectStatuses(project_key: any, org_id: any,callback: any) {
    const benchmarkAPI = END_POINTS.JIRA_PROJECT_STATUSES + "?project_key=" + project_key +  "&org_id="  + org_id;
    this.restService.getApi(benchmarkAPI).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
  }

  public getJiraProjectProperties(project_key: any,callback: any) {
    const benchmarkAPI = END_POINTS.JIRA_PROJECT_STATUSES + "?project_key=" + project_key
    this.restService.getApi(benchmarkAPI).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
  }

  public getJiraOrgDetails(org_id: any,callback: any) {
    const benchmarkAPI = END_POINTS.JIRA_INTEGRATION_ORG + "?org_id=" + org_id
    this.restService.getApi(benchmarkAPI).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        // callback(this.responseService.errorResponse(err));
      },
    });
  }

  public getJiraProjectAssignable(project_key: any,callback: any) {
    const benchmarkAPI = END_POINTS.JIRA_INTEGRATION_ORG + "?project_key=" + project_key
    this.restService.getApi(benchmarkAPI).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
  }

  public getJiraProjectAssignee(project_key: any, org_id: any,callback: any) {
    const benchmarkAPI = END_POINTS.JIRA__ASSIGNE_TICKET + "?project_key=" + project_key + "&org_id="  + org_id;
    this.restService.getApi(benchmarkAPI).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
  }

  
}
