import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-cloud-scan-image',
  templateUrl: './cloud-scan-image.component.html',
  styleUrls: ['./cloud-scan-image.component.scss']
})
export class CloudScanImageComponent {
 params!: ICellRendererParams;
  agInit(params: ICellRendererParams): void {
    this.params = params;
    console.log('params', params);
  }
}
