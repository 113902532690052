import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { CustomDailog } from '../../model/Dialog.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICyberResilienceResponse } from '../cyber-reilience/cyber-reilience.model';

@Component({
  selector: 'app-cyber-zoomin',
  templateUrl: './cyber-zoomin.component.html',
  styleUrls: ['./cyber-zoomin.component.scss']
})
export class CyberZoominComponent {
   title: any = '';
   showFilter: boolean = false;
   apiName: string = '';
   filterPage: string = '';
   deviceId: string = '';
   payLoad: any = {};
   isCompareAvailable: boolean = false;
   public chartDom: HTMLElement | null = null; // This will hold the chart DOM
  @ViewChild('cyberResilience') cyberResilience!: ElementRef;
  response:ICyberResilienceResponse={}
  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog){
    //console.log(data);
    //console.log(data?.title);
    this.title=data?.data?.title;
    this.apiName=data?.data?.apiName;
    this.filterPage=data?.data?.filterPage;
    this.isCompareAvailable=data?.data?.isCompareAvailable;
    this.chartDom = data?.data?.chartDom;
    
    //this.title = data.title;
    
  }

  ngOnInit(): void {
    if (this.chartDom) {
      // Do something with the chartDom, like appending it to the component's view
      // For example, you could add it to a div in your template:
      // const zoomContainer = document.getElementById('zoom-container');
      // if (zoomContainer && this.chartDom) {
        
      //   zoomContainer.appendChild(this.chartDom); // Move the chart DOM to the dialog
      // }
    }
  }

}
