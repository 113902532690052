import { Component, ViewChild } from '@angular/core';
import { FilterService } from '../../shared/services/filter.service';
import { MatSidenav } from '@angular/material/sidenav';
import { CloudSecurityService } from '../Services/cloud-security.service';
import { HeaderService } from '../../shared/services/header.service';
import { CONSTANT, CONTENT_AGENT_MESSAGE, CONTENT_MESSAGE, GET_MENU_ITEM_DATA, ToolTipConfig } from '../../shared/constants/constant-data';
import { PasswordConfirmationPopupComponent } from '../../shared/components/password-confirmation-popup/password-confirmation-popup.component';
import { DialogService } from '../../shared/services/dialog.service';
import { IDialog } from '../../shared/model/Dialog.model';
import { UserDeleteComponentComponent } from '../../admin/components/users/user-delete-component/user-delete-component.component';
import { ApiService } from '../../shared/services/api.service';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-cyber-security',
  templateUrl: './cyber-security.component.html',
  styleUrls: ['./cyber-security.component.scss']
})
export class CyberSecurityComponent {
  @ViewChild('cloudNewScan') cloudNewScan!: MatSidenav;
  type: boolean = false;
  agentData: any;
  agentDetails: any; 
  selectedIndex: number = 0;
  hide:boolean=true;
  isCopied:boolean=false;
  isCopiedId:boolean=false;
  isCopiedOtp :boolean=false;
  private copyTimeout: any; 
  private copyTimeoutId: any;
  private copyTimeoutOtp: any;
  searchAgent: string = '';
  public toolTipOption = ToolTipConfig;
  is_api_called:boolean= false;
  constructor(
    private filterService: FilterService,
    public cloudSecurityService: CloudSecurityService,
    private headerService: HeaderService,
    public dialogService: DialogService,
    private apiService: ApiService,
     private authService: AuthService,
  ) {
    this.headerService.setHeading('Cloud Agent', { type: 'cloudAgent' });
  }

  ngOnInit(): void {
    //this.CloudDevices();
    const isNewUserDetails = this.authService.getCookie('isCloudNewUserDetails') === 'true'
    this.isAgentsAvailable = !isNewUserDetails
    if(!isNewUserDetails){
      this.getAgents();
      this.cloudSecurityService.createDataState.subscribe((payload)=>{
        const {data, action} = payload;
        if(action === 'create'){
         if(!this.isAgentsAvailable){
         return this.getAgents();
         } else {
          this.agentData.push(data.data);
          this.agentData.reverse();
          this.agentData = this.dataMassaging(this.agentData)
          this.agentDetails = this.agentData[0];
          this.selectedIndex = 0;
         }
        }
        else{
          const index = this.agentData.findIndex((item: { id: any; }) => item.id === data.data.id);
          if (index !== -1) {
            this.agentData[index] = { ...this.agentData[index], ...data.data };
          }
          this.agentData = this.dataMassaging(this.agentData);
          this.agentDetails = this.agentData[index]
        }
      })
    }
  }
  ngOnDestroy(): void {
    this.apiService.unsubscribeApiCall();
  }
  isAgentsAvailable: boolean = false;
  public getAgents() {
    this.hide = true;
    this.selectedIndex = 0;
    this.is_api_called = true
    this.cloudSecurityService.cloudAgent(this.agentsCallBack)
  }

  agentsCallBack = (response: any) => {
    //this.agentData = [];
    this.is_api_called = false;
    this.isAgentsAvailable = response.data.length>0?true:false
    if(response.status==200 && response.data.length > 0){
      //this.isAgentsAvailable = response.data.length>0?true:false
      this.agentData = this.dataMassaging(response.data)
      this.agentDetails = this.agentData[0];
    }
    //this.isAgentsAvailable = true
    //this.newDevice = response.data.total_assets === 0 ? true : false
  }

  public dataMassaging(data:any){
   return data.map((item: { status: any; }) => ({
    ...item,
    agentLabel: this.getLabel(item.status),
    agentClass: this.getClass(item.status),
  }));
  }

  private getLabel(agentStatus: number): string {
    const labels:any = {
      1: 'Pending',
      2: 'Active',
      3: 'Inactive',
      4: 'Deleted',
      5: 'Paused',
      6: 'Unenrolled',
      7: 'Installing Agent',
      8: 'Installation Failed',
    };
    return labels[agentStatus] || 'Unknown';
  }
  
  private getClass(agentStatus: number): string {
    const classes:any = {
      1: 'pending',
      2: 'low',
      3: 'inactive',
      4: 'critical',
      5: 'paused',
      6: 'un-enrolled',
      7: 'install-agent',
      8: 'install-failed',
    };
    return classes[agentStatus] || '';
  }


 

  ngAfterViewInit(): void {
    if (this.cloudNewScan) {
      this.filterService.setSidenav(this.cloudNewScan, 'cloudNewScan');
    }

  }
  scanNow() {
    this.type = true;
    this.cloudSecurityService.resetData.next(true);
    this.filterService.toggleSidenav('cloudNewScan');
  }
  openNewScanSidebar() {


  }
  addCloudService() {

  }
  public editCloudScan(agentDetails:any) {
    this.cloudSecurityService.editData.next(agentDetails);
    this.filterService.toggleSidenav('editCloudScan');
  }
  public pauseCloudScan(agentDetails:any,is_pause:boolean) {
     const payload: IDialog = {
            component:UserDeleteComponentComponent,
            width: '380px',
            data: {
              content:is_pause?CONTENT_AGENT_MESSAGE('pause', agentDetails):CONTENT_AGENT_MESSAGE('resume', agentDetails),
              title: is_pause?'pause':'resume',
              setCallbackFunction: (result: any) => {
                if (result) {
                  this.cloudSecurityService.agentPause(this.agent_pause_callback,agentDetails.id,is_pause)
                }else{
                  this.dialogService.closeDialog();
                }
                // this.onOptionCall(result,option,currentUser)
              },
              menuData:is_pause?GET_MENU_ITEM_DATA('pause',{firstname: 'user123'}):GET_MENU_ITEM_DATA('resume',{firstname: 'user123'})
            },
          }
          this.dialogService.openDialog(payload);
    // this.filterService.toggleSidenav('editCloudScan');
  }

  public agent_pause_callback = (res: any) => {
    const index = this.agentData.findIndex((item: { id: any }) => item.id === res.data.id);
    if (index !== -1) {
      const updatedStatus = res.data.is_paused ? 5 : 2; 
      this.agentData[index] = { ...this.agentData[index], ...res.data, status: updatedStatus };
    }
    this.agentData = this.dataMassaging(this.agentData);
    this.agentDetails = this.agentData[index];
  };    
  public deleteCloudScan(agentDetails:any) {
   this.checkAuthorization('delete',agentDetails)
    // this.filterService.toggleSidenav('editCloudScan');
  }
  public unEnrollCloudScan(agentDetails:any) {
    this.checkAuthorization('unEnroll',agentDetails)
  }
    checkAuthorization(action:any='unEnroll',agentDetails?:any): void {
      let passwordData = {
        title: action==='unEnroll'?CONSTANT.AGENT_UNENROLL:CONSTANT.AGENT_DELETE,
        // text: CONSTANT.AGENT_UNENROLL_MESSAGE,
        buttonLabel: action==='unEnroll'? 'Unenroll': 'Delete',
        password: CONSTANT.PASSWORD,
      }
      const payload: IDialog = {
        component: PasswordConfirmationPopupComponent,
        width: '350px',
        data: {
          userData: passwordData,
          setCallbackFunction: (res:any) => {            
           if(res){
            if(action==='unEnroll'){
              this.cloudSecurityService.agentUnEnroll(this.agentUnEnrollCallBack,agentDetails.id)
            } else if(action === 'delete'){
              this.cloudSecurityService.agentDelete(this.agentDeleteCallback,agentDetails.id)
            } 
           }else{
            this.dialogService.closeDialog();
           }
            //this.decommissionRecommissionDevice(item);
          }
        },
      }
      this.dialogService.openDialog(payload);
    }
   public agentUnEnrollCallBack = (res:any)=>{
      // this.getAgents();
      this.dialogService.closeDialog();
      const index = this.agentData.findIndex((item: { id: any; }) => item.id === res.data.id);
      if (index !== -1) {
        const updatedStatus = res.data.is_unenrolled ? 6 : this.agentData[index].status;
        this.agentData[index] = { ...this.agentData[index], ...res.data, status: updatedStatus };
      }
      this.agentData = this.dataMassaging(this.agentData);
      this.agentDetails = this.agentData[index]
    }
  public  agentDeleteCallback= (res:any)=>{
      // this.getAgents();      
      this.dialogService.closeDialog();
      const index = this.agentData.findIndex((item:any) => item.id === res.data.id);
    if (index !== -1) {
      this.agentData.splice(index, 1);
      this.selectedIndex = 0;
      this.agentDetails = this.agentData[0]
    } 

    }
    agentRowClicked(item: any, index: number) {
      this.hide=true;
      this.clearCopyTimeOuts();
      this.agentDetails = item;
      this.selectedIndex = index;
      this.filterService.closeSidenav();
    }    
    // public copyToClipboard(value: string): void {
    //   if (!value) {
    //     return;
    //   }
   
    //   // Create a temporary textarea element
    //   const textarea = document.createElement('textarea');
    //   textarea.value = value;
    //   document.body.appendChild(textarea);
   
    //   // Select the text
    //   textarea.select();
    //   textarea.setSelectionRange(0, 99999); // For mobile devices
   
    //   // Copy the text to clipboard
    //   document.execCommand('copy');
   
    //   // Remove the temporary element
    //   document.body.removeChild(textarea);
    //   this.isCopied = true;
   
    //   // Optionally, you can show a success message or a toast notification
    //   console.log('Copied to clipboard:', value);
    // }
   

    
    public copyToClipboard(value: string, type?: string): void {
      if (!value) {
        return;
      }
    
      navigator.clipboard.writeText(value).then(() => {
        if (type === 'agentId') {
          this.isCopiedId = true;
          clearTimeout(this.copyTimeoutId);
          this.copyTimeoutId = setTimeout(() => {
            this.isCopiedId = false;
          }, 2000);
        } else if (type === 'agentOtp') {
          this.isCopiedOtp = true;
          clearTimeout(this.copyTimeoutOtp);
          this.copyTimeoutOtp = setTimeout(() => {
            this.isCopiedOtp = false;
          }, 2000);
        }
      }).catch(err => {
        console.error('Failed to copy:', err);
      });
    }

    clearCopyTimeOuts=()=>{
      clearTimeout(this.copyTimeoutId);
      // this.copyTimeoutId = setTimeout(() => {
        this.isCopiedId = false;
      // }, 2000);
      clearTimeout(this.copyTimeoutOtp);
      // this.copyTimeoutOtp = setTimeout(() => {
        this.isCopiedOtp = false;
      // }, 2000);
    }
    
  // public getScanStatusClass(value: any): string {
  //   const arr = ['active', 'paused','pending', 'inactive'];
  //   return arr[value];
  // }
  public downloadTemplate(data:any){
    if(data?.cloud_formation_template?.length > 0){
      // this.cloudSecurityService.cloudTemplateData(data?.cloud_formation_template);
      window.open(data?.cloud_formation_template, '_blank');
    }
    
  }
  public goToAws(data:any) {
    if(data?.redirection_url?.length > 0){
      const url=data?.redirection_url;
      window.open(url, '_blank');
    }
  }
}
