import {  Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { IBenchmarkList, IList, ISelectedListDetails, ISelectedListInfoDetails } from '../list/list.model';
import * as _ from 'lodash';
import { ScanService } from '../../services/scan.service';
import { Utils } from '../../utils';
import { DynamicChartData, ISortDrop } from './selected-list-details.model';
import { IBadgeClass } from 'src/app/modules/whitelist/components/whitelist/whitelist.model';
import { CONSTANT, ToolTipConfig } from '../../constants/constant-data';

@Component({
  selector: 'app-selected-list-details',
  templateUrl: './selected-list-details.component.html',
  styleUrls: ['./selected-list-details.component.scss']
})
export class SelectedListDetailsComponent {
  @Input() public selectedRow!: IList;
  @Input() public selectedRowDetails: ISelectedListDetails[] | undefined = [];
  @Input() public selectedRowInfoDetails: ISelectedListInfoDetails | undefined = {};
  @Input() public nist:any | undefined;
  @Input() public aggreDeviceId: string = '';
  @Input() public compType: string = '';
  @Input() public selectedRowApiCall: boolean = false;
  @Input() public page: number = 0;
  @Input() public totalPage: number = 0;
  @Input() public selectedApiAssets: boolean = false;
  @Input() public isScrollEligible: boolean = false;
  
  @Output() public scroll: EventEmitter<number> = new EventEmitter<number>();
  dynamicChartData: DynamicChartData[] = [];
  public toolTipOption = ToolTipConfig;
  public deviceFailed:any;
  public devicePass:any;
  public acceptedRisk:any;
  @Input() public btnLabel: string = 'Apply';
  @Input() public isCloudScanAPi:boolean=false;
  @Output() updateSelectedDetailsList: EventEmitter<ISelectedListDetails[]> = new EventEmitter<ISelectedListDetails[]>();
  public searchDevice: string = '';
  public selectedLists: ISelectedListDetails[] = [];
  public selectedRowDetailsBackUp: ISelectedListDetails[] | undefined = [];
  public selectedTab: string = 'detail';
  public selectedBenchMark:IBenchmarkList  = {};
  public showDeviceDetails: boolean = false;
  public selectedDevice: ISelectedListDetails = {};
  public isSpinner: boolean = false;
  public logs:any[] = [];
  public showContent: boolean = false;
 public  ruleShowContentMap: { [ruleName: string]: boolean } = {};
  public testData =  [];
  public filterXsltData:any;
  public filterValue = 'All';
  public disableTooltip = {
    isFlag: false
  };
public states: string[] = [ 'All',
'Passed',
'Failed']
 public allCollapsed: boolean = true;
  public parameterMenu: ISortDrop[] =[
    { name: 'Default' },
    { name: 'Pass' },
    { name: 'Failed' }
  ];
  public selectedValue:string ='Default' ;
  filteredData: any= [];
  public noDataMsg: String = CONSTANT.NO_DATA_MSG;
  showDescription: boolean = false;
  showRectification: boolean = false;
  showRationale: boolean = false;
  showIssues: boolean = false;
  constructor(private scanService: ScanService){
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedRow'] &&
      changes['selectedRow'].previousValue &&
      changes['selectedRow'].currentValue &&
      !_.isEqual(changes['selectedRow'].previousValue, changes['selectedRow'].currentValue)) {
      this.selectedRow = changes['selectedRow'].currentValue;
      this.selectedLists = [];
      this.selectedTab = 'detail';
      this.showDeviceDetails = false;
      this.searchDevice='';
    }
    
    if (changes['selectedRowDetails'] &&
      changes['selectedRowDetails'].previousValue &&
      changes['selectedRowDetails'].currentValue &&
      !_.isEqual(changes['selectedRowDetails'].previousValue, changes['selectedRowDetails'].currentValue)) {
      this.selectedValue='Default'
      this.selectedRowDetails = changes['selectedRowDetails'].currentValue;
      this.filteredData = changes['selectedRowDetails'].currentValue
      this.selectedRowDetailsBackUp = _.cloneDeep(this.selectedRowDetails);
      this.selectedLists = [];
    }

    if (changes['selectedRowInfoDetails'] &&
      changes['selectedRowInfoDetails'].previousValue &&
      changes['selectedRowInfoDetails'].currentValue &&
      !_.isEqual(changes['selectedRowInfoDetails'].previousValue, changes['selectedRowInfoDetails'].currentValue)) {
      // if(this.selectedRowInfoDetails && this.selectedRowInfoDetails.benchmark_list?.length) {
      //   this.updateBenchmark(this.selectedRowInfoDetails.benchmark_list[0]);
      // }
      // if(this.selectedRowInfoDetails?.benchmark_list && this.selectedRowInfoDetails?.benchmark_list.length > 1){
      //   this.selectedRowInfoDetails?.benchmark_list?.splice(0, 1);
      // }
      if (changes['selectedRowInfoDetails'] && changes['selectedRowInfoDetails'].currentValue) {
        this.selectedTab = 'detail';
        this.page = 1;
        this.getSelectedGraphDetails(changes['selectedRowInfoDetails'].currentValue);
      } 
    }
    this.filterValue = "All"
    this.allCollapsed = true;
    this.showDescription = false;
    this.showRectification = false;
    this.showIssues = false;
    this.showRationale = false;
    this.allCollapsed = false;
    this.filterData()
  }
  ngOnInit(): void {
    this.filteredData=this.selectedRowDetails;
    this.selectedRowDetailsBackUp = _.cloneDeep(this.selectedRowDetails);    
    // if(this.selectedRowInfoDetails && this.selectedRowInfoDetails.benchmark_list?.length) {
    //   this.updateBenchmark(this.selectedRowInfoDetails.benchmark_list[0]);
    // }
    this.getSelectedGraphDetails(this.selectedRowInfoDetails);
  }

  public  getSelectedGraphDetails(selectedRowInfoDetails: ISelectedListInfoDetails | undefined) {
    this.deviceFailed = selectedRowInfoDetails?.device_count?.failed_device_count;
    this.devicePass = selectedRowInfoDetails?.device_count?.passed_device_count;
    this.acceptedRisk = selectedRowInfoDetails?.device_count?.whitelisted_device_count;
   this.dynamicChartData = [
     {value:this.deviceFailed, name:"Assets Failed", itemStyle:{ color: '#FB4B50'}},
     {value:this.devicePass, name:"Assets Pass",itemStyle:{ color: '#19A71F'}},
     {value:this.acceptedRisk, name:"Accepted Risk",itemStyle:{ color: '#B71E31'}}
   ]
 }

   toggleShowContent(rule: any,ruleName?:string) {
    //rule.isOpen = !rule.isOpen;
    for (const rule of this.filterXsltData) {
      if(rule.RuleName == ruleName) { 
        rule.isOpen = !rule.isOpen;
      }
      
    }
    const allClosed = this.filterXsltData.every((rule: { isOpen: any; }) => !rule.isOpen);
    this.allCollapsed = allClosed;
    // this.ruleShowContentMap[ruleName] = !this.ruleShowContentMap[ruleName];
    //this.toggleCollapseExpandAll();
  }

//  objectKeys(obj: any) {
//   return Object.keys(obj);
// }

// isLastKey(key: string) {
//   return key === (this.objectKeys(this.data).length - 1).toString();
// }

  public isItemUpdate(item: ISelectedListDetails) {
    const findIndex = this.selectedLists.findIndex((itemObj: ISelectedListDetails) => {
      return itemObj.device_id === item.device_id;
    })
    if (this.selectedLists.length) {
      if (findIndex === -1) {
        this.selectedLists.push(item)
      } else {
        this.selectedLists[findIndex] = item;
      }
    } else {
      this.selectedLists.push(item);
    }
  }
  public enableWhiteListIndicator(item: ISelectedListDetails) {
    const findIndex = this.selectedRowDetailsBackUp?.findIndex((itemObj: ISelectedListDetails) => {
      return (itemObj.device_id === item.device_id && itemObj.white_list);
    })
    if(findIndex!==undefined && findIndex > -1) {
      return true;
    }
    return false;
  }
  public submitSelectedList() {
    this.updateSelectedDetailsList.emit(this.selectedLists)
  }
  public onScroll = () => {    
    if (this.selectedApiAssets && (this.page < this.totalPage)) {
      this.selectedApiAssets = false;
      this.scroll.emit(++this.page);
    }
  }
  public isBtnEnable() {
    const isBtnEnable = _.isEqual(this.selectedRowDetailsBackUp, this.selectedRowDetails);
    return isBtnEnable;
  }

  public selectedListPopup(item: ISelectedListDetails){
    this.allCollapsed = true;
    this.isSpinner = true;
    this.showDeviceDetails = true;
    this.selectedDevice = item;
    //this.logs = []
    this.testData = []
    const payload: any = {
      "parameter_id": item.master_data_id,
      "device_id": item.device_id
    };
    if(this.compType === 'scan'){
      payload["scan_summary_id"] =  this.aggreDeviceId;
    }
    this.scanService.getDeviceParams(payload, this.getDeviceParamsCallback,this.isCloudScanAPi);
  }
  
  public getDeviceParamsCallback = (response: any) => {  
    this.isSpinner = false; 
    response.data.forEach((element: any) => {
      element.isOpen = false;
    });

    this.testData = response.data;
    //console.log(this.testData);
    this.filterData()
}


  public closeDeviceDetail(){
    this.showDeviceDetails = false;
    this.filterValue = 'All';
  }
  // public updateBenchmark(item: IBenchmarkList) {
  //   this.selectedBenchMark = item;
  // }
  public makeCapital(value: string = ''): string{
    return Utils.makeFirstWordCapital(value)
  }
  public makeFirstLetterCapital(value: string = ''): string{
    return Utils.makeFirstLetterCapital(value)
  }
  public onTabSelectHandler(item: string){
    this.selectedTab = item;
  }
  public sortParameters(value: ISortDrop){
    if(this.selectedValue === "Pass")
        this.filteredData = this.selectedRowDetails?.sort((a, b) => (a.passed_value === b.passed_value)? 0 : a.passed_value? -1 : 1);
    else if(this.selectedValue === "Failed")
        this.filteredData = this.selectedRowDetails?.sort((a, b) => (a.passed_value === b.passed_value)? 0 : a.passed_value? 1 : -1);
    else if(this.selectedValue === "Default") {
        this.filteredData = this.selectedRowDetailsBackUp
    }
}

public validateObjectData(data: any): boolean{
  return Utils.validateObjectData(data);
} 

severityStatus(type:string){
  return Utils.addBadgeClass(type)
}

  hasPassedStatus(tests: any[]): boolean {
    return tests.some((test) => test.Status === 'PASSED');
  }
  
  hasFailedStatus(tests: any[]): boolean {
    return tests.some((test) => test.Status === 'FAILED');
  }

  allPassedStatus(rule:any){
    return rule.Tests.every((test: { Status: string; }) => test.Status === 'PASSED')
  }


  public getFilteredData() {
    const statusFilter = (status: string) => {
      return this.testData.filter((rule: { Tests: any }) =>
        rule.Tests.some((test: { Status: string }) => test.Status === status)
      ).map((filteredRule: { Tests: any }) => ({
        ...filteredRule,
        Tests: filteredRule.Tests.filter((test: { Status: string }) => test.Status === status)
      }));
    };
  
    if (this.states.includes(this.filterValue) && this.filterValue != 'All') {
      return statusFilter(this.filterValue.toUpperCase());
    }  else {
      return this.testData;
    }
  }
  
  
  

  public filterData() {
    this.filterXsltData = this.getFilteredData();
  }

  public toggleCollapseExpandAll() {
    this.allCollapsed = !this.allCollapsed; 
    for (const rule of this.filterXsltData) {
      rule.isOpen = !this.allCollapsed;
    }
  }
  public isDetailsAvailable() {
    let isDetailExists = false;
    if(this.selectedRowInfoDetails && Object.keys(this.selectedRowInfoDetails).length) {
      isDetailExists = true;
    }
    return isDetailExists;
  }

  public noTests=()=>{
    return this.filterValue === 'All' && this.filterXsltData.every((data:any)=>data.Tests.length===0) 
  }
    
 clearSearch(): void {
  this.searchDevice = '';
}

toggleDescription() {
  this.showDescription = !this.showDescription;
}
toggleRectification() {
  this.showRectification = !this.showRectification;
}
toggleRationale() {
  this.showRationale = !this.showRationale;
}
toggleIssues() {
  this.showIssues = !this.showIssues;
}
}
