import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { END_POINTS } from 'src/app/modules/shared/constants/endpoints';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';

@Component({
  selector: 'app-device-popup',
  templateUrl: './device-popup.component.html',
  styleUrls: ['./device-popup.component.scss']
})
export class DevicePopupComponent {
  deviceId: any;
  deviceVendor: any;
  deviceType: any;
  regionName: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog) {
    this.deviceId = data?.data?.deviceData?.device_name || '';
    this.deviceVendor = data?.data?.deviceData?.vendor || '';
    this.deviceType = data?.data?.deviceData?.device_type || '';
    this.regionName = data?.data?.deviceData?.region_name || '';
  }

  public securityGraphTitle = 'Security Score';
  public securityApiName = END_POINTS.SECURITY_SCORE + '?device_uuid=' + this.data?.data?.deviceData.id;

  // public categoryGraphTitle = 'Category wise';
  // public categoryGraphApiName = END_POINTS.CATEGORY_WISE;
  
  public vulnerabilityGraphTitle = 'Misconfigurations';
  public vulnerabilityApiName = END_POINTS.DASHBOARD_VULNARABILITY + '?device_uuid=' + this.data?.data?.deviceData.id;
    public vulnerabilityCveGraphTitle = 'CVE'
  public vulnerabilityCveApiName = END_POINTS.NEW_CVE + '?device_uuid=' + this.data?.data?.deviceData.id;

  public cyberResilienceGraphTitle = 'CYBER RESILIENCE TREND';
  public cyberResilienceGraphApiName = END_POINTS.CYBER_RESILIENCE_DASHBOARD;
  public cyberResilienceFilter = true; 
  public commonVulnerabilityTitle = 'Vulnerabilities List';
  public commonVulnerabilityApiName = END_POINTS.DASHBOARD_COMMONVUL;
  public commonVulnerabilityFilter = true;

  public apiPayload = {device_uuid : this.data?.data?.deviceData.id};
}
