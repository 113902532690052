
<div class="netsec-modelbox">
    <div class="modelbox-body">
      <h2 mat-dialog-title class="header">Rescan with different credential</h2>
      <p class="sub-text">Select a credential option to rescan the asset.</p>
      <form [formGroup]="credentialForm" autocomplete="off">
        <div class="input-box-container">
        <mat-form-field appearance="outline" class="full-width border-field no-bg">
          <mat-label>Select Credentials</mat-label>
          <mat-select formControlName="select" (selectionChange)="onCredentialChange($event)">
            <div class="scroll select-credentials">
            <mat-option [value]="'Create New Credential'">
              <span class="add-icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_5209_25278)">
                  <path d="M9.00012 17.303C13.5857 17.303 17.303 13.5857 17.303 9.00012C17.303 4.41458 13.5857 0.697266 9.00012 0.697266C4.41458 0.697266 0.697266 4.41458 0.697266 9.00012C0.697266 13.5857 4.41458 17.303 9.00012 17.303Z" fill="#3A2ADA"/>
                  <path d="M9 18C4.0378 18 0 13.9629 0 9C0 4.0378 4.0378 0 9 0C13.9629 0 18 4.0378 18 9C18 13.9629 13.9629 18 9 18ZM9 1.39426C4.80604 1.39426 1.39426 4.80604 1.39426 9C1.39426 13.194 4.80604 16.6057 9 16.6057C13.194 16.6057 16.6057 13.1933 16.6057 9C16.6057 4.80604 13.194 1.39426 9 1.39426Z" fill="#3A2ADA"/>
                  <path d="M9.00037 13.1205C8.61555 13.1205 8.30322 12.8089 8.30322 12.4234V5.45203C8.30322 5.06721 8.61555 4.75488 9.00037 4.75488C9.38519 4.75488 9.69752 5.06721 9.69752 5.45203V12.4234C9.69752 12.8089 9.38519 13.1205 9.00037 13.1205Z" fill="white"/>
                  <path d="M12.4859 9.63453H5.51453C5.12971 9.63453 4.81738 9.3222 4.81738 8.93738C4.81738 8.55256 5.12971 8.24023 5.51453 8.24023H12.4859C12.8714 8.24023 13.183 8.55256 13.183 8.93738C13.183 9.3222 12.8714 9.63453 12.4859 9.63453Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_5209_25278">
                  <rect width="18" height="18" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>
                </span>
                  Create New Credential
            </mat-option>
            <mat-option *ngFor="let val of credentials_list; let i = index;" [value]="val.id">
              {{val.name}}
              <span *ngIf="val.global_cred" class="globe-icon">
                <svg width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path 
                    [ngStyle]="{'fill': '#6D738F'}"
                    d="M7 0C3.1313 0 0 3.13078 0 7C0 10.8687 3.13078 14 7 14C10.8687 14 14 10.8692 14 7C14 3.13132 10.8692 0 7 0ZM12.8811 6.45312H10.2262C10.2104 5.98475 10.174 5.52415 10.1179 5.07541C11.2797 4.21042 10.8087 2.37103 9.3809 2.16762C9.2689 1.90498 9.14864 1.66428 9.02065 1.44714C11.0611 2.18824 12.6644 4.07805 12.8811 6.45312ZM7 12.9062C6.57191 12.9062 6.0985 12.4168 5.71287 11.5988C6.16812 11.3112 6.47134 10.8038 6.47134 10.2266C6.47134 9.36439 5.79523 8.65741 4.94542 8.60729C4.90686 8.26066 4.88091 7.90642 4.8679 7.54688H9.13199C9.0802 8.99191 8.82375 10.3272 8.39647 11.3527C7.99113 12.3255 7.46908 12.9062 7 12.9062ZM4.84895 9.69793C5.14043 9.69793 5.37759 9.93508 5.37759 10.2266C5.37759 10.518 5.14043 10.7552 4.84895 10.7552C4.55746 10.7552 4.32031 10.518 4.32031 10.2266C4.32031 9.93508 4.55746 9.69793 4.84895 9.69793ZM4.86801 6.45312C4.9198 5.00809 5.17625 3.67276 5.60353 2.64729C6.00884 1.6745 6.53089 1.09375 7 1.09375C7.42809 1.09375 7.9015 1.58318 8.2871 2.40122C7.83185 2.68887 7.52864 3.19627 7.52864 3.77344C7.52864 4.63561 8.20474 5.34259 9.05455 5.39271C9.09311 5.73934 9.11906 6.09358 9.13207 6.45312H4.86801ZM9.15105 4.30207C8.85954 4.30207 8.62239 4.06492 8.62239 3.77344C8.62239 3.48195 8.85954 3.2448 9.15105 3.2448C9.44254 3.2448 9.67969 3.48195 9.67969 3.77344C9.67969 4.06492 9.44254 4.30207 9.15105 4.30207ZM4.98116 1.44646C4.1586 2.83623 3.82856 4.82467 3.77366 6.45312H1.11893C1.33602 4.07345 2.94377 2.18567 4.98116 1.44646ZM1.11893 7.54688H3.77385C3.78965 8.01525 3.82605 8.47585 3.88216 8.92459C2.72032 9.78958 3.19137 11.6289 4.61912 11.8324C4.73112 12.095 4.85138 12.3357 4.97938 12.5529C2.93885 11.8118 1.33558 9.92195 1.11893 7.54688ZM9.01882 12.5535C9.84099 11.1643 10.1714 9.17662 10.2263 7.54688H12.8811C12.664 9.92657 11.0562 11.8143 9.01882 12.5535Z" />
                </svg>
              </span>
            </mat-option>
          </div>
          </mat-select>
        </mat-form-field>
      </div>
        <div *ngIf="showNewCredentialFields">
        <div>
            <mat-radio-group  class="report-label" formControlName="connectionType">
                <mat-radio-button *ngFor="let item of credentialTypes" [value]="item.key">{{item.value}}</mat-radio-button>
              </mat-radio-group>
      </div>
        <mat-form-field *ngIf="!showApiTokenInput" class="full-width border-field no-bg" appearance="outline">
          <mat-label>User</mat-label>
          <input formControlName="username" matInput>
        </mat-form-field>
        <P *ngIf="!showApiTokenInput" class="header">Password Type</P>
        <div>
            <mat-radio-group *ngIf="!showApiTokenInput" class="report-label"  formControlName="loginParameter">
                <mat-radio-button *ngFor="let pt of passwordTypes" [value]="pt.key">{{pt.value}}</mat-radio-button>
              </mat-radio-group>
        </div>
        <mat-form-field *ngIf="showPasswordInput" class="full-width mb_3 border-field no-bg" appearance="outline">
            <mat-label>Password</mat-label>
            <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" >
            <mat-icon matSuffix (click)="hide = !hide" class="hide-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
          <!-- <div *ngIf="showPrivateKeyInput" class="uploaded-files-section"> 
            <div class="privatefile-list">
              <p class="privatekey-text">Upload PPK File</p>
            </div>
          </div> -->
          <mat-form-field *ngIf="showApiTokenInput" class="full-width mb_3 border-field no-bg" appearance="outline">
            <mat-label>API Token</mat-label>
            <input formControlName="api_token" matInput>
          </mat-form-field>
          <div *ngIf="showPasswordInput || showPrivateKeyInput" class="input-box-container">
            <div class="exec-field">
              <P>Exec Password </P>
              <div class="tool_switch exec-tool_switch right_ele">
                <mat-slide-toggle formControlName="execPasswordToggle"></mat-slide-toggle>
            </div>
            </div>
            <div class="input-box-container input-box-container-pass" [ngClass]="{'field-hide':execPassword === false}">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Exec Password</mat-label>
                <input formControlName="enable_password" matInput [type]="exec_pass_hide? 'password' : 'text'">
                <mat-icon matSuffix (click)="exec_pass_hide = !exec_pass_hide" class="hide-icon">{{exec_pass_hide ? 'visibility_off' :
                  'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="showPasswordInput" class="input-box-container" [ngClass]="{'field-hide': execPassword}">
            <div class="exec-field">
              <P class="sub-text no-padding">Auto Assign 
                <span>This will auto-assign Login Password as Exec Password when missing.</span>
              </P>
              <div class="tool_switch exec-tool_switch right_ele">
                <mat-slide-toggle formControlName="autoAssignToggle"></mat-slide-toggle>
            </div>
            </div>
          </div>
        <mat-form-field class="full-width mb_3 border-field no-bg" appearance="outline">
            <mat-label>Credential Name</mat-label>
            <input formControlName="cred_name" matInput>
          </mat-form-field>
        <mat-checkbox [(ngModel)]="saveCred" (change)="onCheckboxChange($event)" class="mat-checkbox-label">Save Credentials</mat-checkbox>
    </div>
      </form>
    </div>
    <div class="modelbox-footer">
      <button mat-button class="mat-stroked-button btn-lightblack-outline" mat-dialog-close>Cancel</button>
      <button [ngClass]="{'highlight-disabled-text': credentialForm.invalid}"   mat-button class="mat-stroked-button btn btn-primary"  (click)="assetRescanCredential()" [disabled]="credentialForm.invalid">Connect</button>
    </div>
  </div>