<div class="out-layout scroll new-device-section ">
    <div class="new-device-page ">
        <!-- <span *ngIf="currentSelectedDevice"
            class="region-set text_ellipsis " appSafariEllipsis matTooltip="{{currentSelectedDevice?.region_name}}">{{currentSelectedDevice?.region_name}}</span> -->
            <span class="region-set" *ngIf="currentSelectedDevice">
                <p matTooltip="{{currentSelectedDevice?.region_name}}" class="text_ellipsis paramer-value">{{currentSelectedDevice?.region_name}}</p>
            </span>
        <div class="new-device-left">
            <div class="white-box mb_4">
                <div class="chart-section cyber-resilience" [ngClass]="{'load-graph': isChartLoad}">
                    <app-cyber-reilience #cyberReilCmp [title]="'CYBER RESILIENCE TREND'" [isCompareAvailable]="true"
                        [apiName]="cyberResilienceGraphApiName" [showFilter]="cyberResilienceFilter"
                        [hasSingleDateResponse]="hasSingleDateResponse" [deviceId]="deviceId"
                        (updateDataAfterDropApiCall)="updateDataAfterDropApiCall($event)"
                        (updateDateAfterSelect)="updateDate($event)" (compareDataLoad)="compareData()"
                        [showDateDropdown]="true" [isDownload]="false">
                    </app-cyber-reilience>
                </div>
            </div>
            <div class="white-box">
                <div class="device-perameter">
                    <div *ngIf="isDevicePerameterLoad" class="nodata-text loading-text">{{constant.LOADING}}</div>
                    <div *ngIf="!devicePerameterData.length && !isDevicePerameterLoad" class="nodata-text">No Data
                    </div>
                    <div *ngIf="devicePerameterData?.length" class="main-device-perameter"
                        [hidden]="isDevicePerameterLoad" [ngClass]="{'single-response': hasSingleDateResponse  }">
                        <div class="device-header">
                            <div class="parameter">
                                PARAMETERS
                            </div>
                            <!-- <div appSafariEllipsis class="device-start-date" [options]="toolTipOption"
                                [tooltip]="fromSelectedData?.date_added">
                                {{getDateAndMonth(fromDiffSelectedData?.validDate)}}
                            </div> -->
                            <div class="device-start-date">
                                    <p matTooltip="{{getDateAndMonth(fromDiffSelectedData?.validDate)}}" class="text_ellipsis paramer-value">{{getDateAndMonth(fromDiffSelectedData?.validDate)}}</p>
                                
                            </div>
                            <!-- <div *ngIf="!hasSingleDateResponse" class="device-end-date" [options]="toolTipOption" appSafariEllipsis
                                [tooltip]="toSelectedData?.date_added">
                                {{getDateAndMonth(toDiffSelectedData?.validDate)}}
                            </div> -->
                            <div *ngIf="!hasSingleDateResponse" class="device-end-date" >
                                <p matTooltip=" {{getDateAndMonth(toDiffSelectedData?.validDate)}}" class="text_ellipsis paramer-value"> {{getDateAndMonth(toDiffSelectedData?.validDate)}}</p>
                            
                        </div>
                        </div>
                        <div class="device-data-section scroll">
                            <div class="device-data" *ngFor="let deviceData of devicePerameterData">
                                <div class="parameter">
                                    <!-- <span appSafariEllipsis class="overflow text_ellipsis"
                                        [tooltip]="deviceData.parameter" [options]="toolTipOption" appSafariEllipsis>
                                        {{deviceData.parameter}}
                                    </span> -->
                                        <p matTooltip="{{deviceData.parameter}}" class="text_ellipsis paramer-value">{{deviceData.parameter}}</p>
                                    <span *ngIf="deviceData.white_list.value" class="white-list-badge">
                                        Risk-Accepted
                                    </span>
                                </div>
                                <div class="device-start-date">
                                    <div [ngClass]="{'pass-item': deviceData.is_passed_1,'fail-item': !deviceData.is_passed_1 }"
                                        class="selected-list-item-right ">
                                        <mat-icon *ngIf="!deviceData.is_passed_1">close</mat-icon>
                                        <mat-icon *ngIf="deviceData.is_passed_1">check</mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="!hasSingleDateResponse" class="device-end-date">
                                    <div [ngClass]="{'pass-item': deviceData.is_passed_2,'fail-item': !deviceData.is_passed_2 }"
                                        class="selected-list-item-right ">
                                        <mat-icon *ngIf="!deviceData.is_passed_2">close</mat-icon>
                                        <mat-icon *ngIf="deviceData.is_passed_2">check</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="new-device-right">
            <div class="white-box mb_4 config-diff-details">
                <div *ngIf="isCompare" class="nodata-text loading-text">{{constant.LOADING}}</div>
                <div *ngIf="!isCompare">
                    <div class="control-panel-col-header">
                        <h3>Configuration Difference ({{getDateAndMonth(fromDiffSelectedData?.validDate)}} -
                            {{getDateAndMonth(toDiffSelectedData?.validDate)}})</h3>
                        <div class="score-details">
                            Security Score Change: <span class="score-with-direction vulnerabity-values"><mat-icon
                                    *ngIf=" securityScore > 0" class="low-val">call_made</mat-icon>
                                <mat-icon *ngIf="securityScore < 0"
                                    class="high-val">call_received</mat-icon>{{securityScore === 0 ?securityScore:
                                getPositiveValue(securityScore)}}</span>
                        </div>
                    </div>
                    <div class="config-diff-content-section">
                        <div class="config-diff-content">
                            <span class="config-label">Configuration Level Changes</span>
                            <div class="config-diff-blocks">
                                <div class="config-diff-block">
                                    <div>
                                        <span class="value-with-direction vulnerabity-values"><mat-icon
                                                *ngIf=" totalCount > 0"
                                                class="low-val">call_made</mat-icon>{{totalCount}}</span>
                                    </div>
                                    <div class="config-diff-block-label">All</div>
                                </div>
                                <div class="config-diff-block">
                                    <div>
                                        <span class="value-with-direction vulnerabity-values"><mat-icon
                                                *ngIf=" addition > 0"
                                                class="low-val">call_made</mat-icon>{{addition}}</span>

                                    </div>
                                    <div class="config-diff-block-label">Addition</div>
                                </div>
                                <div class="config-diff-block">
                                    <div>
                                        <span class="value-with-direction vulnerabity-values"><mat-icon
                                                *ngIf="deletion > 0"
                                                class="high-val">call_received</mat-icon>{{deletion}}</span>
                                    </div>
                                    <div class="config-diff-block-label">Deletion</div>
                                </div>
                            </div>
                        </div>
                        <div class="config-diff-content">
                            <span class="config-label">Vulnerability Level Changes</span>
                            <div class="config-diff-blocks">
                                <div class="config-diff-block">
                                    <div>

                                        <span class="value-with-direction vulnerabity-values"><mat-icon
                                                *ngIf=" newVulnerabilities > 0" class="high-val">call_made</mat-icon>
                                            <mat-icon *ngIf="newVulnerabilities < 0"
                                                class="low-val">call_received</mat-icon>{{newVulnerabilities}}</span>
                                    </div>
                                    <div class="config-diff-block-label">New</div>
                                </div>
                                <div class="config-diff-block">
                                    <div><span class="value-with-direction vulnerabity-values"><mat-icon
                                                *ngIf="remediated > 0"
                                                class="low-val">call_made</mat-icon>{{remediated}}</span></div>
                                    <div class="config-diff-block-label">Remediated</div>
                                </div>
                                <div class="config-diff-block">
                                    <div>
                                        <span class="value-with-direction vulnerabity-values"><mat-icon
                                                *ngIf=" acceptedRisk > 0" class="high-val">call_made</mat-icon>
                                            <mat-icon *ngIf="acceptedRisk < 0"
                                                class="low-val">call_received</mat-icon>{{acceptedRisk}}</span>
                                    </div>
                                    <div class="config-diff-block-label">Risks accepted</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="white-box comparator-section">
                <div *ngIf="isCompare" class="nodata-text loading-text">{{constant.LOADING}}</div>
                <div *ngIf="!isCompare && !leftFileText && !rightFileText" class="nodata-text">No Data</div>
                <!-- <div *ngIf="leftFileText || rightFileText" [hidden]="isCompare" class="comparator scroll"> -->
                <div class="comparator" [ngClass]="{'single-compare': hasSingleDateResponse}">
                    <div class="comparator-drop-section">
                        <div [ngClass]="{'full-width': hasSingleDateResponse}" class="comparator-head">
                            <span class="date-detail">{{comparefromSelectedData?.date_added}}</span>
                            <span class="score">Security Score: <span
                                    [ngClass]="getScoreStatus(comparefromSelectedData?.security_score)">{{comparefromSelectedData?.security_score}}</span></span>
                        </div>
                        <div *ngIf="!hasSingleDateResponse" class="comparator-head">
                            <span class="date-detail">{{comparetoSelectedData?.date_added}}</span>
                            <span class="score">Security Score: <span
                                    [ngClass]="getScoreStatus(comparetoSelectedData?.security_score)">{{comparetoSelectedData?.security_score}}</span></span>
                        </div>
                    </div>
                    <ngx-monaco-diff-editor *ngIf="(leftFileText || rightFileText) && !hasSingleDateResponse"
                        [options]="editorOptions" appSafariEllipsis [original]="leftFileText" [modified]="rightFileText">
                    </ngx-monaco-diff-editor>
                    <ngx-monaco-editor  *ngIf="hasSingleDateResponse" [options]="editorOptions" appSafariEllipsis 
                        [(ngModel)]="leftFileText" class="editor-height"></ngx-monaco-editor>
                </div>
            </div>
        </div>
    </div>
</div>