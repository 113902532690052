import { Injectable } from '@angular/core';
import { END_POINTS } from '../constants/endpoints';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public isEncryption: boolean = false;
  constructor(private sharedService: SharedService) { }

  public setEncryptionAllowed(isEncryption: boolean = false) {
    this.isEncryption = isEncryption;
    this.sharedService.dispatchDashBoardLoaderActions(true);
  }
  public getEncryptionAllowed() {
    return this.isEncryption;
  }
  
  public ignoredApi() {
    const ignoreApiList = [];
    ignoreApiList.push(END_POINTS.SSH_UPLOAD);
    ignoreApiList.push(END_POINTS.FILE_UPLOAD_TEMP);
    ignoreApiList.push(END_POINTS.SSH_FIELD);
    ignoreApiList.push(END_POINTS.API_FIELD);
    ignoreApiList.push(END_POINTS.FILE_DOWNLOAD);
    ignoreApiList.push(END_POINTS.AUTH);
    ignoreApiList.push(END_POINTS.LOGIN); // done
    ignoreApiList.push(END_POINTS.REFRESH_TOKEN); // Done
    ignoreApiList.push(END_POINTS.LOGOUT); // logout
    ignoreApiList.push(END_POINTS.REGISTER); //
    ignoreApiList.push(END_POINTS.CHANGE_PASSWORD);
    ignoreApiList.push(END_POINTS.UPDATED_PASSWORD);
    ignoreApiList.push(END_POINTS.RESET);
    ignoreApiList.push(END_POINTS.PASSWORD_VALIDATION);
    ignoreApiList.push(END_POINTS.SSO_LOGIN);
    ignoreApiList.push(END_POINTS.APEXIQ_INTEGRATION);
    ignoreApiList.push(END_POINTS.EDIT_ORG_IMAGE);
    ignoreApiList.push(END_POINTS.EDIT_USER_IMAGE);
    ignoreApiList.push(END_POINTS.USER_DETAILS);
    ignoreApiList.push(END_POINTS.USER_PERMISSION);
    ignoreApiList.push(END_POINTS.GET_USER_IMAGE);
    ignoreApiList.push(END_POINTS.RESET_PASS_TOKEN_API);
    ignoreApiList.push(END_POINTS.ACTIVATE_REGISTRATION);
    ignoreApiList.push(END_POINTS.AUTHENTICATE_SINGLE_SIGNIN);
    ignoreApiList.push(END_POINTS.RESEND_EMAIL);
    ignoreApiList.push(END_POINTS.INVITE_SUPER_ADMIN);
    ignoreApiList.push(END_POINTS.GET_ORG_IMAGE);
    ignoreApiList.push(END_POINTS.PROFILE_SETUP_GET_LEVELS);
    // ignoreApiList.push(END_POINTS.ALERT_NOTIFICATION);
    // ignoreApiList.push(END_POINTS.ALERT_NOTIFICATION_DATA);
    // ignoreApiList.push(END_POINTS.READ_NOTIFICATION);
    ignoreApiList.push(END_POINTS.MUTE_NOTIFICATION);
    ignoreApiList.push(END_POINTS.Asset_SUMMARY_DATA);
    ignoreApiList.push(END_POINTS.Asset_SUMMARY_REPORT);
    ignoreApiList.push(END_POINTS.DELETE_ORG);
    ignoreApiList.push(END_POINTS.RESCAN);
    ignoreApiList.push(END_POINTS.SSH_UPLOAD);
    ignoreApiList.push(END_POINTS.CVE_DATA);
    ignoreApiList.push(END_POINTS.Asset_Compliance_Report)
    return ignoreApiList;
  }
  public isUrlAvailable(url: string) {
    let isAvailable = false;
    if(url) {
      let uri = new URL(url),
      path = uri.origin+uri.pathname;
      if(this.ignoredApi().indexOf(path) === -1) {
        isAvailable = true;
      }
    }
    return isAvailable;
  }
}
