<div class="whitelist-container">
    <div class="secsion1">
        <h5>Whitelist/Un Whitelist</h5>
        <span>
            <button mat-button [mat-dialog-close]="true">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </span>
        <div class="sub_header">
            <p class="text_data">Control: <span [title]="Parameter" class="data"> {{ Parameter|uppercase }}</span></p>
        </div>
        
    </div>
    <div class="content">
        
        <div class="right_container">
            <form [formGroup]="whitelistForm" novalidate>
            <p><b>Define the Reason to whitelist the assets</b></p>
            <p class="note-text">*Note: Minimum 8 character are required</p> 
            <div class="field">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Reason</mat-label>
                    <textarea matInput cdkTextareaAutosize #messageInput cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                         #autosize="cdkTextareaAutosize" (input)="checkTextField($event)"  formControlName="regionData" minlength="6"
                        maxlength="120" spellcheck="false"></textarea>
                </mat-form-field>
                <p class="field_length">120</p>
            </div>
            <div class="d-tcell remember_link">
                <mat-checkbox formControlName="rememberMe" [required]="true" 
                    (change)="whitelistForm.controls['rememberMe'].setValue($event.checked)">
                    I have the permissions to whitelist
                </mat-checkbox>
            </div>
            <div class="submit-class mt_3">
                <button mat-raised-button class="white-list-btn" (click)="messageName(messageInput.value)"
                    [disabled]="isButtonDisabled ||  !whitelistForm.controls['rememberMe'].value">
                   Submit
                </button>
            </div>
        </form>
        </div>
    </div>
</div>
