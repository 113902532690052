import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, map, of, zip } from 'rxjs';
import { EncryptService } from './encrypt.service';
import { CommonService } from './common.service';
import { isArray } from 'lodash';
import { ResponseService } from './response.service';

@Injectable({
  providedIn: 'root'
})
export class RestService extends EncryptService {
  
  constructor(
    private httpClient: HttpClient, public override commonService: CommonService) {
      super(commonService);
  }
  
  getResponse(url: any, header?: any, responseType?: any): Observable<any> {
    let isEncryptedEndpooint = false;
    if(false) {
      isEncryptedEndpooint = true;
      responseType = 'text/plain';
    }
    if(false){
      return this.httpClient.get(url,  { observe: 'response', responseType: responseType });
    } else if(responseType){
      return this.httpClient.get(url, { responseType: responseType });
    }else if (header) {
      return this.httpClient.get(url, { observe: 'response', headers: new HttpHeaders().set(header.key, header.value) });
    } else {
      return this.httpClient.get(url, { observe: 'response' });
    }
  }

  

  
  postResponse(url: any, req: any, header?: any,responseType?: any): Observable<any> {
    let finalReq = req;
    let isResText = false;
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    if(this.commonService.getEncryptionAllowed() && this.commonService.isUrlAvailable(url)) {
      isResText = false;
      finalReq = {
        payload: this.doEncrypt(JSON.stringify(req))
      }
    }

    if(responseType){
      return this.httpClient.post(url,finalReq,{ responseType: responseType })
    }
    
    if (header) {
      return this.httpClient.post(url, finalReq, { observe: 'response', headers: new HttpHeaders().set(header.key, header.value) });
    } else {
      if(isResText) {
        return this.httpClient.post(url, finalReq,  {observe: 'response', });
      } else {
        return this.httpClient.post(url, finalReq, { observe: 'response' });
      }
    }
  }
  putResponse(url: any, req: any, header?: any,responseType?: any): Observable<any> {
    let finalReq = req;
    let isResText = false;
    if(this.commonService.getEncryptionAllowed() && this.commonService.isUrlAvailable(url)) {
      isResText = true;
      finalReq = {
        payload: this.doEncrypt(JSON.stringify(req))
      }
    }
    if(responseType){
      return this.httpClient.put(url,finalReq,{ responseType: responseType })
    }
    
    if (header) {
      return this.httpClient.put(url, finalReq, { observe: 'response', headers: new HttpHeaders().set(header.key, header.value) });
    } else {
      if(isResText) {
        return this.httpClient.put(url, finalReq,  {observe: 'response',  });
      } else {
        return this.httpClient.put(url, finalReq, { observe: 'response' });
      }
    }
  }
  deleteResponse(url: any, header?: any, responseType?: any): Observable<any> {
    let isEncryptedEndpooint = false;
    if(this.commonService.getEncryptionAllowed() && this.commonService.isUrlAvailable(url)) {
      isEncryptedEndpooint = true;
      responseType = 'text/plain';
    }
    if(isEncryptedEndpooint){
      return this.httpClient.delete(url,  { observe: 'response' });
    } else if(responseType){
      return this.httpClient.delete(url, { responseType: responseType });
    }else if (header) {
      return this.httpClient.delete(url, { observe: 'response', headers: new HttpHeaders().set(header.key, header.value) });
    } else {
      return this.httpClient.delete(url, { observe: 'response' });
    }
  }
  getApi(endpoint: string, header?: any, responseType?: string): Observable<any> {
    return this.getResponse(endpoint, header, responseType);
  }
   
 

  postApi(endpoint: string, reqObj: any, header?: any,responseType?: string): Observable<any> {
    return this.postResponse(endpoint, reqObj, header,responseType);
  }
  putApi(endpoint: string, reqObj: any, header?: any,responseType?: string): Observable<any> {
    return this.putResponse(endpoint, reqObj, header,responseType);
  }
  deleteApi(endpoint: string, header?: any, responseType?: string): Observable<any> {
    return this.deleteResponse(endpoint, header, responseType);
  }
  public multiplePostApiCall(endpoints: Array<string>, request: Array<any> | null, callBack?: Function, header?: any) {
    const calls: Array<any> = [];
    let finalReq = null;
    let isResText = false;
    endpoints?.map((endpoint, index)=>{
      if(this.commonService.getEncryptionAllowed() && this.commonService.isUrlAvailable(endpoint)) {
        isResText = false;
       let finalReq = {
          payload: this.doEncrypt(JSON.stringify(request?.[index]))
        }
        calls.push(this.httpClient.post(endpoint,finalReq || null, { observe: 'response'}));
      } else {
        calls.push(this.httpClient.post(endpoint, request?.[index] || null, { observe: 'response' }));
      }
      
    });
    return zip(calls);
  }
  public multipleGetApiCall(endpoints: Array<string>, callBack?: Function | undefined, header?: any) {
    const calls: Array<any> = [];
    endpoints?.forEach((endpoint)=>{
      calls.push(this.httpClient.get(endpoint, { observe: 'response' }));
    });
    return zip(calls);
  }
  getDownloadResponse(url: string): Observable<any> {
      return this.httpClient.get(url, { observe: 'response', responseType: 'text' as 'json'});
  }
  getApiDownload(endpoint: string, header?: any): Observable<any> {
    return this.getDownloadResponse(endpoint);
  }
  postApiFileUpload(endpoint: string, reqObj: any, header?: any): Observable<any> {
    return this.postResponseFileUpload(endpoint, reqObj, header);
  }
  postResponseFileUpload(url: any, req: any, header?: any): Observable<any> {
    let finalReq = req;
    let isResText = false;
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    if(this.commonService.getEncryptionAllowed() && this.commonService.isUrlAvailable(url)) {
      isResText = true;
      finalReq = {
        payload: this.doEncrypt(JSON.stringify(req))
      }
    }
    if (header) {
      return this.httpClient.post(url, req, { observe: 'events',reportProgress: true, headers: new HttpHeaders().set(header.key, header.value) });
    } else {
      if(isResText) {
        return this.httpClient.post(url, finalReq,  {observe: 'response' });
      } else {
        return this.httpClient.post(url, req, { observe: 'events', reportProgress: true });
      }
      
    }
  }
}
