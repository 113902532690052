import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Utils } from '../../../utils';

@Component({
  selector: 'app-acceptrisk',
  templateUrl: './acceptrisk.component.html',
  styleUrls: ['./acceptrisk.component.scss']
})
export class AcceptriskComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  filterMenuOpen = false;

  agInit(params: ICellRendererParams): void {
    console.log(this.params);
    this.params = params;
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  severityStatus(type:string){
    return Utils.addBadgeClass(type)
  }

}
