import { Injectable } from '@angular/core';
import { END_POINTS } from 'src/app/modules/shared/constants/endpoints';
import { ResponseService } from 'src/app/modules/shared/services/response.service';
import { RestService } from 'src/app/modules/shared/services/rest.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class CloudControlService {

  constructor(private restService: RestService,
    private responseService: ResponseService,
    private spinnerService: SpinnerService,) { }
  public getCloudControlData(callback: Function,req:any) {
    this.restService.postApi(END_POINTS.CONTROLS,req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
}
