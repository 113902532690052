import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInputCancelScan, IInputRenameScan, IStatus } from '../../my-scans/my-scans.model';
import { ITreeView } from '../components/filter/filter.model';
import { END_POINTS } from '../constants/endpoints';
import { IBenchMark, IVulnerabilityList, ImyScan } from '../model/scan/scan';
import { ResponseService } from './response.service';
import { RestService } from './rest.service';
import { SpinnerService } from './spinner.service';
import { SortingFilterService } from './sorting-filter.service';
import { Utils } from '../utils';
import { GlobalSearchService } from './global-search.service';
import { Observable, Subject, Subscription, catchError, of, throwError } from 'rxjs';
import { SharedService } from './shared.service';
import { ProfileService } from './profile.service';
import { IUserDetail } from '../model/profile/profile';
import { time } from 'echarts';
import { ApiService, IAPIDetails } from './api.service';
import { CONFIG } from '../constants/config';
import { ROLES_CONTENT_PROPS } from '../constants/constant-data';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class MyScansService {
  loggedUser: IUserDetail = {};
  public userMenuClosed = new Subject<any>();
  userMenuClosed$ = this.userMenuClosed.asObservable();
  myScansDetails: any = {
    myScan: [],
    fromBack: false,
    position: 0,
    currentRow:{}
  }
  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }
  constructor(private http: HttpClient,
    private restService: RestService,
    private responseService: ResponseService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private sortingFilterService: SortingFilterService,
    private globalSearchService: GlobalSearchService,
    private profileService: ProfileService,
    private apiService: ApiService) {
  }
  getScanDetailsSearch(page: number | null, contentSize: number | null,user?:any, searchText: string = ""): Observable<any> {
    let url = END_POINTS.SCANS;
    const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone
    
    
    if(searchText){
      searchText = searchText.trim();
      url += `?search=${encodeURIComponent(searchText) || ''}&timezone=${timezone}`;
    }
    
    if(searchText && user){
      url += `&user_ids=${user}`;
    } else if(user) {
      url += `?user_ids=${user}&timezone=${timezone}`;
    } 
    if((!user && searchText=="")  ){
      url += `?timezone=${timezone}`;
    }
    return this.restService.getApi(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    )
  }
  cancelSchedule(pathParams:any,callback: Function) {
    let url = END_POINTS.CANCEL_SCHEDULE;
    return this.restService.postApi(url,pathParams).subscribe({
      next: (res) => {
        callback(this.responseService.successResponse(res));
      },
      error: (err) => {
        this.spinnerService.hide();
        this.responseService.errorResponse(err);
      },
    })



  }


  
  getScanDetails(callback: Function, page: number | null, contentSize: number | null,user?:any, search: string = '',filter_sched?:any) {
    let url = END_POINTS.SCANS;
    
let queryParams = [];
if (search) {
  search = search.trim();
  queryParams.push(`search=${encodeURIComponent(search)}`);
}

if (user) {
  queryParams.push(`user_ids=${user}`);
}

if (filter_sched && filter_sched.length > 0) {
  queryParams.push(`filter_sched=${filter_sched}`);
}
const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
if(timezone){
  queryParams.push(`timezone=${timezone}`)
}

if (queryParams.length > 0) {
  url += `?${queryParams.join('&')}`;
}

    // if(this.globalSearchService.isRedirected && this.globalSearchService.searchTerm) {
    //   //url = url + '?search=' + this.globalSearchService.searchTerm;
    //   url = url + '&search=' + this.globalSearchService.searchTerm;
    // }
    const myScansDetails = this.getMyScans();
    let req:any={};
    req.timezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    req.filter_sched=filter_sched;
    req.user_ids=user;
    req.search=encodeURIComponent(search);
    const scanProgress = this.filterInProgressScan(myScansDetails.myScan) || [];
    if(myScansDetails.fromBack && !scanProgress.length) {
      const res = {
        status: 200,
        data: myScansDetails.myScan,
        message: 'cache'
      }
      myScansDetails.fromBack = false;
      this.setMyScans(myScansDetails);
      callback(res);
    } else {
      
      const apiDetails: IAPIDetails | any = { 
        url: [],
        req: [],
        method: CONFIG.METHOD.POST,
        responseType: CONFIG.RESPONSE_TYPE.NONE,
        callBack: callback
      }
      //apiDetails.req = req;
      req.timezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
      apiDetails.req = req;
      apiDetails.url =END_POINTS.SCANS;
      this.apiService.callAPI(apiDetails);

      // return this.restService.getApi(url).subscribe({
      //   next: (res) => {
      //     callback(this.responseService.successResponse(res));
      //   },
      //   error: (err) => {
      //     this.spinnerService.hide();
      //     this.responseService.errorResponse(err);
      //   },
      // })
    }
    
  }

  getScanStatusDetails(callback:Function, scanUuid:any) {
    let scanObj: IStatus = {}
    scanObj.scan_uuid = scanUuid;

    const apiDetails: IAPIDetails | any = { 
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }
    apiDetails.req = scanObj;
    apiDetails.url = END_POINTS.SCAN_STATUS;
    this.apiService.callAPI(apiDetails);
    // return this.restService.postApi(END_POINTS.SCAN_STATUS, scanObj).subscribe({
    //   next: (response) => {
    //     callback(this.responseService.successResponse(response));
    //   },
    //   error: (err) => {
    //     callback(this.responseService.errorResponse(err));
    //   }
    // });
  }

  public renameScan(scanname: any, scanIdNo: string,scanId: string, callback: Function) {
    const scanObj: IInputRenameScan = {}
    scanObj.scanid = scanId;
    scanObj.scanname = scanname;
    scanObj.scanIdNo = scanIdNo;
    return this.restService.postApi(END_POINTS.RENAME_SCAN, scanObj).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
  }

  public cancelScan(scanIdNo: string, scanId: string, callback: Function) {
    const scanObj: IInputCancelScan = {}
    scanObj.scanid = scanId;
    
    scanObj.scanIdNo = scanIdNo;
    
    
    scanObj.cancel_rescan = "cancel";
    return this.restService.postApi(END_POINTS.CANCEL_RESCAN, scanObj).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
  }

  public reScan(scanId: string,scanIdNo: string ,callback: Function) {
    const scanObj: IInputCancelScan = {}
    scanObj.scan_id = scanId;
    scanObj.scan_source = "Scans";
    return this.restService.postApi(END_POINTS.RESCAN, scanObj).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
  }


  public scanUploadFileData(callback?: any, scan_uuid?: string, deviceId = undefined) {
    let finalPath = END_POINTS.SCAN_SUMMARY + "?scan_uuid=" + scan_uuid + '&vulnerability_name=all';
    if (deviceId) {
      finalPath = END_POINTS.SCAN_SUMMARY_DEVICE + "?scan_uuid=" + scan_uuid + '&scan_device_uuid=' + deviceId + '&vulnerability_name=all';
    }
    this.restService.getApi(finalPath).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }

  public scanInfo(callback?: Function | any, 
    scan_uuid?: string,
    pageNum: Number = 1,
    numberOfResults = 10,
    globalFilter?: ITreeView[],
    searchTerm: string = '') {
    let finalPath = "";
    let req: any = {};
    if (globalFilter?.length) {
      req.data = {};
      req.data.category = [];
      globalFilter?.forEach((obj: ITreeView) => {
        const getGlobalFilterObj = this.prepareDataForGlobalFilter(obj);
        req.data.category.push(getGlobalFilterObj);
      });
    }
    const parameters: string[] = Utils.getSelectedItems(this.sortingFilterService?.filter.scanDetail?.filters.parameters);
    const severity: string[] = Utils.getSelectedItems(this.sortingFilterService?.filter.scanDetail?.filters.severity);
    const benchmark: string[] = Utils.getSelectedItems(this.sortingFilterService?.filter.scanDetail?.filters.benchmark)
    const acceptedRiskSort = Utils.getSelectedItems(this.sortingFilterService?.filter.scanDetail?.filters.accepted_risk_sort);
    const deviceFailedSort = Utils.getSelectedItems(this.sortingFilterService?.filter.scanDetail?.filters.device_failed_sort);
    finalPath = END_POINTS.SCAN_SUMMARY_DETAIL;
    req.scan_uuid = scan_uuid;
    req.pages = pageNum;
    req.content_number = numberOfResults;
    req.filters = {
      parameters: parameters.length ? parameters.toString(): '',
      accepted_risk_sort:acceptedRiskSort.length ? acceptedRiskSort.toString(): '',
      device_failed_sort:deviceFailedSort.length ? deviceFailedSort.toString(): '',
      severity:severity.length ? severity: [],
      benchmark:benchmark.length ? benchmark: [],  
    }
    if(searchTerm) {
      req.search = searchTerm;
    }
    const apiDetails: IAPIDetails | any = { 
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }
    req.type = 1;
    apiDetails.req = req;
    apiDetails.url = END_POINTS.SCAN_SUMMARY_DETAIL;
    this.apiService.callAPI(apiDetails);
    // if (finalPath) {
    //   this.restService.postApi(finalPath, req).subscribe({
    //     next: (response) => {
    //       callback(this.responseService.successResponse(response));
    //     },
    //     error: (err) => {
    //       callback(this.responseService.errorResponse(err));
    //     },
    //   })
    // }
  }

public scanInfoSearch(
    scan_uuid?: string,
    pageNum: Number = 1,
    numberOfResults = 10,
    globalFilter?: ITreeView[],
    searchTerm: string = '') : Observable<any>{
    let finalPath = "";
    let req: any = {};
    if (globalFilter?.length) {
      req.data = {};
      req.data.category = [];
      globalFilter?.forEach((obj: ITreeView) => {
        const getGlobalFilterObj = this.prepareDataForGlobalFilter(obj);
        req.data.category.push(getGlobalFilterObj);
      });
    }
    const parameters: string[] = Utils.getSelectedItems(this.sortingFilterService.filter.scanDetail.filters.parameters);
    const severity: string[] = Utils.getSelectedItems(this.sortingFilterService.filter.scanDetail.filters.severity);
    const benchmark: string[] = Utils.getSelectedItems(this.sortingFilterService.filter.scanDetail.filters.benchmark)
    const acceptedRiskSort = Utils.getSelectedItems(this.sortingFilterService.filter.scanDetail.filters.accepted_risk_sort);
    const deviceFailedSort = Utils.getSelectedItems(this.sortingFilterService.filter.scanDetail.filters.device_failed_sort);
    finalPath = END_POINTS.SCAN_SUMMARY_DETAIL;
    req.scan_uuid = scan_uuid;
    req.pages = pageNum;
    req.content_number = numberOfResults;
    req.filters = {
      parameters: parameters.length ? parameters.toString(): '',
      accepted_risk_sort:acceptedRiskSort.length ? acceptedRiskSort.toString(): '',
      device_failed_sort:deviceFailedSort.length ? deviceFailedSort.toString(): '',
      severity:severity.length ? severity: [],
      benchmark:benchmark.length ? benchmark: [],  
    }
    if(searchTerm) {
      req.search = searchTerm;
    }
    return this.restService.postApi(finalPath, req).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  public getBenchMarkAndVulnerabilityList(device_id: any,callback: any) {
    const benchmarkAPI = END_POINTS.BENCHMARK_LIST + "?device_id=" + device_id
    this.restService.getApi(benchmarkAPI).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
  }
  public prepareDataForGlobalFilter(globalFilter: ITreeView, reqObj: any = {}) {
    reqObj.id = globalFilter.id;
    reqObj.vendor = [];
    globalFilter?.data?.forEach((vendorObj: ITreeView) => {
      const currentVendorObj: any = {};
      currentVendorObj.id = vendorObj.id;
      currentVendorObj.device = [];
      vendorObj?.data?.forEach((deviceObj: ITreeView) => {
        const currentDeviceObj: any = {};
        currentDeviceObj.id = deviceObj.id;
        currentDeviceObj.hostname = deviceObj.label;
        currentVendorObj.device.push(currentDeviceObj);
      })
      reqObj.vendor.push(currentVendorObj);
    })
    return reqObj;
  }

  public hasPermission(roleName: string): boolean {
    return !!this.authService.isRoleAccess(roleName);
  }

  public filterCompletedScan(data: any) {
    this.loggedUser = this.profileService.getUserDetails() || {};
    const inProgress = data?.filter((item: any) => item.status === 3 || item.status === 4 || item.status === 5 || item.status==6);
    inProgress.forEach(((item: any) => {
      const menu = [];
      
      if(item.hasOwnProperty('user')) {
        if((item.user === this.loggedUser.organization_email || this.loggedUser.is_admin) && item.rescan && item.status!==4){
          menu.push({label: 'Re-Scan', data: 'rescan'});
          
        }
        if(item.user === this.loggedUser.organization_email || this.loggedUser.is_admin){
          menu.push({label: 'Rename', data: 'rename'});
          if(item.status==3 ||item.status == 6||item.status === 4 ){
            menu.push({label: 'Scan Information', data: 'status'});
          }
          if(item.schedule_status === "scheduled" && this.hasPermission(this.permissionProps.SCHEDULE) ) {
            menu.push({label: 'Change Schedule', data: 'changeSchedule'})
            menu.push({label: 'Cancel Schedule', data: 'cancelSchedule'})
            
          }
          if(item.schedule_status === "schedulable" && this.hasPermission(this.permissionProps.SCHEDULE) ){
            menu.push({label: 'Schedule Scan', data: 'createSchedule'})
            
          }
        }
        
        item.menu = menu;
      } else {

        if((this.loggedUser.is_admin) && item.rescan){
          menu.push({label: 'Re-Scan', data: 'rescan'});
          menu.push({label: 'Change Schedule', data: 'changeSchedule'})
          menu.push({label: 'Cancel Schedule', data: 'cancelSchedule'})
        }
        if( this.loggedUser.is_admin){
          menu.push({label: 'Rename', data: 'rename'});
          if(item.status) {
            menu.push({label: 'Scan Information', data: 'status'});
            menu.push({label: 'Change Schedule', data: 'changeSchedule'})
            menu.push({label: 'Cancel Schedule', data: 'cancelSchedule'})
          }
        }
        item.menu = menu;
      }
    }))
    return inProgress;
  }
  public filterInProgressScan(data: any) {
    const scanCompleted = data?.filter((item: any) => item.status === 1 || item.status === 2);
    scanCompleted?.forEach(((item: any) => {
      const menu = [];
      if(item.hasOwnProperty('user')) {
        if(item.user === this.loggedUser.organization_email || this.loggedUser.is_admin){
          menu.push({label: 'Rename', data: 'rename'});
        }
        if(item.user === this.loggedUser.organization_email || this.loggedUser.is_admin){
          menu.push({label: 'Cancel', data: 'cancel'});
        }
        item.menu = menu;
      } else {
        if(this.loggedUser.is_admin){
          menu.push({label: 'Rename', data: 'rename'});
        }
        if( this.loggedUser.is_admin){
          menu.push({label: 'Cancel', data: 'cancel'});
        }
        item.menu = menu;
      }
      
    }))
    return scanCompleted;
  }
  public scrollTop(element: string) {
    const el: any = document.getElementById(element);
    el ? el.scrollTop = 0 : null;
  }
  setMyScans(myScansDetails:any = {
    myScan: [],
    fromBack:false,
    position: 0,
    currentRow:{}
  }
    ) {
    this.myScansDetails.myScan = myScansDetails?.myScan;
    this.myScansDetails.fromBack = myScansDetails?.fromBack;
    this.myScansDetails.position = myScansDetails?.position;
    this.myScansDetails.currentRow = myScansDetails?.currentRow;
  }
  getMyScans() {
    return this.myScansDetails;
  }
  public myscansScheduleFilter(callback?: any) {
    this.restService.getApi(END_POINTS.MYSCANS_SCHEDULE_FILTER).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
}
