<div class="ag-center">
    <div *ngIf=" params.data.schedule_status=='scheduled'" class="calender-icon">
                <ng-template #customTooltip>
                  <div class="schedule-tooltip">
                    <p class="tooltip-header">Schedule Information</p>
                    <div class="info-list">
                      <div class="tooltip-label" *ngIf=" params.data.schedule.frequency_name ">
                        <span>Frequency:</span>
                        <span class="tooltip-value">{{ params.data.schedule.frequency_name }}</span>
                      </div>
                      <div *ngIf="params.data.schedule.values.day && params.data.schedule.frequency_name == 'Weekly'">
                        <div class="tooltip-label">
                          <span>On Day:</span>
                          <span class="tooltip-value">{{ params.data.schedule.values.day }}</span>
                        </div>
                      </div>
                      <div *ngIf="params.data.schedule.values.date && params.data.schedule.frequency_name !== 'Weekly'">
                        <div class="tooltip-label">
                          <span>On date:</span>
                          <span class="tooltip-value">{{ params.data.schedule.values.date }}</span>
                        </div>
                      </div>
                      <div class="tooltip-label" *ngIf="params.data.schedule.values.time">
                        <span>At time:</span>
                        <span class="tooltip-value">{{ params.data.schedule.values.time }}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
  <img class="mr-right" [appCustomTooltip]="customTooltip" src="../../../../../assets/images/calender.png" />

  <!-- <span class="text_ellipsis" appSafariEllipsis matTooltip="{{ params.value }} ">
    {{ params.value }}
  </span> -->
  <p matTooltip="{{params?.data?.date_time}}" class="text_ellipsis paramer-value">{{params?.data?.date_time}}</p>
</div>
    <div *ngIf=" params.data.schedule_status!=='scheduled'" class="calender-icon">
      <img class="mr-right" *ngIf=" params.data.schedule_status=='schedulable'"
            src="../../../../../assets/images/calender-blue.png" />
        <!-- <span class="text_ellipsis scantime-calender"appSafariEllipsis  matTooltip=" {{ params.value | date: 'd MMM, y HH:mm:ss'}} ">
          {{params.value | date: 'd MMM, y HH:mm:ss'}}
        </span> -->
        <p matTooltip=" {{ params?.data?.date_time | date: 'd MMM, y HH:mm:ss'}}" class="text_ellipsis scantime-calender paramer-value"> {{params?.data?.date_time | date: 'd MMM, y HH:mm:ss'}}</p>
      </div>
</div>

