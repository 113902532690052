<div class="ag-center">
    <div class="complaint-assets"> 
      <p class="doc-persentage"> {{params.data.passed_count}}/{{params.data.count}} ({{params.data.passd_percentage+'%'}})
      </p>
      <span class="doc-persentage-value"> <span class="gray-bg scale gray-width "></span>
        <span class="green-bg scale" [ngStyle]="{'width': params.data.passd_percentage+'%'}"></span></span>
    </div>
  </div>

  <!-- [appCustomTooltip]="tooltipTemplate" -->

  <!-- <ng-template #tooltipTemplate>
    <div class="tooltip-content">
      <div class="tooltip-item" *ngFor="let item of params.data.compliance_details">
        <img class="icon" [src]="iconMap[item.label] || defaultIcon"/>
        <span class="label">{{ item.label }}</span>
        <span class="value">{{ item.value }}</span>
      </div>
    </div>
  </ng-template> -->
  