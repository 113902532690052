<div class="filter-header" *ngIf="isHeaderHide">
  <mat-toolbar class="header-tool">
    <div class="header-tool-content">
      <span class="header-text">{{title}}</span>
      <div class="page_header">
        <div class="page_header_right">
          <button class="btn-blue btn" [disabled]="!selectedData.length" *ngIf="isFirstLevelFetchBtn"
            (click)="emitFirstLevelSelectedData()">{{fetchBtnlebel}}</button>
            <button class="btn btn-plain" *ngIf="clearButtonRequired" (click)="clearAllFilters()">Clear</button>
            <button class="btn btn-plain" *ngIf="resetButtonRequired" (click)="resetAllFilters()">Reset</button>
            <button [options]="btnToolTipConfig"  [tooltip]="enabledDisaled() ? toolTipMsg: ''" class="btn-primary btn" *ngIf="saveButtonRequired" [disabled]="enabledDisaled()"
            (click)="saveNode()">{{saveBtnLabel}}
          </button>
            <div class="close-btn">
              <button mat-button class="btn close-sidebar" *ngIf="showCloseBtn" (click)="closeFilter()" [mat-dialog-close]="true"><mat-icon>cancel</mat-icon></button>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>
<mat-divider *ngIf="isDividerHide"></mat-divider>
<div [cdkContextMenuTriggerFor]="menuBar" class="filter-section"
  [ngClass]="{'has-second-level': isSecondLevel || isSingleLevel}">
  <div class="search-bar">
    <mat-form-field appearance="fill" class="full-width g-search-bar">
      <input matInput [placeholder]="placeholder" [(ngModel)]="searchText" autocomplete="off" #searchField type="text"
        (input)="filter(searchField.value)">
      <mat-icon class='search-icon srch-icon' matPrefix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="sub-container">
    <div *ngFor="let data of filterColumnData| keyvalue; let last=last" class="filter-row  scroll">
      <app-tree #data.key [type]="type" [ngClass]="{'vertical-divider' : !last}"
      [isGroupLevel]="isGroupLevel"  (filterValues)="getFilterValues($event)" (updatePartialInfo)="firstLevelUpdatePartialInfo($event)" [filterData]="data.value"></app-tree>
    </div>
    <div class="filter-row scroll" *ngIf="filterSecondLevelData.length && isSecondLevel">
      <app-tree [type]="type" (filterValues)="secondLevelFilterValues($event)"
        [filterData]="filterSecondLevelData" (updatePartialInfo)="secondLevelUpdatePartialInfo($event)"></app-tree>
    </div>
    <div class="filter-row scroll" *ngIf="isSecondLevelApiCalled && isSingleLevel">
      <div class="parent-check" *ngIf="filterSecondLevelData.length">
        <mat-checkbox [(ngModel)]="allListSelected" [ngModelOptions]="{standalone: true}"  [ngClass]="{'disabled': (filterSecondLevelData | searchSingle: searchText).length ===0}" (change)="toggleAllSelection()"></mat-checkbox> All Assets
      </div>
      <div class="child-check">
        
        <div *ngFor="let secondObj of filterSecondLevelData | searchSingle : searchText"  class="sub-check-fields">
        <div class="assets-name-section">
        <mat-checkbox
        (change)="toggleSingleCheckBox(secondObj)"
        [(ngModel)]="secondObj.selected"
        [checked]="secondObj.selected"></mat-checkbox>
          <div>
            <div class="name-labl text_ellipsis" appSafariEllipsis [options]="toolTipOption"   [tooltip]="secondObj.assetName" [innerHTML]="secondObj.assetName | Highlight : searchText"></div> 
            <span  [innerHTML]="secondObj.vendorName + '-' + secondObj.assetType  | Highlight : searchText" class="vendor-name text_ellipsis" [options]="toolTipOption"  [tooltip]="secondObj.vendorName +  ' - ' +secondObj.assetType ">
             
            </span>
          </div>
        </div>
        <div [innerHTML]="secondObj.region_name | Highlight : searchText"  class="region-name text_ellipsis" appSafariEllipsis [options]="toolTipOption"  [tooltip]="secondObj.region_name" ></div>
      </div>
        <div *ngIf=" searchText.length>0 && ( filterSecondLevelData | searchSingle: searchText).length === 0  " class="no-single-level dark-image-sec">
          <img src="../../assets/images/no-single-level-msg.svg" alt="" />
          <img class="dark" src="../../assets/images/no-single-level-msg-dark.png" alt="" />
          <div class="no-single-level-text">
            <p>No assets matched the selection.</p> 
            <p>Please try a different selection.</p>
          </div>
        </div>
        <div *ngIf=" searchText.length === 0 && ( filterSecondLevelData | searchSingle: searchText).length === 0  " class="no-single-level dark-image-sec">
          <img src="../../assets/images/no-single-level-msg.svg" alt="" />
          <img class="dark" src="../../assets/images/no-single-level-msg-dark.png" alt="" />
          <div class="no-single-level-text">
            <p>No assets matched the selection.</p> 
            <p>Please try a different selection.</p>
          </div>
        </div>
      </div>
    </div>
    <div  *ngIf="!isSecondLevelApiCalled && (isSecondLevel || isSingleLevel)" class="item filter-row" >
      <ngx-skeleton-loader class="rhs-content-loader checkbox-box-loader" *ngFor="let row of [].constructor(12)" count="2"></ngx-skeleton-loader>
    </div>
  </div>
</div>
<ng-template #menuBar>
  <div class="conext-menu" cdkMenu  *ngIf="isRightClickMenuHide">
    <button class="conext-menu-item" cdkMenuItem *ngIf="saveButtonRequired" [disabled]="enabledDisaled()"
      (click)="saveNode()">{{saveBtnLabel}}</button>
    <button class="conext-menu-item" cdkMenuItem *ngIf="resetButtonRequired" (click)="resetAllFilters()">Reset</button>
    <button class="conext-menu-item" cdkMenuItem *ngIf="clearButtonRequired" (click)="clearAllFilters()">Clear</button>
  </div>
</ng-template>
