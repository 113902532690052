import { Component, ElementRef, ViewChild } from '@angular/core';
import { CONFIG } from '../../shared/constants/config';
import { ColDef } from 'ag-grid-community';
import { CommonTooltipComponent } from '../../shared/components/common-tooltip/common-tooltip.component';
import { DateTimeComponent } from '../../my-scans/date-time/date-time.component';
import { StatusComponent } from '../../my-scans/status/status.component';
import { AssetsComponent } from '../../my-scans/assets/assets.component';
import { SecurityScoreComponent } from '../../my-scans/security-score/security-score.component';
import { VulnerabilitiesComponent } from '../../my-scans/vulnerabilities/vulnerabilities.component';
import { MyscanOptionsComponent } from '../../my-scans/myscan-options/myscan-options.component';
import { IFilterFlags, ImyScan, ImyScanResponse } from '../../shared/model/scan/scan';
import { CONSTANT, ROLES_CONTENT_PROPS, ToolTipConfig } from '../../shared/constants/constant-data';
import { AuthService } from '../../shared/services/auth.service';
import { AgGridComponent } from '../../shared/components/ag-grid/ag-grid.component';
import { CloudscanOPtionsComponent } from './childcomponent/cloudscan-options/cloudscan-options.component';
import { IToastrOptions } from '../../shared/model/IMessage.model';
import { CloudSecurityService } from '../Services/cloud-security.service';
import { MessageServiceService } from '../../shared/services/message-service.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { MyScansService } from '../../shared/services/my-scans.service';
import { DialogService } from '../../shared/services/dialog.service';
import { ScheduleDialogComponent } from '../../shared/components/schedule-dialog/schedule-dialog.component';
import { IDialog } from '../../shared/model/Dialog.model';
import { ScheduleScanService } from '../../shared/services/schedule-scan.service';
import * as _ from 'lodash';
import { SharedService } from '../../shared/services/shared.service';
import { IUserDetail } from '../../shared/model/profile/profile';
import { ProfileService } from '../../shared/services/profile.service';
import * as moment from 'moment';
import { IStatusItem } from '../../my-scans/my-scans.model';
import { Router } from '@angular/router';
import { END_POINTS } from '../../shared/constants/endpoints';
import { ISortingFilterObj } from '../../shared/services/sorting-filter.service';
import { MatSort } from '@angular/material/sort';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { HeaderService } from '../../shared/services/header.service';

@Component({
  selector: 'app-cloudscans',
  templateUrl: './cloudscans.component.html',
  styleUrls: ['./cloudscans.component.scss']
})
export class CloudscansComponent {
   @ViewChild('searchField') searchField!: ElementRef;
     public displayedColumns: string[] =
     ['scannumber', 'scanname', 'DateTime','status', 'numberofdevices', 'securityrating', 'vulnerabilities', 'user','action'];
     public scanDetails = new MatTableDataSource<ImyScan>([]);;
     public clickedRows: ImyScan = {};
     public data: any;
     public initialTableData:any;
     existingScanNames: {[name: string]: string[]} = {};
     public scanDetailsProgress = new MatTableDataSource<ImyScan>([]);
     public scanDetailsCompleted = new MatTableDataSource<ImyScan>([]);
     public allScansBackup: ImyScan[] = [];
     public allScans = new MatTableDataSource<ImyScan>([]);
     public arrowDownOne = true;
     public arrowDownTwo = true;
     public displayColor: boolean = false;
     public statusFilter: IStatusItem[] = [];
     public disableTooltip = false;
     public timer: any;
     public page: number = 1;
     public totalPage: number = 1;
     public numberOfRowInResult: number = Math.ceil((window.innerHeight - 170) / 39) + 4;
     public userData:any;
     public selectedFilter:  any = {
       scanTime : [],
       assignedScan: []
     }
     filterApplyFlags: IFilterFlags = {"scanTime":false, "assignedScan": false };
     isSafari !:boolean
     public filterApplied:boolean = false;
     filterMenuOpen = false;
     toggleState: boolean = false;
     islastScanTimeApplied :boolean = false;
     rotateIcon: boolean = false;
     minimizeTableHeight: number = 0;
     maxmizeTableHeight: number = 0;
     highlightID:boolean=false;
     intervalRequest: ReturnType<typeof setInterval> | undefined;
     filterData: any;
     successChecked = false;
     failedChecked = false;
     canceledChecked = false;
    // glbSearch: Subscription;
     @ViewChild(MatMenuTrigger, { static: false })
     menuTrigger!: MatMenuTrigger;
     isApiCallDone: boolean = true;
     noDataMsg: String = CONSTANT.NO_DATA_MSG;
     isloaderEligible: boolean = true;
     @ViewChild(MatSort) sort!: MatSort;
     benchmarkItems: ISortingFilterObj[] =[];
     initialItems: ISortingFilterObj[] =[];
     benchmarkSelectedList:string[]=[];
     public openedMenuId: string | null = null;
     public is_clicked: boolean = true;
     public selectedScans:any = [];
     public intialSelectedScans: any = [];
     public scanFilterdApplied: boolean = false;
     public toolTipOption = ToolTipConfig;
     public currentUserId: string = ''; 
     public userApi = END_POINTS.SCAN_USER_LIST;
     @ViewChild('myGrid') grid!: AgGridComponent;
     isAgentsAvailable: boolean = false;
 
  constructor(private service:MyScansService,private spinnerService: SpinnerService, public router: Router,
    public dialogservice: DialogService,private messageService: MessageServiceService,
    private profileService: ProfileService,
    private headerService: HeaderService,
    public cloudService:CloudSecurityService,public authService:AuthService, private scheduleService:ScheduleScanService,private sharedService:SharedService,){
      this.headerService.setHeading('Cloud Scans', { type: 'cloudScans' });

  }
 
  ngOnInit(): void{
    const isNewUserDetails = this.authService.getCookie('isCloudNewUserDetails') === 'true'
    this.isAgentsAvailable = !isNewUserDetails
    if(!isNewUserDetails){
      this.getScanDetail();
    }
  }
  public tableHeader = CONFIG.TABLE_INFO.CLOUD_MY_SCANS.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.CLOUD_MY_SCANS.PROPS;
  public agScanColumnDefs: ColDef[] | any[]= [
    {
      headerName: this.tableHeader.SERIAL_NO,
      field:this.tableProps.SERIAL_NO,
      headerTooltip:this.tableHeader.SERIAL_NO,
      filter: false,
      lockPosition: 'left',
      sortable:false,
      cellRenderer: CommonTooltipComponent,
      maxWidth: 100,
      width:100,
      pinned:'left'
    },
    {
      headerName: this.tableHeader.SCAN_NAME,
      field:this.tableProps.SCAN_NAME,
      headerTooltip:this.tableHeader.SCAN_NAME,
      cellRenderer: CommonTooltipComponent,
      lockPosition: false,
      minWidth: 150,
      maxWidth: 300,
      filter: false,
      pinned:'left'
    },
    {
      headerName: this.tableHeader.SCAN_TIME,
      field:this.tableProps.SCAN_TIME,
      headerTooltip:this.tableHeader.SCAN_TIME,
      cellRenderer:DateTimeComponent,
      sortable: true,
      minWidth: 150,
      maxWidth: 250,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        const dateA = nodeA.data.date_time ? new Date(nodeA.data.date_time) : null;
        const dateB = nodeB.data.date_time ? new Date(nodeB.data.date_time) : null;
        
        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return -1;
        if (dateB === null) return 1;
    
        return dateA.getTime() - dateB.getTime();
      },
    },
    {
      headerName: this.tableHeader.STATUS,
      field:this.tableProps.STATUS,
      headerTooltip:this.tableHeader.STATUS,
      filter:true,
      sortable: false,
      cellRenderer:StatusComponent,
      minWidth: 150,
      maxWidth: 300
      
      
    },

    {
      headerName: this.tableHeader.ASSETS,
      field: this.tableProps.ASSETS,
      headerTooltip:this.tableHeader.ASSETS,
      minWidth: 150,
      maxWidth: 300,
      suppressColumnsToolPanel: true,
      cellRenderer:AssetsComponent
    },
    {
      headerName: this.tableHeader.SECURITY_SCORE,
      headerTooltip: this.tableHeader.SECURITY_SCORE,
      field: this.tableProps.SECURITY_SCORE,
      cellRenderer:SecurityScoreComponent,
      width: 180,
      maxWidth:380,
      minWidth: 150
    },
    {
      headerName: this.tableHeader.VULNERABILITIES,
      field: this.tableProps.VULNERABILITIES,
      headerTooltip: this.tableHeader.VULNERABILITIES,
      // tooltipField:this.tableProps.VULNERABILITIES,
      cellRenderer:VulnerabilitiesComponent,
      filter: "agMultiColumnFilter",
      minWidth: 160,
      maxWidth: 300
    },
    {
      headerName: this.tableHeader.USER,
      field: this.tableProps.USER,
      cellRenderer: CommonTooltipComponent,
      headerTooltip: this.tableHeader.USER,
      filter: "agMultiColumnFilter",
      minWidth: 250,
      maxWidth: 400
    },
    {
      headerName: this.tableHeader.BLANK,
      headerTooltip:this.tableHeader.BLANK,
      field: this.tableProps.OPTIONS,
      cellRenderer:CloudscanOPtionsComponent,
      suppressColumnsToolPanel: true,
      lockPosition: 'right',
      minWidth: 60,
      maxWidth: 60,
      filter: false,
      sortable: false,
      pinned: 'right'
    }
  ];
  validateScanStatus(status: number): boolean{
    if(status === 3){
      return true;
    }
    const snakMsg: IToastrOptions = {
      message: "Oops! The selected scan has no scanned details!"
    }
    this.messageService.showWarning(snakMsg);
    return false;
  }
    public getScanList(row: ImyScan): void {
      if( !this.validateScanStatus(Number(row.status))){
        return;
      }
      const allScans: any = this.allScans.data;
      this.headerService.setHeading(row['scanname'] || '',  {dropdown:allScans, type: 'scanDetail', subType:'cloud_scan'});
      this.clickedRows = row;
      const element = document.getElementById('table2');
      this.service.setMyScans({ myScan: allScans,  fromBack: false, position: element?.scrollTop || 0,currentRow:this.clickedRows});
      this.router.navigate(['/cloud/scans/scans-details', this.clickedRows.scan_uuid, 'status',row.status]);
    }

     get permissionProps() {
        return ROLES_CONTENT_PROPS;
      }

      public hasPermission(roleName: string): boolean {
        return !!this.authService.isRoleAccess(roleName);
      }

      public onToggleChange(){
        console.log(this.toggleState)
        // this.headerService.setHeading('My Scans', {type: 'myscans'});
        this.router.navigate(['on-premises/schedule-scan','CloudScanParentPage'])
      }

      filterAssetsField() {
        this.grid.grid.api.setGridOption(
          "quickFilterText",
           this.searchField?.nativeElement?.value
        );
      }
      scheduleData:any
       public openScheduleDialog(scanelement: any,isNewschedule:boolean=false){
          //
          // scanelement['scheduleData']={
          //   "frequency": "e5d79636-34ac-4602-bd4e-340742375383",
          //   "time_to_schedule": "12:03 AM",
          //   "start_from": "2024-06-23T18:30:00.000Z",
          //   "timzone": "Asia/Calcutta"
          // }
          //this.scheduleData=_.cloneDeep(scanelement)
         
          const scanInfo=_.cloneDeep(scanelement)
          // if(scanelement){
          //   scanelement['schedule_scan_id']= scanelement.schedule_id;
          //   scanelement['schedule_scan_id']= '58bef87e-34d9-4a8e-8432-0974f5875848';

          // }
          
          let isMyscansNewschedule:boolean=false
          if(isNewschedule){
            scanelement =null
            isMyscansNewschedule = true
          } else {
             
          }
          const payload: IDialog = {
            component:ScheduleDialogComponent,
            width: '360px',
            data: { 
              isNewschedule:false,
              isMyscansNewschedule:true,
              scheduleData:scanelement,
              isCloudScan:true,
              setCallbackFunction: (radioData?:any,isSave:boolean=false,req:any=null,radioFlag:boolean=false) => {
                this.spinnerService.hide()
                let scan_id=null
                let schedule_scan_id;
                if(req!=null && this.scheduleData.schedule_status=='schedulable'){
                  scan_id=this.scheduleData.scan_uuid;
                } else if(req && this.scheduleData.schedule_status=='scheduled') {
                  schedule_scan_id=this.scheduleData.schedule_id;
                }
                if(req) {
                  //this.sharedService.setAPIData(CONFIG.PAGE_API.MY_SCANS, null, false);
                  this.spinnerService.show();
                  this.scheduleService.scheduleChangeMethod(this.saveScheduleCallback,req,scan_id,scanelement.schedule_id);
                }
                //this.onReScanCall(result,scanelement);
              }
            },
          }
         this.dialogservice.openDialog(payload);
      
        }
      
        saveScheduleCallback=(response:any)=>{
          
          if (response.status === 200) {
           
           const snakMsg: IToastrOptions = {
             message: response.message
           }
           
           this.messageService.showSuccess(snakMsg);
           this.isApiCallDone = false;
      
           this.page =1;
           this.sharedService.setAPIData(CONFIG.PAGE_API.MY_SCANS, {}, false);
           this.getScanDetail();
           
          }
             
         

        }

        openCancelSchedule(element:any) {
          const pathParams = {
            'schedule_id':element.schedule_id
          }
          this.scheduleData=element;
          this.dialogservice.openDialog(
            {
              width: '360px',
              title: `Are you sure you want to cancel this "${element.scanname}"?`,
              content: 'If you cancel, you will not be able to find the schedule again.',
              class: 'free-delete-popup',
              buttons: [
              {
                label: 'No',
                class: 'btn-lightblack-outline',
                callBack: () => {
                }
              },
              {
                label: 'Yes',
                class: 'btn-primary',
                callBack: () => {
                  this.spinnerService.show();
                  //this.sharedService.setAPIData(CONFIG.PAGE_API.MY_SCANS, null, false);
                  this.service.cancelSchedule(pathParams,this.cancelScheduleCallBack,true)
                  //this.deleteDeviceRow(selectedDevice);
                }
              }
              ],
              disableClose: true
            }
          );
        }

        public cancelScheduleCallBack =  (res: any) =>{
          this.spinnerService.hide();
          console.log(res);
          if(res.status === 200){
            const snakMsg: IToastrOptions = {
              message: res.message
            }
            this.messageService.showSuccess(snakMsg);
            // this.isApiCallDone = false;
                // this.allScans.data = [];
                // this.page = 1;
            this.sharedService.setAPIData(CONFIG.PAGE_API.CLOUD_SCANS, {}, false);
            // this.getScanDetail();
          }
        }
        loggedUser: IUserDetail = {};
        public filterCompletedScan(data: any) {
          this.loggedUser = this.profileService.getUserDetails() || {};
          const inProgress = data?.filter((item: any) => item.status === 3 || item.status === 4 || item.status === 5 || item.status==6 || item.status === 7 || item.status === 8 || item.status === 9 || item.status === 10);
          inProgress.forEach(((item: any) => {
            const menu = [];
            
            if(item.hasOwnProperty('user')) {
              // if((item.user === this.loggedUser.organization_email || this.loggedUser.is_admin) && item.rescan && item.status!==4){
              //   menu.push({label: 'Re-Scan', data: 'rescan'});
                
              // }
              if(item.user === this.loggedUser.organization_email || this.loggedUser.is_admin){
                //menu.push({label: 'Rename', data: 'rename'});
                // if(item.status==3 ||item.status == 6||item.status === 4 || item.status === 9 ){
                //   menu.push({label: 'Scan Information', data: 'status'});
                // }
                if(item.schedule_status === "scheduled" && this.hasPermission(this.permissionProps.SCHEDULE) ) {
                  menu.push({label: 'Change Schedule', data: 'changeSchedule'})
                  // menu.push({label: 'Cancel Schedule', data: 'cancelSchedule'})
                  
                }
                if(item.schedule_status === "schedulable" && this.hasPermission(this.permissionProps.SCHEDULE) ){
                  menu.push({label: 'Schedule Scan', data: 'createSchedule'})
                  
                }
              }
              
              item.menu = menu;
            } else {
      
              if((this.loggedUser.is_admin) && item.rescan){
                menu.push({label: 'Re-Scan', data: 'rescan'});
                menu.push({label: 'Change Schedule', data: 'changeSchedule'})
                // menu.push({label: 'Cancel Schedule', data: 'cancelSchedule'})
              }
              if( this.loggedUser.is_admin){
                // menu.push({label: 'Rename', data: 'rename'});
                if(item.status) {
                  // menu.push({label: 'Scan Information', data: 'status'});
                  menu.push({label: 'Change Schedule', data: 'changeSchedule'})
                  // menu.push({label: 'Cancel Schedule', data: 'cancelSchedule'})
                }
              }
              item.menu = menu;
            }
          }))
          return inProgress;
        }

        getSecurityClass(rating:any){
          if(rating<50){
            return "Failed"
          }
          else if (rating >= 50 && rating <80){
            return "Cancelled"
          }
          else if (rating >= 80){
            return "Success"
          }
      
        }
      

          getScanStatusClass(value: number): string {
            const arr = ['', 'in-queue','in-pgr', 'low', 'critical', 'cancle', 'partial', 'in-pgr','in-pgr', 'critical', 'cancle','in-queue'];
            return arr[value];
          }
          getUpdatedStatus(value: number) {
            const arr = ['', 'In Queue', 'In-Progress', 'Success', 'Failed', 'Cancelled', 'Partially Success', 'Fetching Configuration', 'In-Progress', 'Failed', 'Cancelled', 'Renaming...'];
            return arr[value || 0];
          }
        dataMassging(data:any){
          console.log(data);
          data.forEach((element: any, index: number) => {
            // Set position as row index
            element.position = index + 1; // +1 if you want it to start from 1 instead of 0
        
            if (element.status === 5) {
                element.securityrating = 0;
            }
            element.securityrating_rounded = element.securityrating ? Math.floor(element.securityrating) : element.securityrating;
            const formattedDate = element.date_time ? moment(element.date_time).format('D MMM, y HH:mm:ss') : '';
            element.date_time = formattedDate;
            element.securityRatingClass = this.getSecurityClass(element.securityrating_rounded);
            element.statusName = this.getUpdatedStatus(element.status);
            element.statusClass = this.getScanStatusClass(element.status);
        });
        
        // Sort the data by date_time in descending order
        data.sort((a: any, b: any) => {
            const dateA = a.date_time ? new Date(a.date_time).getTime() : 0;
            const dateB = b.date_time ? new Date(b.date_time).getTime() : 0;
            return dateB - dateA;
        });
        
        // Re-increment the position column after sorting
        data.forEach((element: any, index: number) => {
            element.position = index + 1; // Update position based on the new order
        });

      console.log(data);
        
        }
        isApiResponse:boolean=false;
        public getScanDetail(): void {
          //this.spinnerService.show();
          this.isApiCallDone = false;
          //this.data=[];
          // this.numberOfRowInResult = Math.ceil((window.innerHeight - 170) / 39) + 4;
          this.isApiResponse=false;
          const data =  this.sharedService.getAPIData(CONFIG.PAGE_API.CLOUD_SCANS);
          //data && data.isDataAvailable once socket implemented
          if(data && data.isDataAvailable) {
            this.spinnerService.hide();
            this.scanDetailsCallback(data.response)
            // const timer = setTimeout(()=>{
            //   this.scanDetailsCallback(data.response)
            //   clearTimeout(timer);
            // })
          } else {
            this.service.getScanDetails(this.scanDetailsCallback, this.page, this.numberOfRowInResult, this.userData, this.searchField?.nativeElement?.value,this.selectedScans,END_POINTS.CLOUD_SCANS);
             //this.service.getScanDetails(this.scanDetailsCallback,  this.searchField?.nativeElement?.value,);
             this.data = this.filterCompletedScan(this.data)
             this.dataMassging(this.data);
          }
          // const selectedFilters: any = this.statusFilter?.filter((filter: IStatusItem) => {
          //   return filter.selected;
          // })
          // if(selectedFilters?.length>0){
          // this.applyStatusFilter();
          // }
        }
        
         public scanDetailsCallback =  (res: any) => {
            this.spinnerService.hide();
            this.isApiCallDone = true;
            this.data = res.data;
            this.dataMassging(this.data);
             
            // this.data = res.data;
            
            
          //mock data ends here
        
            this.statusFilter=[
              {
                selected: false,
                activated: false,
                name: 'Success'
              },
              {
                selected: false,
                activated: false,
                name: 'Failed'
              },
              {
                selected: false,
                activated: false,
                name: 'Cancelled'
              }];
            //this.totalPage = this.data.slice(-1)[0].num_of_pages;
            const scanProgress = this.service.filterInProgressScan(this.data,'cloudScans');
            const scanCompleted = this.service.filterCompletedScan(this.data,'cloudScans');
            let allScans = scanProgress.concat(scanCompleted);
            this.initialTableData = this.service.filterCompletedScan(this.data,'cloudScans');
            this.scanDetailsProgress = new MatTableDataSource(scanProgress);
            this.arrowDownOne = scanProgress.length > 0;
            
            this.allScans.data = allScans;
            //this.removeDuplicatesByScanname();
            this.allScans = new MatTableDataSource(allScans);
           this.allScans.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'DateTime':
              // Check if item.date_time exists and is a string, otherwise return a default value
              return item.date_time ? new Date(item.date_time) : new Date(0);
            default:
              return item[property as keyof ImyScan];
          }
        };
            this.allScans.sort = this.sort;
            if(!this.allScansBackup.length) {
              this.allScansBackup = _.cloneDeep(allScans);
            }
            this.updateCompletedScans(allScans);
            this.dataMassging(allScans);
            // this.isEligibleForTimer(allScans);
            this.data.forEach((scan: any) => {
              const scanname = scan?.scanname?.toLowerCase();
              if (scanname && Object.keys(this.existingScanNames).some(key => key.toLowerCase() === scanname)){
                const existingKey = Object.keys(this.existingScanNames).find(key => key.toLowerCase() === scanname);
                if (existingKey) {
                  this.existingScanNames[existingKey].push(scan.id);
                }
              } else {
                this.existingScanNames[scanname] = [scan.id];
              }
            });
            const myScansDetails = this.service.getMyScans();
            if(res?.message==='cache') {
              res.message = '';
              let renderTimer;
              if(renderTimer) {
                clearTimeout(renderTimer);
              }
              renderTimer =  setTimeout(()=>{
                const element: any = document.getElementById('table2');
                if(element) {
                  element.scrollTop = myScansDetails.position;
                  this.service.setMyScans(myScansDetails);
                }
              }, 100); 
            }
            this.isApiResponse=true;
            this.sharedService.setAPIData(CONFIG.PAGE_API.CLOUD_SCANS, res, this.allScans?.data.length > 0);
          }

          public updateCompletedScans(data: ImyScan[]) {
              const existingNewScans = _.differenceBy(this.allScansBackup,data, 'isNewScan');
              const diff: ImyScan[] = [];
              const extractInprogress = this.allScansBackup.filter((obj: ImyScan)=> obj.status === 1 || obj.status === 2);
              extractInprogress.forEach((obj: ImyScan)=>{
                 data.forEach((newScanObj: ImyScan)=>{
                   const isCompletedScan = (newScanObj.scan_uuid === obj.scan_uuid && newScanObj.status == 3);
                   if(isCompletedScan){
                    diff.push(newScanObj);
                   }
                })
              })
              
              if(diff.length) {
                if(this.allScans.data.length) {
                  diff.forEach((obj: ImyScan)=>{
                    const currentCompletedScanIndex = data.findIndex(dataObj=> dataObj.scan_uuid === obj.scan_uuid);
                    if(currentCompletedScanIndex > -1) {
                      data.splice(currentCompletedScanIndex, 1);
                      obj.isNewScan = true;
                      data.unshift(obj);
                    }
                    this.allScans.data = data;
                    this.allScansBackup = data;
                    this.dataMassging(data);
                  })
                  this.service.scrollTop('table2');
                }
              } else {
                if(existingNewScans.length) {
                  existingNewScans.forEach((obj: ImyScan)=>{
                    const currentCompletedScanIndex = data.findIndex(dataObj=> dataObj.scan_uuid === obj.scan_uuid);
                    if(currentCompletedScanIndex > -1) {
                      data[currentCompletedScanIndex].isNewScan = true;
                    }
                    this.allScans.data = data;
                    this.allScansBackup = data;
                  })
                }
              }
            }
}
