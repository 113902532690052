import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CyberSecurityComponent } from './cyber-security/cyber-security.component';
import { CloudSecurityComponent } from './cyber-security/cloud-security/cloud-security.component';
import { CloudResourceComponent } from './cloud-resource/cloud-resource.component';
import { OnPremAgentComponent } from './on-prem-agent/on-prem-agent.component';
import { CloudscansComponent } from './cloudscans/cloudscans.component';

const routes: Routes = [
  { path: '', component: CyberSecurityComponent},
  {
    path: 'cloud-controls', component: CloudSecurityComponent
  },
  {
    path: 'cloud-resources', component: CloudResourceComponent
  },
  { path: 'on-prem-agent', component: OnPremAgentComponent},
  {
    path:'scans',component:CloudscansComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CyberSecurityRoutingModule { 
  
}
