<div class="filter-container">
<div class="main-filter">
  <!-- <button mat-button [matMenuTriggerFor]="cloudMenu">Select Clouds</button> -->
  <button mat-button (click)="toggleFilterPopup()">Select Providers</button>
  <!-- <mat-menu #cloudMenu="matMenu">
    <div class="menu-content">
      <div>
        <mat-checkbox *ngFor="let cloud of clouds"   [checked]="selectedClouds.includes(cloud)"
        (change)="onCheckboxCloudChange(cloud, $event)">{{ cloud }}</mat-checkbox>
      </div>
      
    </div>
  </mat-menu> -->
 <div class="fillter-resource">
    <div class="search-section" >
      <div class="serach-field">
        <mat-form-field appearance="outline">
          <mat-label>Search..</mat-label>
          <input
            matInput
            [(ngModel)]="vpcSearchTerm"
            placeholder="Search.."
            (input)="filterVpcs()"
          />
        </mat-form-field>
      </div>

      <button mat-raised-button class="submit-btn btn-primary" (click)="applyFilters()">
        Apply
      </button>
    </div>

    <div class="search-list scroll">
        <mat-checkbox *ngFor="let vpc of filteredVpcs"  [checked]="selectedVpcs.includes(vpc)"
        (change)="onCheckboxVpcChange(vpc, $event)">{{ vpc }}</mat-checkbox>
    </div>

  </div>
</div>

</div>
<div *ngIf="isFilterPopupOpen" class="ag-floating-filter">
  <div class="cloud-section">
    <mat-checkbox
      *ngFor="let cloud of clouds"
      [checked]="selectedClouds.includes(cloud)"
      (change)="onCheckboxCloudChange(cloud, $event)"
      >{{ cloud }}</mat-checkbox
    >
  </div>
</div>

