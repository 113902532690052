import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { CloudSecurityService } from '../Services/cloud-security.service';
import { CONFIG } from '../../shared/constants/config';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { IUpdateOverLayPerameter } from './cloud-resource.model';
import { CveAssetsService } from '../../shared/services/cve-assets.service';
import { FilterService } from '../../shared/services/filter.service';
import { CustomFilterComponent } from './custom-filter/custom-filter.component';
import { ResourceNameComponent } from './resource-name/resource-name.component';
import * as moment from 'moment';
import { VulnerabilityValComponent } from '../../admin/components/device-management/vulnerability-val/vulnerability-val.component';
import { ScoreComponent } from './score/score/score.component';
import { StatusComponent } from './status/status.component';
import { HeaderService } from '../../shared/services/header.service';


@Component({
  selector: 'app-cloud-resource',
  templateUrl: './cloud-resource.component.html',
  styleUrls: ['./cloud-resource.component.scss']
})
export class CloudResourceComponent {
  public dataSource = new MatTableDataSource<any>([]);
  //dataSource:any;
  public rowHeight = 48;
  public selectedRow: any[] = [];
  isApiCallDone = true;
  devicePopup: any;
   @ViewChild('searchField') searchField!: ElementRef;
   public tableHeader = CONFIG.TABLE_INFO.CLOUD_RESOURCE_LIST.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.CLOUD_RESOURCE_LIST.PROPS;
  public isPreviousResource: boolean = false;
  colDefs: ColDef[] = [
      {
        headerName: this.tableHeader.SERIAL_NO,
        field: this.tableProps.SERIAL_NO,
        sortable: false,
        filter: false,
        minWidth: 60,
        //cellRenderer: CommonTooltipComponent,
        maxWidth:70,
        lockPosition: true,
        pinned: 'left'
      },
      {
        headerName: this.tableHeader.RESOURCE_NAME,
        field: this.tableProps.RESOURCE_NAME,
        sortable: true,
        // filter: CustomFilterComponent,
        // filterParams: {  
        //    suppressAndOrCondition: true,
        //    cellClass:'abcd',
          
        //   },
          // floatingFilter: true,
        cellRenderer: ResourceNameComponent,
        minWidth: 250,
         maxWidth: 300,
        lockPosition: true,
        pinned: 'left',
      },
      {
        headerName: this.tableHeader.RESOURCE_TYPE,
        field: this.tableProps.RESOURCE_TYPE,
        headerTooltip: this.tableHeader.RESOURCE_TYPE,
        sortable: true,
        filter: true,
        lockPosition: true,
        pinned: 'left',
        minWidth: 150,
        maxWidth: 300
      },
      {
        headerName: this.tableHeader.ACCOUNT_ID,
        headerTooltip: this.tableHeader.ACCOUNT_ID,
        field: this.tableProps.ACCOUNT_ID,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
      {
        headerName: this.tableHeader.REGION,
        headerTooltip: this.tableHeader.REGION,
        field: this.tableProps.REGION,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
      {
        headerName: this.tableHeader.LAST_SCANNED,
        headerTooltip: this.tableHeader.LAST_SCANNED,
        field: this.tableProps.LAST_SCANNED,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
      {
        headerName: this.tableHeader.SCORE,
        headerTooltip: this.tableHeader.SCORE,
        field: this.tableProps.SCORE,
        cellRenderer: ScoreComponent,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
      {
        headerName: this.tableHeader.VULNERABILITIES,
        headerTooltip: this.tableHeader.VULNERABILITIES,
        field: this.tableProps.VULNERABILITIES,
        cellRenderer: VulnerabilityValComponent,
        sortable: true,
        filter: true,
        minWidth: 150,
      },
    ];
    previousResources = {
      headerName: this.tableHeader.STATUS,
      headerTooltip: this.tableHeader.STATUS,
      field: this.tableProps.STATUS,
      cellRenderer: StatusComponent,
      sortable: true,
      filter: true,
      minWidth: 150,
    }
  constructor(
     private cloudService:CloudSecurityService,
     private cveAssetsService: CveAssetsService,
         private headerService: HeaderService,
     private filterService: FilterService,
      private changeDetectorRefs: ChangeDetectorRef,) {
        this.headerService.setHeading('Cloud Resource', { type: 'cloudResource' });
  }
  ngOnInit(){
    this.loadDeviceList();
  }
  loadDeviceList() {
    this.isApiCallDone = false;
    this.cloudService.resourcesList(this.loadDeviceListCallback,{"type":1});
  }
  loadDeviceListCallback = (response: any) => {
    this.isApiCallDone=true;
    this.dataMassging(response.data.cloud_resource);
    this.dataSource = new MatTableDataSource<any>(response.data.cloud_resource);

  }

  dataMassging(data:any){
    data.forEach((element: any, index: number) => {
      // Set position as row index
      element.position = index + 1; // +1 if you want it to start from 1 instead of 0
      element.security_score_rounded = parseInt(element.score) ? Math.floor(element.score) : element.score;

      const formattedDate = element.last_scanned ? moment(element.last_scanned).format('D MMM, y') : '';
      element.last_scanned = formattedDate;
  });
  
  // Sort the data by date_time in descending order
  data.sort((a: any, b: any) => {
      const dateA = a.last_scanned ? new Date(a.last_scanned).getTime() : 0;
      const dateB = b.last_scanned ? new Date(b.last_scanned).getTime() : 0;
      return dateB - dateA;
  });
  
  // Re-increment the position column after sorting
  data.forEach((element: any, index: number) => {
      element.position = index + 1; // Update position based on the new order
  });
  
  }
  public openAssetsDetails() {
    this.filterService.toggleSidenav('cveAssetsDetails');
  }
  public onRowSelected(selectedRow: any) {
    this.selectedRow = selectedRow;
    const updateRowPerameter = new IUpdateOverLayPerameter(_.cloneDeep(selectedRow[0]), 'cls');
    console.log(updateRowPerameter);
    if(!this.isPreviousResource){
      delete updateRowPerameter.status; 
    }
    this.openAssetsDetails();
    this.cveAssetsService.dataForAssetsDetails.emit({isAssetDetails: true, selectedRow: updateRowPerameter, selectedIndex: 0, isDeviceManagementNavigation:false, isCveHide: true, moduleType: 'cloudResource'});
  }
  showPreviousResources(flag: boolean) {
    // this.headerService.dispatchDisableFilter(flag);
    this.isApiCallDone = false;
    this.dataSource.data = [];
    this.changeDetectorRefs.detectChanges();
    if (flag) {
      // this.noDataMsg = CONSTANT.NO_DECOMMISSION_DEVICE_MSG;
      this.searchField.nativeElement.value = '';
      this.colDefs = [...this.colDefs, this.previousResources];
      this.loadDeviceList();
    } else {
      // this.noDataMsg = CONSTANT.NO_ACTIVE_DEVICE_MSG;
      this.colDefs = this.colDefs.filter((col: any) => col.field !== 'status');
      this.loadDeviceList();
    }
  }
}
