import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { END_POINTS } from 'src/app/modules/shared/constants/endpoints';
import { HeaderService } from 'src/app/modules/shared/services/header.service';
import { IDeviceList, IDeviceListResponse } from '../device.model';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { DeviceService } from 'src/app/modules/shared/services/device.service';
import { ICyberDrop, ICyberResilience, IEventData, cyberConstant } from 'src/app/modules/shared/components/cyber-reilience/cyber-reilience.model';
import { IDeviceCyberResilience } from './device.model';
import { CONSTANT, ToolTipConfig } from 'src/app/modules/shared/constants/constant-data';
import { Utils } from 'src/app/modules/shared/utils';
import { DateAdapter } from '@angular/material/core';
import * as echarts from 'echarts/core';
import { diffLines } from 'diff';
import {
  DatasetComponent,
  DatasetComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  TransformComponent
} from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { RestService } from 'src/app/modules/shared/services/rest.service';
import { ResponseService } from 'src/app/modules/shared/services/response.service';
import * as _ from 'lodash';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { GlobalFilterService } from 'src/app/modules/shared/services/global-filter.service';
import { CyberReilienceComponent } from 'src/app/modules/shared/components/cyber-reilience/cyber-reilience.component';
import { ApiService } from 'src/app/modules/shared/services/api.service';
import { MonacoDiffEditorConstructionOptions, MonacoEditorConstructionOptions, MonacoEditorLoaderService } from '@materia-ui/ngx-monaco-editor';

echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
type EChartsOption = echarts.ComposeOption<
  | DatasetComponentOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LineSeriesOption
>;

@Component({
  selector: 'app-new-device',
  templateUrl: './new-device.component.html',
  styleUrls: ['./new-device.component.scss']
})
export class NewDeviceComponent {
  @ViewChild('cyberResilience') cyberResilience!: ElementRef;
  @ViewChild('cyberReilCmp') cyberReilCmp!: CyberReilienceComponent;
  @ViewChild('diffConfigEditor', { static: false }) diffConfigEditor: any;
  @ViewChild('singleConfigEditor', { static: false }) singleConfigEditor: any;
  
  public cyberResilienceGraphApiName = END_POINTS.CYBER_RESILIENCE_DASHBOARD;
  public cyberResilienceFilter = true;
  public currentSelectedDevice: any = {};
  public isChartLoad: boolean = false;
  public isDevicePerameterLoad: boolean = true;
  public isCompare: boolean = false;
  public isConfigDetails: boolean = false;
  public constant = CONSTANT;
  public selectedMonth: string | undefined = "";
  public monthFilter: ICyberDrop[] = [];
  public deviceId: string = '';
  public selectedMonthLabel: string | undefined = "";
  public title: string = 'CYBER RESILIENCE TREND';
  public cyberResilienceLineChart!: echarts.ECharts;
  public cyberResilienceData: number[] = [];
  public cyberResilienceLabels: string[] = [];
  public chartApiDropdownData: IDeviceCyberResilience[] = [];
  public chartApiData: IDeviceCyberResilience[] = [];
  public minYAxis = 0;

  loaderHeights = ['120px', '90px', '120px', '90px', '120px', '90px', '120px', '90px'];
  public position: any = {
    left: 0,
    top: 0
  };
  public currentSelectedDateEvent: ICyberResilience | any = {};
  public eventData: IEventData[] = [];
  public loadEvents: boolean = false;

  public hasSingleDateResponse: boolean = true;
  public fromSelectedData: IDeviceCyberResilience | any = null;
  public toSelectedData: IDeviceCyberResilience | any = null;

  public comparefromSelectedData: IDeviceCyberResilience | any = null;
  public comparetoSelectedData: IDeviceCyberResilience | any = null;

  public fromDiffSelectedData: IDeviceCyberResilience | any = null;
  public toDiffSelectedData: IDeviceCyberResilience | any = null;

  public devicePerameterData: any = [];
  public newVulnerabilities: number = 0;
  public remediated: number = 0;
  public acceptedRisk: number = 0;
  public securityScore: number = 0;
  public result: { additions: number, deletions: number } | null = null;
  public totalCount: number = 0;
  public addition: number = 0;
  public deletion: number = 0;

  public editorOptions: MonacoEditorConstructionOptions | MonacoDiffEditorConstructionOptions = {
    theme: "vs",
    language: "text",
    readOnly: true,
    accessibilitySupport: 'off',
    originalEditable: false,
    automaticLayout: false,
    contextmenu: false,
    wordWrap: 'on',
    minimap: {
      enabled: false,
    },
    scrollbar: {
      vertical: 'visible'
    },
    scrollBeyondLastLine: false
  };
  leftFileText: string = '';
  rightFileText: string = '';
  public changes: any[] = [];
  public currentIndex = -1;
  public initialPosition: any;
  public dropdownData: any = [];
  public toolTipOption = ToolTipConfig;
  get custom() {
    return cyberConstant.custom_range;
  }
  constructor(
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private spinnerService: SpinnerService,
    private deviceService: DeviceService,
    private _adapter: DateAdapter<any>,
    private restService: RestService,
    private responseService: ResponseService,
    private globalFilterService: GlobalFilterService,
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService,
    private monacoLoaderService: MonacoEditorLoaderService) {
    this._adapter?.setLocale('en-US');
    const yesterday = new Date();
    //yesterday.setDate(yesterday.getDate() - 7);
    // Set maxDate to yesterday

    this.activatedRoute.params.subscribe((param: Params) => {
      if (param['id']) {
        this.resetCommomField();
        this.deviceId = param['id'];
        this.loadCyberResileance();
      }
    })

  }
  getScoreStatus(score: number): string {
    return Utils.getRatingcolor(score);
  }
  ngOnInit(): void {
    this.globalFilterService.updateAfterApplyState.subscribe((flag: boolean) => {
      this.dropdownData = []
      if (this.router.url.includes('/on-premises/asset-compare')) {
        this.router.navigate(['on-premises/asset-management']);
      }
    });
    this.headerService.getDropdown().subscribe((dropdownData: any) => {
      if (dropdownData && dropdownData?.dropdown?.length > 0) {
        this.dropdownData = dropdownData?.dropdown;
      }
    })
    if (!this.dropdownData?.length) {
      this.spinnerService.show();
      this.deviceService.getDevicesDropdown((response: IDeviceListResponse) => {
        this.spinnerService.hide();
        if (response.status === 200 && response.data) {
          if (this.deviceId) {
            const currentRow = response.data.device_list.find((item: IDeviceList) => item.device_id === this.deviceId);
            this.currentSelectedDevice = currentRow;
            const deviceInfo = `${currentRow?.device_name}`;
            this.headerService.setHeading(deviceInfo, { dropdown: response.data.device_list, type: 'device' });
          }
        }
      }, false);
    } else {
      const currentRow = this.dropdownData?.find((item: IDeviceList) => item.id === this.deviceId);
      this.currentSelectedDevice = currentRow;
    }
  }
  ngAfterViewInit(): void {

  }
  ngOnDestroy() {
    this.apiService.unsubscribeApiCall();
  }
  public updateDate(event: any) {
    if (event) {
      if (event.type === 'from') {
        this.fromSelectedData = event.data;
      } else if (event.type === 'to') {
        this.toSelectedData = event.data;
      }
    }

  }
  public loadCyberResileance() {
    this.isChartLoad = true;
    this.isDevicePerameterLoad = true;
    this.isCompare = true;
    this.isConfigDetails = true;
    this.cyberResilienceData = [];
    this.cyberResilienceLabels = [];
    this.chartApiDropdownData = [];
    this.leftFileText = '';
    this.chartApiData = [];
    this.rightFileText = '';
    this.chartApiData = [];
    if(this.cyberReilCmp) {
      this.cyberReilCmp.isSpinner = true;
      this.cyberReilCmp.deviceId = this.deviceId;
    }
    this.cyberReilCmp?.ngAfterViewInit();
  }
  public updateDataAfterDropApiCall(event: any) {
    this.hasSingleDateResponse = event?.hasSingleDateResponse;
    if (event && event.isDropdownnData) {
      this.fromSelectedData = event.fromData;
      this.toSelectedData = event.toData;
      this.fromDiffSelectedData = _.cloneDeep(event.fromData);
      this.toDiffSelectedData = _.cloneDeep(event.toData);
      this.getDevicePerameter();
      this.compareScans();
    } else {
      this.isDevicePerameterLoad = false;
      this.isCompare = false;
      this.isConfigDetails = false;
      this.resetCommomField();
    }
  }
  public compareData() {
    this.fromDiffSelectedData = _.cloneDeep(this.fromSelectedData);
    this.toDiffSelectedData = _.cloneDeep(this.toSelectedData);
    this.getDevicePerameter();
    this.compareScans();
  }
  public resetCommomField() {
    this.hasSingleDateResponse = false;
    this.changes = [];
    this.fromSelectedData = null;
    this.toSelectedData = null;
    this.devicePerameterData = [];
    this.newVulnerabilities = 0;
    this.remediated = 0;
    this.acceptedRisk = 0;
    this.securityScore = 0;
    this.totalCount = 0;
    this.addition = 0;
    this.deletion = 0;
    this.leftFileText = '';
    this.rightFileText = '';
    this.comparefromSelectedData = null;
    this.comparetoSelectedData = null;
  }
  public getDevicePerameter() {
    this.isDevicePerameterLoad = true;
    this.deviceService.fetchDevicePerameter(this.fetchDevicePerameterCallback, this.fromSelectedData?.id, this.toSelectedData?.id);
  }
  public fetchDevicePerameterCallback = (response: any) => {
    this.isDevicePerameterLoad = false;
    if (response.status === 200 && response.data) {
      this.devicePerameterData = response.data.parameter_list1;
      this.newVulnerabilities = response.data.new_vul;
      this.remediated = response.data.remediation;
      this.acceptedRisk = response.data.whitelisted;
      this.securityScore = response.data.security_score;
    }
  }
  public compareScans() {
    this.isCompare = true;
    this.isConfigDetails = true;
    this.devicePerameterData = [];
    this.newVulnerabilities = 0;
    this.remediated = 0;
    this.acceptedRisk = 0;
    this.securityScore = 0;
    this.totalCount = 0;
    this.addition = 0;
    this.deletion = 0;
    this.leftFileText = '';
    this.rightFileText = '';
    this.deviceService.fetchCompareScans(this.fetchCompareScansCallback, this.fromSelectedData?.file_id, this.toSelectedData?.file_id);
  }
  public fetchCompareScansCallback = (response: any) => {
    this.isCompare = false;
    this.comparefromSelectedData = this.fromSelectedData;
    this.comparetoSelectedData = this.toSelectedData;

    if (response.status === 200 && response.data) {
      this.comparefromSelectedData.security_score = Math.floor(this.comparefromSelectedData.security_score);
      this.comparetoSelectedData.security_score = Math.floor(this.comparetoSelectedData.security_score);
      if (Array.isArray(response.data.content1)) {
        this.leftFileText = response.data.content1.join('\n');
        this.leftFileText = this.leftFileText + response.data.content1.join('\n');

      } else {
        this.leftFileText = response.data.content1
      }
      if (Array.isArray(response.data.content2)) {
        this.rightFileText = response.data.content2.join('\n');
        this.rightFileText = this.rightFileText + response.data.content2.join('\n');
      } else {
        this.rightFileText = response.data.content2;
      }
      this.rightFileText = this.hasSingleDateResponse ? '' : this.rightFileText;
      if(this.hasSingleDateResponse) {
        this.isConfigDetails = false;
      }
      
      if (this.leftFileText && this.rightFileText) {
        const finalData: any = {additions: 0, deletions: 0};
        setTimeout(()=>{
          // const ltxt = this.splitIntoBatches(this.leftFileText,  this.leftFileText?.length ? 100 : 1);
          // const rtxt = this.splitIntoBatches(this.rightFileText, this.rightFileText?.length ? 100 : 1);
          // for (let i = 0; i < Math.max(ltxt.length, rtxt.length); i++) {
          //   const oldBatch = ltxt[i] ? ltxt[i].join('\n') : "";
          //   const newBatch = rtxt[i] ? rtxt[i].join('\n') : "";
          //   const result = this.getDiff(oldBatch, newBatch);
          //   finalData.additions = finalData.additions + result.additions;
          //   finalData.deletions = finalData.deletions + result.deletions;
          // }
          this.result = this.getDiff(this.leftFileText, this.rightFileText);;
          this.addition = this.result?.additions || 0;
          this.deletion = this.result?.deletions || 0;
          this.totalCount = (this.result?.additions || 0) + (this.result?.deletions || 0);
          this.isConfigDetails = false;
          this.extractChanges();
        }, 3000);
      }
    }
  }
  public validateDate() {
    if (this.chartApiDropdownData?.length > 1 && this.toSelectedData?.validDate === this.fromSelectedData?.validDate) {
      return true
    }
    return false;
  }
  splitIntoBatches = (deviceString: string, batchSize = 1) => {
    const devices = deviceString.split('\n');
    let batches = [];
    for (let i = 0; i < devices.length; i += batchSize) {
        batches.push(devices.slice(i, i + batchSize));
    }
    return batches;
  }

getDiff(oldText: string, newText: string) {
    const oldBatches = this.splitIntoBatches(oldText, Math.floor(oldText.split('\n').length / 100) > 99 ?  Math.floor(oldText.split('\n').length / 100) : 1);
    const newBatches = this.splitIntoBatches(newText, Math.floor(newText.split('\n').length / 100) > 99 ?  Math.floor(newText.split('\n').length / 100) : 1);

    let additions = 0;
    let deletions = 0;
    
    for (let i = 0; i < Math.max(oldBatches.length, newBatches.length); i++) {
        const oldBatch = oldBatches[i] ? oldBatches[i].join('\n') : "";
        const newBatch = newBatches[i] ? newBatches[i].join('\n') : "";

        const diff = diffLines(oldBatch, newBatch);

        diff.forEach(part => {
            const count = part.count || 0;
            if (part.added) {
                additions += count;
            } else if (part.removed) {
                deletions += count;
            }
        });
    }

    return { additions, deletions };
}

  public getDateAndMonth(date: Date): string {
    const dt = new Date(date);
    const month = dt.toLocaleString('default', { month: 'short' });
    return dt.getDate() + ' ' + month;
  }

  public getPositiveValue(value: number | null) {
    let val = value ?? 0;
    if (val !== 0) {
      val = Math.abs(val);
    }
    return val ? val : null;
  }
  public next() {
    if (this.changes.length === 0) return;
    this.currentIndex = (this.currentIndex + 1) % this.changes.length;
    this.scrollToChange(this.currentIndex);
  }
  public prev() {
    if (this.changes.length === 0) return;

    if (this.currentIndex <= 0) {
      this.currentIndex = -1;
      this.scrollToInitialPosition();
    } else {
      this.currentIndex--;
      this.scrollToChange(this.currentIndex);
    }
  }
  public extractChanges() {
    if (!this.diffConfigEditor?.editor) return;

    const diffConfigEditor = this.diffConfigEditor.editor;
    const lineChanges = diffConfigEditor.getLineChanges();

    if (!lineChanges || lineChanges.length === 0) {
      console.log("No differences found.");
      return;
    }
    this.changes = lineChanges.map((change: any) => ({
      modifiedStart: change.modifiedStartLineNumber,
      modifiedEnd: change.modifiedEndLineNumber
    }));
    this.initialPosition = this.diffConfigEditor.editor.getModifiedEditor().getScrollTop();
  }
  private scrollToChange(index: number) {
    if (!this.diffConfigEditor?.editor || this.changes.length === 0) return;

    const modifiedEditor = this.diffConfigEditor.editor.getModifiedEditor();
    const change = this.changes[index];

    modifiedEditor.revealLineInCenter(change.modifiedStart);
  }
  private scrollToInitialPosition() {
    if (!this.diffConfigEditor?.editor) return;

    const modifiedEditor = this.diffConfigEditor.editor.getModifiedEditor();
    modifiedEditor.setScrollTop(this.initialPosition);
  }
}
