<div class="chart-section cyber-reilience" [ngClass]="{'manage-graph-header': showDateDropdown}">
  <!-- <div *ngIf="isSpinner" class="loading-text">{{constant.LOADING}}</div> -->

  <div class="control-panel-col-header">
    <h3 class="spark-sec-tittle" *ngIf="title">{{title}} </h3>
    <form *ngIf="false" class="date-range-section" [formGroup]="datePickerRange">
      <div class="date-field">
        <label class="date-field-label">From: </label>
        <mat-form-field>
          <mat-label>DD/MM/YYYY</mat-label>
          <input matInput [matDatepicker]="startDate" formControlName="startDate" (dateChange)="getCustomRangeData()"
            [min]="minimumDate" [max]="maximumDate">
          <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
          <!-- <mat-error *ngIf="datePickerRange.get('startDate').hasError('matStartDateInvalid')">
                            Invalid start date.
                        </mat-error> -->
        </mat-form-field>
      </div>
      <div class="date-field">
        <label class="date-field-label">To: </label>
        <mat-form-field>
          <mat-label>DD/MM/YYYY</mat-label>
          <input matInput [matDatepicker]="endDate" formControlName="endDate" (dateChange)="getCustomRangeData()"
            [min]="minimumDate" [max]="maximumDate">
          <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
          <!-- <mat-error *ngIf="datePickerRange.get('endDate').hasError('matEndDateInvalid')">
                            Invalid start date.
                        </mat-error> -->
        </mat-form-field>
      </div>
      <p class="date-err" *ngIf="datePickerRange.touched && datePickerRange.invalid">Please select valid start/end date.
      </p>
    </form>
    <div class="right-btns" *ngIf="isDownload">
      <div>
        <button mat-button class="custom-btn download-btn" (click)="OpenReport()">
          <svg class="download-icon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_19867_16849)">
              <path
                d="M15 0H5C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5L0 15C0 16.3261 0.526784 17.5979 1.46447 18.5355C2.40215 19.4732 3.67392 20 5 20H15C16.3261 20 17.5979 19.4732 18.5355 18.5355C19.4732 17.5979 20 16.3261 20 15V5C20 3.67392 19.4732 2.40215 18.5355 1.46447C17.5979 0.526784 16.3261 0 15 0ZM19.0625 15C19.0613 16.0771 18.6328 17.1097 17.8713 17.8713C17.1097 18.6328 16.0771 19.0613 15 19.0625H5C3.92294 19.0613 2.89035 18.6328 2.12875 17.8713C1.36715 17.1097 0.938741 16.0771 0.9375 15V5C0.938741 3.92294 1.36715 2.89035 2.12875 2.12875C2.89035 1.36715 3.92294 0.938741 5 0.9375H15C16.0771 0.938741 17.1097 1.36715 17.8713 2.12875C18.6328 2.89035 19.0613 3.92294 19.0625 5V15Z" />
              <path
                d="M15.0821 9.48719L14.4193 8.82438L10.4683 12.7753L10.463 4.0625L9.52554 4.06281L9.53085 12.7744L5.58054 8.82438L4.91772 9.48719L9.99991 14.5694L15.0821 9.48719Z" />
              <path d="M4.84375 15H15.1562V15.9375H4.84375V15Z" />
            </g>
            <defs>
              <clipPath id="clip0_19867_16849">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg></button>
      </div>
      <div *ngIf="filterPage=='dashboardCyber' && page!='cyberzoom' && (chartResponse?.length)">
        <button mat-button class="custom-btn " (click)="OpenCyberGraph()"><mat-icon>fullscreen</mat-icon></button>
      </div>
      <div *ngIf="monthFilter.length">
        <!-- <button mat-button [matMenuTriggerFor]="securityHistory" class="btn-custom">
                <label class="sort-title">{{ selectedMonthLabel }}</label>
                <mat-icon>arrow_drop_down</mat-icon>
              </button> -->
        <mat-menu #securityHistory="matMenu" class="level-menu" (closed)="setDates()" [xPosition]="'before'">
          <div class="inner-content" [formGroup]="datePickerRange">
            <div class="date-range-container">
              <div class="d-flex full-width">
                <div class="half-width">
                  <label class="font-bold">From</label>
                  <mat-form-field class="no-bg" appearance="outline" (click)="$event.stopPropagation()">
                    <input matInput [matDatepicker]="startDate" formControlName="startDate"
                      (dateChange)="onDateChange($event,'startDate')" placeholder="Select Date" [max]="maxDate"
                      #dateInput (input)="onDateInput($event, 'startDate')">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="half-width">
                  <label>To</label>
                  <mat-form-field class="no-bg" appearance="outline" (click)="$event.stopPropagation()">
                    <input matInput [matDatepicker]="endDate" (dateChange)="onDateChange($event,'endDate')"
                      formControlName="endDate" placeholder="Select Date" [max]="maxDate"
                      (input)="onDateInput($event, 'endDate')">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="btn-section">
                <button class="btn apply-btn" (click)="applyDateRange()"
                  [disabled]="datePickerRange.invalid || datePickerRange.hasError('invalidRange')">
                  Apply
                </button>
              </div>
            </div>
            <!-- <div class="info-text" *ngIf="datePickerRange.valid && !datePickerRange.hasError('invalidRange')">
                    <mat-icon>info</mat-icon>
                    <span class="info-note">You can select a minimum period of 7 days.</span>
                  </div> -->
            <p class="date-err" *ngIf="datePickerRange.touched && datePickerRange.invalid">Please select a valid
              start/end date.</p>
          </div>




          <div class="menu-item-list">
            <button (click)="filterMonth(month)" *ngFor="let month of monthFilter;let i = index" mat-menu-item
              class="list-item" [ngClass]="{'active': month.id === selectedMonth, 'even-child': i % 2 === 1}">{{
              month.name }}</button>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div *ngIf="showDateDropdown" class="date-range-section-drop"
    [ngClass]="{'hide-date-drop': !chartApiDropdownData?.length}">
    <p>Compare selection :</p>
    <div class="region-sort range-dropdown">
      <button mat-button [disabled]="hasSingleDateResponse"
        [matMenuTriggerFor]="hasSingleDateResponse ? null :fromDropdown">{{fromSelectedData?.date_added}}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #fromDropdown="matMenu" class="range-list scan-list-menu mat-menu-scroll white-menu">
        <button (click)="updateDate(data, 'from')" *ngFor="let data of chartApiDropdownData"
          mat-menu-item>{{data?.date_added}}</button>
      </mat-menu>
    </div>
    <div class="region-sort range-dropdown">
      <button mat-button [disabled]="hasSingleDateResponse"
        [matMenuTriggerFor]="hasSingleDateResponse ? null : toDropdown">{{toSelectedData?.date_added}}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #toDropdown="matMenu" class="range-list scan-list-menu mat-menu-scroll white-menu">
        <button (click)="updateDate(data, 'to')" *ngFor="let data of chartApiDropdownData"
          mat-menu-item>{{data?.date_added}}</button>
      </mat-menu>
    </div>

    <button (click)="compareData()" [disabled]="validateDateForCompare() || hasSingleDateResponse" class="compare-btn"
      mat-flat-button color="accent">Compare</button>
  </div>

  <div class="cyber-resilience">
    <div class="canvas-loader" *ngIf="isSpinner">
      <div class="vertical-items">

        <ngx-skeleton-loader *ngFor="let height of loaderHeights;let i = index" count="1" appearance="circle" [theme]="{
              width: '20px',
              height:height,
              'border-radius': '0px',
            }" class="vertical-bar">
        </ngx-skeleton-loader>
      </div>

      <ngx-skeleton-loader count="1" class="horizontal-bar"></ngx-skeleton-loader>
    </div>
    <div *ngIf="showPopup" (click)="overlayClick()" class="window-over-lay"></div>
    <div *ngIf="showPopup" [style]="positionVal(position.left)" class="cybertooltip-popup-section">
      <h5>Security Score on {{currentSelectedDateEvent.date_time | date:'d MMM, y'}}</h5>
      <div class="cyber-score-list scroll">
        <ul>
          <li *ngFor="let event of eventData; let i = index"
            [class.disable-cursor]="(isClicked && loaderLength === i && (!(event.event_code.includes('device')) && (event.reference_id == null || event.reference_id == undefined))) || filterPage === 'cyberDevice'"
            (click)="(!isClicked || (event.event_code.includes('device') || event.reference_id != null)) && (filterPage == 'dashboardCyber' && !isClicked) ? navigateToUrl(event, i) : null">



            <div class="cyber-time">{{event.event_date | date:'HH:mm:ss'}} -
              <span>{{event.current_score.toFixed(2)}}</span></div>

            <div *ngIf="event.score_difference < 0" class="cyber-arrow arrow-down-evt">
              <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <div *ngIf="event.score_difference > 0" class="cyber-arrow arrow-up-evt">
              <mat-icon>arrow_drop_up</mat-icon>
            </div>

            <div *ngIf="event.score_difference === 0" class="cyber-arrow"></div>

            <div class="cyber-value">{{getPositiveValue(event.score_difference)?.toFixed(2)}}</div>
            <!-- <div class="cyber-status text_ellipsis" appSafariEllipsis [title]="event.event_name">({{event.event_name}})</div> -->
            <div class="cyber-status">
              <p matTooltip="{{event.event_name}}" class="text_ellipsis cyber-paramer-value">({{event.event_name}})</p>
            </div>

            <span *ngIf="isLiLoading[i]" class="btn-loader spin">
              <img src="../../../../../assets/images/btn-loader.png" />
            </span>
          </li>

          <li *ngIf="eventData.length === 0 && !loadEvents">No Data</li>
        </ul>
        <div class="item" *ngIf="loadEvents">
          <ngx-skeleton-loader count="2" class="table-loader cyber-reilience-loader" [theme]="{
                                width: '15%',
                                'border-radius': '0',
                                height: '15px',
                                'margin-bottom': '5px'
                                }" *ngFor="let item of [].constructor(8); let i = index">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div id="cyberResilience" #cyberResilience></div>
    <div *ngIf="!chartResponse?.length && !isSpinner" class="nodata-text">No Data</div>
  </div>

</div>