<div class="ag-center">
    <a mat-icon-button (click)="stopBubbling($event)"
                 [class.show]="openedMenuId === params.data.scan_id" 
                 aria-label="Example icon button with a horizontal three dot icon" 
                 [matMenuTriggerFor]="menu" 
                 (menuOpened)="onMenuOpen(params.data.scan_id)" 
                 (menuClosed)="onMenuClose()" *ngIf="params.data.menu && params.data.menu.length > 0">
                  <mat-icon>more_vert</mat-icon>
              </a>
              <mat-menu #menu="matMenu" class="white-menu">
                <a mat-menu-item *ngFor="let m of params.data.menu" (click)="onKebabMenuHandler(m.data, params.data)" [ngClass]="{'red-text': m.data=='cancelSchedule'}">{{m.label}}</a>     
                </mat-menu>

</div>
