<!-- Agent Details section -->
<div *ngIf="is_api_called" class="admin_section out-layout loader-data-page theme-bg">
  <div class="loader-data-section">
    <div class="loader-list white-box p0">
      <div class="role-data-section cred-data white-box p0">
        <ngx-skeleton-loader count="18">
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="loader-data-section white-box p0">
      <div class="role-data-section cred-data white-box p0">
        <ngx-skeleton-loader count="18">
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAgentsAvailable" class="admin_section out-layout cyber-management-page theme-bg">
  <div class="cyber-main-section">
    <div class="vpn-list white-box p0">
      <div class="role-data-section cred-data white-box p0">
        <!-- <ngx-skeleton-loader count="18" *ngIf="is_api_called">
        </ngx-skeleton-loader> -->
      </div>
      <div class="vpn-search-header">
        <div class="search-bar">
          <mat-form-field appearance="fill">
            <mat-label>Search</mat-label>
            <input matInput placeholder="" [(ngModel)]="searchAgent" autocomplete="off" type="text">
            <mat-icon class='search-icon' matPrefix>search</mat-icon>
          </mat-form-field>
        </div>
        <div class="icon-container">
          <button class="btn add-vpn"><img (click)="getAgents()" src="../../../../assets/images/Refresh.svg"> </button>
          <button class="btn add-vpn" (click)="scanNow()"><mat-icon>add</mat-icon></button>
        </div>
      </div>

      <div class="vpn-search-list scroll">
        <div *ngFor="let item of agentData | searchAgent : searchAgent; let i = index "
          (click)="agentRowClicked(item, i)" [class.active]="i === selectedIndex" class="vpn-list-item">
          <!-- <label class="labelHeading text_ellipsis" appSafariEllipsis tooltip="{{agentData.description}}">{{agentData.description}}</label> -->
          <p class="labelHeading" matTooltip="{{agentData.description}}" class="text_ellipsis">{{agentData.description}}
          </p>
          <div class="vpn-tunnelname">
            <span class="dark-mode-hidden ">
              <img [src]="'../../../../../../assets/images/agent_' + item?.devices_vendor_name.toLowerCase() + '.svg'"
                class="normal-state">
              <img
                [src]="'../../../../../../assets/images/' +'agent_'+ item?.devices_vendor_name.toLowerCase() +'_hover'+ '.svg'"
                class="hover-state">
            </span>
            <span class="dark-mode-visible">
              <img
                [src]="'../../../../../../assets/images/dark_agent_' + item?.devices_vendor_name.toLowerCase() + '.svg'"
                class="normal-state">
              <img
                [src]="'../../../../../../assets/images/' +'agent_'+ item?.devices_vendor_name.toLowerCase() +'_hover'+ '.svg'"
                class="hover-state">
            </span>
            <span class="vulnerab_label cloudvulnerab_label" [ngClass]="item?.agentClass">{{item?.agentLabel}}</span>
          </div>
          <!-- <p class="vpn-description text_ellipsis" appSafariEllipsis matTooltip="{{item?.description}}">{{item?.description}}</p> -->
          <p class="vpn-description">
            <label>
              <p matTooltip="{{item?.description}}" class="text_ellipsis">{{item?.description}}</p>
            </label>
          </p>
        </div>
      </div>

      <!-- <div class="delete-agent">
        <mat-checkbox>
        </mat-checkbox>
        <span>Deleted & Unenrolled Agent</span>
      </div> -->
    </div>
    <div class="vpn-data-section white-box p0">
      <div class="role-data-section cred-data white-box p0">
        <!-- <ngx-skeleton-loader count="18" *ngIf="is_api_called">
        </ngx-skeleton-loader> -->
      </div>
      <div class="vpn-data">
        <div class="vpn-data-header">
          <h3>Agent Details</h3>
          <div class="vpn-btns">
            <button *ngIf="agentDetails?.agentLabel === 'Active'" class="download-btn btn-link"
              (click)="downloadTemplate(agentDetails)"> <img src="../../../../../../assets/images/dowmlod_template.svg"
                alt="">Download template</button>
            <div *ngIf="agentDetails?.agentLabel !== 'Installation Failed'" class="actions-btn">
              <button [matMenuTriggerFor]="menu" class="dropdown-button">Actions <mat-icon
                  class="sort-icon">keyboard_arrow_down</mat-icon></button>
              <mat-menu #menu="matMenu" class="white-menu">
                <button mat-menu-item (click)="editCloudScan(agentDetails)">Edit</button>
                <button mat-menu-item *ngIf="agentDetails?.agentLabel === 'Active'"
                  (click)="pauseCloudScan(agentDetails,true)">Pause</button>
                <!-- <button mat-menu-item (click)="deleteCloudScan(agentDetails)">Delete</button> -->
                <button mat-menu-item *ngIf="agentDetails?.agentLabel === 'Active'"
                  (click)="unEnrollCloudScan(agentDetails)">Unenroll</button>
                <button mat-menu-item *ngIf="agentDetails?.agentLabel === 'Paused'"
                  (click)="pauseCloudScan(agentDetails,false)">Resume</button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div *ngIf="agentDetails?.agentLabel === 'Active'" class="vpn-data-inner">
          <div class="right-inner">
            <div class="item">
              <p class="text">Agent Name</p>
              <div class="text-row">
                <span class="dark-mode-visible">
                  <img
                    [src]="'../../../../../../assets/images/' +'dark_agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <span class="dark-mode-hidden">
                  <img
                    [src]="'../../../../../../assets/images/' +'agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <p class="sub-text">{{agentDetails?.name}}</p>
              </div>
            </div>
            <div class="item">
              <p class="text">Description</p>
              <p class="sub-text text_ellipsis" appSafariEllipsis matTooltip="{{agentDetails?.description}}">
                {{agentDetails?.description}}</p>
            </div>
            <div class="item">
              <p class="text">Status</p>
              <p class="sub-text"> <span class="vulnerab_label cloudvulnerab_label text_ellipsis" appSafariEllipsis
                  [ngClass]="agentDetails?.agentClass"
                  matTooltip="{{agentDetails?.agentLabel}}">{{agentDetails?.agentLabel}}</span></p>
            </div>
            <div class="item">
              <p class="text">Created By</p>
              <p class="sub-text">{{agentDetails?.created_by}}</p>
            </div>
            <div class="item">
              <p class="text">Last Modified</p>
              <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Last Activity Time</p>
              <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Date Installed</p>
              <p class="sub-text">{{agentDetails?.created_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Date Created</p>
              <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text agent-id copied-btn-position">Agent Id <button mat-icon-button (click)="copyToClipboard(agentDetails?.id,'agentId')">
                  <mat-icon class="copy_btn">content_copy</mat-icon>
                </button>
                <span *ngIf="isCopiedId" class="copied-btn">Copied!</span>
              </p>
              <p matTooltip="{{agentDetails?.id}}" class="text_ellipsis sub-text">
                {{agentDetails?.id}}
              </p>
             
            </div>
          </div>
        </div>
        <div
          *ngIf="agentDetails?.agentLabel !== 'Active' && agentDetails?.agentLabel !== 'Pending' && (agentDetails?.agentLabel === 'Installing Agent' || agentDetails?.agentLabel === 'Installation Failed')"
          class="vpn-data-inner">
          <div class="right-inner">
            <div class="item">
              <p class="text">Agent Name</p>
              <div class="text-row">
                <span class="dark-mode-visible">
                  <img
                    [src]="'../../../../../../assets/images/' +'dark_agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <span class="dark-mode-hidden">
                  <img
                    [src]="'../../../../../../assets/images/' +'agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <p class="sub-text">{{agentDetails?.name}}</p>
              </div>
            </div>
            <div class="item">
              <p class="text">Description</p>
              <p class="sub-text text_ellipsis" appSafariEllipsis matTooltip="{{agentDetails?.description}}">
                {{agentDetails?.description}}</p>
            </div>
            <div class="item">
              <p class="text">Status</p>
              <p class="sub-text"> <span class="vulnerab_label cloudvulnerab_label text_ellipsis" appSafariEllipsis
                  [ngClass]="agentDetails?.agentClass"
                  matTooltip="{{agentDetails?.agentLabel}}">{{agentDetails?.agentLabel}}</span></p>
            </div>
            <div class="item">
              <p class="text agent-id copied-btn-position">Agent Id <button mat-icon-button (click)="copyToClipboard(agentDetails?.id,'agentId')">
                  <mat-icon class="copy_btn">content_copy</mat-icon>
                </button>
                <span *ngIf="isCopiedId" class="copied-btn">Copied!</span>
              </p>

              <p matTooltip="{{agentDetails?.id}}" class="text_ellipsis sub-text">
                {{agentDetails?.id}}
              </p>
            </div>
          </div>
          <div class="right-inner-bottom">
            <div class="pending-agent-border"></div>
            <div class="pending-agent">
              <div class="pending-description agent-failed">
                <div *ngIf="agentDetails?.agentLabel === 'Installation Failed'">
                  <p class="installation-failed"><img src="../../../../assets/images/agent-failed-exclamation.svg">
                    Installation of this agent is failed. If you want to continue, create a new agent.</p>
                </div>
                <div *ngIf="agentDetails?.agentLabel === 'Installing Agent'">
                  <img class="agent-installing" src="../../../../assets/images/Animation-agent.gif">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="agentDetails?.agentLabel === 'Paused'" class="vpn-data-inner">
          <div class="right-inner">
            <div class="item">
              <p class="text">Agent Name</p>
              <div class="text-row">
                <span class="dark-mode-visible">
                  <img
                    [src]="'../../../../../../assets/images/' +'dark_agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <span class="dark-mode-hidden">
                  <img
                    [src]="'../../../../../../assets/images/' +'agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <p class="sub-text">{{agentDetails?.name}}</p>
              </div>
            </div>
            <div class="item">
              <p class="text">Description</p>
              <p class="sub-text text_ellipsis" appSafariEllipsis matTooltip="{{agentDetails?.description}}">
                {{agentDetails?.description}}</p>
            </div>
            <div class="item">
              <p class="text">Status</p>
              <p class="sub-text"> <span class="vulnerab_label cloudvulnerab_label text_ellipsis" appSafariEllipsis
                  [ngClass]="agentDetails?.agentClass"
                  matTooltip="{{agentDetails?.agentLabel}}">{{agentDetails?.agentLabel}}</span></p>
            </div>
            <div class="item">
              <p class="text">Created By</p>
              <p class="sub-text">{{agentDetails?.created_by}}</p>
            </div>
            <div class="item">
              <p class="text">Last Modified</p>
              <p class="sub-text">{{agentDetails?.last_modified_by}}</p>
            </div>
            <div class="item">
              <p class="text">Last Activity Time</p>
              <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Date Installed</p>
              <p class="sub-text">{{agentDetails?.created_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Date Created</p>
              <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text agent-id">Agent Id <button mat-icon-button (click)="copyToClipboard(agentDetails?.id,'agentId')">
                  <mat-icon class="copy_btn">content_copy</mat-icon>
                </button></p>
              <p matTooltip="{{agentDetails?.id}}" class="text_ellipsis sub-text">
                {{agentDetails?.id}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="agentDetails?.agentLabel === 'Inactive'" class="vpn-data-inner">
          <div class="right-inner">
            <div class="item">
              <p class="text">Agent Name</p>
              <div class="text-row">
                <span class="dark-mode-visible">
                  <img
                    [src]="'../../../../../../assets/images/' +'dark_agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <span class="dark-mode-hidden">
                  <img
                    [src]="'../../../../../../assets/images/' +'agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <p class="sub-text">{{agentDetails?.name}}</p>
              </div>
            </div>
            <div class="item">
              <p class="text">Description</p>
              <p class="sub-text text_ellipsis" appSafariEllipsis matTooltip="{{agentDetails?.description}}">
                {{agentDetails?.description}}</p>
            </div>
            <div class="item">
              <p class="text">Status</p>
              <p class="sub-text"> <span class="vulnerab_label cloudvulnerab_label text_ellipsis" appSafariEllipsis
                  [ngClass]="agentDetails?.agentClass"
                  matTooltip="{{agentDetails?.agentLabel}}">{{agentDetails?.agentLabel}}</span></p>
            </div>
            <div class="item">
              <p class="text">Created By</p>
              <p class="sub-text">{{agentDetails?.created_by}}</p>
            </div>
            <div class="item">
              <p class="text">Last Modified</p>
              <p class="sub-text">{{agentDetails?.last_modified_by}}</p>
            </div>
            <div class="item">
              <p class="text">Last Activity Time</p>
              <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Date Installed</p>
              <p class="sub-text">{{agentDetails?.created_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Date Created</p>
              <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text agent-id copied-btn-position">Agent Id <button mat-icon-button (click)="copyToClipboard(agentDetails?.id,'agentId')">
                  <mat-icon class="copy_btn">content_copy</mat-icon>
                </button>
                <span *ngIf="isCopiedId" class="copied-btn">Copied!</span></p>
              <p matTooltip="{{agentDetails?.id}}" class="text_ellipsis sub-text">
                {{agentDetails?.id}}
              </p>
           
            </div>
          </div>
        </div>
        <div *ngIf="agentDetails?.agentLabel === 'Pending'" class="vpn-data-inner">
          <div class="right-inner">
            <div class="item">
              <p class="text">Agent Name</p>
              <div class="text-row">
                <span class="dark-mode-visible">
                  <img
                    [src]="'../../../../../../assets/images/' +'dark_agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <span class="dark-mode-hidden">
                  <img
                    [src]="'../../../../../../assets/images/' +'agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'"
                    class="normal-state">
                </span>
                <p class="sub-text">{{agentDetails?.name}}</p>
              </div>
            </div>
            <div class="item">
              <p class="text">Description</p>
              <p class="sub-text text_ellipsis" appSafariEllipsis matTooltip="{{agentDetails?.description}}">
                {{agentDetails?.description}}</p>
            </div>
            <div class="item">
              <p class="text">Status</p>
              <p class="sub-text"> <span class="vulnerab_label cloudvulnerab_label text_ellipsis" appSafariEllipsis
                  [ngClass]="agentDetails?.agentClass"
                  matTooltip="{{agentDetails?.agentLabel}}">{{agentDetails?.agentLabel}}</span></p>
            </div>
            <div class="item">
              <!-- dhbhsd -->
              <p class="text">Agent Id </p>
              <div class="agent-id agent-pending">
                <p matTooltip="{{agentDetails?.id}}" class="text_ellipsis sub-text">
                  {{agentDetails?.id}}</p>
                <div class="copied-btn-position">
                  <button mat-icon-button (click)="copyToClipboard(agentDetails?.id,'agentId')">
                    <mat-icon class="copy_btn">content_copy</mat-icon>
                  </button>
                  <span *ngIf="isCopiedId" class="copied-popup">Copied!</span>
                </div>
              </div>

            </div>
            <div class="item">
              <div>
                <mat-form-field appearance="outline" class="otp-container">
                  <mat-label>OTP</mat-label>
                  <input matInput *ngIf="hide" value="{{agentDetails?.otp}}" type="password" readonly>
                  <input matInput *ngIf="!hide" value="{{agentDetails?.otp}}" type="text" readonly>

                  <span matSuffix class="icon-container">
                    <button mat-icon-button (click)="copyToClipboard(agentDetails?.otp,'agentOtp')">
                      <mat-icon>content_copy</mat-icon>
                    </button>
                    <button mat-icon-button (click)="hide = !hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <span *ngIf="isCopiedOtp" class="copied-popup">Copied!</span>
                  </span>
                 

                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="right-inner-bottom">
            <div class="pending-agent-border"></div>
            <div class="pending-agent">
              <div class="pending-description">
                <p>We offer two methods to deploy the Spark Agent on your AWS Cloud:</p>
              </div>
              <div class="deployment-options">
                <!-- Download Template Option -->
                <div class="option-card">
                  <p class="download-template">Download Template option</p>
                  <ol class="order-list scroll" type="1">
                    <li>Download the CloudFormation template.</li>
                    <li>Log in to your AWS account with admin permissions.</li>
                    <li>Navigate to the CloudFormation console.</li>
                    <li>Click on <b>Create Stack</b>.</li>
                    <li>Enter a name for the stack (e.g., <b>Spark-Agent</b>).</li>
                    <li>Copy the <b>OTP</b> and <b>Agent ID</b> from the Spark Agent Console.</li>
                    <li>Enter them in the CloudFormation stack controls.</li>
                    <li>The OTP will expire after one use.</li>
                    <li>Enable the <b>Acknowledge</b> checkbox to create AWS IAM resources.</li>
                    <li>Click <b>Submit</b>.</li>
                    <li>The agent will be deployed.</li>
                    <li>Click on <b>Refresh</b> under Cloud Agents in Spark and the Agent status will be updated.</li>
                  </ol>
                  <button class="download-btn btn-link" (click)="downloadTemplate(agentDetails)">
                    Download Template
                  </button>
                </div>

                <div class="option-card">
                  <p class="go-to-aws">Go to AWS option</p>
                  <ol class="order-list scroll">
                    <li>Log in to your AWS account with admin permissions in the same browser as Spark. </li>
                    <li>Click the <b>Go to AWS button</b>. </li>
                    <li>You will be redirected to the <b>Quick Create Stack</b> page in your AWS console with our
                      template. </li>
                    <li>Copy the <b>OTP</b> and <b>Agent ID</b> from the Spark Agent Console. </li>
                    <li>Enter them in the CloudFormation stack controls. </li>
                    <li>The OTP will expire after one use.</li>
                    <li>Enable the <b>Acknowledge</b> checkbox to create AWS IAM resources. </li>
                    <li>Click Submit. </li>
                    <li>The agent will be deployed. </li>
                    <li>Click on <b>Refresh</b> under Cloud Agents in Spark and the Agent status will be updated.</li>
                  </ol>
                  <button class="download-btn btn-link" (click)="goToAws(agentDetails)">
                    Go to AWS</button>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div *ngIf="agentDetails?.agentLabel === 'Unenrolled'" class="vpn-data-inner">
          <div class="right-inner">
            <div class="item">
              <p class="text">Agent Name</p>
              <div class="text-row">
              <span class="dark-mode-visible">
                <img [src]="'../../../../../../assets/images/' +'dark_agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'" class="normal-state">
              </span>
              <span class="dark-mode-hidden">
                <img [src]="'../../../../../../assets/images/' +'agent_'+ agentDetails?.devices_vendor_name.toLowerCase() + '.svg'" class="normal-state">
              </span>
              <p class="sub-text">{{agentDetails?.name}}</p>
              </div>
            </div>
            <div class="item">
              <p class="text">Description</p>
              <p class="sub-text text_ellipsis" appSafariEllipsis matTooltip="{{agentDetails?.description}}">{{agentDetails?.description}}</p>
            </div>
            <div class="item">
              <p class="text">Status</p>
              <p class="sub-text"> <span class="vulnerab_label cloudvulnerab_label text_ellipsis" appSafariEllipsis [ngClass]="agentDetails?.agentClass" matTooltip="{{agentDetails?.agentLabel}}">{{agentDetails?.agentLabel}}</span></p>
            </div>
            <div class="item">
              <p class="text">Created By</p>
              <p class="sub-text">{{agentDetails?.created_by}}</p>
            </div>
            <div class="item">
              <p class="text">Last Modified</p>
              <p class="sub-text">{{agentDetails?.last_modified_by}}</p>
            </div>
            <div class="item">
              <p class="text">Last Activity Time</p>
              <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Date Installed</p>
              <p class="sub-text">{{agentDetails?.created_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text">Date Created</p>
              <p class="sub-text">{{agentDetails?.updated_at | date: 'd MMM, y HH:mm:ss'}}</p>
            </div>
            <div class="item">
              <p class="text agent-id">Agent Id  <button mat-icon-button (click)="copyToClipboard(agentDetails?.id)">
                <mat-icon class="copy_btn">content_copy</mat-icon>
              </button>
            </p>
              <p matTooltip="{{agentDetails?.id}}" class="text_ellipsis sub-text">
                {{agentDetails?.id}}
              </p>
              <span *ngIf="isCopied" class="copied-btn">Copied!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isAgentsAvailable" class="no-data-section d-flex theme-bg">
  <div class="no-data-info text_center">
    <img src="../../../../assets/images/Group.svg" />
    <h3>There is no data available,</h3>
    <p>You have zero Cloud Agents. Configure an Agent by clicking on 'Scan Now' to get security analysis of your cloud
      resources.</p>
    <button class="btn btn-primary" (click)="scanNow()">Scan Now</button>
  </div>
</div>
<div class="theme-bg">
  <mat-sidenav-container class="global-filter-main">
    <mat-sidenav #cloudNewScan mode="over" position="end" class="cloud-scan-sidenav">
      <app-cloud-new-scan></app-cloud-new-scan>
    </mat-sidenav>

  </mat-sidenav-container>

</div>