import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ResponseService } from '../../services/response.service';
import { RestService } from '../../services/rest.service';
import { ICommonVulDropResponse, ICommonVulnarability, ICommonVulnarabilityResponse, IDrop, ISelectedVulnarability } from './common.vulnerability.model';
import { END_POINTS } from '../../constants/endpoints';
import { Utils } from '../../utils';
import { SharedService } from '../../services/shared.service';
import {CONSTANT} from '../../../shared/constants/constant-data';
import { AuthService } from '../../services/auth.service';
import { MyScansService } from 'src/app/modules/shared/services/my-scans.service';
import { CONFIG } from '../../constants/config';
import { CellClickedEvent, ColDef , GridApi, GridReadyEvent, ITooltipParams } from 'ag-grid-community';
import { ParameterComponent } from '../parameter/parameter.component';
import { VulnerabilityTooltip } from '../vul-tooltip/vul-tooltip.component';
import { AgGridComponent } from '../ag-grid/ag-grid.component';
import { CveAssetsService } from '../../services/cve-assets.service';
import { SeverityVulComponent } from '../severit-vul/severity-vul.component';

@Component({
  selector: 'app-common-vulnerability',
  templateUrl: './common-vulnerability.component.html',
  styleUrls: ['./common-vulnerability.component.scss']
})
export class CommonVulnerabilityComponent {
  @Input() title: string = '';
  @Input() showFilter: boolean = false;
  @Input() showSearch: boolean = false;
  @Input() apiName: string = '';
  @Input() filterPage: string = '';
  @Input() payLoad: any = {};
  @Input() links: boolean = false;
  @ViewChild('searchField') searchField!: ElementRef;
  public vulnarabilityMenu: IDrop[] = [];
  public selectedVulnarability: ISelectedVulnarability = {};
  public commonVulnarabilites: ICommonVulnarability[] = [];
  public allCommonVulnarabilites: ICommonVulnarability[] = [];
  public removeClassForScroll = '';
  public isSpinner: number = 0;
  public positionVal = {x:0 , y:0};
  public page = 1;
  public totalPage = 1;
  public isApiCalled: boolean = false;
  private isFilterAppplied = false;
  public constant = CONSTANT;
  noDataMsg: String = CONSTANT.NO_DATA_MSG;
  public disableTooltip = {
    isFlag: false
  };
  public tableHeader = CONFIG.TABLE_INFO.COMMON_VULNERABILITY.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.COMMON_VULNERABILITY.PROPS;
  public agDeviceColumnDefs: ColDef[] = [
    {
      headerName: this.tableHeader.Vulnerability_Type,
      field: this.tableProps.PARAMETER,
      headerTooltip:this.tableHeader.Vulnerability_Type,
      // cellRenderer: ParameterComponent,
      lockPosition:true,
      filter:false,
      minWidth:300,
      // pinned: 'left',
      // onCellClicked: (event: CellClickedEvent) => {
      //   this.getRowData(event);
      // }
    },
    {
      headerName: this.tableHeader.TYPE,
      field: this.tableProps.VULNERABILITY,
      headerTooltip:this.tableHeader.TYPE,
      lockPosition:true,
      // pinned: 'left',
      filter:false,
      minWidth:150,
      width:250,
      cellRenderer: SeverityVulComponent,
      // onCellClicked: (event: CellClickedEvent) => {
      //   this.getRowData(event);
      // }
    },
    {
      headerName: this.tableHeader.ASSETS,
      field: this.tableProps.FAILED_COUNT,
      headerTooltip:this.tableHeader.ASSETS,
      lockPosition:true,
      // pinned: 'left',
      filter:false,
      minWidth:120,
      width:250,
      cellRenderer: VulnerabilityTooltip,
      // onCellClicked: (event: CellClickedEvent) => {
      //   this.getRowData(event);
      // }
    },
  ];
  @ViewChild('myGrid') grid!: AgGridComponent;
  constructor(
    private responseService: ResponseService,
    private restService: RestService,
    private sharedService: SharedService,
    private authService: AuthService,
    private myScansService: MyScansService,
    private cveAssetsService: CveAssetsService) {

  }
  ngOnInit(): void {
    if (this.apiName) {
      this.fetchCommonVulFilter(this.fetchCommonVulFilterCallback);
    }
  }

  public fetchCommonVulFilter(callback: Function) {
    this.isSpinner = 1;
    this.isApiCalled = false;
    const filterDetails = JSON.parse(this.authService.getCookie('vulnerability') || '[]');
    this.vulnarabilityMenu = filterDetails;
    if(this.vulnarabilityMenu && this.vulnarabilityMenu.length) {
      callback(this.vulnarabilityMenu);
    } else {
      this.restService.getApi(END_POINTS.DASHBOARD_COMMON_VUL_FILTER).subscribe({
        next: (response) => {
          const data = this.responseService.successResponse(response);
          if(data.status === 200 && data?.data?.vulnerabilitylevel) {
            this.setFilter('vulnerability', data.data.vulnerabilitylevel);
            callback(data?.data?.vulnerabilitylevel);
          }
        },
        error: (err) => {
          this.setFilter('vulnerability', []);
          callback([]);
          this.responseService.errorResponse(err);
        },
      })
    }
  }
  public setFilter(type: string, data: any) {
    this.vulnarabilityMenu = data;
    this.authService.setCookie(type, JSON.stringify(data));
  }
  public fetchCommonVulFilterCallback = (response: IDrop[]) => {
    this.isSpinner = 0;
    this.page = 1;
    this.isApiCalled = false;
    if (response.length) {
      this.vulnarabilityMenu = response;
      this.selectedVulnarability.commonVul = this.vulnarabilityMenu[0].name;
      this.selectedVulnarability.id = this.vulnarabilityMenu[0].id;
      this.setSelectedCookies();
      this.loadCommonVulnaribilites(this.loadCommonVulnaribilitesCallback, this.selectedVulnarability.id);
    }
  }
  setSelectedCookies(){
    if(this.filterPage) {
      const savedSelectedCookies = JSON.parse(this.authService.getCookie(this.filterPage) || '{}');
      if(savedSelectedCookies && Object.keys(savedSelectedCookies).length) {
        this.selectedVulnarability.commonVul = savedSelectedCookies.label;
        this.selectedVulnarability.id = savedSelectedCookies.id;
      }
      this.authService.setCookie(this.filterPage, JSON.stringify({label:this.selectedVulnarability.commonVul, id: this.selectedVulnarability.id }));
    }
  }
  public filterVulnarability(value: IDrop, isSelected: boolean = false) {
    const columnState = this.agDeviceColumnDefs
    .filter(col => col.field)
    .map(col => ({
      colId: col.field as string,
      sort: null
    }));
    this.grid.gridApi.applyColumnState({
      state: columnState,
      applyOrder: true
    });
    this.selectedVulnarability.commonVul = value.name;
    this.selectedVulnarability.id = value.id;
  this.isFilterAppplied = true;
  if (this.selectedVulnarability.commonVul === 'All') {
    this.commonVulnarabilites = [...this.allCommonVulnarabilites];
  } else {
    this.commonVulnarabilites = this.allCommonVulnarabilites.filter(vul => vul.vulnerability === this.selectedVulnarability.commonVul);
  }
    if(this.filterPage) {
      this.authService.setCookie(this.filterPage, JSON.stringify({label:this.selectedVulnarability.commonVul, id: this.selectedVulnarability.id }));
    }
  }
  public loadCommonVulnaribilites(callback: Function, vulnarability: string = 'All', spinnerType: number = 1): void {
    let req = {
     type: 1
    };
    if(this.payLoad){
      req = {...req, ...this.payLoad};
    }
    this.restService.postApi(this.apiName, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public loadCommonVulnaribilitesCallback = (response: ICommonVulnarabilityResponse) => {
    this.isSpinner = 0;
    this.isApiCalled = true;
    if(this.isFilterAppplied){
      this.commonVulnarabilites = [];
    }
    this.isFilterAppplied = false;
    if (response.status === 200) {
      this.totalPage = response.data.numberofpages || 1;
       const data = response.data.indexes || [];
        const critical = data.filter((item) => item.vulnerability === 'Critical');
        const high = data.filter((item) => item.vulnerability === 'High');
        const medium = data.filter((item) => item.vulnerability === 'Medium');
        const low = data.filter((item) => item.vulnerability === 'Low');
        const sortedData = [...critical, ...high, ...medium, ...low];
        this.commonVulnarabilites = [...sortedData]
        this.allCommonVulnarabilites = [...this.commonVulnarabilites];
        this.sharedService.setAPIData(CONFIG.PAGE_API.COMMON_VULNERABILITY, response, this.commonVulnarabilites.length > 0);
        window.setTimeout(() => {
          this.grid.grid.api.sizeColumnsToFit();
        }, 10);
        if(this.selectedVulnarability.id){
          const value = {
            id:this.selectedVulnarability.id,
            name:this.selectedVulnarability.commonVul
          }
          this.filterVulnarability(value, true);
        }
      }

  }
  public currentMousePostion(event: MouseEvent) {
    this.positionVal = { x: event.clientX - 230, y: event.clientY - 80 };
  }
  public addClassCommonVul (value: string = ''): string {
    return value.toLocaleLowerCase();
  }
  makeCapital(str:string | undefined){
    return Utils.makeFirstWordCapital(str)
  }
  onScroll = () => {
    if (this.isApiCalled && (this.page !== this.totalPage)) {
      this.page++;
      this.loadCommonVulnaribilites(this.loadCommonVulnaribilitesCallback, this.selectedVulnarability.id, 2);
    }
  }

  onNavigateTopage(type: string, data: any){
    //sec-info-table
    this.authService.setCookie(`agGridFilter_sec-info-table`,JSON.stringify({}));
    this.sharedService.onNavigateTopage(type, data);
  }

  filterAssetsField() {
    this.grid.grid.api.setGridOption(
      "quickFilterText",
       this.searchField?.nativeElement?.value
    );
  }

  isPreloaderEligible(): boolean{
    return (this.page < this.totalPage) || !this.isApiCalled;
  }
  getRowData(row: any){
    this.links? this.onNavigateTopage('security', row) : '';
    // this.cveAssetsService.vulnerabilityRow.next(row.data);
   }
}
