import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-security-score',
  templateUrl: './security-score.component.html',
  styleUrls: ['./security-score.component.scss']
})
export class SecurityScoreComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  

}
