<div *ngIf="data?.resp && data?.imageUrl !== 'fail' && data?.imageUrl !== 'pass'" class="toast-container">
  <div class="toast-header">
    <!-- <img [src]="data?.imageUrl" alt="Icon" class="toast-icon"> -->
    <div *ngIf="data?.imageUrl === 'device'">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_3448_7787)">
          <path
            d="M21 3H3C1.9 3 1 3.9 1 5V17C1 18.1 1.9 19 3 19H8V20C8 20.55 8.45 21 9 21H15C15.55 21 16 20.55 16 20V19H21C22.1 19 22.99 18.1 22.99 17L23 5C23 3.89 22.1 3 21 3ZM20 17H4C3.45 17 3 16.55 3 16V6C3 5.45 3.45 5 4 5H20C20.55 5 21 5.45 21 6V16C21 16.55 20.55 17 20 17Z"
            [attr.fill]="getFillColor(data.resp?.event_type_name)" />
        </g>
        <defs>
          <clipPath id="clip0_3448_7787">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div *ngIf="data?.imageUrl === 'region'">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_3448_7784)">
          <path
            d="M17 11H20C21.11 11 22 10.1 22 9V5C22 3.89 21.1 3 20 3H17C15.89 3 15 3.9 15 5V6H9.01V5C9.01 3.89 8.11 3 7.01 3H4C2.9 3 2 3.9 2 5V9C2 10.11 2.9 11 4 11H7C8.11 11 9 10.1 9 9V8H11V15.01C11 16.66 12.34 18 13.99 18H15V19C15 20.11 15.9 21 17 21H20C21.11 21 22 20.1 22 19V15C22 13.89 21.1 13 20 13H17C15.89 13 15 13.9 15 15V16H13.99C13.45 16 13 15.55 13 15.01V8H15V9C15 10.1 15.9 11 17 11Z"
            [attr.fill]="getFillColor(data.resp?.event_type_name)" />
        </g>
        <defs>
          <clipPath id="clip0_3448_7784">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div *ngIf="data?.imageUrl === 'accepted'">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M6.52499 15.95C6.72499 15.95 6.89999 15.875 7.04999 15.725C7.19999 15.575 7.27499 15.4 7.27499 15.2C7.27499 15 7.19999 14.825 7.04999 14.675C6.89999 14.525 6.72499 14.45 6.52499 14.45C6.32499 14.45 6.14999 14.525 5.99999 14.675C5.84999 14.825 5.77499 15 5.77499 15.2C5.77499 15.4 5.84999 15.575 5.99999 15.725C6.14999 15.875 6.32499 15.95 6.52499 15.95ZM6.52936 12.7C6.74311 12.7 6.92082 12.6281 7.06249 12.4844C7.20415 12.3406 7.27499 12.1625 7.27499 11.95V8.675C7.27499 8.4625 7.2027 8.28438 7.05811 8.14062C6.91351 7.99688 6.73435 7.925 6.52061 7.925C6.30686 7.925 6.12915 7.99688 5.98749 8.14062C5.84582 8.28438 5.77499 8.4625 5.77499 8.675V11.95C5.77499 12.1625 5.84728 12.3406 5.99186 12.4844C6.13646 12.6281 6.31563 12.7 6.52936 12.7ZM11 14.7H17.475C17.6875 14.7 17.8656 14.6277 18.0094 14.4831C18.1531 14.3385 18.225 14.1594 18.225 13.9456C18.225 13.7319 18.1531 13.5542 18.0094 13.4125C17.8656 13.2708 17.6875 13.2 17.475 13.2H11C10.7875 13.2 10.6094 13.2723 10.4656 13.4169C10.3219 13.5615 10.25 13.7406 10.25 13.9544C10.25 14.1681 10.3219 14.3458 10.4656 14.4875C10.6094 14.6292 10.7875 14.7 11 14.7ZM11 10.425H17.475C17.6875 10.425 17.8656 10.3527 18.0094 10.2081C18.1531 10.0635 18.225 9.88436 18.225 9.67063C18.225 9.45688 18.1531 9.27917 18.0094 9.1375C17.8656 8.99583 17.6875 8.925 17.475 8.925H11C10.7875 8.925 10.6094 8.99729 10.4656 9.14187C10.3219 9.28647 10.25 9.46564 10.25 9.67937C10.25 9.89312 10.3219 10.0708 10.4656 10.2125C10.6094 10.3542 10.7875 10.425 11 10.425ZM3.29999 20C2.89999 20 2.54999 19.85 2.24999 19.55C1.94999 19.25 1.79999 18.9 1.79999 18.5V5.5C1.79999 5.1 1.94999 4.75 2.24999 4.45C2.54999 4.15 2.89999 4 3.29999 4H20.7C21.1 4 21.45 4.15 21.75 4.45C22.05 4.75 22.2 5.1 22.2 5.5V18.5C22.2 18.9 22.05 19.25 21.75 19.55C21.45 19.85 21.1 20 20.7 20H3.29999ZM3.29999 18.5H20.7V5.5H3.29999V18.5Z"
          [attr.fill]="getFillColor(data.resp?.event_type_name)" />
      </svg>
    </div>
    <div *ngIf="data?.imageUrl === 'newScan'">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M4 3H7V1H2V6H4V3ZM22 1V6H20V3H17V1H22ZM4 21H7V23H2V18H4V21ZM20 21V18H22V23H17V21H20ZM13.336 4C13.76 4 14.168 4.168 14.472 4.472L18.328 8.336C18.632 8.632 18.8 9.04 18.8 9.464V11H22V14H18.8V18.4C18.8 19.28 18.08 20 17.2 20H7.592C6.712 20 6 19.28 6 18.4V14H2V11H6V5.6C6 4.72 6.72 4 7.6 4H13.336ZM10 16.8H14.8C15.24 16.8 15.6 16.44 15.6 16C15.6 15.56 15.24 15.2 14.8 15.2H10C9.56 15.2 9.2 15.56 9.2 16C9.2 16.44 9.56 16.8 10 16.8ZM13.2 5.2V8.8C13.2 9.24 13.56 9.6 14 9.6H17.6L13.2 5.2ZM10 11.7C9.55818 11.7 9.20001 12.0582 9.20001 12.5C9.20001 12.9418 9.55819 13.3 10 13.3H14.8C15.2418 13.3 15.6 12.9418 15.6 12.5C15.6 12.0582 15.2418 11.7 14.8 11.7H10Z"
          [attr.fill]="getFillColor(data.resp?.event_type_name)" />
      </svg>
    </div>
    <div *ngIf="data?.imageUrl === 'myScan'">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M7 3H4V6H2V1H7V3ZM22 6V1H17V3H20V6H22ZM7 21H4V18H2V23H7V21ZM20 18V21H17V23H22V18H20ZM19 18C19 19.1 18.1 20 17 20H7C5.9 20 5 19.1 5 18V6C5 4.9 5.9 4 7 4H17C18.1 4 19 4.9 19 6V18ZM15 8H9V10H15V8ZM15 11H9V13H15V11ZM15 14H9V16H15V14Z"
          [attr.fill]="getFillColor(data.resp?.category_type)" />
      </svg>
    </div>
    <div *ngIf="data?.imageUrl === 'role'">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_3448_7779)">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 14.06L9 17L12 20H3V18C3 15.34 8.33 14 11 14C11.32 14 11.61 14.02 12 14.06ZM15 8C15 10.21 13.21 12 11 12C8.79 12 7 10.21 7 8C7 5.79 8.79 4 11 4C13.21 4 15 5.79 15 8ZM12 17L15.47 20.5L22 13.91L20.6 12.5L15.47 17.67L13.4 15.59L12 17Z"
            [attr.fill]="getFillColor(data.resp?.event_type_name)" />
        </g>
        <defs>
          <clipPath id="clip0_3448_7779">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div *ngIf="data?.imageUrl === 'user'">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_3448_7776)">
          <path
            d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
            [attr.fill]="getFillColor(data.resp?.event_type_name)" />
        </g>
        <defs>
          <clipPath id="clip0_3448_7776">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div *ngIf="data?.imageUrl === 'vpn'">
      <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.69 9H9.951L8.97 13.819C8.876 14.304 8.499 15 7.749 15C6.999 15 6.617 14.273 6.535 13.852L5.499 9H6.809L7.749 13.55L8.69 9ZM17.22 12.047L15.78 9.006V9H14.5V15H15.78V11.959L17.22 15H18.5V9H17.22V12.047ZM13.978 10.95C13.978 12.044 13.076 12.935 11.966 12.935L11.749 12.938V15H10.499V8.966H11.966C13.075 8.966 13.978 9.857 13.978 10.951V10.95ZM12.728 10.95C12.728 10.552 12.379 10.215 11.966 10.215H11.751L11.756 11.688L11.966 11.685C12.379 11.685 12.728 11.349 12.728 10.95ZM23.999 11.999C23.999 18.616 18.616 23.999 11.999 23.999C5.382 23.999 0 18.617 0 12C0 5.383 5.383 0 12 0C18.617 0 24 5.383 24 12L23.999 11.999ZM21.948 12.999H19.999V10.999H21.948C21.518 6.693 18.344 3.191 14.207 2.253C14.992 3.422 15.931 5.06 16.605 6.999H14.457C13.693 5.083 12.681 3.519 11.998 2.599C11.315 3.519 10.303 5.083 9.539 6.999H7.391C8.065 5.06 9.004 3.422 9.789 2.253C5.646 3.193 2.469 6.708 2.049 11.026H4.398L4.82 13H2.051C2.481 17.306 5.655 20.808 9.792 21.746C9.007 20.577 8.068 18.939 7.394 17H9.542C10.306 18.916 11.318 20.48 12.001 21.4C12.684 20.48 13.696 18.916 14.46 17H16.608C15.935 18.939 14.995 20.577 14.21 21.746C18.347 20.808 21.521 17.306 21.951 13L21.948 12.999Z"
          [attr.fill]="getFillColor(data.resp?.event_type_name)" />
      </svg>
    </div>
    <span class="toast-title"
      [ngClass]="data?.resp.type"
      >{{data?.resp.title }}</span>
    <div class="close-btn">
      <button mat-button class="btn icon-btn" (click)="closeToast()"><mat-icon>cancel</mat-icon></button>
    </div>
  </div>
  <div class="toast-body">
    <span  *ngFor="let message of data?.resp.message">
       <span class="mar-right"  [innerHTML]="message.mesg"></span>
  </span>
    <!-- <p class="toast-message" *ngIf="isHTML(data?.resp.message); else plainText"
      [innerHTML]="sanitizeHTML(data?.resp.message)"></p>
    <ng-template #plainText>
      <p class="toast-message">{{ data?.resp.message }}</p>
    </ng-template> -->
  </div>
</div>

<div *ngIf="data?.resp && data?.imageUrl === 'pass'" class="toast-container toast-container-sucess">
  <div class="toast-header">
    <div *ngIf="data?.imageUrl === 'pass'">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M8.6 14.6L15.65 7.55L14.25 6.15L8.6 11.8L5.75 8.95L4.35 10.35L8.6 14.6ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z"
          fill="#00DF80" />
      </svg>
    </div>
    <span class="toast-title">{{ data?.resp.title }}</span>
    <div class="close-btn">
      <button mat-button class="btn icon-btn" (click)="closeToast()"><mat-icon>cancel</mat-icon></button>
    </div>
  </div>
  <div class="toast-body">
    <p class="toast-message">{{ data?.resp.message }}</p>
  </div>
</div>

<div *ngIf="data?.resp && data?.imageUrl === 'fail'" class="toast-container toast-container-failed">
  <div class="toast-header">
    <div *ngIf="data?.imageUrl === 'fail'" class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z"
          fill="#F04248" />
      </svg>
    </div>
    <span class="toast-title">{{ data?.resp.title }}</span>
    <div class="close-btn">
      <button mat-button class="btn icon-btn" (click)="closeToast()"><mat-icon>cancel</mat-icon></button>
    </div>
  </div>
  <div class="toast-body">
    <p class="toast-message">{{ data?.resp.message }}</p>
  </div>
</div>