import { Injectable } from '@angular/core';
import { ResponseService } from './response.service';
import { RestService } from './rest.service';
import { END_POINTS } from '../constants/endpoints';
import { ICompliance, ICyberDownload, IDeviceList, IDeviceListResponse } from '../../admin/components/device-management/device.model';
import { GlobalSearchService } from './global-search.service';
import { formatDate } from '@angular/common';
import { cyberConstant } from '../components/cyber-reilience/cyber-reilience.model';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from './spinner.service';
import { CONSTANT } from '../constants/constant-data';
import { AuthService } from './auth.service';
import { method } from 'lodash';
import { ApiService, IAPIDetails } from './api.service';
import { CONFIG } from '../constants/config';
import * as _ from 'lodash';
import { IToastrOptions } from '../model/IMessage.model';
import { MessageServiceService } from './message-service.service';
import * as moment from 'moment';
import { Utils } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  deviceDropdownRes!: IDeviceListResponse | null;
  selectedFormat:string = "pdf"
  private clearSelectionSubject = new BehaviorSubject<boolean>(false);
  clearSelection$ = this.clearSelectionSubject.asObservable();
  devicesDetails: any = {
    page: 1,
    deviceInfo: [],
    totalPage: 1,
    fromBack: false,
    position: 0
  }
  get custom() {
    return cyberConstant.custom_range;
  }
  public complaince = new Subject<string>();
  public complainceState = this.complaince.asObservable();
  constructor(
    private restService: RestService,
    public   responseService: ResponseService,
    private globalSearchService: GlobalSearchService,
    private toastr: ToastrService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private apiService: ApiService,
    private messageService: MessageServiceService,
    ) { }

  public loadDeviceList(callback: Function) {
    const req: any = { };
    if(this.globalSearchService.redirectiontype=='Assets'&&
      this.globalSearchService.isRedirected && this.globalSearchService.searchTerm && !this.globalSearchService.type) {
      req.search =this.globalSearchService.searchTerm;
    }
    if(this.globalSearchService.redirectiontype=='Assets'&&
      this.globalSearchService.isRedirected && this.globalSearchService.searchTerm && this.globalSearchService.type){
      req.search =this.globalSearchService.searchTerm,
      req.type = this.globalSearchService.type
      req.regex  = 1,
      req.redirection = true;
    }
    const apiDetails: IAPIDetails | any = { 
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }
    req.type = 1;
    apiDetails.req = req;
    apiDetails.url = END_POINTS.DEVICE_MANAGEMENT_LIST;
    this.apiService.callAPI(apiDetails);
  }
  
  public saveDevices(callback: Function, selectedRegionId: number, selectedDevices: IDeviceList[]) {
    const req= {
      device_id: selectedDevices.map((device: IDeviceList) => device.id),
      region_id: selectedRegionId
    };
    this.restService.postApi(END_POINTS.UPDATE_DEVICE, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  //   public deviceApi(deviceApi:any,callback? : any){
  //   this.restService.postApi(END_POINTS.DEVICE_POPUP, deviceApi).subscribe({
  //     next: (response) => {
  //       callback(this.responseService.successResponse(response));
  //     },
  //     error: (err) => {
  //       callback(this.responseService.errorResponse(err));
  //     },
  //   })
  // }
  // public deviceVulApi(deviceVulApi:any, callback? :any){
  //   this.restService.postApi(END_POINTS.DEVICE_VUL, deviceVulApi).subscribe({
  //     next: (response) => {
  //       callback(this.responseService.successResponse(response));
  //     },
  //     error: (err) => {
  //       callback(this.responseService.errorResponse(err));
  //     },
  //   })
  // }

  public rescanDevice(selectedDevice: IDeviceList,callback: Function){
    const req= {
      asset_id: [selectedDevice.id], 
      scan_source: "Assets", 
    };
    this.restService.postApi(END_POINTS.RESCAN, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }


  public rescanDeviceOrder(selectedRescanDevicesList: string[], callback: Function) {
    const req = {
     device_uuids: selectedRescanDevicesList
    }
    this.restService.postApi(END_POINTS.DEVICE_MANAGEMENT_RESCAN, req).subscribe({
     next: (response) => {
       callback(this.responseService.successResponse(response));
     },
     error: (err) => {
       callback(this.responseService.errorResponse(err));
     },
   })
 }

  public decommissionDevice(selectedDevice: IDeviceList, action: string, callback: Function) {
    const selectedDevicesArray = [selectedDevice.id]
    const req= {
      device_id: selectedDevicesArray,
      action: action
    };
    this.restService.postApi(END_POINTS.DECOMMISION_DEVICE, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }

  public multipledecommissionDevice(selectedDevices: IDeviceList[], action: string, callback: Function) {
    const selectedDevicesArray: (string | undefined)[] = []
    selectedDevices.forEach( item => {
      selectedDevicesArray.push(item.id)
    });
    const req= {
      device_id: selectedDevicesArray,
      action: action
    };
    this.restService.postApi(END_POINTS.DECOMMISION_DEVICE, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }

  deleteDevices(selectedDevices: any,comment:any ,callback: Function) {
    const isNewUserDetails = JSON.parse(this.authService.getCookie('isNewUserDetails') || '{}');
    if(isNewUserDetails?.device_count === 0){
      isNewUserDetails.isAddOrDeleteAction = true;
      this.authService.setCookie('isNewUserDetails', JSON.stringify(isNewUserDetails || {}));
    }
    const payload = { "comment": comment, "device_id": selectedDevices.map((device: any) => device.id) }
    this.restService.postApi(END_POINTS.DELETE_DEVICE, payload).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public loadFilters(callback: Function): void {
    this.restService.getApi(END_POINTS.DASHBOARD_DURATION_FILTER).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public scanDeviceDropdown(callback: Function, id: string): void {
    const req: any = {
      device_id: id,
      duration: 'custom_date_range',
      start_date: formatDate(moment().subtract(1, 'year').toDate(), 'yyyy-MM-dd', 'en'),
      end_date: formatDate(new Date(), 'yyyy-MM-dd', 'en')
    }
    const subs = this.restService.postApi(END_POINTS.DEVICE_CYBER_RESILIENCE_SCAN_DATES, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }
  public loadCyberResileance(callback: Function, id: string): void {
    const req: any = {
      device_uuid: id,
      duration: 'custom_date_range',
      start_date: formatDate(moment().subtract(1, 'year').toDate(), 'yyyy-MM-dd', 'en'),
      end_date: formatDate(new Date(), 'yyyy-MM-dd', 'en')
    }
    if(Intl.DateTimeFormat().resolvedOptions().timeZone){
      req.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    const subs = this.restService.postApi(END_POINTS.CYBER_RESILIENCE_DASHBOARD, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }
  public fetchDevicePerameter(callback: Function, fromId: string, toId: string): void {
    const req = {
      summary_device_id1: fromId,
      summary_device_id2: toId
    }
   const subs = this.restService.postApi(END_POINTS.DEVICE_PERAMETER, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }

  public fetchCompareScans(callback: Function, fromId: string, toId: string): void {
    const endpoints: Array<string> = [];
    const req: Array<any> = [];
    endpoints.push(END_POINTS.DEVICE_COMPARE);
    req.push({file_id1: fromId});
    endpoints.push(END_POINTS.DEVICE_COMPARE_RIGHT);
    req.push({file_id2: toId});
    const subs = this.restService.multiplePostApiCall(endpoints, req).subscribe({
      next: (response) => {
        let finalResponse: any = {}; 
        if(response.length > 1) {
          response.forEach((resObj: any, index)=>{
            if(index===0) {
              finalResponse = this.responseService.successResponse(resObj);
            } else {
              const res=this.responseService.successResponse(resObj);
              const anotherContent = res.data.content2;
              finalResponse.data.content2 = anotherContent;
            }
          })
        }
        callback(finalResponse);
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
    this.apiService.apiSubscriber.push(subs);
  }
  
  setDevices(deviceDetails:any = {
    page:1,
    deviceInfo: [],
    totalPage: 1,
    fromBack:false,
    position: 0
  }
    ) {
    this.devicesDetails.page = deviceDetails?.page;
    this.devicesDetails.deviceInfo = deviceDetails?.deviceInfo;
    this.devicesDetails.totalPage = deviceDetails?.totalPage;
    this.devicesDetails.fromBack = deviceDetails?.fromBack;
    this.devicesDetails.position = deviceDetails?.position
  }
  getDevices() {
    return this.devicesDetails;
  }
  getDevicesDropdown(callBack: Function,isNewCall = true) {
    if(this.deviceDropdownRes && this.deviceDropdownRes.status === 200 && this.deviceDropdownRes.data && isNewCall) {
      callBack(this.deviceDropdownRes);
    } else {
     const subs = this.restService.getApi(END_POINTS.DEVICE_DROPDOWN_LIST).subscribe({
        next: (response) => {
          response = this.responseService.successResponse(response);
          callBack(response);
          this.setDevicesDropdown(response);
        },
        error: (err) => {
          callBack(this.responseService.errorResponse(err));
        },
      })
     this.apiService.apiSubscriber.push(subs); 
    }
    
  }
  setDevicesDropdown(data: IDeviceListResponse | null) {
    this.deviceDropdownRes = data;
  }
  public getDecommissionedDevices(): Observable<any> {
    let url = END_POINTS.GET_DECOMMISSIONED_DEVICE;
    return this.restService.getApi(url)
  }

  // getTimezoneOffset(): string {
  //   const currentDate = new Date();
  //   const formattedDate = currentDate.toLocaleDateString('en-US', {
  //     month: 'short',
  //     day: 'numeric',
  //     year: 'numeric'
  //   });
  //   const formattedTime = currentDate.toLocaleTimeString('en-US', {
  //     hour12: false,
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     second: '2-digit'
  //   });
  
  //   return `${formattedDate} - ${formattedTime}`;
  // }

  getUserTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  
  

    assetSummaryData(selectedDevices: any,selectedFormat: any) {
        this.selectedFormat = selectedFormat
        const timeZone = this.getUserTimezone();
        const payload = { "output_format": selectedFormat, "device_uuids": selectedDevices.map((device: any) => device.id), "time_zone": timeZone }
        this.spinnerService.show();
        this.restService.postApi(END_POINTS.Asset_SUMMARY_DATA, payload,'','blob').subscribe({
          next: (response) => {
            this.spinnerService.hide();
            this.responseService.successResponse(response);
            // const name = this.selectedFormat === 'pdf'?'AssetsSummaryReport.pdf':'AssetsSummaryReport.xlsx'
            const multiple:boolean = selectedDevices.length > 1 ? true : false;
            const name =  Utils.generateFileName(multiple, this.selectedFormat, selectedDevices[0].device_name, 'AssetSummaryReport'); 
            // this.toastr.success(CONSTANT.ASSEST_SUCCESS,'',{timeOut: 5000, progressBar: true,positionClass: "toast-top-right"});
            const snakMsg: IToastrOptions = {
              message: CONSTANT.ASSEST_SUCCESS
            }
            this.messageService.showSuccess(snakMsg);
              setTimeout(() => {
                this.saveFile(response, name);
              }, 3000); 
            this.clearSelectionSubject.next(true);
          },
          error: (err) => {
            this.spinnerService.hide();
          this.responseService.errorResponse(err)
          },
        });
      }

  cyberExecelData(fromDate: any, toDate:any, selectedFormat: any,deviceid?:any) {
    this.selectedFormat = selectedFormat
    const timeZone = this.getUserTimezone();
    let  payload:ICyberDownload  = { "type": selectedFormat, "from_date": fromDate, "to_date": toDate, "timezone": timeZone }
    if (deviceid) {
      payload.device_uuid = deviceid;
     }
    this.spinnerService.show();
    this.restService.postApi(END_POINTS.CYBER_EXCEL_REPORT, payload,'','blob').subscribe({
      next: (response) => {
        this.spinnerService.hide();
        this.responseService.successResponse(response);
        // const name = this.selectedFormat === 'pdf'?'CyberResilience.pdf':'CyberResilience.xlsx'
        const name =  Utils.generateFileName(false, this.selectedFormat,'CyberResilienceReport', '');
        // this.toastr.success(CONSTANT.CYBER_EXCELDATA,'',{timeOut: 5000, progressBar: true,positionClass: "toast-top-right"});
        const snakMsg: IToastrOptions = {
          message: CONSTANT.CYBER_EXCELDATA
        }
        this.messageService.showSuccess(snakMsg);
          setTimeout(() => {
            this.saveFile(response, name);
          }, 3000); 
        this.clearSelectionSubject.next(true);
      },
      error: (err) => {
        this.spinnerService.hide();
       this.responseService.errorResponse(err)
      },
    });
  }

  
  public assetComplianceData(req:ICompliance, host_name:string,multiple:boolean,callback: Function) {
    this.restService.postApi(END_POINTS.Asset_Compliance_Report, req,null,'blob').subscribe({
      next: (response) => {
        this.responseService.successResponse(response);
        // const name = req.output_format === 'pdf'?'AssetsComplianceReport.pdf':'AssetsComplianceReport.xlsx'
        const name =  Utils.generateFileName(multiple, req.output_format,host_name, 'AssetsComplianceReport');
        this.spinnerService.hide();
        callback(response);
        const snakMsg: IToastrOptions = {
          message: CONSTANT.ASSEST_COMPLIANCE
        }
        this.messageService.showSuccess(snakMsg);
        setTimeout(() => {
          this.saveFile(response, name);
        }, 3000); 
      },
      error: (err) => {
        this.spinnerService.hide();
        callback(this.responseService.errorResponse(err));
      },
    })
  }

  cancelSchedule(pathParams:any,callback: Function) {
    let url = END_POINTS.CANCEL_SCHEDULE;
    return this.restService.postApi(url,pathParams).subscribe({
      next: (res) => {
        callback(this.responseService.successResponse(res));
      },
      error: (err) => {
        this.spinnerService.hide();
        this.responseService.errorResponse(err);
      },
    })
  }

  private saveFile(blob: Blob, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const objectUrl = window.URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(a);
  }

}
