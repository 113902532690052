

import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { GlobalFilterService } from '../../services/global-filter.service';
import { Subscription } from 'rxjs';
import { IOrgDetail, IOrgDetailResponse, IUserDetail } from '../../model/profile/profile';
import { Router } from '@angular/router';
import { ProfileComponent } from 'src/app/modules/admin/components/profile/profile.component';
import { OrganisationComponent } from 'src/app/modules/admin/components/organisation/organisation.component';
import { DialogService } from '../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SSOService } from '../../services/sso-service';
import { SpinnerService } from '../../services/spinner.service';
import { HeaderService } from '../../services/header.service';
import { MessageServiceService } from '../../services/message-service.service';
import { CONSTANT, ToolTipConfig } from 'src/app/modules/shared/constants/constant-data';
import { IToastrOptions } from '../../model/IMessage.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Utils } from '../../utils';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UnsavedDialogComponent } from '../unsaved-dialog/unsaved-dialog.component';
import { ProfileImageComponent } from '../profile-image/profile-image.component';
import { base64ToFile } from 'ngx-image-cropper';
import { JiraService } from '../../services/jira.service';


@Component({
  selector: 'app-account-services',
  templateUrl: './account-services.component.html',
  styleUrls: ['./account-services.component.scss']
})
export class AccountServicesComponent implements OnInit {
  public toolTipOption = ToolTipConfig;
  public accountSubscription!: Subscription;
  private accountFilterSubscription!: Subscription;
  dialogRef!: MatDialogRef<UnsavedDialogComponent> | null;
  userLoggedOutSubscription!: Subscription;
  profileSubscription!: Subscription;
  userFormDirty: boolean = false;
  orgFormDirty: boolean = false;
  userData: any;
  showOrganization: boolean = false;
  public profileData: any = {};
  @ViewChild('tabContainer', { read: ViewContainerRef }) tabContainer!: ViewContainerRef;
  @ViewChild('tabContainerOrganization', { read: ViewContainerRef }) tabContainerOrganization!: ViewContainerRef;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  profileComponentRef: ComponentRef<ProfileComponent> | undefined;
  organizationComponentRef: ComponentRef<OrganisationComponent> | undefined;
  hideOrgdetail: boolean = true;
  orgForm: FormGroup
  orgDetails: IOrgDetail = {}
  ssoList: any[] = [];
  isSafari!: boolean;
  imageSrc: string | undefined;
  ssoData = { sso_status: false };
  defaultSso = false;
  jirainteragationclicked:boolean=false;
  hideTabs:boolean = false;
  isNewUser: any = {};
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  hideUserDetail: boolean = false;
  validation_messages = {
    organization_address: [{ type: 'required', message: CONSTANT.ORGANIZATION.ORG_ADDRESS_REQUIRED },
    { type: 'minlength', message: CONSTANT.ORGANIZATION.ORG_ADDRESS_MIN_LEN },
    { type: 'maxlength', max: 128, message: CONSTANT.ORGANIZATION.ORG_ADDRESS_MAX_LEN },
    { type: 'pattern', pattern: /^[^\s][\s\S]*[^\s]$/, message: CONSTANT.ORGANIZATION.ORG_PROP_ADDRESS }
    ],
    organization_location: [
      { type: 'required', message: CONSTANT.ORGANIZATION.ORG_LOCATION_REQUIRED },
      { type: 'patern', message: CONSTANT.ORGANIZATION.ORG_LOCATION_SELECT }
    ]
  };
  
  constructor(private authService: AuthService, private profileService: ProfileService,
    private globalFilterService: GlobalFilterService,
    private filterService: FilterService,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public ssoService: SSOService,
    private spinnerService: SpinnerService,
    private headerService: HeaderService,
    public jiraService :JiraService,

    public messageService: MessageServiceService) {
    this.orgForm = this.fb.group({
      "organization_address": new FormControl("", Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(128),
        Validators.pattern(/^[^\s][\s\S]*[^\s]$/)
      ])),
      sso_status: new FormControl(null),
      sso_id: new FormControl(null),
    });
    this.jiraService.rowBack.subscribe((data)=>{
      if(data){
        this.jirainteragationclicked = false;
    this.hideTabs = false;
    if(data.sumbitData){
      this.jiraService.getJiraOrgDetails(this.user_org_id,this.jiraCallBack)
    }
      }
    })
  }
  userProfile: IUserDetail | any;
  user_org_id: any;
  ngOnInit(): void {
    this.isSafari=this.detectSafari();
    this.isNewUser = JSON.parse(this.authService.getCookie('isNewUserDetails') || '{}');

    this.userLoggedOutSubscription = this.authService.userLoggedOut.subscribe(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    });
    this.profileSubscription = this.filterService.profileForm$.subscribe(value => {
      this.userFormDirty = value;
    });
    this.orgForm.valueChanges.subscribe(() => {
      if (this.orgForm.dirty) {
        this.orgFormDirty = true;
      }
    });
    this.accountSubscription = this.globalFilterService.accountNavFilterState.subscribe(
      (flag: boolean) => {
        this.loadProfile();
        this.openAccountFilter(flag);
        this.loadTabs();
        this.headerService.setHeading('My Organization');
      }
    )
    this.filterService.hideDetails$.subscribe(value => {
      this.hideUserDetail = value;
    })
    this.authService.userLoggedIn.subscribe(() => {
    this.profileService.fetchUserDetails((userDetails: any) => {
      this.userProfile = userDetails;
      this.user_org_id = this.userProfile?.organization_id;

     },true);
    });

  }

  detectSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
  }

  deleteData() {
    this.filterService.closeSidenav();
    this.dialogService.deleteUserData();
  }

  ngOnDestroy(): void {
    if (this.userLoggedOutSubscription) {
      this.userLoggedOutSubscription.unsubscribe();
    }
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }

  public showHideDetails() {
    this.hideOrgdetail = false;
    this.orgForm.controls['organization_address'].setValue(this.orgDetails.organization_address);
  }

  public hideDetails() {
    this.hideOrgdetail = true;
  }

  public loadOrganisation(): void {
    this.profileService.getOrgDetails(this.organisationCallback);
  }
  public organisationCallback = (results: IOrgDetailResponse) => {
    if (results.status === 200 && results.data) {
      this.orgDetails = results.data;
      this.loadOrgImage()
      this.profileService.fetchUserDetails(this.userCallback);
    }
  }

  public userCallback = (results: IUserDetail|any) => {
    this. user_org_id=results.organization_id;
    this.jiraService.getJiraOrgDetails(this.user_org_id,this.jiraCallBack)
    // this.jiraService.getJiraOrgDetails('36ce0f0a-f9d4-4f5f-9f88-13ae71f85a47',this.jiraCallBack)
    
    if (results?.is_admin) {
      this.ssoService.getListOfSSO(this.onSSOListCallback);
      this.ssoService.getSSODetails(this.getSSODetailsCallback);
    }
  }

  uploadOrganisationConnect(orgForm: FormGroup) {
    if (this.orgForm.valid) {
      const { organization_address, organization_location } = orgForm.value
      const data = { organization_address, organization_location }
      this.profileService.postOrgDetails(data, this.uploadOrganisationResponse);
    }
  }

  public saveChanges(value: boolean) {
    if (value) {
      if (this.hideUserDetail && this.userFormDirty) {
        if (this.profileComponentRef) {
          this.profileComponentRef.instance.uploadProfileConnect(this.profileComponentRef.instance.userForm);
          this.userFormDirty = false;
        }
      }
      else if (!this.hideOrgdetail && this.orgFormDirty) {
        this.uploadOrganisationConnect(this.orgForm)
        this.orgFormDirty = false;
      }
    } else {
      if (this.profileComponentRef) {
        this.profileComponentRef.instance.setHideUserDetailTrue();
      }
      this.hideOrgdetail = true;
      this.userFormDirty = false;
      this.orgFormDirty = false;
    }
  }

  public checkTabChange() {
    this.isNewUser = JSON.parse(this.authService.getCookie('isNewUserDetails') || '{}');
    if ((this.hideUserDetail && this.userFormDirty) || (!this.hideOrgdetail && this.orgFormDirty)) {
      this.saveChangeDialog()
    } else {
      this.hideOrgdetail = true;
      this.userFormDirty = false;
      this.orgFormDirty = false;
      if (this.profileComponentRef) {
        this.profileComponentRef.instance.setHideUserDetailTrue();
      }
    }
  }

  public saveChangeDialog() {
    this.dialogRef = this.dialog.open(UnsavedDialogComponent, {
      width: '360px',
      disableClose: true,
      data: {
        title: 'Save Changes',
        content: 'Do you want to save changes?',
        buttons: [{
          label: 'Yes',
          class: 'btn-primary',
          callBack: () => {
            this.saveChanges(true);
          }
        }, {
          label: 'No',
          lass: 'btn-lightblack-outline',
          callBack: () => {
            this.saveChanges(false);
          }
        }],
        disableClose: true
      },
    });
    return this.dialogRef.afterClosed();
  }
  public getSSODetailsCallback = (response: any) => {
    if (response.status === 200) {
      console.log(response,'response');
      console.log(this.ssoData,'ssoData');
      this.ssoData = response.data;
      
      this.orgForm.controls['sso_status'].setValue(this.ssoData.sso_status);
      
      this.defaultSso = this.ssoData.sso_status;
    }
  }
  JirDetails:any=null;
  isApiIntegrated:boolean=false;
  public jiraCallBack = (res:any)=>{
    if (res.data) {
      this.JirDetails = res.data;
      this.isApiIntegrated=true;

  }
}
  public onSSOListCallback = (response: any) => {
    if (response.status === 200) {
      this.ssoList = response.data.sso_list;
      if (this.ssoList.length > 0) {
        this.orgForm.controls['sso_id'].setValue(this.ssoList[0].id);
      }
    }
  }

  openFilePicker() {
    this.fileInput.nativeElement.click();
  }

  handleImageInput(files: FileList | null | undefined) {
    if (files && files.length > 0) {
      const selectedFile: File = files[0];
      const maxSize = 5 * 1024 * 1024;
      if(selectedFile.size <= maxSize){
      const formData = new FormData();
      this.dialog
        .open(ProfileImageComponent, { data: { selectedFile }, panelClass: 'hide-overflow', width: '600px' })
        .afterClosed().toPromise().then((event) => {
          if (event?.base64) {
            this.resizeImage(event?.base64, (resizedBase64) => {
              if (resizedBase64) {
                try {
                  const file = resizedBase64
                  formData.append('picture', file, selectedFile.name);
                     if (formData) {
                      this.profileService.saveOrgImage(formData, this.saveOrgImageCallback);
                    } 
                } catch (error) {
                  console.error('Error converting base64 to file:', error);
                }
              }
            });
          }
        })
      } else {
        const snakMsg: IToastrOptions = {
          message: 'Image file size cannot exceed 5MB.'
        }
        this.messageService.showError(snakMsg);
      }
    }
  }

  resizeImage(base64: string, callback: (resizedBase64: Blob) => void) {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const maxWidth = 1024; 
      const maxHeight = 1024; 
  
      let width = img.width;
      let height = img.height;
  
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
  
      canvas.width = width;
      canvas.height = height;
      ctx?.drawImage(img, 0, 0, width, height);
  
      canvas.toBlob((blob) => {
        if (blob) {
          callback(blob);
        } else {
          console.error('Error converting canvas to blob.');
        }
      }, 'image/jpeg', 0.7);
    };
    img.src = base64;
  }


  public saveOrgImageCallback = (response: any) => {
    this.spinnerService.hide();
    if (response?.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.profileService.setUserImage('', '');
      this.loadOrgImage();

    }
  }

  public uploadOrganisationResponse = (response: any) => {
    this.spinnerService.hide();
    if (response?.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.hideOrgdetail = true;
      this.loadOrganisation();
    }
  }
  public loadOrgImage() {
    this.profileService.fetchOrgImage(this.fetchOrgImageCallback, 100, 100);
  }

  public fetchOrgImageCallback = (response: any) => {
    this.imageSrc = response;
  }
  makeFirstLetterCapital(str: string | undefined) {
    return Utils.makeFirstLetterCapital(str)
  }

  ssoToggle(event: MatSlideToggleChange): void {
    this.toggleValue(this.ssoData.sso_status);
    const windowInfo = {
      'true': {
        title: 'Are you sure want to turn on the SSO?',
        content: 'If you turn on the SSO, all existing users will now have to login only through SSO and password based login is not allowed.',
        class: 'btn-primary',
        label: 'Turn On'
      },
      'false': {
        title: 'Are you sure want to turn off the SSO?',
        content: 'If you turn off the SSO, all active SPARK users of your application can use Password Forgot/Reset option to login to the application. Please ensure only required users are active before disabling the SSO for security reasons.',
        class: 'btn-red',
        label: 'Turn Off'
      }
    }

    const info = this.ssoData.sso_status ? windowInfo.false : windowInfo.true;

    this.dialogService.openDialog(
      {
        title: info.title,
        content: info.content,
        class: 'sso-popup',
        buttons: [
          {
            label: 'Cancel',
            class: 'btn-grayborder',
            callBack: () => {
              this.toggleValue(this.ssoData.sso_status);
            }
          },
          {
            label: info.label,
            class: info.class,
            callBack: () => {
              this.toggleValue(!this.ssoData.sso_status);
            }
          }
        ],
        disableClose: true
      }
    );
  }

  toggleValue(flag: boolean) {
    this.ssoData.sso_status = flag;
    this.orgForm.controls['sso_status'].setValue(flag);
  }

  onCancelSSO() {
    this.toggleValue(this.defaultSso);
  }

  onSaveSSO() {
    const obj = this.orgForm.value;
    const payload = {
      'sso_id': obj['sso_status'] === true ? obj['sso_id'] : null,
      'sso_status': obj['sso_status']
    }
    this.ssoService.saveSSODetails(payload, this.saveSSODetailsCallback);
  }

  public saveSSODetailsCallback = (response: any) => {
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.defaultSso = this.ssoData.sso_status;
    } else {
      const snakMsg: IToastrOptions = {
        message: CONSTANT.REQUST_FAILURE
      }
      this.messageService.showError(snakMsg);
      this.toggleValue(this.defaultSso);
    }
  }


  loadTabs() {
    this.tabGroup.selectedIndex = 0;
    this.loadOrganisation();
    if (this.profileComponentRef) {
      this.profileComponentRef.destroy();
    }
    if (this.organizationComponentRef) {
      this.organizationComponentRef.destroy();
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ProfileComponent);
    this.profileComponentRef = this.tabContainer.createComponent(componentFactory);
    const organizationComponentFactory = this.componentFactoryResolver.resolveComponentFactory(OrganisationComponent);
    this.organizationComponentRef = this.tabContainerOrganization.createComponent(organizationComponentFactory);
  }
  openAccountFilter(flag: boolean) {
    this.filterService.toggleSidenav('accountFilter');
  }

  public closeFilter() {
    if ((this.hideUserDetail && this.userFormDirty) || (!this.hideOrgdetail && this.orgFormDirty)) {
      this.saveChangeDialog()
    }
    this.filterService.closeSidenav();
  }

  private loadProfile(): void {
    this.profileService.setUserDetails({});
    this.profileService.fetchUserDetails(this.userProfileCallback);
  }

  public userProfileCallback = (results: IUserDetail) => {
    this.userData = results;
    //console.log(this.userData);
    this.profileData['firstname'] = this.userData.firstname;
    this.profileData['lastname'] = this.userData.lastname;
    // Extract date from expiry_date without time
    const expiryDate = new Date(this.userData.expiry_date);
    const expiresInMilliseconds = expiryDate.getTime() - Date.now();
    const expiresInDays = Math.ceil(expiresInMilliseconds / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

    // Extracting date without time and converting to string
    const expiryDateWithoutTime = new Date(expiryDate.getFullYear(), expiryDate.getMonth(), expiryDate.getDate());

    // Add expiresIn and expiryDateWithoutTime to profileData
    this.profileData['expiresIn'] = expiresInDays;
    this.profileData['expiryDateWithoutTime'] = expiryDateWithoutTime;
    this.profileData['subscriptionCode'] = this.userData.subscription_code;

    this.showOrganization = this.userData.is_admin
  }

  navigatePlans() {
    this.filterService.closeSidenav();
    //console.log("sdfds")
    this.dialogService.plans(this.userData)
    //this.router.navigate(['dashboard/',{ allPlans: "true" }]);
  }
  public jiraIntegration(){
    console.log('jira clicked');
    this.jirainteragationclicked=true;
    this.hideTabs = true;

  }
  backToAccount() {
    this.jirainteragationclicked = false;
    this.hideTabs = false;
  }
}

