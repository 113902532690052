import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { COMPOENT_NAME, CONSTANT, CONTENT_MESSAGE, ROLES_CONTENT_PROPS, ToolTipConfig, GET_MENU_ITEM_DATA } from 'src/app/modules/shared/constants/constant-data';
import { IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { ILoggedUser } from 'src/app/modules/shared/model/login/login';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { UserManagementService } from 'src/app/modules/shared/services/user-management.service';
import { RoleResponse } from '../tool-management/model/tool-management.model';
import { CustomizeComponent } from './customize/customize.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { InviteUsersComponent } from './invite-users/invite-users.component';
import { IAciveDeactiveResponse, IDeleteUserResponse, IResedPasswordResponse, IUpdatedUserResponse, IUpdateUserRoleReq, IUserListReq, IUserListResponse, RoleItem, User } from './model/user.model';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { HeaderService } from 'src/app/modules/shared/services/header.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { IUserDetail } from 'src/app/modules/shared/model/profile/profile';
import { ProfileService } from 'src/app/modules/shared/services/profile.service';
import { GlobalSearchService } from 'src/app/modules/shared/services/global-search.service';
import { Subscription, debounceTime, distinctUntilChanged, filter, fromEvent, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserDeleteComponentComponent } from './user-delete-component/user-delete-component.component';
import * as _ from 'lodash';
import { MyScansService } from 'src/app/modules/shared/services/my-scans.service';
import { SortingFilterService } from 'src/app/modules/shared/services/sorting-filter.service';
import { Utils } from 'src/app/modules/shared/utils';
import { IFilterFlags } from 'src/app/modules/shared/components/filter/filter.model';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ResponseService } from 'src/app/modules/shared/services/response.service';
import { AgGridComponent } from 'src/app/modules/shared/components/ag-grid/ag-grid.component';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { MenuOptionsComponent } from './menu-options/menu-options.component';
import { RoleComponent } from './role/role.component';
import {  UserStatusComponent } from './status/status.component';
import { MfaComponent } from './mfa/mfa.component';
import { PhoneComponent } from './phone/phone.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild('searchField') searchField!: ElementRef;
  displayedColumns: string[] = ['select','position', 'firstname', 'lastname', 'organization_email', 'phone_number', 'mfa', 'isActive', 'role', 'extension'];
  dataSource: User[] = [];
  displayUserData: User[] = [];
  displayUserDataBackUp: User[] = [];
  roleItems: RoleItem[] = [];
  selection = new SelectionModel<User>(true, []);
  loginDetail: ILoggedUser = JSON.parse(this.authService.getCookie('userDetail') || '{}');
  maximumTableSize: number = 0;
  userData: IUserDetail = {};
  public disableTooltip = false;
  public toolTipOption = ToolTipConfig;
  public timer: any;
  glbSearch: Subscription;
  private pageType: string = 'userMgnt'
  private userServiceSubscription!: Subscription;
  public isDataLoading: boolean = false;
  public totalPage: number = 1;
  public page: number = 1;
  public numberOfRowInResult: number = Math.ceil((window.innerHeight - 153) / 43);
  public isTableReset: boolean = false;
  public addedUserList: User[] = [];
  public appliedRoles: RoleItem[] = [];
  public userListReq: any = {};
  public isErrorMessageDisplay: boolean = false;
  allSelected = false;
  public selectedRow: any[] = [];
  assestSearchTrue: boolean = false;
  assectSharedServiceData: any;
  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }
  get componentName() {
    return COMPOENT_NAME;
  }
  noDataMsg: String = CONSTANT.NO_DATA_MSG;
  isApiCallDone: boolean = false;
  isDataAvailableOnFilter: boolean = true;
  public filterApplyFlags: IFilterFlags = { "roles": false };
  public openedMenuId: string | null = null;
  public loadingCellRendererParams: any = {
    totalColumn: 6,
    totalRow: 12
  };
  @ViewChild('myGrid') grid!: AgGridComponent;
  public tableHeader = CONFIG.TABLE_INFO.REGION_MANAGEMENT.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.REGION_MANAGEMENT.PROPS;
  public pageNumber: number = 1;
  // public rowHeight = 43;
  public agDeviceColumnDefs: ColDef[] = [
    {
      headerName: '',
      field: this.tableProps.CHECKBOX,
      headerTooltip:this.tableHeader.BLANK,
      filter: false,
      sortable: false,
      // cellRenderer: CheckBoxComponent,
      headerCheckboxSelection: false,
      // checkboxSelection: true,
      showDisabledCheckboxes: true,
      checkboxSelection: (params) => {
        return this.userData.organization_email &&
        params.data.organization_email &&
        this.userData.organization_email !== params.data.organization_email;
      },
      // cellRenderer: (params: any) => {
      //   const isEnabled = this.userData.organization_email &&
      //   params.data.organization_email &&
      //   this.userData.organization_email !== params.data.organization_email;

      //   return `
      //     <input
      //       type="checkbox"
      //       ${isEnabled ? '' : 'disabled'}
      //       ${params.value ? 'checked' : ''}
      //     />
      //   `;
      // },
      lockPosition: true,
      suppressColumnsToolPanel: true,
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      // pinned: 'left'
    },
    {
      headerName: this.tableHeader.FIRST_NAME,
      field: this.tableProps.FIRST_NAME,
      headerTooltip:this.tableHeader.FIRST_NAME,
      minWidth: 150,
    },
    {
      headerName: this.tableHeader.LAST_NAME,
      field: this.tableProps.LAST_NAME,
      headerTooltip:this.tableHeader.LAST_NAME,
      minWidth: 150,
    },
    {
      headerName: this.tableHeader.EMAIL,
      field: this.tableProps.EMAIL,
      headerTooltip:this.tableHeader.EMAIL,
      minWidth: 220,
    },
    {
      headerName: this.tableHeader.PHONE,
      field: this.tableProps.PHONE,
      headerTooltip:this.tableHeader.PHONE,
      cellRenderer: PhoneComponent,
      minWidth: 180,
      tooltipValueGetter: (params: ITooltipParams) => {
        return params.data.phone_number ? params.data.country_code + ' ' +params.data.phone_number: '';
      },
    },
    {
      headerName: this.tableHeader.MFA,
      field: this.tableProps.MFA,
      headerTooltip:this.tableHeader.MFA,
      cellRenderer: MfaComponent,
      minWidth: 100,
    },
    {
      headerName: this.tableHeader.STATUS,
      field: this.tableProps.STATUS,
      headerTooltip:this.tableHeader.STATUS,
      cellRenderer: UserStatusComponent,
      minWidth: 100,
    },
    {
      headerName: this.tableHeader.ROLE,
      field: this.tableProps.ROLE,
      headerTooltip:this.tableHeader.ROLE,
      cellRenderer: RoleComponent,
      tooltipField: this.tableProps.ROLE,
      minWidth: 150,
    },
    {
      headerName: this.tableHeader.BLANK,
      field: this.tableProps.OPTIONS,
      headerTooltip:this.tableHeader.BLANK,
      cellRenderer: MenuOptionsComponent,
      suppressColumnsToolPanel: true,
      //width:300,
      minWidth: 50,
      filter: false,
      sortable: false
    }
  ];
  constructor(
    private dialogService: DialogService,
    private userManagementService: UserManagementService,
    private spinnerService: SpinnerService,
    private messageService: MessageServiceService,
    private headerService: HeaderService,
    private authService: AuthService,
    private profileService: ProfileService,
    private globalSearchService: GlobalSearchService,
    private dialog: MatDialog,
    public myScansService: MyScansService,
    public sortingFilterService: SortingFilterService,
    private sharedService: SharedService,
    private responseService: ResponseService,
    private router: Router,
  ) {
    this.glbSearch = this.globalSearchService.searchCleared.subscribe(() => {
      this.roleItems.map((role: RoleItem) => {
        role.selected = false;
        role.activated=false;
      })
      this.getUserList(null,true);
     });
  }

  ngOnInit(): void {
    this.headerService.setHeading('User Management', { type: 'userMgnt' });
    this.profileService.fetchUserDetails(this.userProfileCallback);
    const navigationState = this.router.getCurrentNavigation()?.extras?.state || history.state;
    if (navigationState && navigationState['searchTrue']) {
      this.assestSearchTrue = navigationState['searchTrue']
    }
  }

  ngOnDestroy(): void {
    if(this.glbSearch){
      this.glbSearch.unsubscribe();
    }
    this.globalSearchService.clearSearch();
  }
  public userProfileCallback = (results: IUserDetail) => {
    this.userData = results;
    this.getUserList(null);
  }
  getUserList(selectedRole: string[] | null, isResetdata: boolean = false) {
    this.isTableReset = isResetdata;
    if(isResetdata) {
      this.page = 1;
      this.isApiCallDone = false;
      this.isDataAvailableOnFilter = true;
      this.displayUserData = [];
    }
    this.userListReq = {};
    this.isApiCallDone = false;
    const userListReq: IUserListReq = {};
    this.userListReq = userListReq;
    if (!this.roleItems.length) {
      this.loadRoleList();
    } else {
      const data =  this.sharedService.getAPIData(CONFIG.PAGE_API.USER_MANAGEMENT);
      if(data && data.isDataAvailable) {
        this.loadUserListCallback(data.response);
      } else {
        this.userManagementService.loadUserList(this.loadUserListCallback, this.userListReq);
      }
    //   this.assectSharedServiceData =  this.sharedService.getAPIData(CONFIG.PAGE_API.ASSETS_DETAILS);
    //   if (this.assestSearchTrue) {
    //     if (this.assectSharedServiceData && this.assectSharedServiceData.isDataAvailable) {
    //       this.assectSharedServiceData = []
    //        this.assectSharedServiceData.response = []
    //     }
    //     this.userManagementService.loadUserList(this.loadUserListCallback, this.userListReq);
    //   }else if(this.assectSharedServiceData && this.assectSharedServiceData.isDataAvailable) {
    //     this.loadUserListCallback(this.assectSharedServiceData.response);
    //   } else {
    //     this.userManagementService.loadUserList(this.loadUserListCallback, this.userListReq);
    //   }
    }
  }

  public onRowSelected(selectedRow: any) {
    this.selectedRow = selectedRow;
  }

  loadUserListCallback = (response: IUserListResponse | any) => {
    this.spinnerService.hide();
    this.isApiCallDone = true;
    this.isDataAvailableOnFilter = false;
    if (response.status === 200 && response.data) {
        if(this.addedUserList && this.addedUserList.length) {
          response.data.users_list = this.removeAvailableData(response.data.users_list || []);
        }
        response.data.users_list = this.getKebabOptions(response.data.users_list);
        response.data.users_list.forEach((user: { firstname: any; lastname: any; }) => {
          user.firstname = this.makeFirstLetterCapital(user.firstname);
          user.lastname = this.makeFirstLetterCapital(user.lastname);
      });
        this.displayUserData = response.data.users_list || [];
        if(!this.displayUserDataBackUp.length) {
          this.displayUserDataBackUp = _.cloneDeep(this.displayUserData);
        }
        this.sharedService.setAPIData(CONFIG.PAGE_API.USER_MANAGEMENT, response, this.displayUserData.length > 0);
        window.setTimeout(() => {
          this.grid.grid.api.sizeColumnsToFit();
        }, 10);
    }
  }
  public removeAvailableData(data: User[]) {
    if(data && data.length && this.addedUserList && this.addedUserList.length) {
      this.addedUserList.forEach((user: User)=>{
        const availableUserIndex = data.findIndex(dataObj=> dataObj.id === user.id);
        if(availableUserIndex > -1) {
          data.splice(availableUserIndex, 1);
        }
      })
    }
    return data;
  }
  public updateAddedUserOnTop() {
    let newUsers: User[] = [];
    if(this.addedUserList && this.addedUserList.length) {
      this.addedUserList.forEach((user: User)=>{
        const currentAddedUserIndex = this.displayUserData.findIndex(dataObj=> dataObj.id === user.id);
        if(currentAddedUserIndex === -1) {
          user.isNewUser = true;
          newUsers.unshift(user);
        }
      })
      this.displayUserData = newUsers.concat(this.displayUserData);
      this.resetUserPositions();
    }
  }

  resetUserPositions() {
    if (this.displayUserData?.length) {
      this.displayUserData.forEach((user, index) => {
        user.position = index + 1;
      });
    }
  }

  loadRoleList = () => {
    this.isApiCallDone = false;
    const data =  this.sharedService.getAPIData(CONFIG.PAGE_API.USER_ROLE);
    if(data && data.isDataAvailable) {
      this.roleListCallback(data.response);
    } else {
      this.userManagementService.loadRoleList(this.roleListCallback);
    }
  }
  roleListCallback = (response: RoleResponse) => {
    this.spinnerService.hide();
    this.isDataAvailableOnFilter = true;
    if (response.status === 200) {
      this.roleItems = response.data || [];
      this.sharedService.setAPIData(CONFIG.PAGE_API.USER_ROLE, response, this.roleItems.length > 0);
      const appliedRoles = JSON.parse(this.authService.getCookie('appliedRoles') || '[]');
      this.roleItems.forEach((role: RoleItem) => {
        if(appliedRoles && appliedRoles.length) {
         const appliedRoleObj = appliedRoles.find((id: string)=>{
          return id === role.id;
         });
         if(appliedRoleObj) {
          role.activated = true;
          role.selected = true;
         } else {
          role.activated = false;
          role.selected = false;
         }
        } else {
          role.activated = false;
          role.selected = false;
        }
      })
      const selectedRole: any = this.roleItems.filter((role: RoleItem) => {
        return role.selected;
      }).map((role: RoleItem) => {
        return role.id;
      });
      const data =  this.sharedService.getAPIData(CONFIG.PAGE_API.USER_MANAGEMENT);
      if(data && data.isDataAvailable) {
        this.loadUserListCallback(data.response);
      } else {
        this.userManagementService.loadUserList(this.loadUserListCallback, this.userListReq);
      }
    }
  }
  onInviteUser(): void {
    const _self = this;
    const payload: IDialog = {
      component: InviteUsersComponent,
      callBack: function (data: User[] | any) {
        if(data && data.length) {
          data.forEach((obj: any)=>{
            if(obj?.status === "success") {
              obj.userlist = _self.getKebabOptions(obj.userlist);
              _self.addedUserList = _self.addedUserList.concat(obj.userlist);
              _self.updateAddedUserOnTop();
            }
          })
        }
      },
      width: '450px',
    class: 'invite-user',
      data: {
        rolesList: this.roleItems
      }
    }
    if (this.permissionCheck(this.componentName.ADD_USER, this.permissionProps.ADD_USER)) {
      this.dialogService.openDialog(payload);
    }
  }
  updateUser(updatedUser: User) {
    this.spinnerService.show();
    this.userManagementService.updateUser(this.updateUserCallback, updatedUser);
  }
  updateUserCallback = (response: IUpdatedUserResponse) => {
    this.spinnerService.hide();
    const snakMsg: IToastrOptions = {
      message: response.message
    }
    this.messageService.showSuccess(snakMsg);
  }
  editUserPopup(currentUser: User): void {
    const payload: IDialog = {
      component: EditUserComponent,
      width: '500px',
      data: {
        user: currentUser,
        getUserListCallback: (updatedUser: User) => {
          this.updateUser(updatedUser)
        }
      }
    }
    this.dialogService.openDialog(payload);
  }
  onOptionsSelection(option: string, currentUser: User): void {

    if (option.toLocaleLowerCase() === 'edit') {
      this.editUserPopup(currentUser);
    }
    else {
      let hasPermission = false;
      if(option.toLocaleLowerCase() === 'delete') {
        hasPermission = this.permissionCheck(this.componentName.DELETE_USER, this.permissionProps.DELETE_USER);
      } else if(option.toLocaleLowerCase() === 'deactivate' || option.toLocaleLowerCase() === 'reactivate'){
        hasPermission = this.permissionCheck(this.componentName.ACTIVATE_DEACTIVATE, this.permissionProps.ACTIVATE_DEACTIVATE);
      }else if(option.toLocaleLowerCase() === 'resend password link'){
        hasPermission = this.permissionCheck(this.componentName.RESET_PASS_LINK, this.permissionProps.RESET_PASS_LINK);
      }
      const payload: IDialog = {
        component:UserDeleteComponentComponent,
        width: '380px',
        data: {
          content:CONTENT_MESSAGE(option, currentUser),
          title: option,
          setCallbackFunction: (result: any) => {
            this.onOptionCall(result,option,currentUser)
          },
          menuData:GET_MENU_ITEM_DATA(option,currentUser)
        },
      }
      this.dialogService.openDialog(payload);
    }
  }
  onOptionCall(result:any,option:string,currentUser: User){
    if (result) {
      this.onOptionsSelectionPopupCallback(option, currentUser);
    }
  }
  onOptionsSelectionPopupCallback = (option: string, currentUser: User) => {
    if (option.toLocaleLowerCase() === 'delete') {
      this.deleteUser(currentUser);
    } else if (option.toLocaleLowerCase() === 'deactivate') {
      this.activateDeactivateUser(currentUser, false);
    } else if (option.toLocaleLowerCase() === 'reactivate') {
      this.activateDeactivateUser(currentUser, true);
    } else if (option.toLocaleLowerCase() === 'resend password link') {
      this.resedPasswordLink(currentUser);
    }
  }
  resedPasswordLink(user: User) {
    this.spinnerService.show();
    this.userManagementService.resedPasswordLink(this.resedPasswordLinkCallback, user);
  }
  resedPasswordLinkCallback = (response: IResedPasswordResponse) => {
    this.spinnerService.hide();
    if (response.status === 200) {
      const snakMsg: IToastrOptions = {
        message: response.message || CONSTANT.RESED_PASSWORD_LINK_MSG
      }
      this.messageService.showSuccess(snakMsg);
    }

  }
  deleteUser(user: User) {
    this.spinnerService.show();
    this.userManagementService.deleteUser(this.deleteUserCallback, user);
  }
  deleteUserCallback = (response: IDeleteUserResponse) => {
    this.spinnerService.hide();
    this.addedUserList = [];
    if (response.status === 200) {
      this.sharedService.setAPIData(CONFIG.PAGE_API.USER_MANAGEMENT,null, false);
      this.getUserList(null, true);
      const snakMsg: IToastrOptions = {
        message: response.message,
      }
      this.messageService.showSuccess(snakMsg);
      this.selectedRow = [];
      this.grid.grid.api.deselectAll();
    }
  }
  activateDeactivateUser(currentUser: User, isActiveDeactive: boolean) {
    this.spinnerService.show();
    this.userManagementService.activateDeactivateUser(this.activateDeactivateUserCallback, currentUser, isActiveDeactive);
  }
  activateDeactivateUserCallback = (response: IAciveDeactiveResponse) => {
    this.spinnerService.hide();
    this.addedUserList = [];
    if (response.status === 200) {
      this.sharedService.setAPIData(CONFIG.PAGE_API.USER_MANAGEMENT,null, false);
      this.getUserList(null, true);
      const snakMsg: IToastrOptions = {
        message: response.message,
      }
      this.messageService.showSuccess(snakMsg);
    }

  }
  updateRoleInSelectedUser(element: User, role: RoleItem): void {
    if (element.role?.toLocaleLowerCase() !== role.name?.toLocaleLowerCase()) {
      this.spinnerService.show();
      const req: IUpdateUserRoleReq = {};
      req.role_id = role.id;
      req.user_id = element.id;

      this.userManagementService.updateRoleForSelectedUser((response: any) => {
        this.spinnerService.hide();
        if (response.status === 200) {
          const index = this.displayUserData.findIndex(x => x === element);
          if (index > -1) {
            this.displayUserData[index].role = role.name
          } else {
            this.displayUserData[index].role = null
          }
          const snakMsg: IToastrOptions = {
            message: response.message,
          }
          this.messageService.showSuccess(snakMsg);
        }

      }, req);
    }
  }

  onCustomizeHandler(): void {
    const payload: IDialog = {
      component: CustomizeComponent,
      width: '70%'
    }
    this.dialogService.openDialog(payload)
  }
  addCustomClass = (user: User) => {
    const customClassString = {
      columnClass: 'set-Region',
      rowClass: 'row-set-Region',
      value: 'Set Regions'
    };
    if (user.customize === 2) {
      customClassString.columnClass = 'view-region';
      customClassString.rowClass = 'row-view-region';
      customClassString.value = 'View Region';
    } else if (user.customize === 3) {
      customClassString.columnClass = 'customize';
      customClassString.rowClass = 'row-customize';
      customClassString.value = 'Customize';
    }
    return customClassString;
  }
  public permissionCheck(title: string, props: string) {
    return this.authService.permissionMsg(title, props);
  }
  public hasPermission(roleName: string): boolean {
    return !!this.authService.isRoleAccess(roleName);
  }

  private getKebabOptions(data: User[]){
    data.forEach(item => {
      item.kebabOptions = this.getOptions(item);
    })
    return data;
  }



  getOptions(element: any): string[] {
    const options: string[] =  [];

    console.log(this.hasPermission(this.permissionProps.DELETE_USER))
    if(this.hasPermission(this.permissionProps.DELETE_USER)) {
      options.push('Delete')
    }
    if(element.mail_verified && this.hasPermission(this.permissionProps.ACTIVATE_DEACTIVATE)){
      options.push(element.is_active ? 'Deactivate' : 'Reactivate');
    }
    const per=this.hasPermission(this.permissionProps.RESET_PASS_LINK)

    if( Object.keys(this.userData).length !== 0 && !this.userData.sso_enabled && element.mail_verified
      && this.hasPermission(this.permissionProps.RESET_PASS_LINK)){
      options.push('Resend Password Link')
    }
    return options;
  }
  showFilter(data: any) {
    return this.sortingFilterService.showFilterDropDown(data);
  }
  isPreloaderEligible(): boolean {
    return this.page !== this.totalPage;
  }
  onMenuOpen(id: string) {
    this.openedMenuId = id;
}

onMenuClose() {
    this.openedMenuId = null;
    const appliedRoles = JSON.parse(this.authService.getCookie('appliedRoles') || '[]');
    this.roleItems?.forEach((role: RoleItem) => {
      if(appliedRoles && appliedRoles.length) {
        const appliedRoleObj = appliedRoles.find((id: string)=>{
        return id === role.id;
        });
        if(appliedRoleObj) {
        role.activated = true;
        role.selected = true;
        } else {
        role.activated = false;
        role.selected = false;
        }
      } else {
        role.activated = false;
        role.selected = false;
      }
    })
}
public makeFirstLetterCapital(value: string = ''): string{
  return Utils.makeFirstLetterCapital(value)
}

toggleAllSelection(){

}
userMultipleDelete(){
  this.spinnerService.show();
  this.userManagementService.multipleDeleteUser(this.deleteUserCallback, this.selectedRow);
}
filterAssetsField() {
  this.grid.grid.api.setGridOption(
    "quickFilterText",
     this.searchField?.nativeElement?.value
  );
}
// exportToExcel() {
//   const columnList = this.grid.grid.api.getAllDisplayedColumns();
//   const params: any = {
//     columnKeys: []
//   };
//   columnList.forEach((colNode: any) => {
//     if (colNode.colDef.headerName && colNode.colDef.field) {
//       const field: any = colNode.colDef.field;
//       params.columnKeys.push(field);
//     }
//   })
//   this.grid.grid.api.exportDataAsExcel(params);
// }
goToPage(): void {
  if (this.pageNumber && this.pageNumber > 0) {
    this.grid.grid.api.paginationGoToPage(this.pageNumber - 1);
  }
}
}
