import { Component } from '@angular/core';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { Authentication } from '../../models/login.mdel';
import { ILoggedUser } from 'src/app/modules/shared/model/login/login';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { ActiveSessionComponent } from 'src/app/modules/shared/components/active-session/active-session.component';
import { MatDialog } from '@angular/material/dialog';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { SSOService } from 'src/app/modules/shared/services/sso-service';
import { IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SsoLoginComponent {
  private isAuthenticated: boolean = false;
  private state: string = '';
  private msg: string = '';
  constructor(private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
    private ssoService: SSOService,
    private authService: AuthService,
    private messageService: MessageServiceService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService) {

  }
  ngOnInit(): void {
    const authFlag = this.activatedRoute.snapshot.queryParamMap?.get('authenticated');
    this.msg = this.activatedRoute.snapshot.queryParamMap?.get('message') || '';
    this.state = this.activatedRoute.snapshot.queryParamMap?.get('state') || '';
    if (authFlag === 'True') {
      this.isAuthenticated = true;
    } else {
      const errRes: IToastrOptions = {
        message: this.msg
      }
      this.spinnerService.hide();
      this.messageService.showError(errRes);
      this.router.navigate(['login']);
      return;
    }

    if (this.state && this.isAuthenticated) {
      this.ssoLogin();
    }
  }
  private ssoLogin(session: boolean = false) {
    //changed sso login apexaiq session 
    this.ssoService.ssoLogin(this.state, true)?.subscribe((res: Authentication | any)=> {
      this.ssoLoginCallback(res)
    }, (err: any) => {
      const errRes: IToastrOptions = {
        message: err?.error?.message ? err?.error?.message : CONSTANT.TOO_MANY_WRONG_ATTEMPTS,
      }
      this.messageService.showError(errRes);
      this.router.navigate(['login']);
    });
  }
  private ssoLoginCallback = (result: Authentication | any) => {
    this.spinnerService.hide();
    const userDetail: ILoggedUser = {};
    if (result.status === 200) {
      if (result.data.active_session) {
         this.activeSessionModalOpen();
        return;
      }
      this.authService.setAccessTokens(result.data);
      if (result.data.permissions?.permissions?.length > 0) {
        this.authService.setRole(result.data.permissions?.permissions);
      }
      this.router.navigate(['dashboard']);
      if (this.authService.checkCookie('userDetail')) {
        this.authService.deleteCookie('userDetail');
      }
      this.authService.setCookie('userDetail', JSON.stringify(userDetail));
    } else {
      const errRes: IToastrOptions = {
        message: this.msg
      }
      this.messageService.showError(errRes);
      this.router.navigate(['login']);
    }
  }
  onSessionCall(result: any){
    if (result === 1) {
      this.ssoLogin(true);
    } else if (result === 2) {
      //this.router.navigate(['login']);
      this.router.navigate(['dashboard']);
      
    }
  }
  activeSessionModalOpen(): void {
    const payload: IDialog = {
      component:ActiveSessionComponent,
      width: '400px',
      data: {  
        setCallbackFunction: (result: any) => {
           this.onSessionCall(result)
        }
      },
    }
   this.dialogService.openDialog(payload);
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result === 1) {
    //     this.ssoLogin(true);
    //   } else if (result === 2) {
    //     this.router.navigate(['login']);
    //   }
    // });
  }
}
