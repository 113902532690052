<div class="out-layout">
    <div class="user_page page_content">
        <div class="user-mgnt-header">
            <div class="left-mgt-head">
                <div class="search-bar">
                    <mat-form-field appearance="fill">
                        <mat-label>Search Users</mat-label>
                        <input matInput placeholder="" autocomplete="off" (input)="filterAssetsField()"
                            autocomplete="off" type="text" #searchField>
                        <mat-icon class='search-icon' matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>

            </div>
            <div class="right-mgt-head">
                <div class="btn-session">
                    <!-- <div class="double-btn-section">
                        <label for="pageNumber">Page:</label>
                        <input id="pageNumber" type="number" [(ngModel)]="pageNumber">
                        <button (click)="goToPage()">Go</button>
                        <button class="btn btn-primary" (click)="  ()">Export to Excel</button>
                    </div> -->
                    <div class="add-btn-session">
                        <button class="btn btn-outline"
                            *ngIf="!selectedRow.length && hasPermission(permissionProps.ADD_USER)"
                            (click)="onInviteUser()">Add
                            User</button>
                        <button class="btn btn-tartorange-outline"
                            *ngIf="selectedRow.length && hasPermission(permissionProps.DELETE_USER)"
                            (click)="userMultipleDelete()">Delete User</button>

                    </div>
                </div>

            </div>
        </div>
        <app-ag-grid
        #myGrid
        [agDeviceColumnDefs]="agDeviceColumnDefs"
        [data]="displayUserData"
        [gridClass]="'user_mngt_class ag-grid-tabel'"
        [loader]="isApiCallDone"
        [loadingCellRendererParams]="loadingCellRendererParams" 
        (onRowSelectedChange)="onRowSelected($event)"
        [tableKey]="'users-table-info'"> 
       </app-ag-grid>
    </div>
</div>