import { ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceService } from 'src/app/modules/shared/services/device.service';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { ICompliance } from '../device-management/device.model';
import { FormControl } from '@angular/forms';
import { MyScansService } from 'src/app/modules/shared/services/my-scans.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
interface Benchmark {
  id: string;
  name: string;
  selected?: boolean;
}


@Component({
  selector: 'app-complaince-report',
  templateUrl: './complaince-report.component.html',
  styleUrls: ['./complaince-report.component.scss']
})
export class ComplainceReportComponent {
  public options: any[] = [];
  public filteredOptions: any[] = [];
  public deviceId:any;
  parameters = { passed: false, failed: false };
  filterList: any;
  selectedItems: any[] = [];   
  selectedItemIds: string[] = [];  
  hostName:string = '';
    itemControl = new FormControl();
    selectedParameters: boolean[] = [];
    accepted: boolean = false; 
    description: boolean = false; 
    remediation: boolean = false; 
    output: string = 'pdf';
  public complianceSubscription!: Subscription; 
   constructor(public deviceService:DeviceService,
    public filterService: FilterService,
    public myscansService:MyScansService,
    private cdr: ChangeDetectorRef,
    public spinnerService: SpinnerService){
   }

   ngOnInit(){
      this.complianceSubscription = this.deviceService.complainceState.subscribe(
        (flag: any) => {
          this.hostName = flag.device_name;
          this.openComplianceSidebar(flag.id); 
        }
      )
   }

   public getbenchmarkdataCallback= (res: any) => {
    console.log(res);
    if (res && Array.isArray(res.data)) {
      this.options = res.data.map((item: any) => ({
        id: item.id,
        name: item.name,
        selected: false
      }));
      this.filteredOptions = this.options
      this.cdr.detectChanges();
    } else {
      console.error("Invalid data received");
    }
  }

   onParameterChange() {
    const passedIndex = this.selectedParameters.indexOf(true); 
    if (this.parameters.passed && passedIndex === -1) {
      this.selectedParameters.push(true);
    } else if (!this.parameters.passed && passedIndex !== -1) {
      this.selectedParameters.splice(passedIndex, 1);
    }
    const failedIndex = this.selectedParameters.indexOf(false); 
    if (this.parameters.failed && failedIndex === -1) {
      this.selectedParameters.push(false);
    } else if (!this.parameters.failed && failedIndex !== -1) {
      this.selectedParameters.splice(failedIndex, 1);
    }
  }
  
  
   public openComplianceSidebar(flag: string){
    this.deviceId = flag;
    this.myscansService.getBenchMarkAndVulnerabilityList(flag,this.getbenchmarkdataCallback); 
     this.filterService.toggleSidenav('complainceReport');
   }

   public closeNotification() {
    this.parameters.passed = false;
    this.parameters.failed = false;
    this.accepted = false;
    this.description = false;
    this.remediation = false;
    this.output = 'pdf'
    this.itemControl.reset();
    this.selectedItems = [];
    this.selectedItemIds = [];
    this.filterService.closeSidenav();
  }

  formatBooleanArray(arr: boolean[]): string {
    return arr.map(item => item.toString()).join(',');
  }

  getUserTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public complianceReport(){
    const timeZone = this.getUserTimezone();
    const selectedParameters = this.formatBooleanArray(this.selectedParameters);
    const payload:ICompliance = {
      "device_uuid":this.deviceId,
      "output_format":this.output,
      "benchmarks":this.selectedItemIds,
      "passed_failed":selectedParameters,
      "accepted_risk":this.accepted,
      "parameter_remediation":this.remediation,
      "parameter_description":this.description,
      "time_zone": timeZone
  }
     this.filterService.closeSidenav();
     this.spinnerService.show();
    this.deviceService.assetComplianceData(payload,this.hostName,false,this.assetComplianceDataCallback)
  }

  assetComplianceDataCallback = (res:any) => {
      this.parameters.passed = false;
      this.parameters.failed = false;
      this.accepted = false;
      this.description = false;
      this.remediation = false;
      this.output = 'pdf'
      this.itemControl.reset();
      this.selectedItems = [];
      this.selectedItemIds = [];
  }
  
  applyFilter(event: KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value.trim().toLowerCase();
    const selectedOptionIdsSet = new Set(this.selectedItemIds);
    const isFilterApplied = value.trim() !== '';
    this.filteredOptions = this.options.filter(option =>
      option.name.toLowerCase().includes(value)
    );
    if (!isFilterApplied) {
      this.filteredOptions.forEach(option => {
        option.selected = selectedOptionIdsSet.has(option.name);
      });
    }
  }
  
  toggleSelection(item: any) {
    const idIndex = this.selectedItemIds.indexOf(item.id);
    if (idIndex === -1) {
      this.selectedItemIds.push(item.id);
      this.selectedItems.push(item);
    } else {
      this.selectedItemIds.splice(idIndex, 1);
      this.selectedItems = this.selectedItems.filter(selected => selected.id !== item.id);
    }
    this.itemControl.setValue(this.selectedItemIds);
  }

  isSelected(id: string): boolean {
    return this.selectedItemIds.includes(id);
  }

  formatText(item: string): string {
    if (item.length > 8) {
      return `${item.substring(0, 5)}...`;
    }
    return item;
  }

  selectedItemsForDisplay(): any[] {
    return this.selectedItems.slice(0, 3); 
  }

  additionalCount(): number {
    return this.selectedItems.length > 3 ? this.selectedItems.length - 3 : 0;
  }
  
}
