import { Component, Input, ViewChild, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { GridApi, RowClickedEvent, SideBarDef } from 'ag-grid-community';
import { CONFIG } from '../../constants/config';
import {
  GridReadyEvent,
  ColDef,
  CellClickedEvent,
  GridSizeChangedEvent
} from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { TableLoaderComponent } from '../table-loader/table-loader.component';
import { SideBarComponent } from '../side-bar/side-bar.component';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-ag-grid',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.scss']
})
export class AgGridComponent {
  // Ag grid
  @ViewChild('myGrid') grid!: AgGridAngular;
  public gridApi!:GridApi;
  @Input()
  public data: any = [];
  @Input()
  loader: boolean = false;
  @Input()
  public rowSelection: string = CONFIG.AG_GRID_CONFIG.ROW_SELETION.MULTIPLE;
  public autoGroupColumnDef: ColDef = {
    headerName: 'Group',
    cellRendererParams: {
      suppressCount: true,  // Optionally suppress the count
    },
    width: 200,
  };
  // @Input()
  // public sideBar: SideBarDef | string | any = CONFIG.AG_GRID_CONFIG.SIDEBAR;
  @Input()
  public restrictCheckBoxSelection: boolean = CONFIG.AG_GRID_CONFIG.RESTRICT_CHECKBOX_SELECTION_ON_ROW_CLICK;
  @Input()
  public hideContextMenu: boolean = CONFIG.AG_GRID_CONFIG.HIDE_CONTEXT_MENU;
  @Input()
  public tableHeader = CONFIG.TABLE_INFO.ASSETS.HEADER_LABEL;
  @Input()
  public tableProps = CONFIG.TABLE_INFO.ASSETS.PROPS;
  @Input()
  public isPegination: any = CONFIG.AG_GRID_CONFIG.pagination.SHOW;
  @Input()
  public pageNumber: number = 1;
  @Input()
  public scrollWidth: number = CONFIG.AG_GRID_CONFIG.SCROLL_WIDTH;
  @Input()
  public paginationPageSizeSelector = CONFIG.AG_GRID_CONFIG.pagination.DROP_DOWN;
  @Input()
  public rowHeight = 43;
  @Input()
  public paginationPageSize = CONFIG.AG_GRID_CONFIG.pagination.PAGE_SIZE;
  @Input()
  public defaultColDef: ColDef = CONFIG.AG_GRID_CONFIG.DEFAULT_COLUMN;
  @Input()
  public loadingCellRenderer: any = TableLoaderComponent;
  @Input()
  public loadingCellRendererParams: any = {
    totalColumn: 8,
    totalRow: 18
  };
  @Input()
  public gridClass: string = '';
  @Input()
  public agDeviceColumnDefs: ColDef[] = [];
  @Input()
  selectedRow: any = [];
  @Input() 
  virtualSideBar:boolean=true

  noRowsOverlayText = 'No Records Found';

  @Output()
  onRowSelectedChange: EventEmitter<any[]> = new EventEmitter();
 

  
  @Output()
  onRowSelect: EventEmitter<any[]> = new EventEmitter();

  @Output()
  onRowDataClick: EventEmitter<any[]> = new EventEmitter();

  @Input()
  public enableOnChange: any = CONFIG.AG_GRID_CONFIG.enableOnChange;

  @ViewChild(SideBarComponent) sideBarComponent!: SideBarComponent;
  @Input()
  public customSidebar:boolean=true;

  // Add an Input for tableKey to uniquely identify each table
  @Input() tableKey: string = ''; 

  public hideTable: string = ' visible-hide';
  
  constructor(private authService: AuthService){

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['data'] &&
      (changes['data'].currentValue != changes['data'].previousValue )) {
        this.grid?.api?.redrawRows();
        this.hideTable = ' visible-hide';
        window.setTimeout(() => {
          this.grid?.api?.sizeColumnsToFit();
          this.hideTable = '';
        }, 10);
      }
      if (this.tableKey) {
        const savedFilterModel = this.authService.getCookie(`agGridFilter_${this.tableKey}`);
        if (savedFilterModel) {
          this.gridApi?.setFilterModel(JSON.parse(savedFilterModel)); // Apply saved filter
        }
      }
  }
  public onColumnVisible = (event: any) => {
     this.hideTable = ' visible-hide';
     this.grid?.api.sizeColumnsToFit();
    // Call onToggleColumn method in SideBarComponent
    const columnField = event.column.getColId();
    const visible = event.visible;
    if (this.sideBarComponent) {
      this.sideBarComponent.onToggleColumn(columnField, { target: { checked: visible } });
    }
    this.hideTable = '';
  };

  public setupToolPanelListeners() {
    this.hideTable = ' visible-hide';
    this.grid?.api.addEventListener('toolPanelVisibleChanged', (event) => {
      this.grid?.api.sizeColumnsToFit();
      this.hideTable = '';
    });
  }
  isDataEmpty: boolean = false;
  public showNoData:boolean=false;
  public onFilterChanged() {
    const rowCount = this.grid?.api.getDisplayedRowCount();
    if (rowCount === 0) {
      this.showNoData=true  //this.grid?.api.showNoRowsOverlay(); // Show overlay when no rows after filtering
      // const headerRows = document.querySelectorAll('.ag-header-row');
      //     headerRows.forEach(headerRow => {
      //     headerRow.classList.add('header-row-z-index');
      //   });
    } else {
      this.showNoData=false;
      this.grid?.api.hideOverlay();
    }
    if (this.tableKey) {
      const filterModel = this.gridApi?.getFilterModel();
      this.authService.setCookie(`agGridFilter_${this.tableKey}`,JSON.stringify(filterModel));
      //this.setCookie(`agGridFilter_${this.tableKey}`, JSON.stringify(filterModel), 7); // Store filter for 7 days
    }
    if(this.grid?.api.paginationGetCurrentPage() > 0) {
      this.grid?.api.paginationGoToPage(0)
    }
    //const rowCount = this.gridApi.getDisplayedRowCount();
        this.isDataEmpty = rowCount === 0;

        // Optionally, refresh grid to apply styles
        this.gridApi.refreshCells();
  }
  get currentColumnDefs() {
    return this.isDataEmpty ? [] : this.agDeviceColumnDefs; // Use your original column definitions
}
  public onSortChanged() {
    if(this.grid?.api.paginationGetCurrentPage() > 0) {
      this.grid?.api.paginationGoToPage(0)
    }
  }
  public onRowSelected() {
    if(this.enableOnChange) {
      this.selectedRow = this.grid?.api.getSelectedRows();
      this.onRowSelectedChange.emit(this.selectedRow);
    }
  }

  public onRowDataSelect(event:any){
    this.onRowDataClick.emit(event);
  }
  public onRowClick(event: any){
    if(!this.enableOnChange) {
      this.selectedRow = this.grid?.api.getSelectedRows();
      this.onRowSelect.emit(event.data);
    }
  }
  public onGridReady(params: GridReadyEvent) {
    params?.api?.addEventListener('columnVisible', this.onColumnVisible.bind(this));
    this.setupToolPanelListeners();

    this.gridApi=params.api;

    //JSON.parse(this.authService.getCookie('newDeviceDate') || '{}');
    if (this.tableKey) {
      const savedFilterModel = this.authService.getCookie(`agGridFilter_${this.tableKey}`);
      if (savedFilterModel) {
        this.gridApi?.setFilterModel(JSON.parse(savedFilterModel)); // Apply saved filter
      }
    }
   
  }
  
  
  public redrow() {
    this.gridApi?.redrawRows();
  }
  public onGridSizeChanged(params: GridSizeChangedEvent) {
    this.hideTable = ' visible-hide';
    window.setTimeout(() => {
      params.api.sizeColumnsToFit();
      this.hideTable = '';
    }, 10);
  }
  
}
