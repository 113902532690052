<div class="ag-center" *ngIf="!isStatusComponent">
    <span class="test vulnerab_label label_dot"  *ngIf="params.data?.status !== 2"
    [ngClass]="params.data?.statusClass">{{params.data?.statusName}}</span>
    <span class="prg-clr" *ngIf="params.value === 2">{{ (params.data.passed_count+params.data.failed_count) / params.data.total_count |  percent:'1.0-0' }} - ({{ params.data.passed_count }} / {{params.data.total_count}} files)
      <mat-icon class="spin">loop</mat-icon>
    </span>
</div>

<div class="ag-center" *ngIf="isStatusComponent">
  <span class="test vulnerab_label label_dot"  
  [ngClass]="params.data?.statusClass">{{params.data?.statusName}}</span>
</div>