import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IDeviceList } from '../device.model';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { ROLES_CONTENT_PROPS } from 'src/app/modules/shared/constants/constant-data';
import * as _ from 'lodash';
import { DeviceManagementComponent } from '../device-management.component';
import { DeviceService } from 'src/app/modules/shared/services/device.service';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  menuListItems: string[] = [];
  DECOMMISSION: string = 'Decommission';
  RECOMMISSION: string = 'Commission';
  COMPAREDSCANS: string = 'Show Config Diff';
  CANCESCHEDULE: string = 'Cancel Schedule';
  CREATESCHEDULE: string = 'Schedule Scan';
  CREDENTIONAL: string = 'Re-scan with different credential';
  RESCAN: string = 'Rescan';
  MAP_CREDENTIONAL: string = 'Map credential';
  DECOMMISIONED_DEVICE: string = 'Decommissioned';
  public openedMenuId: string | null = null;
  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }
  constructor(private authService: AuthService,
    private deviceService: DeviceService,
    private deviceComp: DeviceManagementComponent
  ) {
    this.authService.deviceAction.subscribe(response => {
      //this.refreshScanDetails(notification);
      //console.log("asdfdasf");
      //console.log(response);
      this.menuClick(response.action,response.item);
      
    })

  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setKebabMenuItems();
  }
  isSuccess(item: any): boolean {
    return item?.status === 3;
  }
  menuClick(action: string, item: IDeviceList) {
    switch (action) {
      case this.RESCAN:
        this.deviceComp.deviceScan(item);
        break;
      case this.DECOMMISSION:
      case this.RECOMMISSION:
        this.deviceComp.checkAuthorization(item, action);
        break;

      case this.CREATESCHEDULE:

        this.deviceComp.onScheduleAsset(null, item);
        break;
      case this.CANCESCHEDULE:
        this.deviceComp.cancelMenuItem = item
        this.deviceComp.onCancelSchedule(item)
        break;

      case this.CREDENTIONAL:
        this.deviceComp.rescanWithCredentional(item);
        break;
      case this.MAP_CREDENTIONAL:
        this.deviceComp.MapCredentional(item);
        break;

      case this.COMPAREDSCANS:
        this.deviceComp.deviceDetails(item);
        break;
    }
  }
  setKebabMenuItems() {
    if (this.hasPermission(this.permissionProps.DECOMMISSION_DEVICE)) {
      this.menuListItems.push(this.DECOMMISSION);
    }
    this.menuListItems.push(this.RECOMMISSION);
    this.menuListItems.push(this.COMPAREDSCANS);
    if(this.hasPermission(this.permissionProps.SCHEDULE)){
      this.menuListItems.push(this.CANCESCHEDULE)
      this.menuListItems.push(this.CREATESCHEDULE);
    }
    if (this.hasPermission(this.permissionProps.RESCAN_DEVICE)) {
      this.menuListItems.push(this.RESCAN);
    }
    if (this.hasPermission(this.permissionProps.CREDENTIAL_MANAGER)) {
      this.menuListItems.push(this.CREDENTIONAL);
      this.menuListItems.push(this.MAP_CREDENTIONAL);
    }
  }
  public hasPermission(roleName: string): boolean {
    return !!this.authService.isRoleAccess(roleName);
  }
  onMenuOpen(id: string) {
    this.openedMenuId = id;
  }

  onMenuClose() {
    this.openedMenuId = null;
  }
  valideKebabMenu(device: IDeviceList): boolean {
    const dt: boolean[] = [];
    this.menuListItems.forEach((item) => { dt.push(this.kebabMenuController(item, device)) })
    const unique: boolean[] = _.uniq(dt);
    return unique.length > 0 ? unique.length === 1 ? !unique[0] ? false : true : true : false;
  }
  kebabMenuController(item: string, device: IDeviceList) {
    if (item !== this.RECOMMISSION && device.security_score === this.DECOMMISIONED_DEVICE) {
      return false;
    } else if (item === this.RECOMMISSION && device.security_score !== this.DECOMMISIONED_DEVICE) {
      return false;
    } else if (item === this.RESCAN && (!device.rescan || device.status == 4)) {
      return false;
    } else if (item === this.COMPAREDSCANS && (!device.total)) {
      return false;

    } else if (item === this.CANCESCHEDULE && device.schedule !== 'scheduled') {
      return false;
    } else if (item === this.CREATESCHEDULE && device.schedule !== 'scheduled' && device.schedule != 'schedulable') {
      return false;
    } else if (item === this.CREDENTIONAL && !device.rescan) {
      return false;
    } else if (item === this.MAP_CREDENTIONAL && !device.rescan) {

      return false;
    }
    return true;
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
