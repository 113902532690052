import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IApply, ITreeView } from 'src/app/modules/shared/components/filter/filter.model';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { ScanService } from 'src/app/modules/shared/services/scan.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { WhitelistService } from 'src/app/modules/shared/services/whitelist.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CONSTANT, ROLES_CONTENT_PROPS, ToolTipConfig } from 'src/app/modules/shared/constants/constant-data';
import { ISelectedRowResposnse } from 'src/app/modules/shared/model/scan/scan';
import { IList, ISelectedListInfoDetails } from 'src/app/modules/shared/components/list/list.model';
import * as _ from 'lodash';
import { IBadgeClass, IFilter, IFilterFlags, IFilterSubList, IToastrOptions } from '../whitelist/whitelist.model';
import { MatSort } from '@angular/material/sort';
import { Utils } from 'src/app/modules/shared/utils';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { DynamicChartData } from 'src/app/modules/shared/components/selected-list-details/selected-list-details.model';
import { SortingFilterService } from 'src/app/modules/shared/services/sorting-filter.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { MyScansService } from 'src/app/modules/shared/services/my-scans.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ResponseService } from 'src/app/modules/shared/services/response.service';
import { Observable, Subscription, debounceTime, distinctUntilChanged, filter, fromEvent, switchMap } from 'rxjs';
import { ColDef } from 'ag-grid-community';
import { AgGridComponent } from 'src/app/modules/shared/components/ag-grid/ag-grid.component';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { IDeviceList } from 'src/app/modules/admin/components/device-management/device.model';
import { WhitelistAcceptedComponent } from '../whitelist-accepted/whitelist-accepted.component';
import { CheckboxWhitelistComponent } from 'src/app/modules/shared/components/checkbox-whitelist/checkbox-whitelist.component';
import { CustomheaderCheckboxComponent } from '../customheader-checkbox/customheader-checkbox.component';

@Component({
  selector: 'app-whitelist-details',
  templateUrl: './whitelist-details.component.html',
  styleUrls: ['./whitelist-details.component.scss']
})
export class WhitelistDetailsComponent implements OnInit {
  @ViewChild('searchField') searchField!: ElementRef;
  @Input() public filterList!: IFilterSubList;
  public dataLength: number = 0;
  @ViewChild(MatSort) sort!: MatSort;
  filterColumnData: Map<String, ITreeView[]> = new Map<String, ITreeView[]>();
  selectedRegionId: number | undefined = undefined;
  devicesListBackUp: any[] = [];
  filterApplyFlags: IFilterFlags = {};
  public openedMenuId: string | null = null;
  dataSource = new MatTableDataSource<any>([]);
  dynamicChartData: DynamicChartData[] = [];
  public deviceFailed:any;
  public devicePass:any;
  public acceptedRisk:any;
  isSafari !:boolean
  public acceptedriskReasonForm: FormGroup;
  characterCount: number = 120;
  showDescription: boolean = false;
  showRectification: boolean = false;
  showRationale: boolean = false;
  showIssues: boolean = false;
  showNist:boolean = false;
  public disableTooltip = {
    isFlag: false
  };

  validation_messages = {
    whitelistReason: [
      { type: 'required', message: CONSTANT.WHITELIST_REASON.WHITELIST_REASON_REQUIRED },
      { type: 'minlength', min: 8, message: CONSTANT.WHITELIST_REASON.WHITELIST_REASON_MIN_LENGTH },
      { type: 'maxlength', max: 120, message: CONSTANT.WHITELIST_REASON.WHITELIST_REASON_MAX_LENGTH },
      { type: 'pattern', pattern: /^[^\s][\s\S]*[^\s]$/ , message: CONSTANT.WHITELIST_REASON.WHITELIST_PATTERN }]
  };
  isButtonDisabled!: boolean;
  selection = new SelectionModel<any>(true, []);
  toggleWindows: number = 1; //0 would be no component; 1 would be device Details; 2 would be Device comments; 3 would be filter Section;
  displayedColumns: string[] = ['id', 'device_name', 'vendor', 'devicetype', 'region', 'whitelisted', 'status'];
  vendorItems: IFilter[] = [];
  deviceTypeItems: IFilter[] = [];
  whitelistItems: IFilter[] = [];
  statusItems: IFilter[] = [];
  vulnerabilityType: string = '';
  page: number = 1;
  totalPage: number = 1;
  numberOfContent: number = Math.ceil((window.innerHeight - 49) / 35);
  globalFilter?: ITreeView[] = [];
  selectedRowInfoDetails: ISelectedListInfoDetails | undefined = {};
  selectedParameterInfoDetails:any
  multipleRemediation:any;
  selectedListRow!: any;
  selectedRow!: any;
  parameterDetails!: any;
  allSelected = false;
  isApiCallDone: boolean = false;
  selectedRowForWhiteList: any = [];
  totalPages: number = 1;
  noDataMsg: String = CONSTANT.NO_DATA_MSG;
  isDataAvailableOnFilter: boolean = true;
  isloaderEligible: boolean = true;
  isWhiteListDetailsParamsApiCallDone: boolean = false;
  isFirstLevelFetchBtn: boolean = false;
  deviceListApiCallSubscription!: Subscription;
  public isSpinner: boolean = false;
  public toolTipOption = ToolTipConfig;
  @ViewChild('myButton') myButton!: ElementRef;
  disableOnScroll: boolean = false;
  totalNumberofDevice: number = 0;
  selectedRows: any[] = [];
  unSelectedRows: any[] = [];
  toggleSelectAll: boolean = false;
  state = 0;
  @ViewChild('myGrid') grid!: AgGridComponent;
  initialTableData!: MatTableDataSource<IDeviceList>;
  source: IDeviceList[] = [];
  colDefs: ColDef[] = [
    
    {
      field:'selection',
      headerName:'',
      headerComponent: CustomheaderCheckboxComponent,
      lockPosition: true,  // Assuming the field name for asset in your data
      // pinned: 'left',
      cellRenderer: CheckboxWhitelistComponent,
      minWidth:50,
      maxWidth:50,
      //checkboxSelection:true,
      //headerCheckboxSelection: true, // Adds a checkbox to the header for selecting all rows
    },
    {
      headerName: 'Asset',
      field: 'device_name',
      lockPosition:true,
      // pinned: 'left',
      sortable: true,
      filter: true,
      minWidth:100,
      
    },
    {
      headerName: 'Vendor',
      headerTooltip:'Vendor',
      field: 'vendor',  // Assuming the field name for vendor in your data
      lockPosition:true,
      // pinned: 'left',
      sortable: true,
      filter: true,
      minWidth:100,
      // filterFramework: WhitelistDetailsCustomFilterComponent, // Use the custom filter component
    },
    {
      headerName: 'Asset Type',
      headerTooltip:'Asset Type',
      field: 'device_type',  // Assuming the field name for asset type in your data
      sortable: true,
      filter: true,
      minWidth:130,
      // filterFramework: WhitelistDetailsCustomFilterComponent, // Use the custom filter component

    },
    {
      headerName: 'Region',
      headerTooltip:'Region',
      field: 'region_name',  // Assuming the field name for region in your data
      sortable: true,
      filter: true,
      minWidth:100,
    },
    {
      headerName: 'Accepted Risk',
      headerTooltip:'Accepted Risk',
      field: 'whitelist',  // Assuming the field name for accepted risk in your data
      cellRenderer:WhitelistAcceptedComponent,
      sortable: true,
      filter: true,
      minWidth:150,
      // filterFramework: WhitelistDetailsCustomFilterComponent, // Use the custom filter component
    },
    {
      headerName: 'Is Passed',
      field: 'ispassed', 
      
      filter: false,
      sortable: false,
      minWidth:50,
      
      suppressColumnsToolPanel: true,
      cellRenderer: (params: { value: any; data: any; }) => {
        const isPassed = params.data.passed;
        const statusClass = isPassed ? 'status-success bg-low' : 'status-cancel bg-critical';
        const icon = isPassed ? 'check' : 'close';
        return `
          <div class="${statusClass}" style="display: inline-flex; border-radius: 50%; color: #ffffff;">
            <mat-icon role="img" class="material-icons" style="font-size: 12px; padding: 2px;">${icon}</mat-icon>
          </div>
        `;
      },
    }
  ];

  whiteListParameters=[]
  constructor(
    private filterService: FilterService,
    private spinnerService: SpinnerService,
    public scanService: ScanService,
    public whitelistservice: WhitelistService,
    private fb: FormBuilder,
    private messageService: MessageServiceService,
    private sortingFilterService: SortingFilterService,
    private authService: AuthService,
    private renderer: Renderer2,
    public myScansService: MyScansService,
    private responseService: ResponseService,
    private sharedService: SharedService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    this.acceptedriskReasonForm = this.fb.group({
      whitelistReason: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern(/^[^\s][\s\S]*[^\s]$/),
        Validators.minLength(8),
        Validators.maxLength(120),])),
      rememberMe: new FormControl(false, Validators.requiredTrue),
    });
  }

  ngOnInit(): void {
    this.isSafari = this.detectSafari();
    this.selectedRowForWhiteList = [];
    this.numberOfContent = Math.ceil((window.innerHeight - 49) / 35);
    this.clearCommentBox();
  }
  detectSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
  }
  onMenuOpen(id: string) {
    this.openedMenuId = id;
}

onMenuClose() {
    this.openedMenuId = null;
    this.vendorItems = _.cloneDeep(this.filterList?.filters.vendor) || [];
    this.deviceTypeItems = _.cloneDeep(this.filterList?.filters.device_type) || [];
    this.whitelistItems = _.cloneDeep(this.filterList?.filters.accepted_risk_filter) || [];
    this.statusItems = _.cloneDeep(this.filterList?.filters.device_failed_filter) || [];
}
  public loadWhitelistData(row: any) {
    this.filterApplyFlags = {"device_failed_filter": false, "vendor": false, "device_type": false, "accepted_risk_filter": false }
    this.selectedListRow = row;
    this.allSelected = false;
    this.toggleWindows = 1;
    this.clearCommentBox();
    this.selectedRowForWhiteList = [];
    this.selectedParameterInfoDetails = [];
    this.selectedRowInfoDetails = {};
    this.showDescription = false;
    this.showRectification = false;
    this.showRationale = false;
    this.showIssues = false;
    this.showNist = false;
    this.updateFilterDropdown();
    this.getWhiteListData();
    this.isDataAvailableOnFilter = true;
  }
  public getWhiteListData(isFreshRun: boolean = true): void {
    if(isFreshRun){
      this.dataSource ? this.dataSource.data = [] : this.dataSource;
      this.devicesListBackUp = [];
      this.selectedRowForWhiteList=[];
    }
    this.isApiCallDone = false;
    const cachedData = this.sharedService.getAPIData(CONFIG.PAGE_API.WHITELIST_DETAILS);
    // if (cachedData && cachedData.isDataAvailable) {
    //   const timer = setTimeout(() => {
    //     this.loadWhiteListCallback(cachedData.response);
    //     clearTimeout(timer);
    //   });
    // } else {
    //   // API call with callback if no cached data
    //
    // }
    this.whitelistservice.loadWhitelistDevices(this.selectedListRow?.id, this.loadWhiteListCallback,this.globalFilter);
    if (this.dataSource && (this.dataSource.data?.length === 0)) {
      this.allSelected = false;
    }
  }
  public loadWhiteListCallback = (response: any) => {
    this.spinnerService.hide();
    this.searchField.nativeElement.value = '';
    if (response?.status === 200 && response.data) {
      this.isDataAvailableOnFilter = false;
      response.data.devices.forEach((device: any) => {
        device.ispassed = device.passed ? 'passed' : 'failed';
        device.isSelected = device.whitelist;
    });
    // setTimeout(() => {
    //   this.grid.grid.api.forEachNode((node) => {
    //     if (node.data.whitelist) {
    //       node.setSelected(true);
    //     }
    //   });
    // }, 0);
    this.dataSource.data = response.data.devices;
    let data = this.dataSource?.data.length ? this.dataSource?.data : [];
    data = response.data.devices || [];
      if (this.disableOnScroll && this.toggleSelectAll) {
        data?.forEach((item: any) => {
          item.isSelected = true
        });
      }
      this.devicesListBackUp = _.cloneDeep(response.data.devices);
      if (response?.data?.devices.length) {
        this.selectedDeviceDetails(response.data.devices[0]);
      }
      else {
        this.showNist = true;
      }
    this.dataSource.data = []
    this.dataLength = data.length;
    this.dataSource.data = data;
    if(data.length) {
      this.isAllSelected(this.dataSource.data);
    }
    this.dataSource.sort = this.sort;
      this.initialTableData = this.dataSource;
      this.changeDetectorRefs.detectChanges(); 
      this.isApiCallDone = true; 
      window.setTimeout(() => {
        this.grid.grid.api.sizeColumnsToFit();
      }, 10);
    } else {
      this.isApiCallDone = true; 
    }
  }


    updateCharacterCount(target: any) {
      if(target.value.length <= 120){
        this.characterCount = 120 - target.value.length;
     }
  }
  ngAfterViewInit(): void {
    
  }


  getWhiteListDataResponse = (results: any) => {
    this.spinnerService.hide();
    this.isApiCallDone = true;
    if (results.status === 200 && results.data) {
      this.isDataAvailableOnFilter = false;
      this.totalPages = results.data?.numberofpages || 1;
      this.totalNumberofDevice = results.data?.total_devices_count;
      this.parameterDetails = results.data?.parameter_details;
      results?.data?.devices.forEach((item: any) => {
        item.isSelected = item.whitelist;
      })

      let data = this.dataSource?.data.length ? this.dataSource?.data : [];
      if (data.length) {
        if (results.data) {
          if (this.disableOnScroll && this.toggleSelectAll) {
            results.data.devices?.forEach((item: any) => {
              item.isSelected = true
            });
          }
          data = data.concat(results.data.devices || []);
          this.devicesListBackUp = this.devicesListBackUp.concat(_.cloneDeep(results.data.devices));
        }
      } else {
        data = results.data.devices || [];
        if (this.disableOnScroll && this.toggleSelectAll) {
          data?.forEach((item: any) => {
            item.isSelected = true
          });
        }
        this.devicesListBackUp = _.cloneDeep(results.data.devices);
        if (results?.data?.devices.length) {
          this.selectedDeviceDetails(results.data.devices[0]);
        }
        else {
          this.showNist = true;
        }
      }
      this.dataSource.data = []
      this.dataLength = data.length;
      this.dataSource = new MatTableDataSource<any>(data);

      if(data.length) {
        this.isAllSelected(this.dataSource.data);
      }
      this.selectedRows = this.dataSource.data.filter((obj)=>  obj.isSelected);
      this.dataSource.sort = this.sort;
    }
  }

  public onSort() {
    const sortDirection = this.sort.direction === 'asc' ? 'desc' : 'asc';
    this.dataSource.data.sort((a, b) => {
      return a?.device_name?.localeCompare(b?.device_name) * (sortDirection === 'asc' ? 1 : -1);
    });
    this.dataSource = new MatTableDataSource(this.dataSource.data);
    this.sort.direction = sortDirection;
  }

  public updateFilterDropdown() {
    this.vendorItems = _.cloneDeep(this.filterList?.filters.vendor) || [];
    this.deviceTypeItems = _.cloneDeep(this.filterList?.filters.device_type) || [];
    this.whitelistItems = _.cloneDeep(this.filterList?.filters.accepted_risk_filter) || [];
    this.statusItems = _.cloneDeep(this.filterList?.filters.device_failed_filter) || [];
  }

  closeSidenav() {
    this.myScansService.scrollTop('whitelistDetails');
    this.filterColumnData.clear();
    this.filterService.closeSidenav();
    this.globalFilter = [];
    this.selectedListRow = {};
    this.disableOnScroll = false;
    this.totalNumberofDevice = 0;
    this.page = 1;
    this.toggleSelectAll = false;
    this.characterCount = 120;
    this.state = 0;
    this.isWhiteListDetailsParamsApiCallDone = false;
    this.isApiCallDone = false;
  }

  public openRegionSidebar(): void {
    this.isFirstLevelFetchBtn = false;
    this.toggleWindows = 3;
    if (!this.filterColumnData.get('Region')?.length) {
      this.scanService.loadRegionList(this.loadRegionListCallback);
    } else {
      this.isFirstLevelFetchBtn = true;
    }
  }

  public loadRegionListCallback = (response: any) => {
    this.spinnerService.hide();
    this.isFirstLevelFetchBtn = true;
    if (response?.status === 200 && response.data) {
      this.toggleWindows = 3;
      this.filterColumnData.set('Region', response.data?.regions_list);
    }
  }
  public makeCapital(value: string = ''): string{
    return Utils.makeFirstWordCapital(value)
  }
  public makeFirstLetterCapital(value: string = ''): string{
    return Utils.makeFirstLetterCapital(value)
  }
  public closeComment() {
    this.toggleWindows = 1;
    this.characterCount = 120;
    this.clearCommentBox();
  }

  public closeFilter() {
    this.toggleWindows = 1;
  }

  public selectedFilters(values: IApply) {
    // please do deep copy while using "values.firstLevel"
    this.isDataAvailableOnFilter = true;
    this.toggleWindows = 1;
    this.globalFilter = _.cloneDeep(values.firstLevel);
    this.getWhiteListData();
  }



  getAcceptedRiskData(acceptedrisk: boolean) {
    return acceptedrisk ? "Accepted" : null;
  }

  public selectedDeviceDetails(row: IList) {
    //this.spinnerService.show();
    this.showNist = false;
    this.isWhiteListDetailsParamsApiCallDone = false;
    //this.isApiCallDone = false;
    this.selectedRow = row;
    this.whitelistservice.fetchDeviceDetails(this.selectedListRow, this.selectedDeviceDetailsCallBack);
  }
  public selectedDeviceDetailsCallBack = (response: ISelectedRowResposnse) => {
    this.spinnerService.hide();
    this.isWhiteListDetailsParamsApiCallDone = true;
    //this.isApiCallDone = true;
    if (response && response.status === 200) {
      this.selectedParameterInfoDetails = _.cloneDeep(response.data?.details)
      this.selectedRowInfoDetails = _.cloneDeep(response.data?.details);
      if(this.selectedRowInfoDetails?.benchmark_list){
        this.multipleRemediation = this.selectedRowInfoDetails?.benchmark_list?.length > 0
      }
      this.deviceFailed = this.selectedRowInfoDetails?.device_count?.failed_device_count;
      this.devicePass = this.selectedRowInfoDetails?.device_count?.passed_device_count;
      this.acceptedRisk = this.selectedRowInfoDetails?.device_count?.whitelisted_device_count;
     this.dynamicChartData = [
       {value:this.deviceFailed, name:"Assets Failed", itemStyle:{ color: '#FB4B50'}},
       {value:this.devicePass, name:"Assets Pass",itemStyle:{ color: '#19A71F'}},
       {value:this.acceptedRisk, name:"Accepted Risk",itemStyle:{ color: '#B71E31'}}
     ]
    }
  }
  validateAllChecked(data: any): boolean {
    return data.every((item: any) => item.selected);
  }

  toggleMenuSelection(currentDropdownData: any, selectedItem: any): void {
    if (selectedItem.name?.toLocaleLowerCase() === 'all') {
      if (selectedItem.activated) {
        currentDropdownData.forEach((item: any) => { (item.activated = selectedItem.activated) })
      } else {
        currentDropdownData.forEach((item: any) => { (item.activated = selectedItem.activated) })
      }
    } else {
      let isSelectedAll = true;
      currentDropdownData.forEach((item: any) => {
        if (item.name?.toLocaleLowerCase() !== 'all' && !item.activated) {
          isSelectedAll = false;
        }
      });
      currentDropdownData[0].activated = isSelectedAll;
    }
  }
  onApplyHanlder(type: string): void {
    if (this.dataSource) {
      this.dataSource.data.length = 0;
      let filtered: number = 0;
      this.state = 0;
      this.toggleSelectAll = false;
      this.allSelected = false;
      if (this.filterList && this.filterList?.filters) {
        if (type == 'vendor') {
          this.filterList.filters.vendor = this.vendorItems;
          filtered = Utils.getSelectedItems(this.vendorItems)?.length;

        } else if (type == 'device_type') {
          this.filterList.filters.device_type = this.deviceTypeItems;
          filtered = Utils.getSelectedItems(this.deviceTypeItems)?.length;

        } else if (type == 'accepted_risk_filter') {
          this.filterList.filters.accepted_risk_filter = this.whitelistItems;
          filtered = Utils.getSelectedItems(this.whitelistItems)?.length;

        } else if (type == 'device_failed_filter') {
          this.filterList.filters.device_failed_filter = this.statusItems;
          filtered = Utils.getSelectedItems(this.statusItems)?.length;

        }

      }
      this.filterApplyFlags[type as keyof IFilterFlags] = filtered === 0 ? false : true
    }
    this.page = 1;
    this.isDataAvailableOnFilter = true;
    this.updateAppliedFilter();
    this.getWhiteListData();
  }

  onAcceptedRiskHandler(): void {
    this.toggleWindows = 2;
  }
  public updateAppliedFilter() {
    const filterDetails: any = JSON.parse(this.authService.getCookie('whiteListSub') || '{}');
    filterDetails.filters.vendor = this.filterList?.filters?.vendor;
    filterDetails.filters.device_type = this.filterList?.filters?.device_type;
    filterDetails.filters.accepted_risk_filter = this.filterList?.filters?.accepted_risk_filter;
    filterDetails.filters.device_failed_filter = this.filterList?.filters?.device_failed_filter;
    this.sortingFilterService.setFilter('whiteListSub', filterDetails);
  }
  onApplyAcceptedRisk(): void {
    this.isSpinner = true;
    this.characterCount = 120;
    this.restrictFormAccess(true);
    const acceptedRiskValue = this.acceptedriskReasonForm.get('whitelistReason')?.value.trim();
        let whiteListData = this.selectedRowForWhiteList;
        // this.dataSource.data = this.dataSource.data.filter((d: any) => !(selectedIds.includes(d.id)))
    //     if(this.state === 1) {
    //   whiteListData = this.dataSource.data.filter((obj)=> !obj.isSelected);
    // } else if(this.state === 2) {
    //   whiteListData = this.dataSource.data.filter((obj)=> obj.isSelected);
    // }
    this.whitelistservice.updateWhiteListItem(whiteListData,
      this.parameterDetails,
      acceptedRiskValue,
      this.state,
      this.updateWhiteListItemCallback
    )
    this.acceptedriskReasonForm.controls['rememberMe'].setValue(false);
  }
  public updateWhiteListItemCallback = (response: any) => {
    this.isSpinner = false;
    this.restrictFormAccess(false)
    this.acceptedriskReasonForm.get('whitelistReason')?.reset();
    if (response && response.status === 200) {
      this.toggleWindows = 1;
            this.selection.clear();
      const snakMsg: IToastrOptions = {
        message: response.message
      }
      this.messageService.showSuccess(snakMsg);
      this.selectedRowForWhiteList.forEach((item:any) => {
        const dt: any = this.dataSource.data.find((whitelistItem:any) => whitelistItem.id === item.id);
        if(Object.keys(dt).length > 0){
          dt.whitelist = item.isSelected;
        }
      })
      this.grid.grid.api.refreshCells({ force: true });
      this.selectedRowForWhiteList = [];
      this.devicesListBackUp = _.cloneDeep(this.dataSource.data);
      // this.loadWhitelistData(this.selectedListRow);
      this.selectedDeviceDetails(this.dataSource.data[0]);
      this.state = 0;
      this.toggleSelectAll = false;
      this.whitelistservice.updateDataOnWhiteListUpdate.next(response?.data);
    }
    else{
      // this.selectedRowForWhiteList = [];
      this.clearCommentBox();
    }
  }

  filterData = (event: any) => {
    if (event?.target?.value !== undefined || event?.target?.value !== null) {
      this.isloaderEligible = false;
      this.dataSource.filter = event?.target?.value;
    } else {
      this.isloaderEligible = true;
    }
  };


  toggleAllSelection() {
    if (this.allSelected) {
      this.dataSource?.data.forEach((item: any) => {
        item.isSelected = true
      });
    } else {
      this.dataSource?.data.forEach((item: any) => {
        item.isSelected = false
      });
    }
    this.selectedRows = this.dataSource.data.filter((obj)=>  obj.isSelected);
    this.unSelectedRows = this.dataSource.data.filter((obj)=>  !obj.isSelected);
    this.selectedRowForWhiteList = _.differenceWith(this.dataSource.data, this.devicesListBackUp, _.isEqual);
  }
  selectAll(){
    this.allSelected = true;
    this.toggleSelectAll = true;
    this.state = 1;
    this.toggleAllSelection();
  }
  deselectAll(){
    this.allSelected = false;
    this.toggleSelectAll = false;
    this.state = 2;
    this.toggleAllSelection();
  }
  isAllSelected(selectedItem: any): void {
    let newStatus = true;
    this.dataSource?.data?.forEach((item: any) => {
      if (!item.isSelected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  toggleCheckBox(selectedItem: any): void {
    this.isItemUpdate(selectedItem);
    this.isAllSelected(selectedItem);
    this.unSelectedRows = this.dataSource.data.filter((obj)=>  !obj.isSelected);
    this.selectedRows = this.dataSource.data.filter((obj)=> obj.isSelected);
  }
  public isItemUpdate(item: any) {
    this.selectedRowForWhiteList = _.differenceWith(this.dataSource.data, this.devicesListBackUp, _.isEqual);
  }
  public clearCommentBox() {
    this.acceptedriskReasonForm.reset();
  }
  
severityStatus(type:string){
  return Utils.addBadgeClass(type)
}
  public onScroll = () => {
    if (this.isApiCallDone && (this.page !== this.totalPages)) {
      this.page++;
      if(this.state === 1 || this.state === 2 ) {
        this.disableOnScroll =  true;
      } else {
        this.disableOnScroll =  false;
      }
      this.isDataAvailableOnFilter = false;
      this.isloaderEligible = true;
      this.getWhiteListData(false);
    }
  }

  public validateObjectData(data: any): boolean{
    return Utils.validateObjectData(data);
  }

  isPreloaderEligible(): boolean {
    return this.page !== this.totalPages;
  }
  showFilter(data: any) {
    return this.sortingFilterService.showFilterDropDown(data);
  }
  public hasPermission(roleName: string): boolean {
    return !!this.authService.isRoleAccess(roleName);
  }
  get permissionProps() {
    return ROLES_CONTENT_PROPS;
  }
  restrictFormAccess(flag: boolean): void{
    Utils.restrictFormAccess(flag, this.renderer, this.acceptedriskReasonForm, this.myButton);
  }
  public isAcceptedRiskEnableDisable() {
    let isBtndisable = true;
    const diff = _.differenceWith(this.dataSource.data, this.devicesListBackUp, _.isEqual);
    if((this.state === 0 && this.selectedRowForWhiteList && this.selectedRowForWhiteList.length) || ((this.state === 1 || this.state === 2) && diff.length)) {
      isBtndisable = false;
    }
    return isBtndisable;
  }
  toggleDescription() {
    this.showDescription = !this.showDescription;
  }
  toggleRectification() {
    this.showRectification = !this.showRectification;
  }
  toggleRationale() {
    this.showRationale = !this.showRationale;
  }
  toggleIssues() {
    this.showIssues = !this.showIssues;
  }
  getDescription(): string {
    if (this.selectedRowInfoDetails?.benchmark_list && this.selectedRowInfoDetails?.benchmark_list.length > 0) {
        for (const benchmark of this.selectedRowInfoDetails?.benchmark_list) {
            if (benchmark.description && benchmark.description.trim() !== '') {
                return benchmark.description;
            }
        }
    }
    return '';
}

  getRationale(): string {
    if (this.selectedRowInfoDetails?.benchmark_list && this.selectedRowInfoDetails?.benchmark_list.length > 0) {
      for (const benchmark of this.selectedRowInfoDetails?.benchmark_list) {
        if (benchmark.rationale && benchmark.rationale.trim() !== '') {
          return benchmark.rationale;
        }
      }
    }
    return '';
  }

  getIssue(): string {
    if (this.selectedRowInfoDetails?.benchmark_list && this.selectedRowInfoDetails?.benchmark_list.length > 0) {
      for (const benchmark of this.selectedRowInfoDetails?.benchmark_list) {
        if (benchmark.issues && benchmark.issues.trim() !== '') {
          return benchmark.issues;
        }
      }
    }
    return '';
  }

  hasNonEmptyRemediation(): boolean {
    return this.selectedRowInfoDetails?.benchmark_list?.length
      ? this.selectedRowInfoDetails.benchmark_list.some((benchmark: { remediation: string; }) => benchmark.remediation.trim() !== '')
      : false;
  }

  public onRowSelected(selectedRow: any) {
  this.selectedRow = selectedRow.map((row: any) => {
    return { ...row, isSelected: true };
  });
  this.selectedRowForWhiteList = this.selectedRow;
  }
  onRowSelectedChange(event: any): void {
    this.selectedRows = this.grid.grid.api.getSelectedRows();
    this.updateAcceptRiskButtonState();
  }
  updateAcceptRiskButtonState(): void {
    this.isButtonDisabled = this.selectedRows.length === 0;
  }
  filterAssetsField() {
    this.grid.grid.api.setGridOption(
      "quickFilterText",
       this.searchField?.nativeElement?.value
    );
  }
}
