import { environment } from "src/environments/environment"

export const END_POINTS = {
    AUTH:                   environment.baseURL + 'secure-auth/authentication',
    LOGIN:                  environment.baseURL + 'secure-auth/login',
    REFRESH_TOKEN:          environment.baseURL + 'secure-auth/token',
    LOGOUT:                 environment.baseURL + 'secure-auth/logout',
    REGISTER:               environment.baseURL + 'secure-auth/user-registration',
    ORG_DETAILS:            environment.baseURL + 'authentication/org-details',
    EDIT_ORG:               environment.baseURL + 'authentication/edit-org',
    CHANGE_PASSWORD:        environment.baseURL + 'secure-auth/change-password',
    UPDATED_PASSWORD:       environment.baseURL + 'secure-auth/password/change',
    RESET:                  environment.baseURL + 'authentication/forgot-password',
    USER_DETAILS:           environment.baseURL + 'authentication/user-details',
    EDIT_USER:              environment.baseURL + 'authentication/edit-user',
    DELETE_ORG:              environment.baseURL + 'authentication/org-data-delete',
    EDIT_ORG_IMAGE:         environment.baseURL + 'authentication/org-pic-upload',
    EDIT_USER_IMAGE:        environment.baseURL + 'authentication/user-pic-upload',
    GET_ORG_IMAGE:    environment.baseURL + 'authentication/org-image',
    GET_USER_IMAGE:    environment.baseURL + 'authentication/user-image',
    ROLES_PERMISSIONS:      environment.baseURL + 'authentication/roles-and-permissions',
    SCANS:                  environment.baseURL + 'securityscan/scans',
    SCAN_SUMMARY_NEW:       environment.baseURL + 'securityscan/scan-summary',
    SCAN_SUMMARY_DEVICE_NEW:environment.baseURL + 'securityscan/device-summary',
    SCAN_SUMMARY_DETAIL:    environment.baseURL + 'securityscan/scan-summary-details',
    SCAN_SUMMARY_DEVICE_DETAIL: environment.baseURL + 'securityscan/device-details',
    VULNERABILITY_LIST:     environment.baseURL + 'securityscan/vulnerable-values',
    BENCHMARK_LIST:         environment.baseURL + 'securityscan/benchmark-values',
    SCAN_SUMMARY:           environment.baseURL + 'securityscan/scan-summary-output',
    SCAN_SUMMARY_DEVICE:    environment.baseURL + 'securityscan/scan-device-summary',
    SCAN_STATUS:            environment.baseURL + 'securityscan/scan-status',
    FILE_UPLOAD:            environment.baseURL + 'securityscan/file-upload',
    FILE_UPLOAD_TEMP:       environment.baseURL + 'securityscan/file-chunks',
    FILE_UPLOAD_PROCESS:    environment.baseURL + 'securityscan/process-uploads',
    FILE_DOWNLOAD:          environment.baseURL + 'securityscan/sample-file-download',
    SSH_FIELD:              environment.baseURL + 'secure-scan/new-scan',
    SSH_UPLOAD:             environment.baseURL + 'securityscan/new-multi-scan',
    SCAN_HISTORY_BAR_CHART: environment.baseURL + 'securityscan/scan-history',
    SCAN_HISTORY_STACKED_BAR_CHART: environment.baseURL + 'securityscan/stack-scan-history',
    FILTER_DEVICE_LIST:     environment.baseURL +  'securityscan/devices-dropdown-list',
    DEVICE_MANAGEMENT_LIST: environment.baseURL + 'securityscan/device-list',
    SCHEDULE_LIST:          environment.baseURL + 'securityscan/scheduled-myscans',
    SCHEDULE_ASSET_POPUP:   environment.baseURL + 'securityscan/schedule-asset-popup',
    SCHEDULE_ORG_DEVIC_LIST:environment.baseURL + 'securityscan/scheduled-assets',
                                                  
    UPDATE_DEVICE:          environment.baseURL + 'securityscan/device-region',
    SCAN_LIST_ROW_DETAILS:  environment.baseURL + 'securityscan/pdlist',
    WHITE_LIST:             environment.baseURL + 'securityscan/white-list',
    DASHBOARD_DURATION_FILTER:environment.baseURL + 'securityscan/duration-dropdown',
    DASHBOARD_DEVICE_FILTER:environment.baseURL + 'securityscan/dashboard-devices-dropdown',
    DASHBOARD_COMMON_VUL_FILTER:environment.baseURL + 'securityscan/dashboard-commonvul-dropdown',
    DASHBOARD_DATA:         environment.baseURL + 'securityscan/dashboard-data',
    DASHBOARD_SCORE_DATA:         environment.baseURL + 'securityscan/dashboard-score-data',
    DASHBOARD_WHITELIST_DATA:         environment.baseURL + 'securityscan/dashboard-whitelist-data',
    DASHBOARD_NO_DEVICE_SCAN:environment.baseURL + 'securityscan/dashboard-devicesnotscaned',
    SECURITY_SCORE:         environment.baseURL + 'securityscan/dashboard-security-score',
    CATEGORY_WISE:          environment.baseURL + 'securityscan/dashboard-category',
    DASHBOARD_VULNARABILITY:environment.baseURL + 'securityscan/dashboard-vulnerabilities',
    CYBER_RESILIENCE:       environment.baseURL + 'securityscan/cyber-resilience-trend',
    CYBER_RESILIENCE_DASHBOARD: environment.baseURL + 'securityscan/dashboard-cyber-resilience-trend',
    DASHBOARD_COMMONVUL:    environment.baseURL + 'securityscan/dashboard-commonvul',
    DAILY_EVENT:            environment.baseURL + 'securityscan/daily-event-data',
    DASHBOARD_REGION_WISE:  environment.baseURL + 'securityscan/dashboard-regionwise',
    DASHBOARD_REGION_DROPDOWN:  environment.baseURL + 'securityscan/dashboard-region-dropdown',
    DASHBOARD_DEVICE_WITH_SCORE:  environment.baseURL + 'securityscan/dashboard-devices',
    SECURITY_INFORMATION_LIST:  environment.baseURL + 'securityscan/security-information-params',
    SECURITY_INFORMATION_SELECTED_DETAIL:  environment.baseURL + 'securityscan/params-security-info',
    SECURITY_INFORMATION_SELECTED_ASSETS_LIST:  environment.baseURL + 'securityscan/param-device-list',

    SCAN_DEVICE_LIST:       environment.baseURL + 'securityscan/scan-device-list',
    SCAN_DETAILS:           environment.baseURL + 'scan-details',
    SCAN_SCHEDULE:          environment.baseURL+ 'securityscan/schedule-scan',
    CANCEL_SCHEDULE:        environment.baseURL + '/securityscan/cancel-schedule',
    WHITELIST_PARAMTER:    environment.baseURL + 'white-list-parameter',
    NEW_SCAN_DATA:          environment.baseURL + 'uploadDisplay',
    ACTIVATE_REGISTRATION:  environment.baseURL + 'authentication/user-registration',
    RESET_PASS_TOKEN_API:   environment.baseURL + 'authentication/token-validation',
    INVITE_SUPER_ADMIN:     environment.baseURL + 'authentication/org-registration',
    USER_PERMISSION:        environment.baseURL + 'authentication/user-permissions',
    SEARCH_ORG_USERS:       environment.baseURL + 'authentication/search-org-users',
    ORG_LIST:               environment.baseURL + 'authentication/parent-org',
    SSO_MASTER:             environment.baseURL + '/authentication/sso-master',
    SSO_ORG:                environment.baseURL + '/authentication/sso-org',
    PROFILE_SETUP_GET_LEVELS: environment.baseURL + 'authentication/list-region-labels',
    PROFILE_SETUP_SET_LEVELS: environment.baseURL + 'authentication/region-labels',
    PROFILE_SETUP_LIST_DATA: environment.baseURL + 'authentication/list-region-data',
    PROFILE_SETUP_REGION_DATA: environment.baseURL + 'authentication/region-data',
    ORG_ROLES:              environment.baseURL + 'authentication/org-roles',
    ORG_ROLE_DETAILS:       environment.baseURL + 'authentication/roles',
    ORG_CREATE_UPDATE_ROLE: environment.baseURL + 'authentication/role-permissions',
    DELETE_ROLE:            environment.baseURL + 'authentication/delete-role',
    RENAME_SCAN:            environment.baseURL + 'securityscan/rename-scan',
    RENAME_ROLE:            environment.baseURL + 'authentication/role-name',
    CANCEL_RESCAN:          environment.baseURL + 'securityscan/cancel-rescan',
    RESCAN:                 environment.baseURL + 'securityscan/rescan',
    USER_MANAGEMENT_LIST:   environment.baseURL + 'authentication/org-users',
    UPDATE_USER_ROLE:       environment.baseURL + 'authentication/update-user-role',
    INVITE_USER:            environment.baseURL + 'authentication/invite-users',
    ACTIVATE_DEACTIVATE_USER:environment.baseURL + 'authentication/user-activation',
    DELETE_USER:            environment.baseURL + 'authentication/delete-user',
    UPDATED_USER:           environment.baseURL + 'authentication/update-org-user',
    REGION_LIST:            environment.baseURL + 'authentication/drop-down-region',
    ORG_SETUP_DETAILS:      environment.baseURL + 'authentication/org-modification',
    ADD_ASSETS:             environment.baseURL + 'authentication/save-filters',
    SAVE_DASHBOARD_FILTER:  environment.baseURL + 'authentication/save-filters',
    GLOBAL_REGION:          environment.baseURL + 'authentication/global-regions',
    GLOBAL_DASHBOARD_REGION:environment.baseURL + 'authentication/global-filter-region',
    GLOBAL_DASHBOARD_L_REGION:environment.baseURL + 'authentication/global-filter-Ldata',
    GLOBAL_DASHBOARD_L_DEVICE:environment.baseURL + 'securityscan/global-filter-device-list',
    GLOBAL_DASHBOARD_DEVICE:environment.baseURL + 'securityscan/global-filter-device',
    GLOBAL_DEVICE:          environment.baseURL + 'securityscan/global-devices-filter',
    GLOBAL_REGION_DEVICE: environment.baseURL + 'securityscan/saving-data',
    DEVICE_VUL: environment.baseURL + 'securityscan/device-management-vul',
    DEVICE_POPUP: environment.baseURL + 'securityscan/device-management-device',
    API_FIELD:              environment.baseURL + '/secure-scan/new-scan',
    RESEND_EMAIL:           environment.baseURL + 'authentication/resend-email',
    CONFIRM_DECOMMISSION_PASSWORD: environment.baseURL + 'authentication/decommission-auth',
    DECOMMISION_DEVICE: environment.baseURL + '/securityscan/decommission-recommission',
    SECURITY_DEVICE_PARAMS: environment.baseURL + '/securityscan/device-parameter-scan-log',
    DEVICE_CYBER_RESILIENCE_SCAN_DATES:environment.baseURL + 'securityscan/scaned-device-dates',
    DEVICE_PERAMETER:       environment.baseURL + 'securityscan/scaned-device-parameters',
    DEVICE_COMPARE:         environment.baseURL + 'securityscan/config-file-differ',
    DEVICE_COMPARE_RIGHT:   environment.baseURL + 'securityscan/config-new-file-differ',
    WHITELIST_GRAPHS: environment.baseURL + 'securityscan/whitelist-graphs',
    WHITELIST_HISTORY: environment.baseURL+ 'securityscan/whitelist-history',
    WHITELIST_PARAMETER: environment.baseURL+'securityscan/whitelisted-params',
    WHITELIST_DEVICES: environment.baseURL+'securityscan/whitelisted-devices',
    SECURITY_INFO_FILTER: environment.baseURL+'securityscan/security_info_filters',
    WHITELIST_MAIN_FILTER: environment.baseURL+'securityscan/whitelist_main_filters',
    WHITELIST_SUB_FILTER: environment.baseURL+'securityscan/whitelist_sub_filters',
    CVE_FILTER: environment.baseURL+'securityscan/cve/cve-dropdown',
    ASSETS_CVE_FILTER: environment.baseURL+'securityscan/cve/af_list_filter_get',
    WHITELIST_HISTORY_DETAILS: environment.baseURL+'securityscan/whitelist_history_details',
    DEVICE_MANAGEMENT_FILTER: environment.baseURL+'securityscan/device_management_filters',
    GET_DECOMMISSIONED_DEVICE: environment.baseURL+ 'securityscan/decommissioned-devices',
    DELETE_DEVICE: environment.baseURL+'securityscan/delete-device',
    SEARCH: environment.baseURL+'scansearch/search',
    DEVICE_MANAGEMENT_RESCAN: environment.baseURL + 'securityscan/devices-list-od',
    DEVICE_DROPDOWN_LIST: environment.baseURL+'securityscan/compare-device-list',
    AFFECTED_DEVICE: environment.baseURL+'securityscan/cve/affected_assets',
    VENDOR_SPECIFIC_VULNERABILITY: environment.baseURL+'securityscan/cve/vs_vulnerability',
    CVE_DATA: environment.baseURL+'securityscan/cve/search_cve',
    CVE_DETAILS: environment.baseURL+'securityscan/cve/cve_summary',
    CVE_VULNERABILITY: environment.baseURL+'securityscan/cve/vulnerability',
    CVE_ASSETS_LIST: environment.baseURL+'securityscan/cve/af_list',
    PASSWORD_VALIDATION: environment.baseURL+'secure-auth/password-validation',
    AUTHENTICATE_SINGLE_SIGNIN: environment.baseURL+'authentication/sso',
    SSO_LOGIN: environment.baseURL+ 'secure-auth/sso-login',
    VENDOR_LIST: environment.baseURL+'securityscan/vendors-list',
    SCHEDULE_FREQUENCY: environment.baseURL+'securityscan/schedule-frequency',
    ALERT_NOTIFICATION: environment.baseURL + 'events-notifications/bell-notification-count',
    ALERT_NOTIFICATION_DATA: environment.baseURL + 'events-notifications/notifications',
    READ_NOTIFICATION: environment.baseURL + 'events-notifications/read-notification',
    MUTE_NOTIFICATION:  environment.baseURL + 'authentication/mute-notification',
    Asset_SUMMARY_DATA: environment.baseURL + 'securityscan/asset-summary-data',
    Asset_SUMMARY_REPORT: environment.baseURL + 'securityscan/asset-summary-report',
    SCAN_USER_LIST: environment.baseURL + 'securityscan/scan-user-search',
    DEVICE_COUNT: environment.baseURL + 'securityscan/device-count',
    CREDENTIAL_TYPES: environment.baseURL + 'securityscan/credential-types',
    CREDENTIAL_LIST: environment.baseURL + 'securityscan/asset-credentials',
    
    SCHEDULE_INFO: environment.baseURL + 'securityscan/schedule-info',
    MYSCANS_SCHEDULE_FILTER: environment.baseURL + 'securityscan/schedule-dropdown',
    IS_NEW_USER: environment.baseURL + 'securityscan/device-count',
    RE_SCAN_CRED: environment.baseURL + 'securityscan/rescan-with-cred',
    MAP_CRED: environment.baseURL + 'securityscan/asset-credential-mapping',
    //New points for AG grid
    AG_DEVICE_MANAGEMENT_LIST: environment.baseURL + 'securityscan/device-list',
    NEW_GRAPH: environment.baseURL + 'securityscan/asset-vulnerabilities',
    NEW_CVE: environment.baseURL + 'securityscan/dashboard-cve-vulnerabilities',
    CREATE_UPDATE_VPN: environment.baseURL + 'securityscan/vpn',
    TEST_VPN:environment.baseURL + 'securityscan/test-vpn',
    CONNECTION_STATUS:environment.baseURL + 'securityscan/connection-status',
    Asset_Compliance_Report: environment.baseURL + 'securityscan/asset-compliance-data',
    CYBER_EXCEL_REPORT: environment.baseURL + 'securityscan/reports/cyber-resilience-trend',
    CREATE_JIRA_INTEGRATION: environment.baseURL + 'jira-events/jira_integration_create',
    JIRA_PROJECT_LIST: environment.baseURL + 'jira-events/project-list',
    JIRA_PROJECT_STATUSES: environment.baseURL +'jira-events/get_project_statuses',
    JIRA_PROJECT_PROPERTIES: environment.baseURL +'/jira-events/get_project_priorities',
    JIRA_INTEGRATION_UPDATE: environment.baseURL +'/jira-events/jira_integration_update',
    JIRA_INTEGRATION_ORG: environment.baseURL +'jira-events/jira_integration',
    JIRA_PROJECT_ASSIGNALBE: environment.baseURL +'jira-events/get_project_assignable_users',
    JIRA_CREATE_TICKET: environment.baseURL +'jira-events/create-ticket',
    JIRA__ASSIGNE_TICKET: environment.baseURL + 'jira-events/get_project_assignable_users'
    


}
    

