<div class="cyber-popup">
    <div class="close-btn">
        <button mat-button class="btn icon-btn" [mat-dialog-close]="true"><mat-icon>cancel</mat-icon></button>
    </div>
    <div class="spark-whitebox spark-whiteboxshadow">
        <div id="zoom-container">
            <app-cyber-reilience #cyberReilCmp [title]="title" [isCompareAvailable]="true"
                    [apiName]="apiName" [showFilter]="true"
                    [isZoomOn]="true"
                    [filterPage]="'dashboardCyber'" [page]="'cyberzoom'" [cyberzoomResponse]="data.data.response"  [cookiesName]="'dataZoomValue'">
                </app-cyber-reilience>
            <!-- The chart DOM will be appended here dynamically -->
        </div>
    </div>
</div>