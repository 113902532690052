import { Component } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FilterService } from 'src/app/modules/shared/services/filter.service';
import { ICloudBenchmark } from '../../cloud-security';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { OverlayResourceTypeComponent } from './overlay-resource-type/overlay-resource-type.component';
import { OverlayComplainceComponent } from './overlay-complaince/overlay-complaince.component';
import { OverlayAccptedRiskComponent } from './overlay-accpted-risk/overlay-accpted-risk.component';
import { Subscription } from 'rxjs';
import { CloudTabsService } from '../../Services/cloud-tabs.service';
import { CloudSecurityService } from '../../Services/cloud-security.service';

@Component({
  selector: 'app-cloud-security-overlay',
  templateUrl: './cloud-security-overlay.component.html',
  styleUrls: ['./cloud-security-overlay.component.scss']
})
export class CloudSecurityOverlayComponent {
//cis json 
isApiCallDone:boolean=false;
rowHeight:number=40;
public resourceSubscribe!: Subscription;
public resourceData:boolean = false;
public hideTab: boolean = false;
public selectedReviews: any = [];
public currentPage = 1
public pageSize = 2;
public selectedTabIndex = 0;
public get paginatedItems(): any {
  const start = (this.currentPage - 1) * this.pageSize;
  return this.reviewDetails.reviewList.slice(start, start + this.pageSize);
}
public get totalPages(): number {
  return Math.ceil(this.reviewDetails.reviewList.length / this.pageSize);
}
public lastReviewDetails = {
  finalStatus: {
    status: true
  },
  reviewList: [
    {
    reviewName: 'Table ID 1',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 2',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: true
  }
]
}
public reviewDetails = {
  finalStatus: {
    status: true
  },
  reviewList: [
    {
    reviewName: 'Table ID 1',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 2',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: true
  },
  {
    reviewName: 'Table ID 3',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 4',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 5',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 6',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 7',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 8',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 9',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 10',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  },
  {
    reviewName: 'Table ID 11',
    list:[
      'Route 1','Route 2', 'Route 3'
    ],
    status: false
  }
]
}
public cisJson = {
  "cisVersions": [
    {
      "cisVersion": "CIS Version 8",
      "cisHeader": "17.2 Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam.",
      "cisData": "Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam pharetra. Nibh eget maecenas diam feugiat sit lacinia bibendum rhoncus. Cursus rhoncus at condimentum id ut cursus suscipit. Vestibulum eu diam faucibus ipsum elit orci pellentesque quis vel."
    },
    {
      "cisVersion": "CIS Version 7",
      "cisHeader": "17.3 Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam.",
      "cisData": "Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam pharetra. Nibh eget maecenas diam feugiat sit lacinia bibendum rhoncus. Cursus rhoncus at condimentum id ut cursus suscipit. Vestibulum eu diam faucibus ipsum elit orci pellentesque quis vel."
    },
    {
      "cisVersion": "CIS Version 6",
      "cisHeader": "17.4 Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam.",
      "cisData": "Lorem ipsum dolor sit amet consectetur. Eget metus adipiscing magna quam pharetra. Nibh eget maecenas diam feugiat sit lacinia bibendum rhoncus. Cursus rhoncus at condimentum id ut cursus suscipit. Vestibulum eu diam faucibus ipsum elit orci pellentesque quis vel."
    }
  ]
}


  showBenchamrks = [
    {
      type:'cis',
      image_src:'../../../../../assets/images/cis_overlay.svg'
    },
    {
      type:'nist',
      image_src:'../../../../../assets/images/nist_overlay.svg'
    },
    {
     type:'hippa',
     image_src:'../../../../../assets/images/hippa_overlay.svg'
    },
    {
      type:'pci',
      image_src:'../../../../../assets/images/pci_overlay.svg'
    },
    {
      type:'mitre',
      image_src:'../../../../../assets/images/mitre_overlay.svg'
    }
  ]
  showBenchmarkContainer = ['cis','nist','hippa','pci','mitre'];
  selectedBenchmark:string= this.showBenchamrks[0].type;
  constructor(private filterService: FilterService,public cloudTabsService:CloudTabsService,
      private cloudSecurityService: CloudSecurityService
  )
  {
    console.log('loaded');
    this.filterService.sidenavCloseEvent.subscribe(()=>{
      if(this.resourceSubscribe) {
        this.resourceSubscribe.unsubscribe();
      }
    })
    this.resourceSubscribe = this.cloudTabsService.resourceRow.subscribe((data:any)=>{
      console.log(data,'hi I am data');
        if(data){ 
          this.resourceData = true;
          this.hideTab = true;
          this.cloudSecurityService.getReviewDetails(this.getReviewDetailsCallBack, data)
        }
     });
  }

  public goBack(){
    this.resourceData = false;
    this.hideTab = false; 
  }

  public closeSidenav() {
    this.filterService.closeSidenav();
  }
    onTabChange(event: MatTabChangeEvent) {

    }
    logoclick(event:ICloudBenchmark){
     console.log('clicked', event); 
     this.selectedBenchmark = event.type || '';     
    }
    
    public getReviewDetailsCallBack = (response: any)=>  {
      if(response) {

      } else {

      }
    }
    
  
    public changePage(page: number): void {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    }
  
    public nextPage(): void {
      if (this.currentPage < this.totalPages) {
        this.changePage(this.currentPage + 1);
      }
    }
  
    public prevPage(): void {
      if (this.currentPage > 1) {
        this.changePage(this.currentPage - 1);
      }
    }
    public selectReview(evt: any, currentReview: any){
      currentReview.isSelected = evt.checked;
      if(evt.checked) {
        if(this.selectedReviews.length) {
          const isReviewExist = this.selectedReviews.findIndex((rev: any)=>{
            return rev.reviewName === currentReview.reviewName;
          })
          if(isReviewExist === -1) {
            this.selectedReviews.push(currentReview);
          }
        } else {
          this.selectedReviews.push(currentReview);
        }
      } else {
        if(this.selectedReviews.length) {
          const isReviewExist = this.selectedReviews.findIndex((rev: any)=>{
            return rev.reviewName === currentReview.reviewName;
          })
          if(isReviewExist > -1) {
            this.selectedReviews.splice(isReviewExist, 1);
          }
        }
      }
    }
    public submitReview() {
      this.cloudSecurityService.addLastReview(this.addLastReviewDetailsCallBack, this.selectedReviews)
    }
    public addLastReviewDetailsCallBack = (response: any)=>  {
      if(response.status === 200) {

      } else {
        this.selectedTabIndex = 1;
        this.cloudSecurityService.fetchLastReview(this.fetchLastReviewDetailsCallBack)
      }
    }
    public fetchLastReviewDetailsCallBack = (response: any)=>  {
      if(response.status === 200) {

      } else {
        
      }
    }
  }

